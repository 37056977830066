/*!
 * Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2023 Fonticons, Inc.
 */
/*!
 * Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2023 Fonticons, Inc.
 */
:root, [data-bs-theme="light"] {
  --tblr-blue: #066fd1;
  --tblr-indigo: #4263eb;
  --tblr-purple: #ae3ec9;
  --tblr-pink: #d6336c;
  --tblr-red: #d63939;
  --tblr-orange: #f76707;
  --tblr-yellow: #f59f00;
  --tblr-green: #2fb344;
  --tblr-teal: #0ca678;
  --tblr-cyan: #17a2b8;
  --tblr-black: #000;
  --tblr-white: #fff;
  --tblr-gray: #49566c;
  --tblr-gray-dark: #182433;
  --tblr-gray-100: #eef3f6;
  --tblr-gray-200: #dce1e7;
  --tblr-gray-300: #b8c4d4;
  --tblr-gray-400: #8a97ab;
  --tblr-gray-500: #6c7a91;
  --tblr-gray-600: #49566c;
  --tblr-gray-700: #3a4859;
  --tblr-gray-800: #182433;
  --tblr-gray-900: #040a11;
  --tblr-primary: #066fd1;
  --tblr-secondary: #6c7a91;
  --tblr-success: #2fb344;
  --tblr-info: #4299e1;
  --tblr-warning: #f76707;
  --tblr-danger: #d63939;
  --tblr-light: #f6f8fb;
  --tblr-dark: #182433;
  --tblr-muted: #6c7a91;
  --tblr-azure: #4299e1;
  --tblr-lime: #74b816;
  --tblr-x: #000;
  --tblr-facebook: #1877f2;
  --tblr-twitter: #1da1f2;
  --tblr-linkedin: #0a66c2;
  --tblr-google: #dc4e41;
  --tblr-youtube: red;
  --tblr-vimeo: #1ab7ea;
  --tblr-dribbble: #ea4c89;
  --tblr-github: #181717;
  --tblr-instagram: #e4405f;
  --tblr-pinterest: #bd081c;
  --tblr-vk: #6383a8;
  --tblr-rss: orange;
  --tblr-flickr: #0063dc;
  --tblr-bitbucket: #0052cc;
  --tblr-tabler: #066fd1;
  --tblr-primary-rgb: 6, 111, 209;
  --tblr-secondary-rgb: 108, 122, 145;
  --tblr-success-rgb: 47, 179, 68;
  --tblr-info-rgb: 66, 153, 225;
  --tblr-warning-rgb: 247, 103, 7;
  --tblr-danger-rgb: 214, 57, 57;
  --tblr-light-rgb: 246, 248, 251;
  --tblr-dark-rgb: 24, 36, 51;
  --tblr-muted-rgb: 108, 122, 145;
  --tblr-blue-rgb: 6, 111, 209;
  --tblr-azure-rgb: 66, 153, 225;
  --tblr-indigo-rgb: 66, 99, 235;
  --tblr-purple-rgb: 174, 62, 201;
  --tblr-pink-rgb: 214, 51, 108;
  --tblr-red-rgb: 214, 57, 57;
  --tblr-orange-rgb: 247, 103, 7;
  --tblr-yellow-rgb: 245, 159, 0;
  --tblr-lime-rgb: 116, 184, 22;
  --tblr-green-rgb: 47, 179, 68;
  --tblr-teal-rgb: 12, 166, 120;
  --tblr-cyan-rgb: 23, 162, 184;
  --tblr-x-rgb: 0, 0, 0;
  --tblr-facebook-rgb: 24, 119, 242;
  --tblr-twitter-rgb: 29, 161, 242;
  --tblr-linkedin-rgb: 10, 102, 194;
  --tblr-google-rgb: 220, 78, 65;
  --tblr-youtube-rgb: 255, 0, 0;
  --tblr-vimeo-rgb: 26, 183, 234;
  --tblr-dribbble-rgb: 234, 76, 137;
  --tblr-github-rgb: 24, 23, 23;
  --tblr-instagram-rgb: 228, 64, 95;
  --tblr-pinterest-rgb: 189, 8, 28;
  --tblr-vk-rgb: 99, 131, 168;
  --tblr-rss-rgb: 255, 165, 0;
  --tblr-flickr-rgb: 0, 99, 220;
  --tblr-bitbucket-rgb: 0, 82, 204;
  --tblr-tabler-rgb: 6, 111, 209;
  --tblr-primary-text-emphasis: #022c54;
  --tblr-secondary-text-emphasis: #2b313a;
  --tblr-success-text-emphasis: #13481b;
  --tblr-info-text-emphasis: #1a3d5a;
  --tblr-warning-text-emphasis: #632903;
  --tblr-danger-text-emphasis: #561717;
  --tblr-light-text-emphasis: #3a4859;
  --tblr-dark-text-emphasis: #3a4859;
  --tblr-primary-bg-subtle: #cde2f6;
  --tblr-secondary-bg-subtle: #e2e4e9;
  --tblr-success-bg-subtle: #d5f0da;
  --tblr-info-bg-subtle: #d9ebf9;
  --tblr-warning-bg-subtle: #fde1cd;
  --tblr-danger-bg-subtle: #f7d7d7;
  --tblr-light-bg-subtle: #f7f9fb;
  --tblr-dark-bg-subtle: #8a97ab;
  --tblr-primary-border-subtle: #9bc5ed;
  --tblr-secondary-border-subtle: #c4cad3;
  --tblr-success-border-subtle: #ace1b4;
  --tblr-info-border-subtle: #b3d6f3;
  --tblr-warning-border-subtle: #fcc29c;
  --tblr-danger-border-subtle: #efb0b0;
  --tblr-light-border-subtle: #dce1e7;
  --tblr-dark-border-subtle: #6c7a91;
  --tblr-white-rgb: 255, 255, 255;
  --tblr-black-rgb: 0, 0, 0;
  --tblr-font-sans-serif: "Inter Var", Inter, -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif;
  --tblr-font-monospace: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  --tblr-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --tblr-body-font-family: var(--tblr-font-sans-serif);
  --tblr-body-font-size: .875rem;
  --tblr-body-font-weight: 400;
  --tblr-body-line-height: 1.42857;
  --tblr-body-color: #182433;
  --tblr-body-color-rgb: 24, 36, 51;
  --tblr-body-bg: #f6f8fb;
  --tblr-body-bg-rgb: 246, 248, 251;
  --tblr-emphasis-color: #3a4859;
  --tblr-emphasis-color-rgb: 58, 72, 89;
  --tblr-secondary-color: #182433bf;
  --tblr-secondary-color-rgb: 24, 36, 51;
  --tblr-secondary-bg: #dce1e7;
  --tblr-secondary-bg-rgb: 220, 225, 231;
  --tblr-tertiary-color: #18243380;
  --tblr-tertiary-color-rgb: 24, 36, 51;
  --tblr-tertiary-bg: #eef3f6;
  --tblr-tertiary-bg-rgb: 238, 243, 246;
  --tblr-heading-color: inherit;
  --tblr-link-color: #066fd1;
  --tblr-link-color-rgb: 6, 111, 209;
  --tblr-link-decoration: none;
  --tblr-link-hover-color: #0559a7;
  --tblr-link-hover-color-rgb: 5, 89, 167;
  --tblr-link-hover-decoration: underline;
  --tblr-code-color: var(--tblr-gray-600);
  --tblr-highlight-color: #182433;
  --tblr-highlight-bg: #fdeccc;
  --tblr-border-width: 1px;
  --tblr-border-style: solid;
  --tblr-border-color: #dce1e7;
  --tblr-border-color-translucent: #0420451a;
  --tblr-border-radius: 4px;
  --tblr-border-radius-sm: 2px;
  --tblr-border-radius-lg: 8px;
  --tblr-border-radius-xl: 1rem;
  --tblr-border-radius-xxl: 2rem;
  --tblr-border-radius-2xl: var(--tblr-border-radius-xxl);
  --tblr-border-radius-pill: 100rem;
  --tblr-box-shadow: rgba(var(--tblr-body-color-rgb), .04) 0 2px 4px 0;
  --tblr-box-shadow-sm: 0 .125rem .25rem #00000013;
  --tblr-box-shadow-lg: 0 1rem 3rem #0000002d;
  --tblr-box-shadow-inset: 0 0 transparent;
  --tblr-focus-ring-width: .25rem;
  --tblr-focus-ring-opacity: .25;
  --tblr-focus-ring-color: rgba(var(--tblr-primary-rgb), .25);
  --tblr-form-valid-color: #2fb344;
  --tblr-form-valid-border-color: #2fb344;
  --tblr-form-invalid-color: #d63939;
  --tblr-form-invalid-border-color: #d63939;
}

[data-bs-theme="dark"], body[data-bs-theme="dark"] [data-bs-theme="light"] {
  color-scheme: dark;
  --tblr-body-color: #dce1e7;
  --tblr-body-color-rgb: 220, 225, 231;
  --tblr-body-bg: #040a11;
  --tblr-body-bg-rgb: 4, 10, 17;
  --tblr-emphasis-color: #fff;
  --tblr-emphasis-color-rgb: 255, 255, 255;
  --tblr-secondary-color: #dce1e7bf;
  --tblr-secondary-color-rgb: 220, 225, 231;
  --tblr-secondary-bg: #182433;
  --tblr-secondary-bg-rgb: 24, 36, 51;
  --tblr-tertiary-color: #dce1e780;
  --tblr-tertiary-color-rgb: 220, 225, 231;
  --tblr-tertiary-bg: #0e1722;
  --tblr-tertiary-bg-rgb: 14, 23, 34;
  --tblr-primary-text-emphasis: #6aa9e3;
  --tblr-secondary-text-emphasis: #a7afbd;
  --tblr-success-text-emphasis: #82d18f;
  --tblr-info-text-emphasis: #8ec2ed;
  --tblr-warning-text-emphasis: #faa46a;
  --tblr-danger-text-emphasis: #e68888;
  --tblr-light-text-emphasis: #eef3f6;
  --tblr-dark-text-emphasis: #b8c4d4;
  --tblr-primary-bg-subtle: #01162a;
  --tblr-secondary-bg-subtle: #16181d;
  --tblr-success-bg-subtle: #09240e;
  --tblr-info-bg-subtle: #0d1f2d;
  --tblr-warning-bg-subtle: #311501;
  --tblr-danger-bg-subtle: #2b0b0b;
  --tblr-light-bg-subtle: #182433;
  --tblr-dark-bg-subtle: #0c121a;
  --tblr-primary-border-subtle: #04437d;
  --tblr-secondary-border-subtle: #414957;
  --tblr-success-border-subtle: #1c6b29;
  --tblr-info-border-subtle: #285c87;
  --tblr-warning-border-subtle: #943e04;
  --tblr-danger-border-subtle: #802222;
  --tblr-light-border-subtle: #3a4859;
  --tblr-dark-border-subtle: #182433;
  --tblr-heading-color: inherit;
  --tblr-link-color: #6aa9e3;
  --tblr-link-hover-color: #88bae9;
  --tblr-link-color-rgb: 106, 169, 227;
  --tblr-link-hover-color-rgb: 136, 186, 233;
  --tblr-code-color: var(--tblr-gray-300);
  --tblr-highlight-color: #dce1e7;
  --tblr-highlight-bg: #624000;
  --tblr-border-color: #25384f;
  --tblr-border-color-translucent: #486e9524;
  --tblr-form-valid-color: #82d18f;
  --tblr-form-valid-border-color: #82d18f;
  --tblr-form-invalid-color: #e68888;
  --tblr-form-invalid-border-color: #e68888;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--tblr-body-font-family);
  font-size: var(--tblr-body-font-size);
  font-weight: var(--tblr-body-font-weight);
  line-height: var(--tblr-body-line-height);
  color: var(--tblr-body-color);
  text-align: var(--tblr-body-text-align);
  background-color: var(--tblr-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr, .hr {
  color: inherit;
  border: 0;
  border-top: var(--tblr-border-width) solid;
  opacity: .16;
  margin: 2rem 0;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: var(--tblr-spacer);
  font-weight: var(--tblr-font-weight-bold);
  color: var(--tblr-heading-color);
  line-height: 1.2;
}

h1, .h1 {
  font-size: 1.5rem;
}

h2, .h2 {
  font-size: 1.25rem;
}

h3, .h3 {
  font-size: 1rem;
}

h4, .h4 {
  font-size: .875rem;
}

h5, .h5 {
  font-size: .75rem;
}

h6, .h6 {
  font-size: .625rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  cursor: help;
  text-decoration-skip-ink: none;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small, .small {
  font-size: 85.7143%;
}

mark, .mark {
  color: var(--tblr-highlight-color);
  background-color: var(--tblr-highlight-bg);
  padding: .1875em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: rgba(var(--tblr-link-color-rgb), var(--tblr-link-opacity, 1));
  text-decoration: none;
}

a:hover {
  --tblr-link-color-rgb: var(--tblr-link-hover-color-rgb);
  text-decoration: underline;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: var(--tblr-font-monospace);
  font-size: 1em;
}

pre {
  color: var(--tblr-light);
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 85.7143%;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--tblr-code-color);
  word-wrap: break-word;
  font-size: 85.7143%;
}

a > code {
  color: inherit;
}

kbd {
  font-size: var(--tblr-font-size-h5);
  color: var(--tblr-text-secondary-dark);
  background-color: var(--tblr-code-bg);
  border-radius: 2px;
  padding: .25rem .5rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: var(--tblr-secondary-color);
  text-align: left;
  padding-top: .75rem;
  padding-bottom: .75rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  width: 100%;
  font-size: 1.5rem;
  line-height: inherit;
  margin-bottom: .5rem;
  padding: 0;
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-datetime-edit-text {
  padding: 0;
}

::-webkit-datetime-edit-minute {
  padding: 0;
}

::-webkit-datetime-edit-hour-field {
  padding: 0;
}

::-webkit-datetime-edit-day-field {
  padding: 0;
}

::-webkit-datetime-edit-month-field {
  padding: 0;
}

::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: .875rem;
  font-weight: var(--tblr-font-weight-normal);
}

.display-1 {
  font-size: 5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-5 {
  font-size: 3rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-6 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.2;
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: 85.7143%;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: .875rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  color: #49566c;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 85.7143%;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  background-color: var(--tblr-body-bg);
  border: var(--tblr-border-width) solid var(--tblr-border-color);
  border-radius: var(--tblr-border-radius);
  box-shadow: var(--tblr-box-shadow-sm);
  max-width: 100%;
  height: auto;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  color: var(--tblr-secondary-color);
  font-size: 85.7143%;
}

.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
  --tblr-gutter-x: calc(var(--tblr-page-padding) * 2);
  --tblr-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--tblr-gutter-x) * .5);
  padding-left: calc(var(--tblr-gutter-x) * .5);
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}

@media (width >= 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}

@media (width >= 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}

@media (width >= 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}

@media (width >= 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}

:root {
  --tblr-breakpoint-xs: 0;
  --tblr-breakpoint-sm: 576px;
  --tblr-breakpoint-md: 768px;
  --tblr-breakpoint-lg: 992px;
  --tblr-breakpoint-xl: 1200px;
  --tblr-breakpoint-xxl: 1400px;
}

.row {
  --tblr-gutter-x: var(--tblr-page-padding);
  --tblr-gutter-y: 0;
  margin-top: calc(-1 * var(--tblr-gutter-y));
  margin-right: calc(-.5 * var(--tblr-gutter-x));
  margin-left: calc(-.5 * var(--tblr-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--tblr-gutter-x) * .5);
  padding-left: calc(var(--tblr-gutter-x) * .5);
  margin-top: var(--tblr-gutter-y);
  flex-shrink: 0;
}

.grid {
  grid-template-rows: repeat(var(--tblr-rows, 1), 1fr);
  grid-template-columns: repeat(var(--tblr-columns, 12), 1fr);
  gap: var(--tblr-gap, var(--tblr-page-padding));
  display: grid;
}

.grid .g-col-1 {
  grid-column: auto / span 1;
}

.grid .g-col-2 {
  grid-column: auto / span 2;
}

.grid .g-col-3 {
  grid-column: auto / span 3;
}

.grid .g-col-4 {
  grid-column: auto / span 4;
}

.grid .g-col-5 {
  grid-column: auto / span 5;
}

.grid .g-col-6 {
  grid-column: auto / span 6;
}

.grid .g-col-7 {
  grid-column: auto / span 7;
}

.grid .g-col-8 {
  grid-column: auto / span 8;
}

.grid .g-col-9 {
  grid-column: auto / span 9;
}

.grid .g-col-10 {
  grid-column: auto / span 10;
}

.grid .g-col-11 {
  grid-column: auto / span 11;
}

.grid .g-col-12 {
  grid-column: auto / span 12;
}

.grid .g-start-1 {
  grid-column-start: 1;
}

.grid .g-start-2 {
  grid-column-start: 2;
}

.grid .g-start-3 {
  grid-column-start: 3;
}

.grid .g-start-4 {
  grid-column-start: 4;
}

.grid .g-start-5 {
  grid-column-start: 5;
}

.grid .g-start-6 {
  grid-column-start: 6;
}

.grid .g-start-7 {
  grid-column-start: 7;
}

.grid .g-start-8 {
  grid-column-start: 8;
}

.grid .g-start-9 {
  grid-column-start: 9;
}

.grid .g-start-10 {
  grid-column-start: 10;
}

.grid .g-start-11 {
  grid-column-start: 11;
}

@media (width >= 576px) {
  .grid .g-col-sm-1 {
    grid-column: auto / span 1;
  }

  .grid .g-col-sm-2 {
    grid-column: auto / span 2;
  }

  .grid .g-col-sm-3 {
    grid-column: auto / span 3;
  }

  .grid .g-col-sm-4 {
    grid-column: auto / span 4;
  }

  .grid .g-col-sm-5 {
    grid-column: auto / span 5;
  }

  .grid .g-col-sm-6 {
    grid-column: auto / span 6;
  }

  .grid .g-col-sm-7 {
    grid-column: auto / span 7;
  }

  .grid .g-col-sm-8 {
    grid-column: auto / span 8;
  }

  .grid .g-col-sm-9 {
    grid-column: auto / span 9;
  }

  .grid .g-col-sm-10 {
    grid-column: auto / span 10;
  }

  .grid .g-col-sm-11 {
    grid-column: auto / span 11;
  }

  .grid .g-col-sm-12 {
    grid-column: auto / span 12;
  }

  .grid .g-start-sm-1 {
    grid-column-start: 1;
  }

  .grid .g-start-sm-2 {
    grid-column-start: 2;
  }

  .grid .g-start-sm-3 {
    grid-column-start: 3;
  }

  .grid .g-start-sm-4 {
    grid-column-start: 4;
  }

  .grid .g-start-sm-5 {
    grid-column-start: 5;
  }

  .grid .g-start-sm-6 {
    grid-column-start: 6;
  }

  .grid .g-start-sm-7 {
    grid-column-start: 7;
  }

  .grid .g-start-sm-8 {
    grid-column-start: 8;
  }

  .grid .g-start-sm-9 {
    grid-column-start: 9;
  }

  .grid .g-start-sm-10 {
    grid-column-start: 10;
  }

  .grid .g-start-sm-11 {
    grid-column-start: 11;
  }
}

@media (width >= 768px) {
  .grid .g-col-md-1 {
    grid-column: auto / span 1;
  }

  .grid .g-col-md-2 {
    grid-column: auto / span 2;
  }

  .grid .g-col-md-3 {
    grid-column: auto / span 3;
  }

  .grid .g-col-md-4 {
    grid-column: auto / span 4;
  }

  .grid .g-col-md-5 {
    grid-column: auto / span 5;
  }

  .grid .g-col-md-6 {
    grid-column: auto / span 6;
  }

  .grid .g-col-md-7 {
    grid-column: auto / span 7;
  }

  .grid .g-col-md-8 {
    grid-column: auto / span 8;
  }

  .grid .g-col-md-9 {
    grid-column: auto / span 9;
  }

  .grid .g-col-md-10 {
    grid-column: auto / span 10;
  }

  .grid .g-col-md-11 {
    grid-column: auto / span 11;
  }

  .grid .g-col-md-12 {
    grid-column: auto / span 12;
  }

  .grid .g-start-md-1 {
    grid-column-start: 1;
  }

  .grid .g-start-md-2 {
    grid-column-start: 2;
  }

  .grid .g-start-md-3 {
    grid-column-start: 3;
  }

  .grid .g-start-md-4 {
    grid-column-start: 4;
  }

  .grid .g-start-md-5 {
    grid-column-start: 5;
  }

  .grid .g-start-md-6 {
    grid-column-start: 6;
  }

  .grid .g-start-md-7 {
    grid-column-start: 7;
  }

  .grid .g-start-md-8 {
    grid-column-start: 8;
  }

  .grid .g-start-md-9 {
    grid-column-start: 9;
  }

  .grid .g-start-md-10 {
    grid-column-start: 10;
  }

  .grid .g-start-md-11 {
    grid-column-start: 11;
  }
}

@media (width >= 992px) {
  .grid .g-col-lg-1 {
    grid-column: auto / span 1;
  }

  .grid .g-col-lg-2 {
    grid-column: auto / span 2;
  }

  .grid .g-col-lg-3 {
    grid-column: auto / span 3;
  }

  .grid .g-col-lg-4 {
    grid-column: auto / span 4;
  }

  .grid .g-col-lg-5 {
    grid-column: auto / span 5;
  }

  .grid .g-col-lg-6 {
    grid-column: auto / span 6;
  }

  .grid .g-col-lg-7 {
    grid-column: auto / span 7;
  }

  .grid .g-col-lg-8 {
    grid-column: auto / span 8;
  }

  .grid .g-col-lg-9 {
    grid-column: auto / span 9;
  }

  .grid .g-col-lg-10 {
    grid-column: auto / span 10;
  }

  .grid .g-col-lg-11 {
    grid-column: auto / span 11;
  }

  .grid .g-col-lg-12 {
    grid-column: auto / span 12;
  }

  .grid .g-start-lg-1 {
    grid-column-start: 1;
  }

  .grid .g-start-lg-2 {
    grid-column-start: 2;
  }

  .grid .g-start-lg-3 {
    grid-column-start: 3;
  }

  .grid .g-start-lg-4 {
    grid-column-start: 4;
  }

  .grid .g-start-lg-5 {
    grid-column-start: 5;
  }

  .grid .g-start-lg-6 {
    grid-column-start: 6;
  }

  .grid .g-start-lg-7 {
    grid-column-start: 7;
  }

  .grid .g-start-lg-8 {
    grid-column-start: 8;
  }

  .grid .g-start-lg-9 {
    grid-column-start: 9;
  }

  .grid .g-start-lg-10 {
    grid-column-start: 10;
  }

  .grid .g-start-lg-11 {
    grid-column-start: 11;
  }
}

@media (width >= 1200px) {
  .grid .g-col-xl-1 {
    grid-column: auto / span 1;
  }

  .grid .g-col-xl-2 {
    grid-column: auto / span 2;
  }

  .grid .g-col-xl-3 {
    grid-column: auto / span 3;
  }

  .grid .g-col-xl-4 {
    grid-column: auto / span 4;
  }

  .grid .g-col-xl-5 {
    grid-column: auto / span 5;
  }

  .grid .g-col-xl-6 {
    grid-column: auto / span 6;
  }

  .grid .g-col-xl-7 {
    grid-column: auto / span 7;
  }

  .grid .g-col-xl-8 {
    grid-column: auto / span 8;
  }

  .grid .g-col-xl-9 {
    grid-column: auto / span 9;
  }

  .grid .g-col-xl-10 {
    grid-column: auto / span 10;
  }

  .grid .g-col-xl-11 {
    grid-column: auto / span 11;
  }

  .grid .g-col-xl-12 {
    grid-column: auto / span 12;
  }

  .grid .g-start-xl-1 {
    grid-column-start: 1;
  }

  .grid .g-start-xl-2 {
    grid-column-start: 2;
  }

  .grid .g-start-xl-3 {
    grid-column-start: 3;
  }

  .grid .g-start-xl-4 {
    grid-column-start: 4;
  }

  .grid .g-start-xl-5 {
    grid-column-start: 5;
  }

  .grid .g-start-xl-6 {
    grid-column-start: 6;
  }

  .grid .g-start-xl-7 {
    grid-column-start: 7;
  }

  .grid .g-start-xl-8 {
    grid-column-start: 8;
  }

  .grid .g-start-xl-9 {
    grid-column-start: 9;
  }

  .grid .g-start-xl-10 {
    grid-column-start: 10;
  }

  .grid .g-start-xl-11 {
    grid-column-start: 11;
  }
}

@media (width >= 1400px) {
  .grid .g-col-xxl-1 {
    grid-column: auto / span 1;
  }

  .grid .g-col-xxl-2 {
    grid-column: auto / span 2;
  }

  .grid .g-col-xxl-3 {
    grid-column: auto / span 3;
  }

  .grid .g-col-xxl-4 {
    grid-column: auto / span 4;
  }

  .grid .g-col-xxl-5 {
    grid-column: auto / span 5;
  }

  .grid .g-col-xxl-6 {
    grid-column: auto / span 6;
  }

  .grid .g-col-xxl-7 {
    grid-column: auto / span 7;
  }

  .grid .g-col-xxl-8 {
    grid-column: auto / span 8;
  }

  .grid .g-col-xxl-9 {
    grid-column: auto / span 9;
  }

  .grid .g-col-xxl-10 {
    grid-column: auto / span 10;
  }

  .grid .g-col-xxl-11 {
    grid-column: auto / span 11;
  }

  .grid .g-col-xxl-12 {
    grid-column: auto / span 12;
  }

  .grid .g-start-xxl-1 {
    grid-column-start: 1;
  }

  .grid .g-start-xxl-2 {
    grid-column-start: 2;
  }

  .grid .g-start-xxl-3 {
    grid-column-start: 3;
  }

  .grid .g-start-xxl-4 {
    grid-column-start: 4;
  }

  .grid .g-start-xxl-5 {
    grid-column-start: 5;
  }

  .grid .g-start-xxl-6 {
    grid-column-start: 6;
  }

  .grid .g-start-xxl-7 {
    grid-column-start: 7;
  }

  .grid .g-start-xxl-8 {
    grid-column-start: 8;
  }

  .grid .g-start-xxl-9 {
    grid-column-start: 9;
  }

  .grid .g-start-xxl-10 {
    grid-column-start: 10;
  }

  .grid .g-start-xxl-11 {
    grid-column-start: 11;
  }
}

.col {
  flex: 1 0;
}

.row-cols-auto > * {
  flex: none;
  width: auto;
}

.row-cols-1 > * {
  flex: none;
  width: 100%;
}

.row-cols-2 > * {
  flex: none;
  width: 50%;
}

.row-cols-3 > * {
  flex: none;
  width: 33.3333%;
}

.row-cols-4 > * {
  flex: none;
  width: 25%;
}

.row-cols-5 > * {
  flex: none;
  width: 20%;
}

.row-cols-6 > * {
  flex: none;
  width: 16.6667%;
}

.col-auto {
  flex: none;
  width: auto;
}

.col-1 {
  flex: none;
  width: 8.33333%;
}

.col-2 {
  flex: none;
  width: 16.6667%;
}

.col-3 {
  flex: none;
  width: 25%;
}

.col-4 {
  flex: none;
  width: 33.3333%;
}

.col-5 {
  flex: none;
  width: 41.6667%;
}

.col-6 {
  flex: none;
  width: 50%;
}

.col-7 {
  flex: none;
  width: 58.3333%;
}

.col-8 {
  flex: none;
  width: 66.6667%;
}

.col-9 {
  flex: none;
  width: 75%;
}

.col-10 {
  flex: none;
  width: 83.3333%;
}

.col-11 {
  flex: none;
  width: 91.6667%;
}

.col-12 {
  flex: none;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

.g-0, .gx-0 {
  --tblr-gutter-x: 0;
}

.g-0, .gy-0 {
  --tblr-gutter-y: 0;
}

.g-1, .gx-1 {
  --tblr-gutter-x: .25rem;
}

.g-1, .gy-1 {
  --tblr-gutter-y: .25rem;
}

.g-2, .gx-2 {
  --tblr-gutter-x: .5rem;
}

.g-2, .gy-2 {
  --tblr-gutter-y: .5rem;
}

.g-3, .gx-3 {
  --tblr-gutter-x: 1rem;
}

.g-3, .gy-3 {
  --tblr-gutter-y: 1rem;
}

.g-4, .gx-4 {
  --tblr-gutter-x: 1.5rem;
}

.g-4, .gy-4 {
  --tblr-gutter-y: 1.5rem;
}

.g-5, .gx-5 {
  --tblr-gutter-x: 2rem;
}

.g-5, .gy-5 {
  --tblr-gutter-y: 2rem;
}

.g-6, .gx-6 {
  --tblr-gutter-x: 2.5rem;
}

.g-6, .gy-6 {
  --tblr-gutter-y: 2.5rem;
}

@media (width >= 576px) {
  .col-sm {
    flex: 1 0;
  }

  .row-cols-sm-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-sm-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-sm-auto {
    flex: none;
    width: auto;
  }

  .col-sm-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-sm-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-sm-3 {
    flex: none;
    width: 25%;
  }

  .col-sm-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-sm-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-sm-6 {
    flex: none;
    width: 50%;
  }

  .col-sm-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-sm-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-sm-9 {
    flex: none;
    width: 75%;
  }

  .col-sm-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-sm-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-sm-12 {
    flex: none;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }

  .g-sm-0, .gx-sm-0 {
    --tblr-gutter-x: 0;
  }

  .g-sm-0, .gy-sm-0 {
    --tblr-gutter-y: 0;
  }

  .g-sm-1, .gx-sm-1 {
    --tblr-gutter-x: .25rem;
  }

  .g-sm-1, .gy-sm-1 {
    --tblr-gutter-y: .25rem;
  }

  .g-sm-2, .gx-sm-2 {
    --tblr-gutter-x: .5rem;
  }

  .g-sm-2, .gy-sm-2 {
    --tblr-gutter-y: .5rem;
  }

  .g-sm-3, .gx-sm-3 {
    --tblr-gutter-x: 1rem;
  }

  .g-sm-3, .gy-sm-3 {
    --tblr-gutter-y: 1rem;
  }

  .g-sm-4, .gx-sm-4 {
    --tblr-gutter-x: 1.5rem;
  }

  .g-sm-4, .gy-sm-4 {
    --tblr-gutter-y: 1.5rem;
  }

  .g-sm-5, .gx-sm-5 {
    --tblr-gutter-x: 2rem;
  }

  .g-sm-5, .gy-sm-5 {
    --tblr-gutter-y: 2rem;
  }

  .g-sm-6, .gx-sm-6 {
    --tblr-gutter-x: 2.5rem;
  }

  .g-sm-6, .gy-sm-6 {
    --tblr-gutter-y: 2.5rem;
  }
}

@media (width >= 768px) {
  .col-md {
    flex: 1 0;
  }

  .row-cols-md-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-md-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-md-auto {
    flex: none;
    width: auto;
  }

  .col-md-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-md-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-md-3 {
    flex: none;
    width: 25%;
  }

  .col-md-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-md-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-md-6 {
    flex: none;
    width: 50%;
  }

  .col-md-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-md-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-md-9 {
    flex: none;
    width: 75%;
  }

  .col-md-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-md-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-md-12 {
    flex: none;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }

  .g-md-0, .gx-md-0 {
    --tblr-gutter-x: 0;
  }

  .g-md-0, .gy-md-0 {
    --tblr-gutter-y: 0;
  }

  .g-md-1, .gx-md-1 {
    --tblr-gutter-x: .25rem;
  }

  .g-md-1, .gy-md-1 {
    --tblr-gutter-y: .25rem;
  }

  .g-md-2, .gx-md-2 {
    --tblr-gutter-x: .5rem;
  }

  .g-md-2, .gy-md-2 {
    --tblr-gutter-y: .5rem;
  }

  .g-md-3, .gx-md-3 {
    --tblr-gutter-x: 1rem;
  }

  .g-md-3, .gy-md-3 {
    --tblr-gutter-y: 1rem;
  }

  .g-md-4, .gx-md-4 {
    --tblr-gutter-x: 1.5rem;
  }

  .g-md-4, .gy-md-4 {
    --tblr-gutter-y: 1.5rem;
  }

  .g-md-5, .gx-md-5 {
    --tblr-gutter-x: 2rem;
  }

  .g-md-5, .gy-md-5 {
    --tblr-gutter-y: 2rem;
  }

  .g-md-6, .gx-md-6 {
    --tblr-gutter-x: 2.5rem;
  }

  .g-md-6, .gy-md-6 {
    --tblr-gutter-y: 2.5rem;
  }
}

@media (width >= 992px) {
  .col-lg {
    flex: 1 0;
  }

  .row-cols-lg-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-lg-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-lg-auto {
    flex: none;
    width: auto;
  }

  .col-lg-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-lg-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-lg-3 {
    flex: none;
    width: 25%;
  }

  .col-lg-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-lg-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-lg-6 {
    flex: none;
    width: 50%;
  }

  .col-lg-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-lg-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-lg-9 {
    flex: none;
    width: 75%;
  }

  .col-lg-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-lg-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-lg-12 {
    flex: none;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }

  .g-lg-0, .gx-lg-0 {
    --tblr-gutter-x: 0;
  }

  .g-lg-0, .gy-lg-0 {
    --tblr-gutter-y: 0;
  }

  .g-lg-1, .gx-lg-1 {
    --tblr-gutter-x: .25rem;
  }

  .g-lg-1, .gy-lg-1 {
    --tblr-gutter-y: .25rem;
  }

  .g-lg-2, .gx-lg-2 {
    --tblr-gutter-x: .5rem;
  }

  .g-lg-2, .gy-lg-2 {
    --tblr-gutter-y: .5rem;
  }

  .g-lg-3, .gx-lg-3 {
    --tblr-gutter-x: 1rem;
  }

  .g-lg-3, .gy-lg-3 {
    --tblr-gutter-y: 1rem;
  }

  .g-lg-4, .gx-lg-4 {
    --tblr-gutter-x: 1.5rem;
  }

  .g-lg-4, .gy-lg-4 {
    --tblr-gutter-y: 1.5rem;
  }

  .g-lg-5, .gx-lg-5 {
    --tblr-gutter-x: 2rem;
  }

  .g-lg-5, .gy-lg-5 {
    --tblr-gutter-y: 2rem;
  }

  .g-lg-6, .gx-lg-6 {
    --tblr-gutter-x: 2.5rem;
  }

  .g-lg-6, .gy-lg-6 {
    --tblr-gutter-y: 2.5rem;
  }
}

@media (width >= 1200px) {
  .col-xl {
    flex: 1 0;
  }

  .row-cols-xl-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-xl-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-xl-auto {
    flex: none;
    width: auto;
  }

  .col-xl-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-xl-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-xl-3 {
    flex: none;
    width: 25%;
  }

  .col-xl-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-xl-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-xl-6 {
    flex: none;
    width: 50%;
  }

  .col-xl-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-xl-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-xl-9 {
    flex: none;
    width: 75%;
  }

  .col-xl-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-xl-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-xl-12 {
    flex: none;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }

  .g-xl-0, .gx-xl-0 {
    --tblr-gutter-x: 0;
  }

  .g-xl-0, .gy-xl-0 {
    --tblr-gutter-y: 0;
  }

  .g-xl-1, .gx-xl-1 {
    --tblr-gutter-x: .25rem;
  }

  .g-xl-1, .gy-xl-1 {
    --tblr-gutter-y: .25rem;
  }

  .g-xl-2, .gx-xl-2 {
    --tblr-gutter-x: .5rem;
  }

  .g-xl-2, .gy-xl-2 {
    --tblr-gutter-y: .5rem;
  }

  .g-xl-3, .gx-xl-3 {
    --tblr-gutter-x: 1rem;
  }

  .g-xl-3, .gy-xl-3 {
    --tblr-gutter-y: 1rem;
  }

  .g-xl-4, .gx-xl-4 {
    --tblr-gutter-x: 1.5rem;
  }

  .g-xl-4, .gy-xl-4 {
    --tblr-gutter-y: 1.5rem;
  }

  .g-xl-5, .gx-xl-5 {
    --tblr-gutter-x: 2rem;
  }

  .g-xl-5, .gy-xl-5 {
    --tblr-gutter-y: 2rem;
  }

  .g-xl-6, .gx-xl-6 {
    --tblr-gutter-x: 2.5rem;
  }

  .g-xl-6, .gy-xl-6 {
    --tblr-gutter-y: 2.5rem;
  }
}

@media (width >= 1400px) {
  .col-xxl {
    flex: 1 0;
  }

  .row-cols-xxl-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-xxl-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-xxl-auto {
    flex: none;
    width: auto;
  }

  .col-xxl-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-xxl-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-xxl-3 {
    flex: none;
    width: 25%;
  }

  .col-xxl-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-xxl-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-xxl-6 {
    flex: none;
    width: 50%;
  }

  .col-xxl-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-xxl-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-xxl-9 {
    flex: none;
    width: 75%;
  }

  .col-xxl-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-xxl-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-xxl-12 {
    flex: none;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .g-xxl-0, .gx-xxl-0 {
    --tblr-gutter-x: 0;
  }

  .g-xxl-0, .gy-xxl-0 {
    --tblr-gutter-y: 0;
  }

  .g-xxl-1, .gx-xxl-1 {
    --tblr-gutter-x: .25rem;
  }

  .g-xxl-1, .gy-xxl-1 {
    --tblr-gutter-y: .25rem;
  }

  .g-xxl-2, .gx-xxl-2 {
    --tblr-gutter-x: .5rem;
  }

  .g-xxl-2, .gy-xxl-2 {
    --tblr-gutter-y: .5rem;
  }

  .g-xxl-3, .gx-xxl-3 {
    --tblr-gutter-x: 1rem;
  }

  .g-xxl-3, .gy-xxl-3 {
    --tblr-gutter-y: 1rem;
  }

  .g-xxl-4, .gx-xxl-4 {
    --tblr-gutter-x: 1.5rem;
  }

  .g-xxl-4, .gy-xxl-4 {
    --tblr-gutter-y: 1.5rem;
  }

  .g-xxl-5, .gx-xxl-5 {
    --tblr-gutter-x: 2rem;
  }

  .g-xxl-5, .gy-xxl-5 {
    --tblr-gutter-y: 2rem;
  }

  .g-xxl-6, .gx-xxl-6 {
    --tblr-gutter-x: 2.5rem;
  }

  .g-xxl-6, .gy-xxl-6 {
    --tblr-gutter-y: 2.5rem;
  }
}

.table, .markdown > table {
  --tblr-table-color-type: initial;
  --tblr-table-bg-type: initial;
  --tblr-table-color-state: initial;
  --tblr-table-bg-state: initial;
  --tblr-table-color: inherit;
  --tblr-table-bg: transparent;
  --tblr-table-border-color: var(--tblr-border-color-translucent);
  --tblr-table-accent-bg: transparent;
  --tblr-table-striped-color: inherit;
  --tblr-table-striped-bg: var(--tblr-bg-surface-tertiary);
  --tblr-table-active-color: inherit;
  --tblr-table-active-bg: rgba(var(--tblr-emphasis-color-rgb), .1);
  --tblr-table-hover-color: inherit;
  --tblr-table-hover-bg: rgba(var(--tblr-emphasis-color-rgb), .075);
  vertical-align: top;
  border-color: var(--tblr-table-border-color);
  width: 100%;
  margin-bottom: 1rem;
}

.table > :not(caption) > * > *, .markdown > table > :not(caption) > * > * {
  color: var(--tblr-table-color-state, var(--tblr-table-color-type, var(--tblr-table-color)));
  background-color: var(--tblr-table-bg);
  border-bottom-width: var(--tblr-border-width);
  box-shadow: inset 0 0 0 9999px var(--tblr-table-bg-state, var(--tblr-table-bg-type, var(--tblr-table-accent-bg)));
  padding: .75rem;
}

.table > tbody, .markdown > table > tbody {
  vertical-align: inherit;
}

.table > thead, .markdown > table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--tblr-border-width) * 2) solid var(--tblr-border-color-translucent);
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: .25rem;
}

.table-bordered > :not(caption) > *, .markdown > table > :not(caption) > * {
  border-width: var(--tblr-border-width) 0;
}

.table-bordered > :not(caption) > * > *, .markdown > table > :not(caption) > * > * {
  border-width: 0 var(--tblr-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(2n) > *, .table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --tblr-table-color-type: var(--tblr-table-striped-color);
  --tblr-table-bg-type: var(--tblr-table-striped-bg);
}

.table-active {
  --tblr-table-color-state: var(--tblr-table-active-color);
  --tblr-table-bg-state: var(--tblr-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --tblr-table-color-state: var(--tblr-table-hover-color);
  --tblr-table-bg-state: var(--tblr-table-hover-bg);
}

.table-primary {
  --tblr-table-color: #182433;
  --tblr-table-bg: #cde2f6;
  --tblr-table-border-color: #a9bccf;
  --tblr-table-striped-bg: #c4d9ec;
  --tblr-table-striped-color: #182433;
  --tblr-table-active-bg: #bbcfe3;
  --tblr-table-active-color: #f6f8fb;
  --tblr-table-hover-bg: #bfd4e7;
  --tblr-table-hover-color: #182433;
  color: var(--tblr-table-color);
  border-color: var(--tblr-table-border-color);
}

.table-secondary {
  --tblr-table-color: #182433;
  --tblr-table-bg: #e2e4e9;
  --tblr-table-border-color: #babec5;
  --tblr-table-striped-bg: #d8dae0;
  --tblr-table-striped-color: #182433;
  --tblr-table-active-bg: #ced1d7;
  --tblr-table-active-color: #182433;
  --tblr-table-hover-bg: #d3d6db;
  --tblr-table-hover-color: #182433;
  color: var(--tblr-table-color);
  border-color: var(--tblr-table-border-color);
}

.table-success {
  --tblr-table-color: #182433;
  --tblr-table-bg: #d5f0da;
  --tblr-table-border-color: #afc7b9;
  --tblr-table-striped-bg: #cce6d2;
  --tblr-table-striped-color: #182433;
  --tblr-table-active-bg: #c2dcc9;
  --tblr-table-active-color: #182433;
  --tblr-table-hover-bg: #c7e1cd;
  --tblr-table-hover-color: #182433;
  color: var(--tblr-table-color);
  border-color: var(--tblr-table-border-color);
}

.table-info {
  --tblr-table-color: #182433;
  --tblr-table-bg: #d9ebf9;
  --tblr-table-border-color: #b2c3d1;
  --tblr-table-striped-bg: #cfe1ef;
  --tblr-table-striped-color: #182433;
  --tblr-table-active-bg: #c6d7e5;
  --tblr-table-active-color: #182433;
  --tblr-table-hover-bg: #cbdcea;
  --tblr-table-hover-color: #182433;
  color: var(--tblr-table-color);
  border-color: var(--tblr-table-border-color);
}

.table-warning {
  --tblr-table-color: #182433;
  --tblr-table-bg: #fde1cd;
  --tblr-table-border-color: #cfbbae;
  --tblr-table-striped-bg: #f2d8c5;
  --tblr-table-striped-color: #182433;
  --tblr-table-active-bg: #e6cebe;
  --tblr-table-active-color: #182433;
  --tblr-table-hover-bg: #ecd3c1;
  --tblr-table-hover-color: #182433;
  color: var(--tblr-table-color);
  border-color: var(--tblr-table-border-color);
}

.table-danger {
  --tblr-table-color: #182433;
  --tblr-table-bg: #f7d7d7;
  --tblr-table-border-color: #cab3b6;
  --tblr-table-striped-bg: #eccecf;
  --tblr-table-striped-color: #182433;
  --tblr-table-active-bg: #e1c5c7;
  --tblr-table-active-color: #f6f8fb;
  --tblr-table-hover-bg: #e6cacb;
  --tblr-table-hover-color: #182433;
  color: var(--tblr-table-color);
  border-color: var(--tblr-table-border-color);
}

.table-light {
  --tblr-table-color: #182433;
  --tblr-table-bg: #f6f8fb;
  --tblr-table-border-color: #caced3;
  --tblr-table-striped-bg: #ebedf1;
  --tblr-table-striped-color: #182433;
  --tblr-table-active-bg: #e0e3e7;
  --tblr-table-active-color: #182433;
  --tblr-table-hover-bg: #e5e8ec;
  --tblr-table-hover-color: #182433;
  color: var(--tblr-table-color);
  border-color: var(--tblr-table-border-color);
}

.table-dark {
  --tblr-table-color: #f6f8fb;
  --tblr-table-bg: #182433;
  --tblr-table-border-color: #444e5b;
  --tblr-table-striped-bg: #232f3d;
  --tblr-table-striped-color: #f6f8fb;
  --tblr-table-active-bg: #2e3947;
  --tblr-table-active-color: #f6f8fb;
  --tblr-table-hover-bg: #293442;
  --tblr-table-hover-color: #f6f8fb;
  color: var(--tblr-table-color);
  border-color: var(--tblr-table-border-color);
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (width <= 575.98px) {
  .table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 767.98px) {
  .table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 991.98px) {
  .table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 1199.98px) {
  .table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 1399.98px) {
  .table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

.form-label {
  font-size: .875rem;
  font-weight: var(--tblr-font-weight-medium);
  margin-bottom: .5rem;
}

.col-form-label {
  padding-top: calc(.4375rem + var(--tblr-border-width));
  padding-bottom: calc(.4375rem + var(--tblr-border-width));
  font-size: inherit;
  font-weight: var(--tblr-font-weight-medium);
  margin-bottom: 0;
  line-height: 1.42857;
}

.col-form-label-lg {
  padding-top: calc(.6875rem + var(--tblr-border-width));
  padding-bottom: calc(.6875rem + var(--tblr-border-width));
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(.0625rem + var(--tblr-border-width));
  padding-bottom: calc(.0625rem + var(--tblr-border-width));
  font-size: .75rem;
}

.form-text {
  color: var(--tblr-secondary-color);
  margin-top: .25rem;
  font-size: 85.7143%;
}

.form-control {
  width: 100%;
  font-family: var(--tblr-font-sans-serif);
  color: var(--tblr-body-color);
  appearance: none;
  background-color: var(--tblr-bg-forms);
  border: var(--tblr-border-width) solid var(--tblr-border-color);
  border-radius: var(--tblr-border-radius);
  box-shadow: var(--tblr-box-shadow-input);
  background-clip: padding-box;
  padding: .4375rem .75rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.42857;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: var(--tblr-body-color);
  background-color: var(--tblr-bg-forms);
  box-shadow: var(--tblr-box-shadow-input), 0 0 0 .25rem rgba(var(--tblr-primary-rgb), .25);
  border-color: #83b7e8;
  outline: 0;
}

.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.42857em;
  margin: 0;
}

.form-control::-webkit-datetime-edit {
  padding: 0;
  display: block;
}

.form-control::-webkit-input-placeholder {
  color: #8a97ab;
  opacity: 1;
}

.form-control:-ms-placeholder-shown {
  color: #8a97ab;
  opacity: 1;
}

.form-control::placeholder {
  color: #8a97ab;
  opacity: 1;
}

.form-control:disabled {
  background-color: var(--tblr-bg-surface-secondary);
  opacity: 1;
}

.form-control::-webkit-file-upload-button {
  -webkit-margin-end: .75rem;
  color: var(--tblr-body-color);
  background-color: var(--tblr-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--tblr-border-width);
  margin: -.4375rem -.75rem;
  border-radius: 0;
  margin-inline-end: .75rem;
  padding: .4375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control::file-selector-button {
  -webkit-margin-end: .75rem;
  color: var(--tblr-body-color);
  background-color: var(--tblr-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--tblr-border-width);
  border-radius: 0;
  margin: -.4375rem -.75rem;
  margin-inline-end: .75rem;
  padding: .4375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control-plaintext {
  width: 100%;
  color: var(--tblr-body-color);
  border: solid #0000;
  border-width: var(--tblr-border-width) 0;
  background-color: #0000;
  margin-bottom: 0;
  padding: .4375rem 0;
  line-height: 1.42857;
  display: block;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  min-height: calc(1.42857em + .125rem + calc(var(--tblr-border-width) * 2));
  border-radius: var(--tblr-border-radius-sm);
  padding: .0625rem .25rem;
  font-size: .75rem;
}

.form-control-sm::file-selector-button {
  -webkit-margin-end: .25rem;
  margin: -.0625rem -.25rem;
  margin-inline-end: .25rem;
  padding: .0625rem .25rem;
}

.form-control-lg {
  min-height: calc(1.42857em + 1.375rem + calc(var(--tblr-border-width) * 2));
  border-radius: var(--tblr-border-radius-lg);
  padding: .6875rem 1.5rem;
  font-size: 1.25rem;
}

.form-control-lg::file-selector-button {
  -webkit-margin-end: 1.5rem;
  margin: -.6875rem -1.5rem;
  margin-inline-end: 1.5rem;
  padding: .6875rem 1.5rem;
}

textarea.form-control {
  min-height: calc(1.42857em + .875rem + calc(var(--tblr-border-width) * 2));
}

textarea.form-control-sm {
  min-height: calc(1.42857em + .125rem + calc(var(--tblr-border-width) * 2));
}

textarea.form-control-lg {
  min-height: calc(1.42857em + 1.375rem + calc(var(--tblr-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.42857em + .875rem + calc(var(--tblr-border-width) * 2));
  padding: .4375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border-radius: var(--tblr-border-radius);
  border: 0 !important;
}

.form-control-color::-webkit-color-swatch {
  border-radius: var(--tblr-border-radius);
  border: 0 !important;
}

.form-control-color.form-control-sm {
  height: calc(1.42857em + .125rem + calc(var(--tblr-border-width) * 2));
}

.form-control-color.form-control-lg {
  height: calc(1.42857em + 1.375rem + calc(var(--tblr-border-width) * 2));
}

.form-select {
  --tblr-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%238a97ab' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  width: 100%;
  font-family: var(--tblr-font-sans-serif);
  color: var(--tblr-body-color);
  appearance: none;
  background-color: var(--tblr-bg-forms);
  background-image: var(--tblr-form-select-bg-img), var(--tblr-form-select-bg-icon, none);
  border: var(--tblr-border-width) solid var(--tblr-border-color);
  border-radius: var(--tblr-border-radius);
  box-shadow: var(--tblr-box-shadow-input);
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  padding: .4375rem 2.25rem .4375rem .75rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.42857;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  box-shadow: var(--tblr-box-shadow-input), 0 0 0 .25rem rgba(var(--tblr-primary-rgb), .25);
  border-color: #83b7e8;
  outline: 0;
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: .75rem;
}

.form-select:disabled {
  background-color: var(--tblr-bg-surface-secondary);
}

.form-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 0 var(--tblr-body-color);
}

.form-select-sm {
  border-radius: var(--tblr-border-radius-sm);
  padding-top: .0625rem;
  padding-bottom: .0625rem;
  padding-left: .25rem;
  font-size: .75rem;
}

.form-select-lg {
  border-radius: var(--tblr-border-radius-lg);
  padding-top: .6875rem;
  padding-bottom: .6875rem;
  padding-left: 1.5rem;
  font-size: 1.25rem;
}

[data-bs-theme="dark"] .form-select, body[data-bs-theme="dark"] [data-bs-theme="light"] .form-select {
  --tblr-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dce1e7' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  min-height: 1.25rem;
  margin-bottom: .75rem;
  padding-left: 2rem;
  display: block;
}

.form-check .form-check-input {
  float: left;
  margin-left: -2rem;
}

.form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 2rem;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -2rem;
}

.form-check-input {
  --tblr-form-check-bg: var(--tblr-bg-forms);
  vertical-align: top;
  appearance: none;
  background-color: var(--tblr-form-check-bg);
  background-image: var(--tblr-form-check-bg-image);
  border: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent);
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  print-color-adjust: exact;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: .0892857rem;
}

.form-check-input[type="checkbox"] {
  border-radius: var(--tblr-border-radius);
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  box-shadow: 0 0 0 .25rem rgba(var(--tblr-primary-rgb), .25);
  border-color: #83b7e8;
  outline: 0;
}

.form-check-input:checked {
  background-color: var(--tblr-primary);
  border-color: var(--tblr-border-color-translucent);
}

.form-check-input:checked[type="checkbox"] {
  --tblr-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8.5l2.5 2.5l5.5 -5.5'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  --tblr-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3ccircle r='3' fill='%23ffffff' cx='8' cy='8' /%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: var(--tblr-primary);
  border-color: var(--tblr-primary);
  --tblr-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: .7;
}

.form-switch {
  padding-left: 2.5rem;
}

.form-switch .form-check-input {
  --tblr-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23dce1e7'/%3e%3c/svg%3e");
  background-image: var(--tblr-form-switch-bg);
  background-position: 0;
  border-radius: 2rem;
  width: 2rem;
  margin-left: -2.5rem;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  --tblr-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2383b7e8'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  --tblr-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
  background-position: 100%;
}

.form-switch.form-check-reverse {
  padding-left: 0;
  padding-right: 2.5rem;
}

.form-switch.form-check-reverse .form-check-input {
  margin-left: 0;
  margin-right: -2.5rem;
}

.form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: .4;
}

[data-bs-theme="dark"] .form-switch .form-check-input:not(:checked):not(:focus) {
  --tblr-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  appearance: none;
  background-color: #0000;
  width: 100%;
  height: 1.25rem;
  padding: 0;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f6f8fb, 0 0 0 .25rem rgba(var(--tblr-primary-rgb), .25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f6f8fb, 0 0 0 .25rem rgba(var(--tblr-primary-rgb), .25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  appearance: none;
  background-color: var(--tblr-primary);
  border: 2px var(--tblr-border-style) #fff;
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  margin-top: -.375rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  box-shadow: 0 .1rem .25rem #0000001a;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #b4d4f1;
}

.form-range::-webkit-slider-runnable-track {
  color: #0000;
  cursor: pointer;
  background-color: var(--tblr-border-color);
  width: 100%;
  height: .25rem;
  box-shadow: var(--tblr-box-shadow-inset);
  border-color: #0000;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  appearance: none;
  background-color: var(--tblr-primary);
  border: 2px var(--tblr-border-style) #fff;
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  box-shadow: 0 .1rem .25rem #0000001a;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #b4d4f1;
}

.form-range::-moz-range-track {
  color: #0000;
  cursor: pointer;
  background-color: var(--tblr-border-color);
  width: 100%;
  height: .25rem;
  box-shadow: var(--tblr-box-shadow-inset);
  border-color: #0000;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--tblr-secondary-color);
}

.form-range:disabled::-moz-range-thumb {
  background-color: var(--tblr-secondary-color);
}

.form-floating {
  position: relative;
}

.form-floating > .form-control, .form-floating > .form-control-plaintext, .form-floating > .form-select {
  height: calc(3.5rem + calc(var(--tblr-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--tblr-border-width) * 2));
  line-height: 1.25;
}

.form-floating > label {
  z-index: 2;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--tblr-border-width) solid transparent;
  transform-origin: 0 0;
  height: 100%;
  padding: 1rem .75rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control, .form-floating > .form-control-plaintext {
  padding: 1rem .75rem;
}

.form-floating > .form-control::-webkit-input-placeholder {
  color: #0000;
}

.form-floating > .form-control-plaintext::-webkit-input-placeholder {
  color: #0000;
}

:is(.form-floating > .form-control:-ms-placeholder-shown, .form-floating > .form-control-plaintext:-ms-placeholder-shown), :is(.form-floating > .form-control:placeholder-shown, .form-floating > .form-control-plaintext:placeholder-shown) {
  color: #0000;
}

.form-floating > .form-control::-moz-placeholder {
  color: #0000;
}

.form-floating > .form-control-plaintext::-moz-placeholder {
  color: #0000;
}

.form-floating > .form-control::placeholder, .form-floating > .form-control-plaintext::placeholder {
  color: #0000;
}

:-webkit-any(.form-floating > .form-control:not(:placeholder-shown), .form-floating > .form-control-plaintext:not(:placeholder-shown)), .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > .form-control-plaintext:focus, .form-floating > .form-control-plaintext:not(:placeholder-shown), :-webkit-any(.form-floating > .form-control:-webkit-autofill, .form-floating > .form-control-plaintext:-webkit-autofill), .form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

:is(.form-floating > .form-control:not(:placeholder-shown), .form-floating > .form-control-plaintext:not(:placeholder-shown)), .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > .form-control-plaintext:focus, .form-floating > .form-control-plaintext:not(:placeholder-shown), :is(.form-floating > .form-control:autofill, .form-floating > .form-control-plaintext:autofill), .form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:not(:-ms-placeholder-shown) ~ label {
  color: rgba(var(--tblr-body-color-rgb), .65);
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label {
  color: rgba(var(--tblr-body-color-rgb), .65);
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control:not(:-ms-placeholder-shown) ~ label:after {
  z-index: -1;
  content: "";
  background-color: var(--tblr-bg-forms);
  border-radius: var(--tblr-border-radius);
  height: 1.5em;
  position: absolute;
  inset: 1rem .375rem;
}

.form-floating > .form-control:focus ~ label:after, .form-floating > .form-control:not(:placeholder-shown) ~ label:after, .form-floating > .form-control-plaintext ~ label:after, .form-floating > .form-select ~ label:after {
  z-index: -1;
  content: "";
  background-color: var(--tblr-bg-forms);
  border-radius: var(--tblr-border-radius);
  height: 1.5em;
  position: absolute;
  inset: 1rem .375rem;
}

.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--tblr-body-color-rgb), .65);
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control-plaintext ~ label {
  border-width: var(--tblr-border-width) 0;
}

.form-floating > :disabled ~ label, .form-floating > .form-control:disabled ~ label {
  color: #49566c;
}

.form-floating > :disabled ~ label:after, .form-floating > .form-control:disabled ~ label:after {
  background-color: var(--tblr-bg-surface-secondary);
}

.input-group {
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  display: flex;
  position: relative;
}

.input-group > .form-control, .input-group > .form-select, .input-group > .form-floating {
  flex: auto;
  width: 1%;
  min-width: 0;
  position: relative;
}

.input-group > .form-control:focus, .input-group > .form-select:focus, .input-group > .form-floating:focus-within {
  z-index: 5;
}

.input-group .btn {
  z-index: 2;
  position: relative;
}

.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  color: var(--tblr-gray-500);
  text-align: center;
  white-space: nowrap;
  background-color: var(--tblr-bg-surface-secondary);
  border: var(--tblr-border-width) solid var(--tblr-border-color);
  border-radius: var(--tblr-border-radius);
  align-items: center;
  padding: .4375rem .75rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.42857;
  display: flex;
}

.input-group-lg > .form-control, .input-group-lg > .form-select, .input-group-lg > .input-group-text, .input-group-lg > .btn {
  border-radius: var(--tblr-border-radius-lg);
  padding: .6875rem 1.5rem;
  font-size: 1.25rem;
}

.input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text, .input-group-sm > .btn {
  border-radius: var(--tblr-border-radius-sm);
  padding: .0625rem .25rem;
  font-size: .75rem;
}

.input-group-lg > .form-select, .input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select, .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4), .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control, .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--tblr-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .form-floating:not(:first-child) > .form-control, .input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  width: 100%;
  color: var(--tblr-form-valid-color);
  margin-top: .25rem;
  font-size: 85.7143%;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  max-width: 100%;
  padding: var(--tblr-spacer-2) var(--tblr-spacer-2);
  color: #fff;
  background-color: var(--tblr-success);
  border-radius: var(--tblr-border-radius);
  margin-top: .1rem;
  font-size: .765625rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip, .is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--tblr-form-valid-border-color);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%232fb344' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='20 6 9 17 4 12'%3e%3c/polyline%3e%3c/svg%3e");
  background-position: right calc(.357143em + .21875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.714286em + .4375rem) calc(.714286em + .4375rem);
  padding-right: calc(1.42857em + .875rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--tblr-form-valid-border-color);
  box-shadow: var(--tblr-box-shadow-input), 0 0 0 .25rem rgba(var(--tblr-success-rgb), .25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  background-position: right calc(.357143em + .21875rem) top calc(.357143em + .21875rem);
  padding-right: calc(1.42857em + .875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--tblr-form-valid-border-color);
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --tblr-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%232fb344' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='20 6 9 17 4 12'%3e%3c/polyline%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.714286em + .4375rem) calc(.714286em + .4375rem);
  padding-right: 4.125rem;
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--tblr-form-valid-border-color);
  box-shadow: var(--tblr-box-shadow-input), 0 0 0 .25rem rgba(var(--tblr-success-rgb), .25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(1.42857em + 3.875rem);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--tblr-form-valid-border-color);
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--tblr-form-valid-color);
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 .25rem rgba(var(--tblr-success-rgb), .25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--tblr-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .was-validated .input-group > .form-select:not(:focus):valid, .input-group > .form-select:not(:focus).is-valid, .was-validated .input-group > .form-floating:not(:focus-within):valid, .input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  width: 100%;
  color: var(--tblr-form-invalid-color);
  margin-top: .25rem;
  font-size: 85.7143%;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  padding: var(--tblr-spacer-2) var(--tblr-spacer-2);
  color: #fff;
  background-color: var(--tblr-danger);
  border-radius: var(--tblr-border-radius);
  margin-top: .1rem;
  font-size: .765625rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip, .is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--tblr-form-invalid-border-color);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23d63939' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cline x1='18' y1='6' x2='6' y2='18'%3e%3c/line%3e%3cline x1='6' y1='6' x2='18' y2='18'%3e%3c/line%3e%3c/svg%3e");
  background-position: right calc(.357143em + .21875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.714286em + .4375rem) calc(.714286em + .4375rem);
  padding-right: calc(1.42857em + .875rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--tblr-form-invalid-border-color);
  box-shadow: var(--tblr-box-shadow-input), 0 0 0 .25rem rgba(var(--tblr-danger-rgb), .25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  background-position: right calc(.357143em + .21875rem) top calc(.357143em + .21875rem);
  padding-right: calc(1.42857em + .875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--tblr-form-invalid-border-color);
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --tblr-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23d63939' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cline x1='18' y1='6' x2='6' y2='18'%3e%3c/line%3e%3cline x1='6' y1='6' x2='18' y2='18'%3e%3c/line%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.714286em + .4375rem) calc(.714286em + .4375rem);
  padding-right: 4.125rem;
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--tblr-form-invalid-border-color);
  box-shadow: var(--tblr-box-shadow-input), 0 0 0 .25rem rgba(var(--tblr-danger-rgb), .25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(1.42857em + 3.875rem);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--tblr-form-invalid-border-color);
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--tblr-form-invalid-color);
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 .25rem rgba(var(--tblr-danger-rgb), .25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--tblr-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated .input-group > .form-select:not(:focus):invalid, .input-group > .form-select:not(:focus).is-invalid, .was-validated .input-group > .form-floating:not(:focus-within):invalid, .input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --tblr-btn-padding-x: 1rem;
  --tblr-btn-padding-y: .4375rem;
  --tblr-btn-font-family: var(--tblr-font-sans-serif);
  --tblr-btn-font-size: .875rem;
  --tblr-btn-font-weight: var(--tblr-font-weight-medium);
  --tblr-btn-line-height: 1.42857;
  --tblr-btn-color: var(--tblr-body-color);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-width: var(--tblr-border-width);
  --tblr-btn-border-color: transparent;
  --tblr-btn-border-radius: var(--tblr-border-radius);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
  --tblr-btn-disabled-opacity: .4;
  --tblr-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--tblr-btn-focus-shadow-rgb), .5);
  padding: var(--tblr-btn-padding-y) var(--tblr-btn-padding-x);
  font-family: var(--tblr-btn-font-family);
  font-size: var(--tblr-btn-font-size);
  font-weight: var(--tblr-btn-font-weight);
  line-height: var(--tblr-btn-line-height);
  color: var(--tblr-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--tblr-btn-border-width) solid var(--tblr-btn-border-color);
  border-radius: var(--tblr-btn-border-radius);
  background-color: var(--tblr-btn-bg);
  box-shadow: var(--tblr-btn-box-shadow);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--tblr-btn-hover-color);
  background-color: var(--tblr-btn-hover-bg);
  border-color: var(--tblr-btn-hover-border-color);
  text-decoration: none;
}

.btn:focus-visible {
  color: var(--tblr-btn-hover-color);
  background-color: var(--tblr-btn-hover-bg);
  border-color: var(--tblr-btn-hover-border-color);
  box-shadow: var(--tblr-btn-box-shadow), var(--tblr-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:focus-visible + .btn {
  border-color: var(--tblr-btn-hover-border-color);
  box-shadow: var(--tblr-btn-box-shadow), var(--tblr-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--tblr-btn-active-color);
  background-color: var(--tblr-btn-active-bg);
  border-color: var(--tblr-btn-active-border-color);
  box-shadow: var(--tblr-btn-active-shadow);
}

.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible, .btn-check:checked:focus-visible + .btn {
  box-shadow: var(--tblr-btn-active-shadow), var(--tblr-btn-focus-box-shadow);
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--tblr-btn-disabled-color);
  pointer-events: none;
  background-color: var(--tblr-btn-disabled-bg);
  border-color: var(--tblr-btn-disabled-border-color);
  opacity: var(--tblr-btn-disabled-opacity);
  box-shadow: none;
}

.btn-link {
  --tblr-btn-font-weight: 400;
  --tblr-btn-color: var(--tblr-link-color);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-link-hover-color);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-color: var(--tblr-link-hover-color);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-disabled-color: #49566c;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-btn-box-shadow: 0 0 0 #000;
  --tblr-btn-focus-shadow-rgb: 42, 132, 215;
  text-decoration: none;
}

.btn-link:hover, .btn-link:focus-visible {
  text-decoration: underline;
}

.btn-link:focus-visible {
  color: var(--tblr-btn-color);
}

.btn-link:hover {
  color: var(--tblr-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --tblr-btn-padding-y: .6875rem;
  --tblr-btn-padding-x: 1.5rem;
  --tblr-btn-font-size: 1.25rem;
  --tblr-btn-border-radius: var(--tblr-border-radius-lg);
}

.btn-sm, .btn-group-sm > .btn {
  --tblr-btn-padding-y: .0625rem;
  --tblr-btn-padding-x: .25rem;
  --tblr-btn-font-size: .75rem;
  --tblr-btn-border-radius: var(--tblr-border-radius-sm);
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup, .dropend, .dropdown, .dropstart, .dropup-center, .dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  content: "";
  vertical-align: .306em;
  border-bottom: 1px var(--tblr-border-style);
  border-left: 1px var(--tblr-border-style);
  width: .36em;
  height: .36em;
  margin-left: .4em;
  margin-right: .1em;
  display: inline-block;
  transform: rotate(-45deg);
}

.dropdown-menu {
  --tblr-dropdown-zindex: 1000;
  --tblr-dropdown-min-width: 11rem;
  --tblr-dropdown-padding-x: 0;
  --tblr-dropdown-padding-y: .25rem;
  --tblr-dropdown-spacer: 1px;
  --tblr-dropdown-font-size: .875rem;
  --tblr-dropdown-color: var(--tblr-body-color);
  --tblr-dropdown-bg: var(--tblr-bg-surface);
  --tblr-dropdown-border-color: var(--tblr-border-color-translucent);
  --tblr-dropdown-border-radius: var(--tblr-border-radius);
  --tblr-dropdown-border-width: var(--tblr-border-width);
  --tblr-dropdown-inner-border-radius: calc(var(--tblr-border-radius)  - var(--tblr-border-width));
  --tblr-dropdown-divider-bg: var(--tblr-border-color-translucent);
  --tblr-dropdown-divider-margin-y: var(--tblr-spacer-2);
  --tblr-dropdown-box-shadow: var(--tblr-box-shadow-dropdown);
  --tblr-dropdown-link-color: inherit;
  --tblr-dropdown-link-hover-color: inherit;
  --tblr-dropdown-link-hover-bg: rgba(var(--tblr-secondary-rgb), .08);
  --tblr-dropdown-link-active-color: var(--tblr-primary);
  --tblr-dropdown-link-active-bg: var(--tblr-active-bg);
  --tblr-dropdown-link-disabled-color: var(--tblr-tertiary-color);
  --tblr-dropdown-item-padding-x: .75rem;
  --tblr-dropdown-item-padding-y: .5rem;
  --tblr-dropdown-header-color: #49566c;
  --tblr-dropdown-header-padding-x: .75rem;
  --tblr-dropdown-header-padding-y: .25rem;
  z-index: var(--tblr-dropdown-zindex);
  min-width: var(--tblr-dropdown-min-width);
  padding: var(--tblr-dropdown-padding-y) var(--tblr-dropdown-padding-x);
  font-size: var(--tblr-dropdown-font-size);
  color: var(--tblr-dropdown-color);
  text-align: left;
  background-color: var(--tblr-dropdown-bg);
  border: var(--tblr-dropdown-border-width) solid var(--tblr-dropdown-border-color);
  border-radius: var(--tblr-dropdown-border-radius);
  box-shadow: var(--tblr-dropdown-box-shadow);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

.dropdown-menu[data-bs-popper] {
  margin-top: var(--tblr-dropdown-spacer);
  top: 100%;
  left: 0;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (width >= 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--tblr-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-toggle:after {
  content: "";
  vertical-align: .306em;
  border-bottom: 1px var(--tblr-border-style);
  border-left: 1px var(--tblr-border-style);
  width: .36em;
  height: .36em;
  margin-left: .4em;
  margin-right: .1em;
  display: inline-block;
  transform: rotate(135deg);
}

.dropend .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--tblr-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

.dropend .dropdown-toggle:after {
  content: "";
  vertical-align: .306em;
  border-bottom: 1px var(--tblr-border-style);
  border-left: 1px var(--tblr-border-style);
  vertical-align: 0;
  width: .36em;
  height: .36em;
  margin-left: .4em;
  margin-right: .1em;
  display: inline-block;
  transform: rotate(-135deg);
}

.dropstart .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--tblr-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

.dropstart .dropdown-toggle:after {
  content: "";
  vertical-align: .306em;
  border-bottom: 1px var(--tblr-border-style);
  border-left: 1px var(--tblr-border-style);
  width: .36em;
  height: .36em;
  margin-left: .4em;
  margin-right: .1em;
  display: inline-block;
  transform: rotate(45deg);
}

.dropstart .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--tblr-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--tblr-dropdown-divider-bg);
  opacity: 1;
  overflow: hidden;
}

.dropdown-item {
  width: 100%;
  padding: var(--tblr-dropdown-item-padding-y) var(--tblr-dropdown-item-padding-x);
  clear: both;
  color: var(--tblr-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  border-radius: var(--tblr-dropdown-item-border-radius, 0);
  background-color: #0000;
  border: 0;
  font-weight: 400;
  display: block;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: var(--tblr-dropdown-link-hover-color);
  background-color: var(--tblr-dropdown-link-hover-bg);
  text-decoration: none;
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--tblr-dropdown-link-active-color);
  background-color: var(--tblr-dropdown-link-active-bg);
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--tblr-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  padding: var(--tblr-dropdown-header-padding-y) var(--tblr-dropdown-header-padding-x);
  color: var(--tblr-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .765625rem;
  display: block;
}

.dropdown-item-text {
  padding: var(--tblr-dropdown-item-padding-y) var(--tblr-dropdown-item-padding-x);
  color: var(--tblr-dropdown-link-color);
  display: block;
}

.dropdown-menu-dark {
  --tblr-dropdown-color: #b8c4d4;
  --tblr-dropdown-bg: #182433;
  --tblr-dropdown-border-color: var(--tblr-border-color-translucent);
  --tblr-dropdown-box-shadow: ;
  --tblr-dropdown-link-color: #b8c4d4;
  --tblr-dropdown-link-hover-color: #fff;
  --tblr-dropdown-divider-bg: var(--tblr-border-color-translucent);
  --tblr-dropdown-link-hover-bg: #ffffff26;
  --tblr-dropdown-link-active-color: var(--tblr-primary);
  --tblr-dropdown-link-active-bg: var(--tblr-active-bg);
  --tblr-dropdown-link-disabled-color: #6c7a91;
  --tblr-dropdown-header-color: #6c7a91;
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.btn-group > .btn, .btn-group-vertical > .btn {
  flex: auto;
  position: relative;
}

.btn-group > .btn-check:checked + .btn, .btn-group > .btn-check:focus + .btn, .btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn-check:checked + .btn, .btn-group-vertical > .btn-check:focus + .btn, .btn-group-vertical > .btn:hover, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--tblr-border-radius);
}

.btn-group > :not(.btn-check:first-child) + .btn, .btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--tblr-border-width) * -1);
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn.dropdown-toggle-split:first-child, .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n+3), .btn-group > :not(.btn-check) + .btn, .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.dropdown-toggle-split:after, .dropup .dropdown-toggle-split:after, .dropend .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-left: .1875rem;
  padding-right: .1875rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-left: 1.125rem;
  padding-right: 1.125rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px #00000020;
}

.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--tblr-border-width) * -1);
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn, .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --tblr-nav-link-padding-x: .75rem;
  --tblr-nav-link-padding-y: .5rem;
  --tblr-nav-link-font-weight: ;
  --tblr-nav-link-color: var(--tblr-gray-500);
  --tblr-nav-link-hover-color: var(--tblr-link-hover-color);
  --tblr-nav-link-disabled-color: var(--tblr-disabled-color);
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  padding: var(--tblr-nav-link-padding-y) var(--tblr-nav-link-padding-x);
  font-size: var(--tblr-nav-link-font-size);
  font-weight: var(--tblr-nav-link-font-weight);
  color: var(--tblr-nav-link-color);
  background: none;
  border: 0;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: var(--tblr-nav-link-hover-color);
  text-decoration: none;
}

.nav-link:focus-visible {
  box-shadow: 0 0 0 .25rem rgba(var(--tblr-primary-rgb), .25);
  outline: 0;
}

.nav-link.disabled, .nav-link:disabled {
  color: var(--tblr-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --tblr-nav-tabs-border-width: var(--tblr-border-width);
  --tblr-nav-tabs-border-color: var(--tblr-border-color);
  --tblr-nav-tabs-border-radius: var(--tblr-border-radius);
  --tblr-nav-tabs-link-hover-border-color: var(--tblr-border-color) var(--tblr-border-color) var(--tblr-border-color);
  --tblr-nav-tabs-link-active-color: var(--tblr-body-color);
  --tblr-nav-tabs-link-active-bg: var(--tblr-body-bg);
  --tblr-nav-tabs-link-active-border-color: var(--tblr-border-color) var(--tblr-border-color) var(--tblr-border-color);
  border-bottom: var(--tblr-nav-tabs-border-width) solid var(--tblr-nav-tabs-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--tblr-nav-tabs-border-width));
  border: var(--tblr-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--tblr-nav-tabs-border-radius);
  border-top-right-radius: var(--tblr-nav-tabs-border-radius);
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--tblr-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: var(--tblr-nav-tabs-link-active-color);
  background-color: var(--tblr-nav-tabs-link-active-bg);
  border-color: var(--tblr-nav-tabs-link-active-border-color);
}

.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--tblr-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --tblr-nav-pills-border-radius: var(--tblr-border-radius);
  --tblr-nav-pills-link-active-color: var(--tblr-primary);
  --tblr-nav-pills-link-active-bg: var(--tblr-active-bg);
}

.nav-pills .nav-link {
  border-radius: var(--tblr-nav-pills-border-radius);
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: var(--tblr-nav-pills-link-active-color);
  background-color: var(--tblr-nav-pills-link-active-bg);
}

.nav-underline {
  --tblr-nav-underline-gap: 1rem;
  --tblr-nav-underline-border-width: .125rem;
  --tblr-nav-underline-link-active-color: var(--tblr-emphasis-color);
  gap: var(--tblr-nav-underline-gap);
}

.nav-underline .nav-link {
  border-bottom: var(--tblr-nav-underline-border-width) solid transparent;
  padding-left: 0;
  padding-right: 0;
}

.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentColor;
}

.nav-underline .nav-link.active, .nav-underline .show > .nav-link {
  color: var(--tblr-nav-underline-link-active-color);
  border-bottom-color: currentColor;
  font-weight: 600;
}

.nav-fill > .nav-link, .nav-fill .nav-item {
  text-align: center;
  flex: auto;
}

.nav-justified > .nav-link, .nav-justified .nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  --tblr-navbar-padding-x: 0;
  --tblr-navbar-padding-y: .25rem;
  --tblr-navbar-color: var(--tblr-muted);
  --tblr-navbar-hover-color: var(--tblr-body-color) color;
  --tblr-navbar-disabled-color: var(--tblr-disabled-color);
  --tblr-navbar-active-color: var(--tblr-body-color) color;
  --tblr-navbar-brand-padding-y: .5rem;
  --tblr-navbar-brand-margin-end: 1rem;
  --tblr-navbar-brand-font-size: 1.25rem;
  --tblr-navbar-brand-color: var(--tblr-body-color);
  --tblr-navbar-brand-hover-color: var(--tblr-body-color) color;
  --tblr-navbar-nav-link-padding-x: .75rem;
  --tblr-navbar-toggler-padding-y: 0;
  --tblr-navbar-toggler-padding-x: 0;
  --tblr-navbar-toggler-font-size: 1rem;
  --tblr-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2824, 36, 51, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --tblr-navbar-toggler-border-color: rgba(var(--tblr-emphasis-color-rgb), .15);
  --tblr-navbar-toggler-border-radius: var(--tblr-border-radius);
  --tblr-navbar-toggler-focus-width: 0;
  --tblr-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--tblr-navbar-padding-y) var(--tblr-navbar-padding-x);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.navbar > .container, .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  padding-top: var(--tblr-navbar-brand-padding-y);
  padding-bottom: var(--tblr-navbar-brand-padding-y);
  margin-right: var(--tblr-navbar-brand-margin-end);
  font-size: var(--tblr-navbar-brand-font-size);
  color: var(--tblr-navbar-brand-color);
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  color: var(--tblr-navbar-brand-hover-color);
  text-decoration: none;
}

.navbar-nav {
  --tblr-nav-link-padding-x: 0;
  --tblr-nav-link-padding-y: .5rem;
  --tblr-nav-link-font-weight: ;
  --tblr-nav-link-color: var(--tblr-navbar-color);
  --tblr-nav-link-hover-color: var(--tblr-navbar-hover-color);
  --tblr-nav-link-disabled-color: var(--tblr-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--tblr-navbar-active-color);
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  color: var(--tblr-navbar-color);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.navbar-text a, .navbar-text a:hover, .navbar-text a:focus {
  color: var(--tblr-navbar-active-color);
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  padding: var(--tblr-navbar-toggler-padding-y) var(--tblr-navbar-toggler-padding-x);
  font-size: var(--tblr-navbar-toggler-font-size);
  color: var(--tblr-navbar-color);
  border: var(--tblr-border-width) solid var(--tblr-navbar-toggler-border-color);
  border-radius: var(--tblr-navbar-toggler-border-radius);
  transition: var(--tblr-navbar-toggler-transition);
  background-color: #0000;
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 var(--tblr-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

.navbar-toggler-icon {
  vertical-align: middle;
  background-image: var(--tblr-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
}

.navbar-nav-scroll {
  max-height: var(--tblr-scroll-height, 75vh);
  overflow-y: auto;
}

@media (width >= 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--tblr-navbar-nav-link-padding-x);
    padding-left: var(--tblr-navbar-nav-link-padding-x);
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    z-index: auto;
    box-shadow: none;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--tblr-navbar-nav-link-padding-x);
    padding-left: var(--tblr-navbar-nav-link-padding-x);
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    z-index: auto;
    box-shadow: none;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--tblr-navbar-nav-link-padding-x);
    padding-left: var(--tblr-navbar-nav-link-padding-x);
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    z-index: auto;
    box-shadow: none;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--tblr-navbar-nav-link-padding-x);
    padding-left: var(--tblr-navbar-nav-link-padding-x);
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    z-index: auto;
    box-shadow: none;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--tblr-navbar-nav-link-padding-x);
    padding-left: var(--tblr-navbar-nav-link-padding-x);
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    z-index: auto;
    box-shadow: none;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--tblr-navbar-nav-link-padding-x);
  padding-left: var(--tblr-navbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  z-index: auto;
  box-shadow: none;
  flex-grow: 1;
  transition: none;
  position: static;
  visibility: visible !important;
  background-color: #0000 !important;
  border: 0 !important;
  width: auto !important;
  height: auto !important;
  transform: none !important;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

.navbar-dark, .navbar[data-bs-theme="dark"], body[data-bs-theme="dark"] .navbar[data-bs-theme="light"] {
  --tblr-navbar-color: #ffffffb3;
  --tblr-navbar-hover-color: #ffffffbf;
  --tblr-navbar-disabled-color: var(--tblr-disabled-color);
  --tblr-navbar-active-color: #fff;
  --tblr-navbar-brand-color: #fff;
  --tblr-navbar-brand-hover-color: #fff;
  --tblr-navbar-toggler-border-color: #ffffff1a;
  --tblr-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.7%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme="dark"] .navbar-toggler-icon, body[data-bs-theme="dark"] [data-bs-theme="light"] .navbar-toggler-icon {
  --tblr-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.7%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --tblr-card-spacer-y: 1rem;
  --tblr-card-spacer-x: 1.25rem;
  --tblr-card-title-spacer-y: 1.25rem;
  --tblr-card-title-color: ;
  --tblr-card-subtitle-color: ;
  --tblr-card-border-width: var(--tblr-border-width);
  --tblr-card-border-color: var(--tblr-border-color-translucent);
  --tblr-card-border-radius: var(--tblr-border-radius);
  --tblr-card-box-shadow: var(--tblr-shadow-card);
  --tblr-card-inner-border-radius: calc(var(--tblr-border-radius)  - (var(--tblr-border-width)));
  --tblr-card-cap-padding-y: 1rem;
  --tblr-card-cap-padding-x: 1.25rem;
  --tblr-card-cap-bg: var(--tblr-bg-surface-tertiary);
  --tblr-card-cap-color: inherit;
  --tblr-card-height: ;
  --tblr-card-color: inherit;
  --tblr-card-bg: var(--tblr-bg-surface);
  --tblr-card-img-overlay-padding: 1rem;
  --tblr-card-group-margin: 1.5rem;
  min-width: 0;
  height: var(--tblr-card-height);
  color: var(--tblr-body-color);
  word-wrap: break-word;
  background-color: var(--tblr-card-bg);
  border: var(--tblr-card-border-width) solid var(--tblr-card-border-color);
  border-radius: var(--tblr-card-border-radius);
  box-shadow: var(--tblr-card-box-shadow);
  background-clip: border-box;
  flex-direction: column;
  display: flex;
  position: relative;
}

.card > hr, .card > .hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-left-radius: var(--tblr-card-inner-border-radius);
  border-top-right-radius: var(--tblr-card-inner-border-radius);
  border-top-width: 0;
}

.card > .list-group:last-child {
  border-bottom-right-radius: var(--tblr-card-inner-border-radius);
  border-bottom-left-radius: var(--tblr-card-inner-border-radius);
  border-bottom-width: 0;
}

.card > .card-header + .list-group, .card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  padding: var(--tblr-card-spacer-y) var(--tblr-card-spacer-x);
  color: var(--tblr-card-color);
  flex: auto;
}

.card-title {
  margin-bottom: var(--tblr-card-title-spacer-y);
  color: var(--tblr-card-title-color);
}

.card-subtitle {
  margin-top: calc(-.5 * var(--tblr-card-title-spacer-y));
  color: var(--tblr-card-subtitle-color);
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: var(--tblr-card-spacer-x);
}

.card-header {
  padding: var(--tblr-card-cap-padding-y) var(--tblr-card-cap-padding-x);
  color: var(--tblr-card-cap-color);
  background-color: var(--tblr-card-cap-bg);
  border-bottom: var(--tblr-card-border-width) solid var(--tblr-card-border-color);
  margin-bottom: 0;
}

.card-footer {
  padding: var(--tblr-card-cap-padding-y) var(--tblr-card-cap-padding-x);
  color: var(--tblr-card-cap-color);
  background-color: var(--tblr-card-cap-bg);
  border-top: var(--tblr-card-border-width) solid var(--tblr-card-border-color);
}

.card-header-tabs {
  margin-right: calc(-.5 * var(--tblr-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--tblr-card-cap-padding-y));
  margin-left: calc(-.5 * var(--tblr-card-cap-padding-x));
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: var(--tblr-card-bg);
  border-bottom-color: var(--tblr-card-bg);
}

.card-header-pills {
  margin-right: calc(-.5 * var(--tblr-card-cap-padding-x));
  margin-left: calc(-.5 * var(--tblr-card-cap-padding-x));
}

.card-img-overlay {
  padding: var(--tblr-card-img-overlay-padding);
  border-radius: var(--tblr-card-inner-border-radius);
  position: absolute;
  inset: 0;
}

.card-img, .card-img-top, .card-img-bottom {
  width: 100%;
}

.card-img, .card-img-top {
  border-top-left-radius: var(--tblr-card-inner-border-radius);
  border-top-right-radius: var(--tblr-card-inner-border-radius);
}

.card-img, .card-img-bottom {
  border-bottom-right-radius: var(--tblr-card-inner-border-radius);
  border-bottom-left-radius: var(--tblr-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--tblr-card-group-margin);
}

@media (width >= 576px) {
  .card-group {
    flex-flow: wrap;
    display: flex;
  }

  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top, .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom, .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top, .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom, .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --tblr-accordion-color: var(--tblr-body-color);
  --tblr-accordion-bg: transparent;
  --tblr-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --tblr-accordion-border-color: var(--tblr-border-color-translucent);
  --tblr-accordion-border-width: var(--tblr-border-width);
  --tblr-accordion-border-radius: var(--tblr-border-radius);
  --tblr-accordion-inner-border-radius: calc(var(--tblr-border-radius)  - (var(--tblr-border-width)));
  --tblr-accordion-btn-padding-x: 1.25rem;
  --tblr-accordion-btn-padding-y: 1rem;
  --tblr-accordion-btn-color: var(--tblr-body-color);
  --tblr-accordion-btn-bg: transparent;
  --tblr-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23182433' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --tblr-accordion-btn-icon-width: 1rem;
  --tblr-accordion-btn-icon-transform: rotate(-180deg);
  --tblr-accordion-btn-icon-transition: transform .2s ease-in-out;
  --tblr-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23022c54' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --tblr-accordion-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--tblr-primary-rgb), .25);
  --tblr-accordion-body-padding-x: 1.25rem;
  --tblr-accordion-body-padding-y: 1rem;
  --tblr-accordion-active-color: inherit;
  --tblr-accordion-active-bg: transparent;
}

.accordion-button {
  width: 100%;
  padding: var(--tblr-accordion-btn-padding-y) var(--tblr-accordion-btn-padding-x);
  color: var(--tblr-accordion-btn-color);
  text-align: left;
  background-color: var(--tblr-accordion-btn-bg);
  overflow-anchor: none;
  transition: var(--tblr-accordion-transition);
  border: 0;
  border-radius: 0;
  align-items: center;
  font-size: .875rem;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--tblr-accordion-active-color);
  background-color: var(--tblr-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--tblr-accordion-border-width)) 0 var(--tblr-accordion-border-color);
}

.accordion-button:not(.collapsed):after {
  background-image: var(--tblr-accordion-btn-active-icon);
  transform: var(--tblr-accordion-btn-icon-transform);
}

.accordion-button:after {
  width: var(--tblr-accordion-btn-icon-width);
  height: var(--tblr-accordion-btn-icon-width);
  content: "";
  background-image: var(--tblr-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--tblr-accordion-btn-icon-width);
  transition: var(--tblr-accordion-btn-icon-transition);
  flex-shrink: 0;
  margin-left: auto;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button:after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  box-shadow: var(--tblr-accordion-btn-focus-box-shadow);
  outline: 0;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--tblr-accordion-color);
  background-color: var(--tblr-accordion-bg);
  border: var(--tblr-accordion-border-width) solid var(--tblr-accordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--tblr-accordion-border-radius);
  border-top-right-radius: var(--tblr-accordion-border-radius);
}

.accordion-item:first-of-type > .accordion-header .accordion-button {
  border-top-left-radius: var(--tblr-accordion-inner-border-radius);
  border-top-right-radius: var(--tblr-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--tblr-accordion-border-radius);
  border-bottom-left-radius: var(--tblr-accordion-border-radius);
}

.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-bottom-right-radius: var(--tblr-accordion-inner-border-radius);
  border-bottom-left-radius: var(--tblr-accordion-inner-border-radius);
}

.accordion-item:last-of-type > .accordion-collapse {
  border-bottom-right-radius: var(--tblr-accordion-border-radius);
  border-bottom-left-radius: var(--tblr-accordion-border-radius);
}

.accordion-body {
  padding: var(--tblr-accordion-body-padding-y) var(--tblr-accordion-body-padding-x);
}

.accordion-flush > .accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.accordion-flush > .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush > .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush > .accordion-item > .accordion-header .accordion-button, .accordion-flush > .accordion-item > .accordion-header .accordion-button.collapsed, .accordion-flush > .accordion-item > .accordion-collapse {
  border-radius: 0;
}

[data-bs-theme="dark"] .accordion-button:after {
  --tblr-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236aa9e3'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --tblr-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236aa9e3'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --tblr-breadcrumb-padding-x: 0;
  --tblr-breadcrumb-padding-y: 0;
  --tblr-breadcrumb-margin-bottom: 1rem;
  --tblr-breadcrumb-bg: ;
  --tblr-breadcrumb-border-radius: ;
  --tblr-breadcrumb-divider-color: var(--tblr-gray-500);
  --tblr-breadcrumb-item-padding-x: .5rem;
  --tblr-breadcrumb-item-active-color: inherit;
  padding: var(--tblr-breadcrumb-padding-y) var(--tblr-breadcrumb-padding-x);
  margin-bottom: var(--tblr-breadcrumb-margin-bottom);
  font-size: var(--tblr-breadcrumb-font-size);
  background-color: var(--tblr-breadcrumb-bg);
  border-radius: var(--tblr-breadcrumb-border-radius);
  flex-wrap: wrap;
  list-style: none;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--tblr-breadcrumb-item-padding-x);
}

.breadcrumb-item + .breadcrumb-item:before {
  float: left;
  padding-right: var(--tblr-breadcrumb-item-padding-x);
  color: var(--tblr-breadcrumb-divider-color);
  content: var(--tblr-breadcrumb-divider, "/");
}

.breadcrumb-item.active {
  color: var(--tblr-breadcrumb-item-active-color);
}

.pagination {
  --tblr-pagination-padding-x: .25rem;
  --tblr-pagination-padding-y: .25rem;
  --tblr-pagination-font-size: .875rem;
  --tblr-pagination-color: var(--tblr-gray-500);
  --tblr-pagination-bg: transparent;
  --tblr-pagination-border-width: 0;
  --tblr-pagination-border-color: var(--tblr-border-color);
  --tblr-pagination-border-radius: var(--tblr-border-radius);
  --tblr-pagination-hover-color: var(--tblr-link-hover-color);
  --tblr-pagination-hover-bg: var(--tblr-tertiary-bg);
  --tblr-pagination-hover-border-color: var(--tblr-border-color);
  --tblr-pagination-focus-color: var(--tblr-link-hover-color);
  --tblr-pagination-focus-bg: var(--tblr-secondary-bg);
  --tblr-pagination-focus-box-shadow: 0 0 0 .25rem rgba(var(--tblr-primary-rgb), .25);
  --tblr-pagination-active-color: #fff;
  --tblr-pagination-active-bg: var(--tblr-primary);
  --tblr-pagination-active-border-color: var(--tblr-primary);
  --tblr-pagination-disabled-color: var(--tblr-disabled-color);
  --tblr-pagination-disabled-bg: transparent;
  --tblr-pagination-disabled-border-color: var(--tblr-border-color);
  padding-left: 0;
  list-style: none;
  display: flex;
}

.page-link {
  padding: var(--tblr-pagination-padding-y) var(--tblr-pagination-padding-x);
  font-size: var(--tblr-pagination-font-size);
  color: var(--tblr-pagination-color);
  background-color: var(--tblr-pagination-bg);
  border: var(--tblr-pagination-border-width) solid var(--tblr-pagination-border-color);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--tblr-pagination-hover-color);
  background-color: var(--tblr-pagination-hover-bg);
  border-color: var(--tblr-pagination-hover-border-color);
  text-decoration: none;
}

.page-link:focus {
  z-index: 3;
  color: var(--tblr-pagination-focus-color);
  background-color: var(--tblr-pagination-focus-bg);
  box-shadow: var(--tblr-pagination-focus-box-shadow);
  outline: 0;
}

.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--tblr-pagination-active-color);
  background-color: var(--tblr-pagination-active-bg);
  border-color: var(--tblr-pagination-active-border-color);
}

.page-link.disabled, .disabled > .page-link {
  color: var(--tblr-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--tblr-pagination-disabled-bg);
  border-color: var(--tblr-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: 0;
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--tblr-pagination-border-radius);
  border-bottom-left-radius: var(--tblr-pagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--tblr-pagination-border-radius);
  border-bottom-right-radius: var(--tblr-pagination-border-radius);
}

.pagination-lg {
  --tblr-pagination-padding-x: 1.5rem;
  --tblr-pagination-padding-y: .75rem;
  --tblr-pagination-font-size: 1.09375rem;
  --tblr-pagination-border-radius: var(--tblr-border-radius-lg);
}

.pagination-sm {
  --tblr-pagination-padding-x: .5rem;
  --tblr-pagination-padding-y: .25rem;
  --tblr-pagination-font-size: .765625rem;
  --tblr-pagination-border-radius: var(--tblr-border-radius-sm);
}

.badge {
  --tblr-badge-padding-x: .5em;
  --tblr-badge-padding-y: .25em;
  --tblr-badge-font-size: 85.7143%;
  --tblr-badge-font-weight: var(--tblr-font-weight-medium);
  --tblr-badge-color: var(--tblr-gray-500);
  --tblr-badge-border-radius: var(--tblr-border-radius);
  padding: var(--tblr-badge-padding-y) var(--tblr-badge-padding-x);
  font-size: var(--tblr-badge-font-size);
  font-weight: var(--tblr-badge-font-weight);
  color: var(--tblr-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--tblr-badge-border-radius);
  line-height: 1;
  display: inline-block;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --tblr-alert-bg: transparent;
  --tblr-alert-padding-x: 1rem;
  --tblr-alert-padding-y: .75rem;
  --tblr-alert-margin-bottom: 1rem;
  --tblr-alert-color: inherit;
  --tblr-alert-border-color: transparent;
  --tblr-alert-border: var(--tblr-border-width) solid var(--tblr-alert-border-color);
  --tblr-alert-border-radius: var(--tblr-border-radius);
  --tblr-alert-link-color: inherit;
  padding: var(--tblr-alert-padding-y) var(--tblr-alert-padding-x);
  margin-bottom: var(--tblr-alert-margin-bottom);
  color: var(--tblr-alert-color);
  background-color: var(--tblr-alert-bg);
  border: var(--tblr-alert-border);
  border-radius: var(--tblr-alert-border-radius);
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: var(--tblr-font-weight-bold);
  color: var(--tblr-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  z-index: 2;
  padding: .9375rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  --tblr-alert-color: var(--tblr-primary-text-emphasis);
  --tblr-alert-bg: var(--tblr-primary-bg-subtle);
  --tblr-alert-border-color: var(--tblr-primary-border-subtle);
  --tblr-alert-link-color: var(--tblr-primary-text-emphasis);
}

.alert-secondary {
  --tblr-alert-color: var(--tblr-secondary-text-emphasis);
  --tblr-alert-bg: var(--tblr-secondary-bg-subtle);
  --tblr-alert-border-color: var(--tblr-secondary-border-subtle);
  --tblr-alert-link-color: var(--tblr-secondary-text-emphasis);
}

.alert-success {
  --tblr-alert-color: var(--tblr-success-text-emphasis);
  --tblr-alert-bg: var(--tblr-success-bg-subtle);
  --tblr-alert-border-color: var(--tblr-success-border-subtle);
  --tblr-alert-link-color: var(--tblr-success-text-emphasis);
}

.alert-info {
  --tblr-alert-color: var(--tblr-info-text-emphasis);
  --tblr-alert-bg: var(--tblr-info-bg-subtle);
  --tblr-alert-border-color: var(--tblr-info-border-subtle);
  --tblr-alert-link-color: var(--tblr-info-text-emphasis);
}

.alert-warning {
  --tblr-alert-color: var(--tblr-warning-text-emphasis);
  --tblr-alert-bg: var(--tblr-warning-bg-subtle);
  --tblr-alert-border-color: var(--tblr-warning-border-subtle);
  --tblr-alert-link-color: var(--tblr-warning-text-emphasis);
}

.alert-danger {
  --tblr-alert-color: var(--tblr-danger-text-emphasis);
  --tblr-alert-bg: var(--tblr-danger-bg-subtle);
  --tblr-alert-border-color: var(--tblr-danger-border-subtle);
  --tblr-alert-link-color: var(--tblr-danger-text-emphasis);
}

.alert-light {
  --tblr-alert-color: var(--tblr-light-text-emphasis);
  --tblr-alert-bg: var(--tblr-light-bg-subtle);
  --tblr-alert-border-color: var(--tblr-light-border-subtle);
  --tblr-alert-link-color: var(--tblr-light-text-emphasis);
}

.alert-dark {
  --tblr-alert-color: var(--tblr-dark-text-emphasis);
  --tblr-alert-bg: var(--tblr-dark-bg-subtle);
  --tblr-alert-border-color: var(--tblr-dark-border-subtle);
  --tblr-alert-link-color: var(--tblr-dark-text-emphasis);
}

.alert-muted {
  --tblr-alert-color: var(--tblr-muted-text-emphasis);
  --tblr-alert-bg: var(--tblr-muted-bg-subtle);
  --tblr-alert-border-color: var(--tblr-muted-border-subtle);
  --tblr-alert-link-color: var(--tblr-muted-text-emphasis);
}

.alert-blue {
  --tblr-alert-color: var(--tblr-blue-text-emphasis);
  --tblr-alert-bg: var(--tblr-blue-bg-subtle);
  --tblr-alert-border-color: var(--tblr-blue-border-subtle);
  --tblr-alert-link-color: var(--tblr-blue-text-emphasis);
}

.alert-azure {
  --tblr-alert-color: var(--tblr-azure-text-emphasis);
  --tblr-alert-bg: var(--tblr-azure-bg-subtle);
  --tblr-alert-border-color: var(--tblr-azure-border-subtle);
  --tblr-alert-link-color: var(--tblr-azure-text-emphasis);
}

.alert-indigo {
  --tblr-alert-color: var(--tblr-indigo-text-emphasis);
  --tblr-alert-bg: var(--tblr-indigo-bg-subtle);
  --tblr-alert-border-color: var(--tblr-indigo-border-subtle);
  --tblr-alert-link-color: var(--tblr-indigo-text-emphasis);
}

.alert-purple {
  --tblr-alert-color: var(--tblr-purple-text-emphasis);
  --tblr-alert-bg: var(--tblr-purple-bg-subtle);
  --tblr-alert-border-color: var(--tblr-purple-border-subtle);
  --tblr-alert-link-color: var(--tblr-purple-text-emphasis);
}

.alert-pink {
  --tblr-alert-color: var(--tblr-pink-text-emphasis);
  --tblr-alert-bg: var(--tblr-pink-bg-subtle);
  --tblr-alert-border-color: var(--tblr-pink-border-subtle);
  --tblr-alert-link-color: var(--tblr-pink-text-emphasis);
}

.alert-red {
  --tblr-alert-color: var(--tblr-red-text-emphasis);
  --tblr-alert-bg: var(--tblr-red-bg-subtle);
  --tblr-alert-border-color: var(--tblr-red-border-subtle);
  --tblr-alert-link-color: var(--tblr-red-text-emphasis);
}

.alert-orange {
  --tblr-alert-color: var(--tblr-orange-text-emphasis);
  --tblr-alert-bg: var(--tblr-orange-bg-subtle);
  --tblr-alert-border-color: var(--tblr-orange-border-subtle);
  --tblr-alert-link-color: var(--tblr-orange-text-emphasis);
}

.alert-yellow {
  --tblr-alert-color: var(--tblr-yellow-text-emphasis);
  --tblr-alert-bg: var(--tblr-yellow-bg-subtle);
  --tblr-alert-border-color: var(--tblr-yellow-border-subtle);
  --tblr-alert-link-color: var(--tblr-yellow-text-emphasis);
}

.alert-lime {
  --tblr-alert-color: var(--tblr-lime-text-emphasis);
  --tblr-alert-bg: var(--tblr-lime-bg-subtle);
  --tblr-alert-border-color: var(--tblr-lime-border-subtle);
  --tblr-alert-link-color: var(--tblr-lime-text-emphasis);
}

.alert-green {
  --tblr-alert-color: var(--tblr-green-text-emphasis);
  --tblr-alert-bg: var(--tblr-green-bg-subtle);
  --tblr-alert-border-color: var(--tblr-green-border-subtle);
  --tblr-alert-link-color: var(--tblr-green-text-emphasis);
}

.alert-teal {
  --tblr-alert-color: var(--tblr-teal-text-emphasis);
  --tblr-alert-bg: var(--tblr-teal-bg-subtle);
  --tblr-alert-border-color: var(--tblr-teal-border-subtle);
  --tblr-alert-link-color: var(--tblr-teal-text-emphasis);
}

.alert-cyan {
  --tblr-alert-color: var(--tblr-cyan-text-emphasis);
  --tblr-alert-bg: var(--tblr-cyan-bg-subtle);
  --tblr-alert-border-color: var(--tblr-cyan-border-subtle);
  --tblr-alert-link-color: var(--tblr-cyan-text-emphasis);
}

.alert-x {
  --tblr-alert-color: var(--tblr-x-text-emphasis);
  --tblr-alert-bg: var(--tblr-x-bg-subtle);
  --tblr-alert-border-color: var(--tblr-x-border-subtle);
  --tblr-alert-link-color: var(--tblr-x-text-emphasis);
}

.alert-facebook {
  --tblr-alert-color: var(--tblr-facebook-text-emphasis);
  --tblr-alert-bg: var(--tblr-facebook-bg-subtle);
  --tblr-alert-border-color: var(--tblr-facebook-border-subtle);
  --tblr-alert-link-color: var(--tblr-facebook-text-emphasis);
}

.alert-twitter {
  --tblr-alert-color: var(--tblr-twitter-text-emphasis);
  --tblr-alert-bg: var(--tblr-twitter-bg-subtle);
  --tblr-alert-border-color: var(--tblr-twitter-border-subtle);
  --tblr-alert-link-color: var(--tblr-twitter-text-emphasis);
}

.alert-linkedin {
  --tblr-alert-color: var(--tblr-linkedin-text-emphasis);
  --tblr-alert-bg: var(--tblr-linkedin-bg-subtle);
  --tblr-alert-border-color: var(--tblr-linkedin-border-subtle);
  --tblr-alert-link-color: var(--tblr-linkedin-text-emphasis);
}

.alert-google {
  --tblr-alert-color: var(--tblr-google-text-emphasis);
  --tblr-alert-bg: var(--tblr-google-bg-subtle);
  --tblr-alert-border-color: var(--tblr-google-border-subtle);
  --tblr-alert-link-color: var(--tblr-google-text-emphasis);
}

.alert-youtube {
  --tblr-alert-color: var(--tblr-youtube-text-emphasis);
  --tblr-alert-bg: var(--tblr-youtube-bg-subtle);
  --tblr-alert-border-color: var(--tblr-youtube-border-subtle);
  --tblr-alert-link-color: var(--tblr-youtube-text-emphasis);
}

.alert-vimeo {
  --tblr-alert-color: var(--tblr-vimeo-text-emphasis);
  --tblr-alert-bg: var(--tblr-vimeo-bg-subtle);
  --tblr-alert-border-color: var(--tblr-vimeo-border-subtle);
  --tblr-alert-link-color: var(--tblr-vimeo-text-emphasis);
}

.alert-dribbble {
  --tblr-alert-color: var(--tblr-dribbble-text-emphasis);
  --tblr-alert-bg: var(--tblr-dribbble-bg-subtle);
  --tblr-alert-border-color: var(--tblr-dribbble-border-subtle);
  --tblr-alert-link-color: var(--tblr-dribbble-text-emphasis);
}

.alert-github {
  --tblr-alert-color: var(--tblr-github-text-emphasis);
  --tblr-alert-bg: var(--tblr-github-bg-subtle);
  --tblr-alert-border-color: var(--tblr-github-border-subtle);
  --tblr-alert-link-color: var(--tblr-github-text-emphasis);
}

.alert-instagram {
  --tblr-alert-color: var(--tblr-instagram-text-emphasis);
  --tblr-alert-bg: var(--tblr-instagram-bg-subtle);
  --tblr-alert-border-color: var(--tblr-instagram-border-subtle);
  --tblr-alert-link-color: var(--tblr-instagram-text-emphasis);
}

.alert-pinterest {
  --tblr-alert-color: var(--tblr-pinterest-text-emphasis);
  --tblr-alert-bg: var(--tblr-pinterest-bg-subtle);
  --tblr-alert-border-color: var(--tblr-pinterest-border-subtle);
  --tblr-alert-link-color: var(--tblr-pinterest-text-emphasis);
}

.alert-vk {
  --tblr-alert-color: var(--tblr-vk-text-emphasis);
  --tblr-alert-bg: var(--tblr-vk-bg-subtle);
  --tblr-alert-border-color: var(--tblr-vk-border-subtle);
  --tblr-alert-link-color: var(--tblr-vk-text-emphasis);
}

.alert-rss {
  --tblr-alert-color: var(--tblr-rss-text-emphasis);
  --tblr-alert-bg: var(--tblr-rss-bg-subtle);
  --tblr-alert-border-color: var(--tblr-rss-border-subtle);
  --tblr-alert-link-color: var(--tblr-rss-text-emphasis);
}

.alert-flickr {
  --tblr-alert-color: var(--tblr-flickr-text-emphasis);
  --tblr-alert-bg: var(--tblr-flickr-bg-subtle);
  --tblr-alert-border-color: var(--tblr-flickr-border-subtle);
  --tblr-alert-link-color: var(--tblr-flickr-text-emphasis);
}

.alert-bitbucket {
  --tblr-alert-color: var(--tblr-bitbucket-text-emphasis);
  --tblr-alert-bg: var(--tblr-bitbucket-bg-subtle);
  --tblr-alert-border-color: var(--tblr-bitbucket-border-subtle);
  --tblr-alert-link-color: var(--tblr-bitbucket-text-emphasis);
}

.alert-tabler {
  --tblr-alert-color: var(--tblr-tabler-text-emphasis);
  --tblr-alert-bg: var(--tblr-tabler-bg-subtle);
  --tblr-alert-border-color: var(--tblr-tabler-border-subtle);
  --tblr-alert-link-color: var(--tblr-tabler-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: .5rem;
  }
}

.progress, .progress-stacked {
  --tblr-progress-height: .5rem;
  --tblr-progress-font-size: .65625rem;
  --tblr-progress-bg: var(--tblr-border-color);
  --tblr-progress-border-radius: var(--tblr-border-radius);
  --tblr-progress-box-shadow: var(--tblr-box-shadow-inset);
  --tblr-progress-bar-color: #fff;
  --tblr-progress-bar-bg: var(--tblr-primary);
  --tblr-progress-bar-transition: width .6s ease;
  height: var(--tblr-progress-height);
  font-size: var(--tblr-progress-font-size);
  background-color: var(--tblr-progress-bg);
  border-radius: var(--tblr-progress-border-radius);
  box-shadow: var(--tblr-progress-box-shadow);
  display: flex;
  overflow: hidden;
}

.progress-bar {
  color: var(--tblr-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--tblr-progress-bar-bg);
  transition: var(--tblr-progress-bar-transition);
  flex-direction: column;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: var(--tblr-progress-height) var(--tblr-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --tblr-list-group-color: var(--tblr-body-color);
  --tblr-list-group-bg: inherit;
  --tblr-list-group-border-color: var(--tblr-border-color);
  --tblr-list-group-border-width: var(--tblr-border-width);
  --tblr-list-group-border-radius: var(--tblr-border-radius);
  --tblr-list-group-item-padding-x: 1.25rem;
  --tblr-list-group-item-padding-y: 1rem;
  --tblr-list-group-action-color: inherit;
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: rgba(var(--tblr-secondary-rgb), .08);
  --tblr-list-group-action-active-color: var(--tblr-body-color);
  --tblr-list-group-action-active-bg: var(--tblr-secondary-bg);
  --tblr-list-group-disabled-color: var(--tblr-secondary-color);
  --tblr-list-group-disabled-bg: inherit;
  --tblr-list-group-active-color: inherit;
  --tblr-list-group-active-bg: var(--tblr-active-bg);
  --tblr-list-group-active-border-color: var(--tblr-border-color);
  border-radius: var(--tblr-list-group-border-radius);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

.list-group-numbered > .list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--tblr-list-group-action-color);
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--tblr-list-group-action-hover-color);
  background-color: var(--tblr-list-group-action-hover-bg);
  text-decoration: none;
}

.list-group-item-action:active {
  color: var(--tblr-list-group-action-active-color);
  background-color: var(--tblr-list-group-action-active-bg);
}

.list-group-item {
  padding: var(--tblr-list-group-item-padding-y) var(--tblr-list-group-item-padding-x);
  color: var(--tblr-list-group-color);
  background-color: var(--tblr-list-group-bg);
  border: var(--tblr-list-group-border-width) solid var(--tblr-list-group-border-color);
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: var(--tblr-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--tblr-list-group-disabled-bg);
}

.list-group-item.active {
  z-index: 2;
  color: var(--tblr-list-group-active-color);
  background-color: var(--tblr-list-group-active-bg);
  border-color: var(--tblr-list-group-active-border-color);
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--tblr-list-group-border-width));
  border-top-width: var(--tblr-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--tblr-list-group-border-radius);
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--tblr-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--tblr-list-group-border-width);
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--tblr-list-group-border-width));
  border-left-width: var(--tblr-list-group-border-width);
}

@media (width >= 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--tblr-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--tblr-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--tblr-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--tblr-list-group-border-width));
    border-left-width: var(--tblr-list-group-border-width);
  }
}

@media (width >= 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--tblr-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--tblr-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--tblr-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--tblr-list-group-border-width));
    border-left-width: var(--tblr-list-group-border-width);
  }
}

@media (width >= 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--tblr-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--tblr-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--tblr-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--tblr-list-group-border-width));
    border-left-width: var(--tblr-list-group-border-width);
  }
}

@media (width >= 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--tblr-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--tblr-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--tblr-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--tblr-list-group-border-width));
    border-left-width: var(--tblr-list-group-border-width);
  }
}

@media (width >= 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--tblr-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--tblr-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--tblr-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--tblr-list-group-border-width));
    border-left-width: var(--tblr-list-group-border-width);
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 var(--tblr-list-group-border-width);
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --tblr-list-group-color: var(--tblr-primary-text-emphasis);
  --tblr-list-group-bg: var(--tblr-primary-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-primary-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-primary-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-primary-border-subtle);
  --tblr-list-group-active-color: var(--tblr-primary-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-primary-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-primary-text-emphasis);
}

.list-group-item-secondary {
  --tblr-list-group-color: var(--tblr-secondary-text-emphasis);
  --tblr-list-group-bg: var(--tblr-secondary-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-secondary-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-secondary-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-secondary-border-subtle);
  --tblr-list-group-active-color: var(--tblr-secondary-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-secondary-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-secondary-text-emphasis);
}

.list-group-item-success {
  --tblr-list-group-color: var(--tblr-success-text-emphasis);
  --tblr-list-group-bg: var(--tblr-success-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-success-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-success-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-success-border-subtle);
  --tblr-list-group-active-color: var(--tblr-success-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-success-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-success-text-emphasis);
}

.list-group-item-info {
  --tblr-list-group-color: var(--tblr-info-text-emphasis);
  --tblr-list-group-bg: var(--tblr-info-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-info-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-info-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-info-border-subtle);
  --tblr-list-group-active-color: var(--tblr-info-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-info-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-info-text-emphasis);
}

.list-group-item-warning {
  --tblr-list-group-color: var(--tblr-warning-text-emphasis);
  --tblr-list-group-bg: var(--tblr-warning-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-warning-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-warning-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-warning-border-subtle);
  --tblr-list-group-active-color: var(--tblr-warning-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-warning-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-warning-text-emphasis);
}

.list-group-item-danger {
  --tblr-list-group-color: var(--tblr-danger-text-emphasis);
  --tblr-list-group-bg: var(--tblr-danger-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-danger-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-danger-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-danger-border-subtle);
  --tblr-list-group-active-color: var(--tblr-danger-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-danger-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-danger-text-emphasis);
}

.list-group-item-light {
  --tblr-list-group-color: var(--tblr-light-text-emphasis);
  --tblr-list-group-bg: var(--tblr-light-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-light-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-light-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-light-border-subtle);
  --tblr-list-group-active-color: var(--tblr-light-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-light-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-light-text-emphasis);
}

.list-group-item-dark {
  --tblr-list-group-color: var(--tblr-dark-text-emphasis);
  --tblr-list-group-bg: var(--tblr-dark-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-dark-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-dark-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-dark-border-subtle);
  --tblr-list-group-active-color: var(--tblr-dark-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-dark-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-dark-text-emphasis);
}

.list-group-item-muted {
  --tblr-list-group-color: var(--tblr-muted-text-emphasis);
  --tblr-list-group-bg: var(--tblr-muted-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-muted-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-muted-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-muted-border-subtle);
  --tblr-list-group-active-color: var(--tblr-muted-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-muted-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-muted-text-emphasis);
}

.list-group-item-blue {
  --tblr-list-group-color: var(--tblr-blue-text-emphasis);
  --tblr-list-group-bg: var(--tblr-blue-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-blue-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-blue-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-blue-border-subtle);
  --tblr-list-group-active-color: var(--tblr-blue-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-blue-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-blue-text-emphasis);
}

.list-group-item-azure {
  --tblr-list-group-color: var(--tblr-azure-text-emphasis);
  --tblr-list-group-bg: var(--tblr-azure-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-azure-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-azure-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-azure-border-subtle);
  --tblr-list-group-active-color: var(--tblr-azure-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-azure-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-azure-text-emphasis);
}

.list-group-item-indigo {
  --tblr-list-group-color: var(--tblr-indigo-text-emphasis);
  --tblr-list-group-bg: var(--tblr-indigo-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-indigo-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-indigo-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-indigo-border-subtle);
  --tblr-list-group-active-color: var(--tblr-indigo-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-indigo-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-indigo-text-emphasis);
}

.list-group-item-purple {
  --tblr-list-group-color: var(--tblr-purple-text-emphasis);
  --tblr-list-group-bg: var(--tblr-purple-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-purple-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-purple-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-purple-border-subtle);
  --tblr-list-group-active-color: var(--tblr-purple-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-purple-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-purple-text-emphasis);
}

.list-group-item-pink {
  --tblr-list-group-color: var(--tblr-pink-text-emphasis);
  --tblr-list-group-bg: var(--tblr-pink-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-pink-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-pink-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-pink-border-subtle);
  --tblr-list-group-active-color: var(--tblr-pink-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-pink-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-pink-text-emphasis);
}

.list-group-item-red {
  --tblr-list-group-color: var(--tblr-red-text-emphasis);
  --tblr-list-group-bg: var(--tblr-red-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-red-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-red-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-red-border-subtle);
  --tblr-list-group-active-color: var(--tblr-red-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-red-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-red-text-emphasis);
}

.list-group-item-orange {
  --tblr-list-group-color: var(--tblr-orange-text-emphasis);
  --tblr-list-group-bg: var(--tblr-orange-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-orange-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-orange-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-orange-border-subtle);
  --tblr-list-group-active-color: var(--tblr-orange-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-orange-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-orange-text-emphasis);
}

.list-group-item-yellow {
  --tblr-list-group-color: var(--tblr-yellow-text-emphasis);
  --tblr-list-group-bg: var(--tblr-yellow-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-yellow-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-yellow-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-yellow-border-subtle);
  --tblr-list-group-active-color: var(--tblr-yellow-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-yellow-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-yellow-text-emphasis);
}

.list-group-item-lime {
  --tblr-list-group-color: var(--tblr-lime-text-emphasis);
  --tblr-list-group-bg: var(--tblr-lime-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-lime-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-lime-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-lime-border-subtle);
  --tblr-list-group-active-color: var(--tblr-lime-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-lime-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-lime-text-emphasis);
}

.list-group-item-green {
  --tblr-list-group-color: var(--tblr-green-text-emphasis);
  --tblr-list-group-bg: var(--tblr-green-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-green-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-green-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-green-border-subtle);
  --tblr-list-group-active-color: var(--tblr-green-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-green-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-green-text-emphasis);
}

.list-group-item-teal {
  --tblr-list-group-color: var(--tblr-teal-text-emphasis);
  --tblr-list-group-bg: var(--tblr-teal-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-teal-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-teal-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-teal-border-subtle);
  --tblr-list-group-active-color: var(--tblr-teal-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-teal-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-teal-text-emphasis);
}

.list-group-item-cyan {
  --tblr-list-group-color: var(--tblr-cyan-text-emphasis);
  --tblr-list-group-bg: var(--tblr-cyan-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-cyan-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-cyan-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-cyan-border-subtle);
  --tblr-list-group-active-color: var(--tblr-cyan-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-cyan-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-cyan-text-emphasis);
}

.list-group-item-x {
  --tblr-list-group-color: var(--tblr-x-text-emphasis);
  --tblr-list-group-bg: var(--tblr-x-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-x-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-x-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-x-border-subtle);
  --tblr-list-group-active-color: var(--tblr-x-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-x-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-x-text-emphasis);
}

.list-group-item-facebook {
  --tblr-list-group-color: var(--tblr-facebook-text-emphasis);
  --tblr-list-group-bg: var(--tblr-facebook-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-facebook-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-facebook-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-facebook-border-subtle);
  --tblr-list-group-active-color: var(--tblr-facebook-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-facebook-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-facebook-text-emphasis);
}

.list-group-item-twitter {
  --tblr-list-group-color: var(--tblr-twitter-text-emphasis);
  --tblr-list-group-bg: var(--tblr-twitter-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-twitter-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-twitter-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-twitter-border-subtle);
  --tblr-list-group-active-color: var(--tblr-twitter-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-twitter-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-twitter-text-emphasis);
}

.list-group-item-linkedin {
  --tblr-list-group-color: var(--tblr-linkedin-text-emphasis);
  --tblr-list-group-bg: var(--tblr-linkedin-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-linkedin-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-linkedin-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-linkedin-border-subtle);
  --tblr-list-group-active-color: var(--tblr-linkedin-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-linkedin-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-linkedin-text-emphasis);
}

.list-group-item-google {
  --tblr-list-group-color: var(--tblr-google-text-emphasis);
  --tblr-list-group-bg: var(--tblr-google-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-google-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-google-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-google-border-subtle);
  --tblr-list-group-active-color: var(--tblr-google-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-google-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-google-text-emphasis);
}

.list-group-item-youtube {
  --tblr-list-group-color: var(--tblr-youtube-text-emphasis);
  --tblr-list-group-bg: var(--tblr-youtube-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-youtube-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-youtube-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-youtube-border-subtle);
  --tblr-list-group-active-color: var(--tblr-youtube-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-youtube-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-youtube-text-emphasis);
}

.list-group-item-vimeo {
  --tblr-list-group-color: var(--tblr-vimeo-text-emphasis);
  --tblr-list-group-bg: var(--tblr-vimeo-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-vimeo-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-vimeo-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-vimeo-border-subtle);
  --tblr-list-group-active-color: var(--tblr-vimeo-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-vimeo-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-vimeo-text-emphasis);
}

.list-group-item-dribbble {
  --tblr-list-group-color: var(--tblr-dribbble-text-emphasis);
  --tblr-list-group-bg: var(--tblr-dribbble-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-dribbble-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-dribbble-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-dribbble-border-subtle);
  --tblr-list-group-active-color: var(--tblr-dribbble-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-dribbble-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-dribbble-text-emphasis);
}

.list-group-item-github {
  --tblr-list-group-color: var(--tblr-github-text-emphasis);
  --tblr-list-group-bg: var(--tblr-github-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-github-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-github-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-github-border-subtle);
  --tblr-list-group-active-color: var(--tblr-github-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-github-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-github-text-emphasis);
}

.list-group-item-instagram {
  --tblr-list-group-color: var(--tblr-instagram-text-emphasis);
  --tblr-list-group-bg: var(--tblr-instagram-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-instagram-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-instagram-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-instagram-border-subtle);
  --tblr-list-group-active-color: var(--tblr-instagram-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-instagram-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-instagram-text-emphasis);
}

.list-group-item-pinterest {
  --tblr-list-group-color: var(--tblr-pinterest-text-emphasis);
  --tblr-list-group-bg: var(--tblr-pinterest-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-pinterest-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-pinterest-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-pinterest-border-subtle);
  --tblr-list-group-active-color: var(--tblr-pinterest-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-pinterest-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-pinterest-text-emphasis);
}

.list-group-item-vk {
  --tblr-list-group-color: var(--tblr-vk-text-emphasis);
  --tblr-list-group-bg: var(--tblr-vk-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-vk-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-vk-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-vk-border-subtle);
  --tblr-list-group-active-color: var(--tblr-vk-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-vk-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-vk-text-emphasis);
}

.list-group-item-rss {
  --tblr-list-group-color: var(--tblr-rss-text-emphasis);
  --tblr-list-group-bg: var(--tblr-rss-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-rss-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-rss-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-rss-border-subtle);
  --tblr-list-group-active-color: var(--tblr-rss-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-rss-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-rss-text-emphasis);
}

.list-group-item-flickr {
  --tblr-list-group-color: var(--tblr-flickr-text-emphasis);
  --tblr-list-group-bg: var(--tblr-flickr-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-flickr-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-flickr-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-flickr-border-subtle);
  --tblr-list-group-active-color: var(--tblr-flickr-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-flickr-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-flickr-text-emphasis);
}

.list-group-item-bitbucket {
  --tblr-list-group-color: var(--tblr-bitbucket-text-emphasis);
  --tblr-list-group-bg: var(--tblr-bitbucket-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-bitbucket-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-bitbucket-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-bitbucket-border-subtle);
  --tblr-list-group-active-color: var(--tblr-bitbucket-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-bitbucket-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-bitbucket-text-emphasis);
}

.list-group-item-tabler {
  --tblr-list-group-color: var(--tblr-tabler-text-emphasis);
  --tblr-list-group-bg: var(--tblr-tabler-bg-subtle);
  --tblr-list-group-border-color: var(--tblr-tabler-border-subtle);
  --tblr-list-group-action-hover-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-hover-bg: var(--tblr-tabler-border-subtle);
  --tblr-list-group-action-active-color: var(--tblr-emphasis-color);
  --tblr-list-group-action-active-bg: var(--tblr-tabler-border-subtle);
  --tblr-list-group-active-color: var(--tblr-tabler-bg-subtle);
  --tblr-list-group-active-bg: var(--tblr-tabler-text-emphasis);
  --tblr-list-group-active-border-color: var(--tblr-tabler-text-emphasis);
}

.btn-close {
  --tblr-btn-close-color: #182433;
  --tblr-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23182433'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --tblr-btn-close-opacity: .4;
  --tblr-btn-close-hover-opacity: .75;
  --tblr-btn-close-focus-shadow: 0 0 0 .25rem rgba(var(--tblr-primary-rgb), .25);
  --tblr-btn-close-focus-opacity: 1;
  --tblr-btn-close-disabled-opacity: .25;
  --tblr-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  color: var(--tblr-btn-close-color);
  background: transparent var(--tblr-btn-close-bg) center / 1em auto no-repeat;
  opacity: var(--tblr-btn-close-opacity);
  border: 0;
  border-radius: 4px;
  padding: .25em;
}

.btn-close:hover {
  color: var(--tblr-btn-close-color);
  opacity: var(--tblr-btn-close-hover-opacity);
  text-decoration: none;
}

.btn-close:focus {
  box-shadow: var(--tblr-btn-close-focus-shadow);
  opacity: var(--tblr-btn-close-focus-opacity);
  outline: 0;
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: var(--tblr-btn-close-disabled-opacity);
}

.btn-close-white, [data-bs-theme="dark"] .btn-close, body[data-bs-theme="dark"] [data-bs-theme="light"] .btn-close {
  filter: var(--tblr-btn-close-white-filter);
}

.toast {
  --tblr-toast-zindex: 1090;
  --tblr-toast-padding-x: .75rem;
  --tblr-toast-padding-y: .5rem;
  --tblr-toast-spacing: calc(var(--tblr-page-padding) * 2);
  --tblr-toast-max-width: 350px;
  --tblr-toast-font-size: .875rem;
  --tblr-toast-color: ;
  --tblr-toast-bg: var(--tblr-bg-surface);
  --tblr-toast-border-width: var(--tblr-border-width);
  --tblr-toast-border-color: var(--tblr-border-color);
  --tblr-toast-border-radius: var(--tblr-border-radius);
  --tblr-toast-box-shadow: var(--tblr-box-shadow);
  --tblr-toast-header-color: var(--tblr-gray-500);
  --tblr-toast-header-bg: rgba(var(--tblr-body-bg-rgb), .85);
  --tblr-toast-header-border-color: var(--tblr-border-color);
  width: var(--tblr-toast-max-width);
  max-width: 100%;
  font-size: var(--tblr-toast-font-size);
  color: var(--tblr-toast-color);
  pointer-events: auto;
  background-color: var(--tblr-toast-bg);
  border: var(--tblr-toast-border-width) solid var(--tblr-toast-border-color);
  box-shadow: var(--tblr-toast-box-shadow);
  border-radius: var(--tblr-toast-border-radius);
  background-clip: padding-box;
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  --tblr-toast-zindex: 1090;
  z-index: var(--tblr-toast-zindex);
  pointer-events: none;
  width: max-content;
  max-width: 100%;
  position: absolute;
}

.toast-container > :not(:last-child) {
  margin-bottom: var(--tblr-toast-spacing);
}

.toast-header {
  padding: var(--tblr-toast-padding-y) var(--tblr-toast-padding-x);
  color: var(--tblr-toast-header-color);
  background-color: var(--tblr-toast-header-bg);
  border-bottom: var(--tblr-toast-border-width) solid var(--tblr-toast-header-border-color);
  border-top-left-radius: calc(var(--tblr-toast-border-radius)  - var(--tblr-toast-border-width));
  border-top-right-radius: calc(var(--tblr-toast-border-radius)  - var(--tblr-toast-border-width));
  background-clip: padding-box;
  align-items: center;
  display: flex;
}

.toast-header .btn-close {
  margin-right: calc(-.5 * var(--tblr-toast-padding-x));
  margin-left: var(--tblr-toast-padding-x);
}

.toast-body {
  padding: var(--tblr-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --tblr-modal-zindex: 1055;
  --tblr-modal-width: 540px;
  --tblr-modal-padding: 1.5rem;
  --tblr-modal-margin: .5rem;
  --tblr-modal-color: ;
  --tblr-modal-bg: var(--tblr-bg-surface);
  --tblr-modal-border-color: transparent;
  --tblr-modal-border-width: var(--tblr-border-width);
  --tblr-modal-border-radius: var(--tblr-border-radius-lg);
  --tblr-modal-box-shadow: var(--tblr-box-shadow-sm);
  --tblr-modal-inner-border-radius: calc(var(--tblr-modal-border-radius)  - 1px);
  --tblr-modal-header-padding-x: 1.5rem;
  --tblr-modal-header-padding-y: 1.5rem;
  --tblr-modal-header-padding: 1.5rem;
  --tblr-modal-header-border-color: var(--tblr-border-color);
  --tblr-modal-header-border-width: var(--tblr-border-width);
  --tblr-modal-title-line-height: 1.42857;
  --tblr-modal-footer-gap: .75rem;
  --tblr-modal-footer-bg: var(--tblr-bg-surface-tertiary);
  --tblr-modal-footer-border-color: var(--tblr-border-color);
  --tblr-modal-footer-border-width: var(--tblr-border-width);
  z-index: var(--tblr-modal-zindex);
  outline: 0;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden auto;
}

.modal-dialog {
  width: auto;
  margin: var(--tblr-modal-margin);
  pointer-events: none;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -1rem);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--tblr-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  min-height: calc(100% - var(--tblr-modal-margin) * 2);
  align-items: center;
  display: flex;
}

.modal-content {
  width: 100%;
  color: var(--tblr-modal-color);
  pointer-events: auto;
  background-color: var(--tblr-modal-bg);
  border: var(--tblr-modal-border-width) solid var(--tblr-modal-border-color);
  border-radius: var(--tblr-modal-border-radius);
  box-shadow: var(--tblr-modal-box-shadow);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.modal-backdrop {
  --tblr-backdrop-zindex: 1050;
  --tblr-backdrop-bg: #182433;
  --tblr-backdrop-opacity: .24;
  z-index: var(--tblr-backdrop-zindex);
  background-color: var(--tblr-backdrop-bg);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--tblr-backdrop-opacity);
}

.modal-header {
  padding: var(--tblr-modal-header-padding);
  border-bottom: var(--tblr-modal-header-border-width) solid var(--tblr-modal-header-border-color);
  border-top-left-radius: var(--tblr-modal-inner-border-radius);
  border-top-right-radius: var(--tblr-modal-inner-border-radius);
  flex-shrink: 0;
  align-items: center;
  display: flex;
}

.modal-header .btn-close {
  padding: calc(var(--tblr-modal-header-padding-y) * .5) calc(var(--tblr-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--tblr-modal-header-padding-y)) calc(-.5 * var(--tblr-modal-header-padding-x)) calc(-.5 * var(--tblr-modal-header-padding-y)) auto;
}

.modal-title {
  line-height: var(--tblr-modal-title-line-height);
  margin-bottom: 0;
}

.modal-body {
  padding: var(--tblr-modal-padding);
  flex: auto;
  position: relative;
}

.modal-footer {
  padding: calc(var(--tblr-modal-padding)  - var(--tblr-modal-footer-gap) * .5);
  background-color: var(--tblr-modal-footer-bg);
  border-top: var(--tblr-modal-footer-border-width) solid var(--tblr-modal-footer-border-color);
  border-bottom-right-radius: var(--tblr-modal-inner-border-radius);
  border-bottom-left-radius: var(--tblr-modal-inner-border-radius);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.modal-footer > * {
  margin: calc(var(--tblr-modal-footer-gap) * .5);
}

@media (width >= 576px) {
  .modal {
    --tblr-modal-margin: 1.75rem;
    --tblr-modal-box-shadow: var(--tblr-box-shadow);
  }

  .modal-dialog {
    max-width: var(--tblr-modal-width);
    margin-left: auto;
    margin-right: auto;
  }

  .modal-sm {
    --tblr-modal-width: 380px;
  }
}

@media (width >= 992px) {
  .modal-lg, .modal-xl {
    --tblr-modal-width: 720px;
  }
}

@media (width >= 1200px) {
  .modal-xl {
    --tblr-modal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  border: 0;
  border-radius: 0;
  height: 100%;
}

.modal-fullscreen .modal-header, .modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (width <= 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-sm-down .modal-header, .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-md-down .modal-header, .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-lg-down .modal-header, .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-xl-down .modal-header, .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-xxl-down .modal-header, .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}

.tooltip {
  --tblr-tooltip-zindex: 1080;
  --tblr-tooltip-max-width: 200px;
  --tblr-tooltip-padding-x: var(--tblr-spacer-2);
  --tblr-tooltip-padding-y: var(--tblr-spacer-2);
  --tblr-tooltip-margin: ;
  --tblr-tooltip-font-size: .765625rem;
  --tblr-tooltip-color: var(--tblr-light);
  --tblr-tooltip-bg: var(--tblr-bg-surface-dark);
  --tblr-tooltip-border-radius: var(--tblr-border-radius);
  --tblr-tooltip-opacity: .9;
  --tblr-tooltip-arrow-width: .8rem;
  --tblr-tooltip-arrow-height: .4rem;
  z-index: var(--tblr-tooltip-zindex);
  margin: var(--tblr-tooltip-margin);
  font-family: var(--tblr-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857;
  font-size: var(--tblr-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
  text-decoration: none;
  display: block;
}

.tooltip.show {
  opacity: var(--tblr-tooltip-opacity);
}

.tooltip .tooltip-arrow {
  width: var(--tblr-tooltip-arrow-width);
  height: var(--tblr-tooltip-arrow-height);
  display: block;
}

.tooltip .tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: calc(-1 * var(--tblr-tooltip-arrow-height));
}

.bs-tooltip-top .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow:before {
  border-width: var(--tblr-tooltip-arrow-height) calc(var(--tblr-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--tblr-tooltip-bg);
  top: -1px;
}

.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: calc(-1 * var(--tblr-tooltip-arrow-height));
  width: var(--tblr-tooltip-arrow-height);
  height: var(--tblr-tooltip-arrow-width);
}

.bs-tooltip-end .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow:before {
  border-width: calc(var(--tblr-tooltip-arrow-width) * .5) var(--tblr-tooltip-arrow-height) calc(var(--tblr-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--tblr-tooltip-bg);
  right: -1px;
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: calc(-1 * var(--tblr-tooltip-arrow-height));
}

.bs-tooltip-bottom .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow:before {
  border-width: 0 calc(var(--tblr-tooltip-arrow-width) * .5) var(--tblr-tooltip-arrow-height);
  border-bottom-color: var(--tblr-tooltip-bg);
  bottom: -1px;
}

.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: calc(-1 * var(--tblr-tooltip-arrow-height));
  width: var(--tblr-tooltip-arrow-height);
  height: var(--tblr-tooltip-arrow-width);
}

.bs-tooltip-start .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow:before {
  border-width: calc(var(--tblr-tooltip-arrow-width) * .5) 0 calc(var(--tblr-tooltip-arrow-width) * .5) var(--tblr-tooltip-arrow-height);
  border-left-color: var(--tblr-tooltip-bg);
  left: -1px;
}

.tooltip-inner {
  max-width: var(--tblr-tooltip-max-width);
  padding: var(--tblr-tooltip-padding-y) var(--tblr-tooltip-padding-x);
  color: var(--tblr-tooltip-color);
  text-align: center;
  background-color: var(--tblr-tooltip-bg);
  border-radius: var(--tblr-tooltip-border-radius);
}

.popover {
  --tblr-popover-zindex: 1070;
  --tblr-popover-max-width: 276px;
  --tblr-popover-font-size: .765625rem;
  --tblr-popover-bg: var(--tblr-bg-surface);
  --tblr-popover-border-width: var(--tblr-border-width);
  --tblr-popover-border-color: var(--tblr-border-color);
  --tblr-popover-border-radius: var(--tblr-border-radius-lg);
  --tblr-popover-inner-border-radius: calc(var(--tblr-border-radius-lg)  - var(--tblr-border-width));
  --tblr-popover-box-shadow: var(--tblr-box-shadow);
  --tblr-popover-header-padding-x: 1rem;
  --tblr-popover-header-padding-y: .5rem;
  --tblr-popover-header-font-size: .875rem;
  --tblr-popover-header-color: inherit;
  --tblr-popover-header-bg: transparent;
  --tblr-popover-body-padding-x: 1rem;
  --tblr-popover-body-padding-y: 1rem;
  --tblr-popover-body-color: inherit;
  --tblr-popover-arrow-width: 1rem;
  --tblr-popover-arrow-height: .5rem;
  --tblr-popover-arrow-border: var(--tblr-popover-border-color);
  z-index: var(--tblr-popover-zindex);
  max-width: var(--tblr-popover-max-width);
  font-family: var(--tblr-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857;
  font-size: var(--tblr-popover-font-size);
  word-wrap: break-word;
  background-color: var(--tblr-popover-bg);
  border: var(--tblr-popover-border-width) solid var(--tblr-popover-border-color);
  border-radius: var(--tblr-popover-border-radius);
  box-shadow: var(--tblr-popover-box-shadow);
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}

.popover .popover-arrow {
  width: var(--tblr-popover-arrow-width);
  height: var(--tblr-popover-arrow-height);
  display: block;
}

.popover .popover-arrow:before, .popover .popover-arrow:after {
  content: "";
  border: 0 solid #0000;
  display: block;
  position: absolute;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-1 * (var(--tblr-popover-arrow-height))  - var(--tblr-popover-border-width));
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before, .bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  border-width: var(--tblr-popover-arrow-height) calc(var(--tblr-popover-arrow-width) * .5) 0;
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before {
  border-top-color: var(--tblr-popover-arrow-border);
  bottom: 0;
}

.bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  bottom: var(--tblr-popover-border-width);
  border-top-color: var(--tblr-popover-bg);
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-1 * (var(--tblr-popover-arrow-height))  - var(--tblr-popover-border-width));
  width: var(--tblr-popover-arrow-height);
  height: var(--tblr-popover-arrow-width);
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before, .bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  border-width: calc(var(--tblr-popover-arrow-width) * .5) var(--tblr-popover-arrow-height) calc(var(--tblr-popover-arrow-width) * .5) 0;
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before {
  border-right-color: var(--tblr-popover-arrow-border);
  left: 0;
}

.bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  left: var(--tblr-popover-border-width);
  border-right-color: var(--tblr-popover-bg);
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-1 * (var(--tblr-popover-arrow-height))  - var(--tblr-popover-border-width));
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before, .bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  border-width: 0 calc(var(--tblr-popover-arrow-width) * .5) var(--tblr-popover-arrow-height);
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before {
  border-bottom-color: var(--tblr-popover-arrow-border);
  top: 0;
}

.bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  top: var(--tblr-popover-border-width);
  border-bottom-color: var(--tblr-popover-bg);
}

.bs-popover-bottom .popover-header:before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header:before {
  width: var(--tblr-popover-arrow-width);
  margin-left: calc(-.5 * var(--tblr-popover-arrow-width));
  content: "";
  border-bottom: var(--tblr-popover-border-width) solid var(--tblr-popover-header-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-1 * (var(--tblr-popover-arrow-height))  - var(--tblr-popover-border-width));
  width: var(--tblr-popover-arrow-height);
  height: var(--tblr-popover-arrow-width);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before, .bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  border-width: calc(var(--tblr-popover-arrow-width) * .5) 0 calc(var(--tblr-popover-arrow-width) * .5) var(--tblr-popover-arrow-height);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before {
  border-left-color: var(--tblr-popover-arrow-border);
  right: 0;
}

.bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  right: var(--tblr-popover-border-width);
  border-left-color: var(--tblr-popover-bg);
}

.popover-header {
  padding: var(--tblr-popover-header-padding-y) var(--tblr-popover-header-padding-x);
  font-size: var(--tblr-popover-header-font-size);
  color: var(--tblr-popover-header-color);
  background-color: var(--tblr-popover-header-bg);
  border-bottom: var(--tblr-popover-border-width) solid var(--tblr-popover-border-color);
  border-top-left-radius: var(--tblr-popover-inner-border-radius);
  border-top-right-radius: var(--tblr-popover-inner-border-radius);
  margin-bottom: 0;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--tblr-popover-body-padding-y) var(--tblr-popover-body-padding-x);
  color: var(--tblr-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.carousel-item {
  float: left;
  backface-visibility: hidden;
  width: 100%;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active, .carousel-item-next, .carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start), .active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end), .active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active, .carousel-fade .carousel-item-next.carousel-item-start, .carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev, .carousel-control-next {
  z-index: 1;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  width: 15%;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev, .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='15 18 9 12 15 6'%3e%3c/polyline%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='9 18 15 12 9 6'%3e%3c/polyline%3e%3c/svg%3e");
}

.carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid #0000;
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  width: 30px;
  height: 3px;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  color: #fff;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

.carousel-dark .carousel-control-prev-icon, .carousel-dark .carousel-control-next-icon {
  filter: invert() grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-control-prev-icon, [data-bs-theme="dark"] .carousel .carousel-control-next-icon, [data-bs-theme="dark"].carousel .carousel-control-prev-icon, [data-bs-theme="dark"].carousel .carousel-control-next-icon {
  filter: invert() grayscale(100);
}

[data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target], [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-caption, [data-bs-theme="dark"].carousel .carousel-caption {
  color: #000;
}

.spinner-grow, .spinner-border {
  width: var(--tblr-spinner-width);
  height: var(--tblr-spinner-height);
  vertical-align: var(--tblr-spinner-vertical-align);
  animation: var(--tblr-spinner-animation-speed) linear infinite var(--tblr-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  --tblr-spinner-width: 1.5rem;
  --tblr-spinner-height: 1.5rem;
  --tblr-spinner-vertical-align: -.125em;
  --tblr-spinner-border-width: 2px;
  --tblr-spinner-animation-speed: .75s;
  --tblr-spinner-animation-name: spinner-border;
  border: var(--tblr-spinner-border-width) solid currentcolor;
  border-right-color: #0000;
}

.spinner-border-sm {
  --tblr-spinner-width: 1rem;
  --tblr-spinner-height: 1rem;
  --tblr-spinner-border-width: 1px;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  --tblr-spinner-width: 1.5rem;
  --tblr-spinner-height: 1.5rem;
  --tblr-spinner-vertical-align: -.125em;
  --tblr-spinner-animation-speed: .75s;
  --tblr-spinner-animation-name: spinner-grow;
  opacity: 0;
  background-color: currentColor;
}

.spinner-grow-sm {
  --tblr-spinner-width: 1rem;
  --tblr-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border, .spinner-grow {
    --tblr-spinner-animation-speed: 1.5s;
  }
}

.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --tblr-offcanvas-zindex: 1045;
  --tblr-offcanvas-width: 400px;
  --tblr-offcanvas-height: 30vh;
  --tblr-offcanvas-padding-x: 1.5rem;
  --tblr-offcanvas-padding-y: 1.5rem;
  --tblr-offcanvas-color: var(--tblr-body-color);
  --tblr-offcanvas-bg: var(--tblr-bg-surface);
  --tblr-offcanvas-border-width: var(--tblr-border-width);
  --tblr-offcanvas-border-color: var(--tblr-border-color);
  --tblr-offcanvas-box-shadow: var(--tblr-box-shadow-sm);
  --tblr-offcanvas-transition: transform .3s ease-in-out;
  --tblr-offcanvas-title-line-height: 1.42857;
}

@media (width <= 575.98px) {
  .offcanvas-sm {
    z-index: var(--tblr-offcanvas-zindex);
    max-width: 100%;
    color: var(--tblr-offcanvas-color);
    visibility: hidden;
    background-color: var(--tblr-offcanvas-bg);
    box-shadow: var(--tblr-offcanvas-box-shadow);
    transition: var(--tblr-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (width <= 575.98px) {
  .offcanvas-sm.offcanvas-start {
    width: var(--tblr-offcanvas-width);
    border-right: var(--tblr-offcanvas-border-width) solid var(--tblr-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-sm.offcanvas-end {
    width: var(--tblr-offcanvas-width);
    border-left: var(--tblr-offcanvas-border-width) solid var(--tblr-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-sm.offcanvas-top {
    height: var(--tblr-offcanvas-height);
    border-bottom: var(--tblr-offcanvas-border-width) solid var(--tblr-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-sm.offcanvas-bottom {
    height: var(--tblr-offcanvas-height);
    border-top: var(--tblr-offcanvas-border-width) solid var(--tblr-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}

@media (width >= 576px) {
  .offcanvas-sm {
    --tblr-offcanvas-height: auto;
    --tblr-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-sm .offcanvas-header {
    display: none;
  }

  .offcanvas-sm .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 767.98px) {
  .offcanvas-md {
    z-index: var(--tblr-offcanvas-zindex);
    max-width: 100%;
    color: var(--tblr-offcanvas-color);
    visibility: hidden;
    background-color: var(--tblr-offcanvas-bg);
    box-shadow: var(--tblr-offcanvas-box-shadow);
    transition: var(--tblr-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (width <= 767.98px) {
  .offcanvas-md.offcanvas-start {
    width: var(--tblr-offcanvas-width);
    border-right: var(--tblr-offcanvas-border-width) solid var(--tblr-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-md.offcanvas-end {
    width: var(--tblr-offcanvas-width);
    border-left: var(--tblr-offcanvas-border-width) solid var(--tblr-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-md.offcanvas-top {
    height: var(--tblr-offcanvas-height);
    border-bottom: var(--tblr-offcanvas-border-width) solid var(--tblr-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-md.offcanvas-bottom {
    height: var(--tblr-offcanvas-height);
    border-top: var(--tblr-offcanvas-border-width) solid var(--tblr-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}

@media (width >= 768px) {
  .offcanvas-md {
    --tblr-offcanvas-height: auto;
    --tblr-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-md .offcanvas-header {
    display: none;
  }

  .offcanvas-md .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 991.98px) {
  .offcanvas-lg {
    z-index: var(--tblr-offcanvas-zindex);
    max-width: 100%;
    color: var(--tblr-offcanvas-color);
    visibility: hidden;
    background-color: var(--tblr-offcanvas-bg);
    box-shadow: var(--tblr-offcanvas-box-shadow);
    transition: var(--tblr-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (width <= 991.98px) {
  .offcanvas-lg.offcanvas-start {
    width: var(--tblr-offcanvas-width);
    border-right: var(--tblr-offcanvas-border-width) solid var(--tblr-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-lg.offcanvas-end {
    width: var(--tblr-offcanvas-width);
    border-left: var(--tblr-offcanvas-border-width) solid var(--tblr-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-lg.offcanvas-top {
    height: var(--tblr-offcanvas-height);
    border-bottom: var(--tblr-offcanvas-border-width) solid var(--tblr-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-lg.offcanvas-bottom {
    height: var(--tblr-offcanvas-height);
    border-top: var(--tblr-offcanvas-border-width) solid var(--tblr-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}

@media (width >= 992px) {
  .offcanvas-lg {
    --tblr-offcanvas-height: auto;
    --tblr-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-lg .offcanvas-header {
    display: none;
  }

  .offcanvas-lg .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 1199.98px) {
  .offcanvas-xl {
    z-index: var(--tblr-offcanvas-zindex);
    max-width: 100%;
    color: var(--tblr-offcanvas-color);
    visibility: hidden;
    background-color: var(--tblr-offcanvas-bg);
    box-shadow: var(--tblr-offcanvas-box-shadow);
    transition: var(--tblr-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (width <= 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    width: var(--tblr-offcanvas-width);
    border-right: var(--tblr-offcanvas-border-width) solid var(--tblr-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-xl.offcanvas-end {
    width: var(--tblr-offcanvas-width);
    border-left: var(--tblr-offcanvas-border-width) solid var(--tblr-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-xl.offcanvas-top {
    height: var(--tblr-offcanvas-height);
    border-bottom: var(--tblr-offcanvas-border-width) solid var(--tblr-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xl.offcanvas-bottom {
    height: var(--tblr-offcanvas-height);
    border-top: var(--tblr-offcanvas-border-width) solid var(--tblr-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}

@media (width >= 1200px) {
  .offcanvas-xl {
    --tblr-offcanvas-height: auto;
    --tblr-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-xl .offcanvas-header {
    display: none;
  }

  .offcanvas-xl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 1399.98px) {
  .offcanvas-xxl {
    z-index: var(--tblr-offcanvas-zindex);
    max-width: 100%;
    color: var(--tblr-offcanvas-color);
    visibility: hidden;
    background-color: var(--tblr-offcanvas-bg);
    box-shadow: var(--tblr-offcanvas-box-shadow);
    transition: var(--tblr-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}

@media (width <= 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    width: var(--tblr-offcanvas-width);
    border-right: var(--tblr-offcanvas-border-width) solid var(--tblr-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-xxl.offcanvas-end {
    width: var(--tblr-offcanvas-width);
    border-left: var(--tblr-offcanvas-border-width) solid var(--tblr-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-xxl.offcanvas-top {
    height: var(--tblr-offcanvas-height);
    border-bottom: var(--tblr-offcanvas-border-width) solid var(--tblr-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xxl.offcanvas-bottom {
    height: var(--tblr-offcanvas-height);
    border-top: var(--tblr-offcanvas-border-width) solid var(--tblr-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}

@media (width >= 1400px) {
  .offcanvas-xxl {
    --tblr-offcanvas-height: auto;
    --tblr-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-xxl .offcanvas-header {
    display: none;
  }

  .offcanvas-xxl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

.offcanvas {
  z-index: var(--tblr-offcanvas-zindex);
  max-width: 100%;
  color: var(--tblr-offcanvas-color);
  visibility: hidden;
  background-color: var(--tblr-offcanvas-bg);
  box-shadow: var(--tblr-offcanvas-box-shadow);
  transition: var(--tblr-offcanvas-transition);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.offcanvas-start {
  width: var(--tblr-offcanvas-width);
  border-right: var(--tblr-offcanvas-border-width) solid var(--tblr-offcanvas-border-color);
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.offcanvas.offcanvas-end {
  width: var(--tblr-offcanvas-width);
  border-left: var(--tblr-offcanvas-border-width) solid var(--tblr-offcanvas-border-color);
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.offcanvas.offcanvas-top {
  height: var(--tblr-offcanvas-height);
  border-bottom: var(--tblr-offcanvas-border-width) solid var(--tblr-offcanvas-border-color);
  max-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom {
  height: var(--tblr-offcanvas-height);
  border-top: var(--tblr-offcanvas-border-width) solid var(--tblr-offcanvas-border-color);
  max-height: 100%;
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  z-index: 1040;
  background-color: #182433;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: .24;
}

.offcanvas-header {
  padding: var(--tblr-offcanvas-padding-y) var(--tblr-offcanvas-padding-x);
  align-items: center;
  display: flex;
}

.offcanvas-header .btn-close {
  padding: calc(var(--tblr-offcanvas-padding-y) * .5) calc(var(--tblr-offcanvas-padding-x) * .5);
  margin: calc(-.5 * var(--tblr-offcanvas-padding-y)) calc(-.5 * var(--tblr-offcanvas-padding-x)) calc(-.5 * var(--tblr-offcanvas-padding-y)) auto;
}

.offcanvas-title {
  line-height: var(--tblr-offcanvas-title-line-height);
  margin-bottom: 0;
}

.offcanvas-body {
  padding: var(--tblr-offcanvas-padding-y) var(--tblr-offcanvas-padding-x);
  flex-grow: 1;
  overflow-y: auto;
}

.placeholder {
  vertical-align: middle;
  cursor: wait;
  opacity: .2;
  background-color: currentColor;
  min-height: 1em;
  display: inline-block;
}

.placeholder.btn:before {
  content: "";
  display: inline-block;
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: 2s ease-in-out infinite placeholder-glow;
}

@keyframes placeholder-glow {
  50% {
    opacity: .1;
  }
}

.placeholder-wave {
  animation: 2s linear infinite placeholder-wave;
  mask-image: linear-gradient(130deg, #000 55%, #000000e6 75%, #000 95%);
  mask-size: 200% 100%;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0;
  }
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.text-bg-primary {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-primary-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-secondary-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-success-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-info-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-warning-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-danger-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #182433 !important;
  background-color: RGBA(var(--tblr-light-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-dark-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-muted {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-muted-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-blue {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-blue-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-azure {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-azure-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-indigo {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-indigo-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-purple {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-purple-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-pink {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-pink-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-red {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-red-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-orange {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-orange-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-yellow {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-yellow-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-lime {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-lime-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-green {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-green-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-teal {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-teal-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-cyan {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-cyan-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-x {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-x-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-facebook {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-facebook-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-twitter {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-twitter-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-linkedin {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-linkedin-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-google {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-google-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-youtube {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-youtube-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-vimeo {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-vimeo-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-dribbble {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-dribbble-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-github {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-github-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-instagram {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-instagram-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-pinterest {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-pinterest-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-vk {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-vk-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-rss {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-rss-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-flickr {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-flickr-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-bitbucket {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-bitbucket-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.text-bg-tabler {
  color: #f6f8fb !important;
  background-color: RGBA(var(--tblr-tabler-rgb), var(--tblr-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--tblr-primary-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-primary-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-primary-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-primary-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-primary:hover, .link-primary:focus {
  color: RGBA(5, 89, 167, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(5, 89, 167, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(5, 89, 167, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(5, 89, 167, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--tblr-secondary-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-secondary-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-secondary-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-secondary-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-secondary:hover, .link-secondary:focus {
  color: RGBA(86, 98, 116, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(86, 98, 116, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(86, 98, 116, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(86, 98, 116, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--tblr-success-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-success-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-success-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-success-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-success:hover, .link-success:focus {
  color: RGBA(38, 143, 54, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(38, 143, 54, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(38, 143, 54, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(38, 143, 54, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--tblr-info-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-info-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-info-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-info-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-info:hover, .link-info:focus {
  color: RGBA(53, 122, 180, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(53, 122, 180, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(53, 122, 180, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(53, 122, 180, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--tblr-warning-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-warning-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-warning-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-warning-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-warning:hover, .link-warning:focus {
  color: RGBA(198, 82, 6, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(198, 82, 6, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(198, 82, 6, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(198, 82, 6, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--tblr-danger-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-danger-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-danger-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-danger-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-danger:hover, .link-danger:focus {
  color: RGBA(171, 46, 46, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(171, 46, 46, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(171, 46, 46, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(171, 46, 46, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--tblr-light-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-light-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-light-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-light-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-light:hover, .link-light:focus {
  color: RGBA(248, 249, 252, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(248, 249, 252, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(248, 249, 252, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(248, 249, 252, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--tblr-dark-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-dark-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-dark-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-dark-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-dark:hover, .link-dark:focus {
  color: RGBA(19, 29, 41, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(19, 29, 41, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(19, 29, 41, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(19, 29, 41, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-muted {
  color: RGBA(var(--tblr-muted-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-muted-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-muted-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-muted-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-muted:hover, .link-muted:focus {
  color: RGBA(86, 98, 116, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(86, 98, 116, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(86, 98, 116, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(86, 98, 116, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-blue {
  color: RGBA(var(--tblr-blue-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-blue-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-blue-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-blue-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-blue:hover, .link-blue:focus {
  color: RGBA(5, 89, 167, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(5, 89, 167, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(5, 89, 167, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(5, 89, 167, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-azure {
  color: RGBA(var(--tblr-azure-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-azure-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-azure-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-azure-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-azure:hover, .link-azure:focus {
  color: RGBA(53, 122, 180, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(53, 122, 180, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(53, 122, 180, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(53, 122, 180, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-indigo {
  color: RGBA(var(--tblr-indigo-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-indigo-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-indigo-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-indigo-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-indigo:hover, .link-indigo:focus {
  color: RGBA(53, 79, 188, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(53, 79, 188, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(53, 79, 188, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(53, 79, 188, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-purple {
  color: RGBA(var(--tblr-purple-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-purple-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-purple-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-purple-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-purple:hover, .link-purple:focus {
  color: RGBA(139, 50, 161, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(139, 50, 161, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(139, 50, 161, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(139, 50, 161, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-pink {
  color: RGBA(var(--tblr-pink-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-pink-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-pink-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-pink-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-pink:hover, .link-pink:focus {
  color: RGBA(171, 41, 86, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(171, 41, 86, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(171, 41, 86, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(171, 41, 86, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-red {
  color: RGBA(var(--tblr-red-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-red-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-red-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-red-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-red:hover, .link-red:focus {
  color: RGBA(171, 46, 46, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(171, 46, 46, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(171, 46, 46, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(171, 46, 46, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-orange {
  color: RGBA(var(--tblr-orange-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-orange-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-orange-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-orange-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-orange:hover, .link-orange:focus {
  color: RGBA(198, 82, 6, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(198, 82, 6, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(198, 82, 6, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(198, 82, 6, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-yellow {
  color: RGBA(var(--tblr-yellow-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-yellow-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-yellow-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-yellow-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-yellow:hover, .link-yellow:focus {
  color: RGBA(196, 127, 0, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(196, 127, 0, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(196, 127, 0, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(196, 127, 0, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-lime {
  color: RGBA(var(--tblr-lime-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-lime-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-lime-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-lime-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-lime:hover, .link-lime:focus {
  color: RGBA(93, 147, 18, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(93, 147, 18, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(93, 147, 18, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(93, 147, 18, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-green {
  color: RGBA(var(--tblr-green-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-green-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-green-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-green-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-green:hover, .link-green:focus {
  color: RGBA(38, 143, 54, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(38, 143, 54, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(38, 143, 54, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(38, 143, 54, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-teal {
  color: RGBA(var(--tblr-teal-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-teal-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-teal-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-teal-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-teal:hover, .link-teal:focus {
  color: RGBA(10, 133, 96, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(10, 133, 96, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(10, 133, 96, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(10, 133, 96, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-cyan {
  color: RGBA(var(--tblr-cyan-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-cyan-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-cyan-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-cyan-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-cyan:hover, .link-cyan:focus {
  color: RGBA(18, 130, 147, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(18, 130, 147, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(18, 130, 147, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(18, 130, 147, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-x {
  color: RGBA(var(--tblr-x-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-x-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-x-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-x-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-x:hover, .link-x:focus {
  color: RGBA(0, 0, 0, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(0, 0, 0, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(0, 0, 0, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-facebook {
  color: RGBA(var(--tblr-facebook-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-facebook-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-facebook-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-facebook-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-facebook:hover, .link-facebook:focus {
  color: RGBA(19, 95, 194, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(19, 95, 194, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(19, 95, 194, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(19, 95, 194, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-twitter {
  color: RGBA(var(--tblr-twitter-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-twitter-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-twitter-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-twitter-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-twitter:hover, .link-twitter:focus {
  color: RGBA(23, 129, 194, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(23, 129, 194, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(23, 129, 194, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(23, 129, 194, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-linkedin {
  color: RGBA(var(--tblr-linkedin-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-linkedin-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-linkedin-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-linkedin-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-linkedin:hover, .link-linkedin:focus {
  color: RGBA(8, 82, 155, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(8, 82, 155, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(8, 82, 155, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(8, 82, 155, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-google {
  color: RGBA(var(--tblr-google-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-google-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-google-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-google-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-google:hover, .link-google:focus {
  color: RGBA(176, 62, 52, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(176, 62, 52, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(176, 62, 52, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(176, 62, 52, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-youtube {
  color: RGBA(var(--tblr-youtube-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-youtube-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-youtube-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-youtube-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-youtube:hover, .link-youtube:focus {
  color: RGBA(204, 0, 0, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(204, 0, 0, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(204, 0, 0, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(204, 0, 0, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-vimeo {
  color: RGBA(var(--tblr-vimeo-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-vimeo-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-vimeo-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-vimeo-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-vimeo:hover, .link-vimeo:focus {
  color: RGBA(21, 146, 187, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(21, 146, 187, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(21, 146, 187, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(21, 146, 187, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-dribbble {
  color: RGBA(var(--tblr-dribbble-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-dribbble-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-dribbble-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-dribbble-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-dribbble:hover, .link-dribbble:focus {
  color: RGBA(187, 61, 110, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(187, 61, 110, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(187, 61, 110, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(187, 61, 110, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-github {
  color: RGBA(var(--tblr-github-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-github-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-github-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-github-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-github:hover, .link-github:focus {
  color: RGBA(19, 18, 18, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(19, 18, 18, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(19, 18, 18, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(19, 18, 18, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-instagram {
  color: RGBA(var(--tblr-instagram-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-instagram-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-instagram-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-instagram-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-instagram:hover, .link-instagram:focus {
  color: RGBA(182, 51, 76, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(182, 51, 76, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(182, 51, 76, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(182, 51, 76, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-pinterest {
  color: RGBA(var(--tblr-pinterest-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-pinterest-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-pinterest-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-pinterest-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-pinterest:hover, .link-pinterest:focus {
  color: RGBA(151, 6, 22, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(151, 6, 22, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(151, 6, 22, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(151, 6, 22, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-vk {
  color: RGBA(var(--tblr-vk-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-vk-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-vk-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-vk-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-vk:hover, .link-vk:focus {
  color: RGBA(79, 105, 134, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(79, 105, 134, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(79, 105, 134, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(79, 105, 134, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-rss {
  color: RGBA(var(--tblr-rss-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-rss-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-rss-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-rss-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-rss:hover, .link-rss:focus {
  color: RGBA(204, 132, 0, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(204, 132, 0, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(204, 132, 0, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(204, 132, 0, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-flickr {
  color: RGBA(var(--tblr-flickr-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-flickr-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-flickr-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-flickr-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-flickr:hover, .link-flickr:focus {
  color: RGBA(0, 79, 176, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(0, 79, 176, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(0, 79, 176, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 79, 176, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-bitbucket {
  color: RGBA(var(--tblr-bitbucket-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-bitbucket-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-bitbucket-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-bitbucket-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-bitbucket:hover, .link-bitbucket:focus {
  color: RGBA(0, 66, 163, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(0, 66, 163, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(0, 66, 163, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 66, 163, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-tabler {
  color: RGBA(var(--tblr-tabler-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-tabler-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-tabler-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-tabler-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-tabler:hover, .link-tabler:focus {
  color: RGBA(5, 89, 167, var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(5, 89, 167, var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(5, 89, 167, var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(5, 89, 167, var(--tblr-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--tblr-emphasis-color-rgb), var(--tblr-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-emphasis-color-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-emphasis-color-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--tblr-emphasis-color-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--tblr-emphasis-color-rgb), var(--tblr-link-opacity, .75)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-emphasis-color-rgb), var(--tblr-link-underline-opacity, .75)) !important;
  -webkit-text-decoration-color: RGBA(var(--tblr-emphasis-color-rgb), var(--tblr-link-underline-opacity, .75)) !important;
  text-decoration-color: RGBA(var(--tblr-emphasis-color-rgb), var(--tblr-link-underline-opacity, .75)) !important;
}

.focus-ring:focus {
  box-shadow: var(--tblr-focus-ring-x, 0) var(--tblr-focus-ring-y, 0) var(--tblr-focus-ring-blur, 0) var(--tblr-focus-ring-width) var(--tblr-focus-ring-color);
  outline: 0;
}

.icon-link {
  -webkit-text-decoration-color: rgba(var(--tblr-link-color-rgb), var(--tblr-link-opacity, .5));
  -webkit-text-decoration-color: rgba(var(--tblr-link-color-rgb), var(--tblr-link-opacity, .5));
  text-decoration-color: rgba(var(--tblr-link-color-rgb), var(--tblr-link-opacity, .5));
  text-underline-offset: .25em;
  backface-visibility: hidden;
  align-items: center;
  gap: .375rem;
  display: inline-flex;
}

.icon-link > .bi {
  fill: currentColor;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  transition: transform .2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--tblr-icon-link-transform, translate3d(.25em, 0, 0));
}

.ratio {
  width: 100%;
  position: relative;
}

.ratio:before {
  padding-top: var(--tblr-aspect-ratio);
  content: "";
  display: block;
}

.ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-1x1 {
  --tblr-aspect-ratio: 100%;
}

.ratio-2x1 {
  --tblr-aspect-ratio: 50%;
}

.ratio-1x2 {
  --tblr-aspect-ratio: 200%;
}

.ratio-3x1 {
  --tblr-aspect-ratio: 33.3333%;
}

.ratio-1x3 {
  --tblr-aspect-ratio: 300%;
}

.ratio-4x1 {
  --tblr-aspect-ratio: 25%;
}

.ratio-1x4 {
  --tblr-aspect-ratio: 400%;
}

.ratio-4x3 {
  --tblr-aspect-ratio: 75%;
}

.ratio-3x4 {
  --tblr-aspect-ratio: 133.333%;
}

.ratio-16x9 {
  --tblr-aspect-ratio: 56.25%;
}

.ratio-9x16 {
  --tblr-aspect-ratio: 177.778%;
}

.ratio-21x9 {
  --tblr-aspect-ratio: 42.8571%;
}

.ratio-9x21 {
  --tblr-aspect-ratio: 233.333%;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

.sticky-bottom {
  z-index: 1020;
  position: sticky;
  bottom: 0;
}

@media (width >= 576px) {
  .sticky-sm-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-sm-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 768px) {
  .sticky-md-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-md-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 992px) {
  .sticky-lg-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-lg-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 1200px) {
  .sticky-xl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-xl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 1400px) {
  .sticky-xxl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-xxl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

.hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
}

.visually-hidden:not(caption), .visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.vr {
  width: var(--tblr-border-width);
  opacity: .16;
  background-color: currentColor;
  align-self: stretch;
  min-height: 1em;
  display: inline-block;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  -o-object-fit: contain !important;
  object-fit: contain !important;
}

.object-fit-cover {
  -o-object-fit: cover !important;
  object-fit: cover !important;
}

.object-fit-fill {
  -o-object-fit: fill !important;
  object-fit: fill !important;
}

.object-fit-scale {
  -o-object-fit: scale-down !important;
  object-fit: scale-down !important;
}

.object-fit-none {
  -o-object-fit: none !important;
  object-fit: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--tblr-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--tblr-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--tblr-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --tblr-focus-ring-color: rgba(var(--tblr-primary-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-secondary {
  --tblr-focus-ring-color: rgba(var(--tblr-secondary-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-success {
  --tblr-focus-ring-color: rgba(var(--tblr-success-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-info {
  --tblr-focus-ring-color: rgba(var(--tblr-info-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-warning {
  --tblr-focus-ring-color: rgba(var(--tblr-warning-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-danger {
  --tblr-focus-ring-color: rgba(var(--tblr-danger-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-light {
  --tblr-focus-ring-color: rgba(var(--tblr-light-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-dark {
  --tblr-focus-ring-color: rgba(var(--tblr-dark-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-muted {
  --tblr-focus-ring-color: rgba(var(--tblr-muted-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-blue {
  --tblr-focus-ring-color: rgba(var(--tblr-blue-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-azure {
  --tblr-focus-ring-color: rgba(var(--tblr-azure-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-indigo {
  --tblr-focus-ring-color: rgba(var(--tblr-indigo-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-purple {
  --tblr-focus-ring-color: rgba(var(--tblr-purple-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-pink {
  --tblr-focus-ring-color: rgba(var(--tblr-pink-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-red {
  --tblr-focus-ring-color: rgba(var(--tblr-red-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-orange {
  --tblr-focus-ring-color: rgba(var(--tblr-orange-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-yellow {
  --tblr-focus-ring-color: rgba(var(--tblr-yellow-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-lime {
  --tblr-focus-ring-color: rgba(var(--tblr-lime-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-green {
  --tblr-focus-ring-color: rgba(var(--tblr-green-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-teal {
  --tblr-focus-ring-color: rgba(var(--tblr-teal-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-cyan {
  --tblr-focus-ring-color: rgba(var(--tblr-cyan-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-x {
  --tblr-focus-ring-color: rgba(var(--tblr-x-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-facebook {
  --tblr-focus-ring-color: rgba(var(--tblr-facebook-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-twitter {
  --tblr-focus-ring-color: rgba(var(--tblr-twitter-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-linkedin {
  --tblr-focus-ring-color: rgba(var(--tblr-linkedin-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-google {
  --tblr-focus-ring-color: rgba(var(--tblr-google-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-youtube {
  --tblr-focus-ring-color: rgba(var(--tblr-youtube-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-vimeo {
  --tblr-focus-ring-color: rgba(var(--tblr-vimeo-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-dribbble {
  --tblr-focus-ring-color: rgba(var(--tblr-dribbble-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-github {
  --tblr-focus-ring-color: rgba(var(--tblr-github-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-instagram {
  --tblr-focus-ring-color: rgba(var(--tblr-instagram-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-pinterest {
  --tblr-focus-ring-color: rgba(var(--tblr-pinterest-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-vk {
  --tblr-focus-ring-color: rgba(var(--tblr-vk-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-rss {
  --tblr-focus-ring-color: rgba(var(--tblr-rss-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-flickr {
  --tblr-focus-ring-color: rgba(var(--tblr-flickr-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-bitbucket {
  --tblr-focus-ring-color: rgba(var(--tblr-bitbucket-rgb), var(--tblr-focus-ring-opacity));
}

.focus-ring-tabler {
  --tblr-focus-ring-color: rgba(var(--tblr-tabler-rgb), var(--tblr-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent) !important;
}

.border-wide {
  border: 2px var(--tblr-border-style) var(--tblr-border-color-translucent) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent) !important;
}

.border-top-wide {
  border-top: 2px var(--tblr-border-style) var(--tblr-border-color-translucent) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent) !important;
}

.border-end-wide {
  border-right: 2px var(--tblr-border-style) var(--tblr-border-color-translucent) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent) !important;
}

.border-bottom-wide {
  border-bottom: 2px var(--tblr-border-style) var(--tblr-border-color-translucent) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent) !important;
}

.border-start-wide {
  border-left: 2px var(--tblr-border-style) var(--tblr-border-color-translucent) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary-subtle {
  border-color: var(--tblr-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--tblr-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--tblr-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--tblr-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--tblr-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--tblr-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--tblr-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--tblr-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --tblr-border-opacity: .1;
}

.border-opacity-25 {
  --tblr-border-opacity: .25;
}

.border-opacity-50 {
  --tblr-border-opacity: .5;
}

.border-opacity-75 {
  --tblr-border-opacity: .75;
}

.border-opacity-100 {
  --tblr-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-33 {
  width: 33.3333% !important;
}

.w-50 {
  width: 50% !important;
}

.w-66 {
  width: 66.6667% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-33 {
  height: 33.3333% !important;
}

.h-50 {
  height: 50% !important;
}

.h-66 {
  height: 66.6667% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.m-6 {
  margin: 2.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.mx-6 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-6 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 2rem !important;
}

.mt-6 {
  margin-top: 2.5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: .25rem !important;
}

.me-2 {
  margin-right: .5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 2rem !important;
}

.me-6 {
  margin-right: 2.5rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 2rem !important;
}

.mb-6 {
  margin-bottom: 2.5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: .25rem !important;
}

.ms-2 {
  margin-left: .5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 2rem !important;
}

.ms-6 {
  margin-left: 2.5rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -.25rem !important;
}

.m-n2 {
  margin: -.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -2rem !important;
}

.m-n6 {
  margin: -2.5rem !important;
}

.mx-n1 {
  margin-left: -.25rem !important;
  margin-right: -.25rem !important;
}

.mx-n2 {
  margin-left: -.5rem !important;
  margin-right: -.5rem !important;
}

.mx-n3 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.mx-n4 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.mx-n5 {
  margin-left: -2rem !important;
  margin-right: -2rem !important;
}

.mx-n6 {
  margin-left: -2.5rem !important;
  margin-right: -2.5rem !important;
}

.my-n1 {
  margin-top: -.25rem !important;
  margin-bottom: -.25rem !important;
}

.my-n2 {
  margin-top: -.5rem !important;
  margin-bottom: -.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.my-n6 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.mt-n1 {
  margin-top: -.25rem !important;
}

.mt-n2 {
  margin-top: -.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -2rem !important;
}

.mt-n6 {
  margin-top: -2.5rem !important;
}

.me-n1 {
  margin-right: -.25rem !important;
}

.me-n2 {
  margin-right: -.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -2rem !important;
}

.me-n6 {
  margin-right: -2.5rem !important;
}

.mb-n1 {
  margin-bottom: -.25rem !important;
}

.mb-n2 {
  margin-bottom: -.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -2rem !important;
}

.mb-n6 {
  margin-bottom: -2.5rem !important;
}

.ms-n1 {
  margin-left: -.25rem !important;
}

.ms-n2 {
  margin-left: -.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -2rem !important;
}

.ms-n6 {
  margin-left: -2.5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.p-6 {
  padding: 2.5rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-5 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.px-6 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-6 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 2rem !important;
}

.pt-6 {
  padding-top: 2.5rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: .25rem !important;
}

.pe-2 {
  padding-right: .5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 2rem !important;
}

.pe-6 {
  padding-right: 2.5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 2rem !important;
}

.pb-6 {
  padding-bottom: 2.5rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: .25rem !important;
}

.ps-2 {
  padding-left: .5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 2rem !important;
}

.ps-6 {
  padding-left: 2.5rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: .25rem !important;
}

.gap-2 {
  gap: .5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 2rem !important;
}

.gap-6 {
  gap: 2.5rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: .25rem !important;
}

.row-gap-2 {
  row-gap: .5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 2rem !important;
}

.row-gap-6 {
  row-gap: 2.5rem !important;
}

.column-gap-0 {
  -moz-column-gap: 0 !important;
  column-gap: 0 !important;
}

.column-gap-1 {
  -moz-column-gap: .25rem !important;
  column-gap: .25rem !important;
}

.column-gap-2 {
  -moz-column-gap: .5rem !important;
  column-gap: .5rem !important;
}

.column-gap-3 {
  -moz-column-gap: 1rem !important;
  column-gap: 1rem !important;
}

.column-gap-4 {
  -moz-column-gap: 1.5rem !important;
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  -moz-column-gap: 2rem !important;
  column-gap: 2rem !important;
}

.column-gap-6 {
  -moz-column-gap: 2.5rem !important;
  column-gap: 2.5rem !important;
}

.font-monospace {
  font-family: var(--tblr-font-monospace) !important;
}

.fs-1 {
  font-size: 1.5rem !important;
}

.fs-2 {
  font-size: 1.25rem !important;
}

.fs-3 {
  font-size: 1rem !important;
}

.fs-4 {
  font-size: .875rem !important;
}

.fs-5 {
  font-size: .75rem !important;
}

.fs-6 {
  font-size: .625rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold, .fw-bold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.14286 !important;
}

.lh-base {
  line-height: 1.42857 !important;
}

.lh-lg {
  line-height: 1.71429 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-black {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-black-rgb), var(--tblr-text-opacity)) !important;
}

.text-white {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-white-rgb), var(--tblr-text-opacity)) !important;
}

.text-body {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-body-color-rgb), var(--tblr-text-opacity)) !important;
}

.text-black-50 {
  --tblr-text-opacity: 1;
  color: #00000080 !important;
}

.text-white-50 {
  --tblr-text-opacity: 1;
  color: #ffffff80 !important;
}

.text-body-secondary {
  --tblr-text-opacity: 1;
  color: var(--tblr-secondary-color) !important;
}

.text-body-tertiary {
  --tblr-text-opacity: 1;
  color: var(--tblr-tertiary-color) !important;
}

.text-body-emphasis {
  --tblr-text-opacity: 1;
  color: var(--tblr-emphasis-color) !important;
}

.text-reset {
  --tblr-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --tblr-text-opacity: .25;
}

.text-opacity-50 {
  --tblr-text-opacity: .5;
}

.text-opacity-75 {
  --tblr-text-opacity: .75;
}

.text-opacity-100 {
  --tblr-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--tblr-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--tblr-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--tblr-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--tblr-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--tblr-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--tblr-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--tblr-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--tblr-dark-text-emphasis) !important;
}

.link-opacity-10, .link-opacity-10-hover:hover {
  --tblr-link-opacity: .1;
}

.link-opacity-25, .link-opacity-25-hover:hover {
  --tblr-link-opacity: .25;
}

.link-opacity-50, .link-opacity-50-hover:hover {
  --tblr-link-opacity: .5;
}

.link-opacity-75, .link-opacity-75-hover:hover {
  --tblr-link-opacity: .75;
}

.link-opacity-100, .link-opacity-100-hover:hover {
  --tblr-link-opacity: 1;
}

.link-offset-1, .link-offset-1-hover:hover {
  text-underline-offset: .125em !important;
}

.link-offset-2, .link-offset-2-hover:hover {
  text-underline-offset: .25em !important;
}

.link-offset-3, .link-offset-3-hover:hover {
  text-underline-offset: .375em !important;
}

.link-underline-primary {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-primary-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-primary-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-primary-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-secondary-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-secondary-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-secondary-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-success {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-success-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-success-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-success-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-info {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-info-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-info-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-info-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-warning {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-warning-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-warning-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-warning-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-danger {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-danger-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-danger-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-danger-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-light {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-light-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-light-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-light-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-dark {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-dark-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-dark-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-dark-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-muted {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-muted-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-muted-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-muted-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-blue {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-blue-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-blue-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-blue-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-azure {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-azure-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-azure-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-azure-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-indigo {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-indigo-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-indigo-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-indigo-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-purple {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-purple-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-purple-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-purple-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-pink {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-pink-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-pink-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-pink-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-red {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-red-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-red-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-red-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-orange {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-orange-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-orange-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-orange-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-yellow {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-yellow-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-yellow-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-yellow-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-lime {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-lime-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-lime-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-lime-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-green {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-green-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-green-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-green-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-teal {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-teal-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-teal-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-teal-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-cyan {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-cyan-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-cyan-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-cyan-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-x {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-x-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-x-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-x-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-facebook {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-facebook-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-facebook-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-facebook-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-twitter {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-twitter-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-twitter-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-twitter-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-linkedin {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-linkedin-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-linkedin-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-linkedin-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-google {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-google-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-google-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-google-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-youtube {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-youtube-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-youtube-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-youtube-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-vimeo {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-vimeo-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-vimeo-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-vimeo-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-dribbble {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-dribbble-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-dribbble-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-dribbble-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-github {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-github-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-github-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-github-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-instagram {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-instagram-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-instagram-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-instagram-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-pinterest {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-pinterest-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-pinterest-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-pinterest-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-vk {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-vk-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-vk-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-vk-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-rss {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-rss-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-rss-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-rss-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-flickr {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-flickr-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-flickr-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-flickr-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-bitbucket {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-bitbucket-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-bitbucket-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-bitbucket-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline-tabler {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-tabler-rgb), var(--tblr-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-tabler-rgb), var(--tblr-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--tblr-tabler-rgb), var(--tblr-link-underline-opacity)) !important;
}

.link-underline {
  --tblr-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--tblr-link-color-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: rgba(var(--tblr-link-color-rgb), var(--tblr-link-underline-opacity, 1)) !important;
  text-decoration-color: rgba(var(--tblr-link-color-rgb), var(--tblr-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0, .link-underline-opacity-0-hover:hover {
  --tblr-link-underline-opacity: 0;
}

.link-underline-opacity-10, .link-underline-opacity-10-hover:hover {
  --tblr-link-underline-opacity: .1;
}

.link-underline-opacity-25, .link-underline-opacity-25-hover:hover {
  --tblr-link-underline-opacity: .25;
}

.link-underline-opacity-50, .link-underline-opacity-50-hover:hover {
  --tblr-link-underline-opacity: .5;
}

.link-underline-opacity-75, .link-underline-opacity-75-hover:hover {
  --tblr-link-underline-opacity: .75;
}

.link-underline-opacity-100, .link-underline-opacity-100-hover:hover {
  --tblr-link-underline-opacity: 1;
}

.bg-black {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-black-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-body {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-body-bg-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-transparent {
  --tblr-bg-opacity: 1;
  background-color: #0000 !important;
}

.bg-body-secondary {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-secondary-bg-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-body-tertiary {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-tertiary-bg-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-opacity-10 {
  --tblr-bg-opacity: .1;
}

.bg-opacity-25 {
  --tblr-bg-opacity: .25;
}

.bg-opacity-50 {
  --tblr-bg-opacity: .5;
}

.bg-opacity-75 {
  --tblr-bg-opacity: .75;
}

.bg-opacity-100 {
  --tblr-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--tblr-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--tblr-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--tblr-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--tblr-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--tblr-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--tblr-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--tblr-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--tblr-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--tblr-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--tblr-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--tblr-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--tblr-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--tblr-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--tblr-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--tblr-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--tblr-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--tblr-border-radius) !important;
  border-top-right-radius: var(--tblr-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--tblr-border-radius-sm) !important;
  border-top-right-radius: var(--tblr-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--tblr-border-radius) !important;
  border-top-right-radius: var(--tblr-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--tblr-border-radius-lg) !important;
  border-top-right-radius: var(--tblr-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--tblr-border-radius-xl) !important;
  border-top-right-radius: var(--tblr-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--tblr-border-radius-xxl) !important;
  border-top-right-radius: var(--tblr-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--tblr-border-radius-pill) !important;
  border-top-right-radius: var(--tblr-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--tblr-border-radius) !important;
  border-bottom-right-radius: var(--tblr-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--tblr-border-radius-sm) !important;
  border-bottom-right-radius: var(--tblr-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--tblr-border-radius) !important;
  border-bottom-right-radius: var(--tblr-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--tblr-border-radius-lg) !important;
  border-bottom-right-radius: var(--tblr-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--tblr-border-radius-xl) !important;
  border-bottom-right-radius: var(--tblr-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--tblr-border-radius-xxl) !important;
  border-bottom-right-radius: var(--tblr-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--tblr-border-radius-pill) !important;
  border-bottom-right-radius: var(--tblr-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--tblr-border-radius) !important;
  border-bottom-left-radius: var(--tblr-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--tblr-border-radius-sm) !important;
  border-bottom-left-radius: var(--tblr-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--tblr-border-radius) !important;
  border-bottom-left-radius: var(--tblr-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--tblr-border-radius-lg) !important;
  border-bottom-left-radius: var(--tblr-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--tblr-border-radius-xl) !important;
  border-bottom-left-radius: var(--tblr-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--tblr-border-radius-xxl) !important;
  border-bottom-left-radius: var(--tblr-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--tblr-border-radius-pill) !important;
  border-bottom-left-radius: var(--tblr-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--tblr-border-radius) !important;
  border-top-left-radius: var(--tblr-border-radius) !important;
}

.rounded-start-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--tblr-border-radius-sm) !important;
  border-top-left-radius: var(--tblr-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--tblr-border-radius) !important;
  border-top-left-radius: var(--tblr-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--tblr-border-radius-lg) !important;
  border-top-left-radius: var(--tblr-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--tblr-border-radius-xl) !important;
  border-top-left-radius: var(--tblr-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--tblr-border-radius-xxl) !important;
  border-top-left-radius: var(--tblr-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--tblr-border-radius-pill) !important;
  border-top-left-radius: var(--tblr-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

.object-contain {
  -o-object-fit: contain !important;
  object-fit: contain !important;
}

.object-cover {
  -o-object-fit: cover !important;
  object-fit: cover !important;
}

.object-fill {
  -o-object-fit: fill !important;
  object-fit: fill !important;
}

.object-scale-down {
  -o-object-fit: scale-down !important;
  object-fit: scale-down !important;
}

.object-none {
  -o-object-fit: none !important;
  object-fit: none !important;
}

.cursor-auto {
  cursor: auto !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-move {
  cursor: move !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.cursor-zoom-in {
  cursor: zoom-in !important;
}

.cursor-zoom-out {
  cursor: zoom-out !important;
}

.cursor-default {
  cursor: default !important;
}

.cursor-none {
  cursor: none !important;
}

.cursor-help {
  cursor: help !important;
}

.cursor-progress {
  cursor: progress !important;
}

.cursor-wait {
  cursor: wait !important;
}

.cursor-text {
  cursor: text !important;
}

.cursor-v-text {
  cursor: vertical-text !important;
}

.cursor-grab {
  cursor: -webkit-grab !important;
  cursor: grab !important;
}

.cursor-grabbing {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
}

.border-x {
  border-left: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent) !important;
  border-right: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent) !important;
}

.border-x-wide {
  border-left: 2px var(--tblr-border-style) var(--tblr-border-color-translucent) !important;
  border-right: 2px var(--tblr-border-style) var(--tblr-border-color-translucent) !important;
}

.border-x-0 {
  border-left: 0 !important;
  border-right: 0 !important;
}

.border-y {
  border-top: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent) !important;
  border-bottom: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent) !important;
}

.border-y-wide {
  border-top: 2px var(--tblr-border-style) var(--tblr-border-color-translucent) !important;
  border-bottom: 2px var(--tblr-border-style) var(--tblr-border-color-translucent) !important;
}

.border-y-0 {
  border-top: 0 !important;
  border-bottom: 0 !important;
}

.columns-2 {
  -moz-columns: 2 !important;
  columns: 2 !important;
}

.columns-3 {
  -moz-columns: 3 !important;
  columns: 3 !important;
}

.columns-4 {
  -moz-columns: 4 !important;
  columns: 4 !important;
}

@media (width >= 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .object-fit-sm-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }

  .object-fit-sm-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }

  .object-fit-sm-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }

  .object-fit-sm-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }

  .object-fit-sm-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-inline-grid {
    display: inline-grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 2rem !important;
  }

  .m-sm-6 {
    margin: 2.5rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .mx-sm-6 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-sm-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: .25rem !important;
  }

  .mt-sm-2 {
    margin-top: .5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 2rem !important;
  }

  .mt-sm-6 {
    margin-top: 2.5rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: .25rem !important;
  }

  .me-sm-2 {
    margin-right: .5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 2rem !important;
  }

  .me-sm-6 {
    margin-right: 2.5rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 2rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: .25rem !important;
  }

  .ms-sm-2 {
    margin-left: .5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 2rem !important;
  }

  .ms-sm-6 {
    margin-left: 2.5rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -.25rem !important;
  }

  .m-sm-n2 {
    margin: -.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -2rem !important;
  }

  .m-sm-n6 {
    margin: -2.5rem !important;
  }

  .mx-sm-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-sm-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-sm-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-sm-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-sm-n5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .mx-sm-n6 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .my-sm-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-sm-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .mt-sm-n1 {
    margin-top: -.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -1rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n5 {
    margin-top: -2rem !important;
  }

  .mt-sm-n6 {
    margin-top: -2.5rem !important;
  }

  .me-sm-n1 {
    margin-right: -.25rem !important;
  }

  .me-sm-n2 {
    margin-right: -.5rem !important;
  }

  .me-sm-n3 {
    margin-right: -1rem !important;
  }

  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n5 {
    margin-right: -2rem !important;
  }

  .me-sm-n6 {
    margin-right: -2.5rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-sm-n6 {
    margin-bottom: -2.5rem !important;
  }

  .ms-sm-n1 {
    margin-left: -.25rem !important;
  }

  .ms-sm-n2 {
    margin-left: -.5rem !important;
  }

  .ms-sm-n3 {
    margin-left: -1rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n5 {
    margin-left: -2rem !important;
  }

  .ms-sm-n6 {
    margin-left: -2.5rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 2rem !important;
  }

  .p-sm-6 {
    padding: 2.5rem !important;
  }

  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-sm-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .px-sm-6 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-sm-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: .25rem !important;
  }

  .pt-sm-2 {
    padding-top: .5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 2rem !important;
  }

  .pt-sm-6 {
    padding-top: 2.5rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: .25rem !important;
  }

  .pe-sm-2 {
    padding-right: .5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 2rem !important;
  }

  .pe-sm-6 {
    padding-right: 2.5rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 2rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 2.5rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: .25rem !important;
  }

  .ps-sm-2 {
    padding-left: .5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 2rem !important;
  }

  .ps-sm-6 {
    padding-left: 2.5rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: .25rem !important;
  }

  .gap-sm-2 {
    gap: .5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 2rem !important;
  }

  .gap-sm-6 {
    gap: 2.5rem !important;
  }

  .row-gap-sm-0 {
    row-gap: 0 !important;
  }

  .row-gap-sm-1 {
    row-gap: .25rem !important;
  }

  .row-gap-sm-2 {
    row-gap: .5rem !important;
  }

  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }

  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-sm-5 {
    row-gap: 2rem !important;
  }

  .row-gap-sm-6 {
    row-gap: 2.5rem !important;
  }

  .column-gap-sm-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }

  .column-gap-sm-1 {
    -moz-column-gap: .25rem !important;
    column-gap: .25rem !important;
  }

  .column-gap-sm-2 {
    -moz-column-gap: .5rem !important;
    column-gap: .5rem !important;
  }

  .column-gap-sm-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }

  .column-gap-sm-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }

  .column-gap-sm-5 {
    -moz-column-gap: 2rem !important;
    column-gap: 2rem !important;
  }

  .column-gap-sm-6 {
    -moz-column-gap: 2.5rem !important;
    column-gap: 2.5rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }

  .columns-sm-2 {
    -moz-columns: 2 !important;
    columns: 2 !important;
  }

  .columns-sm-3 {
    -moz-columns: 3 !important;
    columns: 3 !important;
  }

  .columns-sm-4 {
    -moz-columns: 4 !important;
    columns: 4 !important;
  }
}

@media (width >= 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .object-fit-md-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }

  .object-fit-md-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }

  .object-fit-md-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }

  .object-fit-md-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }

  .object-fit-md-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-inline-grid {
    display: inline-grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 2rem !important;
  }

  .m-md-6 {
    margin: 2.5rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-md-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .mx-md-6 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-md-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: .25rem !important;
  }

  .mt-md-2 {
    margin-top: .5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 2rem !important;
  }

  .mt-md-6 {
    margin-top: 2.5rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: .25rem !important;
  }

  .me-md-2 {
    margin-right: .5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 2rem !important;
  }

  .me-md-6 {
    margin-right: 2.5rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: .25rem !important;
  }

  .mb-md-2 {
    margin-bottom: .5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 2rem !important;
  }

  .mb-md-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: .25rem !important;
  }

  .ms-md-2 {
    margin-left: .5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 2rem !important;
  }

  .ms-md-6 {
    margin-left: 2.5rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -.25rem !important;
  }

  .m-md-n2 {
    margin: -.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -2rem !important;
  }

  .m-md-n6 {
    margin: -2.5rem !important;
  }

  .mx-md-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-md-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-md-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-md-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-md-n5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .mx-md-n6 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .my-md-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-md-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-md-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .mt-md-n1 {
    margin-top: -.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -1rem !important;
  }

  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n5 {
    margin-top: -2rem !important;
  }

  .mt-md-n6 {
    margin-top: -2.5rem !important;
  }

  .me-md-n1 {
    margin-right: -.25rem !important;
  }

  .me-md-n2 {
    margin-right: -.5rem !important;
  }

  .me-md-n3 {
    margin-right: -1rem !important;
  }

  .me-md-n4 {
    margin-right: -1.5rem !important;
  }

  .me-md-n5 {
    margin-right: -2rem !important;
  }

  .me-md-n6 {
    margin-right: -2.5rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-md-n6 {
    margin-bottom: -2.5rem !important;
  }

  .ms-md-n1 {
    margin-left: -.25rem !important;
  }

  .ms-md-n2 {
    margin-left: -.5rem !important;
  }

  .ms-md-n3 {
    margin-left: -1rem !important;
  }

  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n5 {
    margin-left: -2rem !important;
  }

  .ms-md-n6 {
    margin-left: -2.5rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 2rem !important;
  }

  .p-md-6 {
    padding: 2.5rem !important;
  }

  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-md-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .px-md-6 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-md-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: .25rem !important;
  }

  .pt-md-2 {
    padding-top: .5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 2rem !important;
  }

  .pt-md-6 {
    padding-top: 2.5rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: .25rem !important;
  }

  .pe-md-2 {
    padding-right: .5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 2rem !important;
  }

  .pe-md-6 {
    padding-right: 2.5rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: .25rem !important;
  }

  .pb-md-2 {
    padding-bottom: .5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 2rem !important;
  }

  .pb-md-6 {
    padding-bottom: 2.5rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: .25rem !important;
  }

  .ps-md-2 {
    padding-left: .5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 2rem !important;
  }

  .ps-md-6 {
    padding-left: 2.5rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: .25rem !important;
  }

  .gap-md-2 {
    gap: .5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 2rem !important;
  }

  .gap-md-6 {
    gap: 2.5rem !important;
  }

  .row-gap-md-0 {
    row-gap: 0 !important;
  }

  .row-gap-md-1 {
    row-gap: .25rem !important;
  }

  .row-gap-md-2 {
    row-gap: .5rem !important;
  }

  .row-gap-md-3 {
    row-gap: 1rem !important;
  }

  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-md-5 {
    row-gap: 2rem !important;
  }

  .row-gap-md-6 {
    row-gap: 2.5rem !important;
  }

  .column-gap-md-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }

  .column-gap-md-1 {
    -moz-column-gap: .25rem !important;
    column-gap: .25rem !important;
  }

  .column-gap-md-2 {
    -moz-column-gap: .5rem !important;
    column-gap: .5rem !important;
  }

  .column-gap-md-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }

  .column-gap-md-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }

  .column-gap-md-5 {
    -moz-column-gap: 2rem !important;
    column-gap: 2rem !important;
  }

  .column-gap-md-6 {
    -moz-column-gap: 2.5rem !important;
    column-gap: 2.5rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .columns-md-2 {
    -moz-columns: 2 !important;
    columns: 2 !important;
  }

  .columns-md-3 {
    -moz-columns: 3 !important;
    columns: 3 !important;
  }

  .columns-md-4 {
    -moz-columns: 4 !important;
    columns: 4 !important;
  }
}

@media (width >= 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .object-fit-lg-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }

  .object-fit-lg-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }

  .object-fit-lg-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }

  .object-fit-lg-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }

  .object-fit-lg-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-inline-grid {
    display: inline-grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 2rem !important;
  }

  .m-lg-6 {
    margin: 2.5rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .mx-lg-6 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-lg-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: .25rem !important;
  }

  .mt-lg-2 {
    margin-top: .5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 2rem !important;
  }

  .mt-lg-6 {
    margin-top: 2.5rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: .25rem !important;
  }

  .me-lg-2 {
    margin-right: .5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 2rem !important;
  }

  .me-lg-6 {
    margin-right: 2.5rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 2rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: .25rem !important;
  }

  .ms-lg-2 {
    margin-left: .5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 2rem !important;
  }

  .ms-lg-6 {
    margin-left: 2.5rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -.25rem !important;
  }

  .m-lg-n2 {
    margin: -.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -2rem !important;
  }

  .m-lg-n6 {
    margin: -2.5rem !important;
  }

  .mx-lg-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-lg-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-lg-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-lg-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-lg-n5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .mx-lg-n6 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .my-lg-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-lg-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .mt-lg-n1 {
    margin-top: -.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -1rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n5 {
    margin-top: -2rem !important;
  }

  .mt-lg-n6 {
    margin-top: -2.5rem !important;
  }

  .me-lg-n1 {
    margin-right: -.25rem !important;
  }

  .me-lg-n2 {
    margin-right: -.5rem !important;
  }

  .me-lg-n3 {
    margin-right: -1rem !important;
  }

  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n5 {
    margin-right: -2rem !important;
  }

  .me-lg-n6 {
    margin-right: -2.5rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-lg-n6 {
    margin-bottom: -2.5rem !important;
  }

  .ms-lg-n1 {
    margin-left: -.25rem !important;
  }

  .ms-lg-n2 {
    margin-left: -.5rem !important;
  }

  .ms-lg-n3 {
    margin-left: -1rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n5 {
    margin-left: -2rem !important;
  }

  .ms-lg-n6 {
    margin-left: -2.5rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 2rem !important;
  }

  .p-lg-6 {
    padding: 2.5rem !important;
  }

  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-lg-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .px-lg-6 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-lg-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: .25rem !important;
  }

  .pt-lg-2 {
    padding-top: .5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 2rem !important;
  }

  .pt-lg-6 {
    padding-top: 2.5rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: .25rem !important;
  }

  .pe-lg-2 {
    padding-right: .5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 2rem !important;
  }

  .pe-lg-6 {
    padding-right: 2.5rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 2rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 2.5rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: .25rem !important;
  }

  .ps-lg-2 {
    padding-left: .5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 2rem !important;
  }

  .ps-lg-6 {
    padding-left: 2.5rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: .25rem !important;
  }

  .gap-lg-2 {
    gap: .5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 2rem !important;
  }

  .gap-lg-6 {
    gap: 2.5rem !important;
  }

  .row-gap-lg-0 {
    row-gap: 0 !important;
  }

  .row-gap-lg-1 {
    row-gap: .25rem !important;
  }

  .row-gap-lg-2 {
    row-gap: .5rem !important;
  }

  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }

  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-lg-5 {
    row-gap: 2rem !important;
  }

  .row-gap-lg-6 {
    row-gap: 2.5rem !important;
  }

  .column-gap-lg-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }

  .column-gap-lg-1 {
    -moz-column-gap: .25rem !important;
    column-gap: .25rem !important;
  }

  .column-gap-lg-2 {
    -moz-column-gap: .5rem !important;
    column-gap: .5rem !important;
  }

  .column-gap-lg-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }

  .column-gap-lg-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }

  .column-gap-lg-5 {
    -moz-column-gap: 2rem !important;
    column-gap: 2rem !important;
  }

  .column-gap-lg-6 {
    -moz-column-gap: 2.5rem !important;
    column-gap: 2.5rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }

  .columns-lg-2 {
    -moz-columns: 2 !important;
    columns: 2 !important;
  }

  .columns-lg-3 {
    -moz-columns: 3 !important;
    columns: 3 !important;
  }

  .columns-lg-4 {
    -moz-columns: 4 !important;
    columns: 4 !important;
  }
}

@media (width >= 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .object-fit-xl-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }

  .object-fit-xl-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }

  .object-fit-xl-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }

  .object-fit-xl-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }

  .object-fit-xl-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-inline-grid {
    display: inline-grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 2rem !important;
  }

  .m-xl-6 {
    margin: 2.5rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .mx-xl-6 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xl-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: .25rem !important;
  }

  .mt-xl-2 {
    margin-top: .5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 2rem !important;
  }

  .mt-xl-6 {
    margin-top: 2.5rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: .25rem !important;
  }

  .me-xl-2 {
    margin-right: .5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 2rem !important;
  }

  .me-xl-6 {
    margin-right: 2.5rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 2rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: .25rem !important;
  }

  .ms-xl-2 {
    margin-left: .5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 2rem !important;
  }

  .ms-xl-6 {
    margin-left: 2.5rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -.25rem !important;
  }

  .m-xl-n2 {
    margin: -.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -2rem !important;
  }

  .m-xl-n6 {
    margin: -2.5rem !important;
  }

  .mx-xl-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-xl-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-xl-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-xl-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-xl-n5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .mx-xl-n6 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .my-xl-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xl-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .mt-xl-n1 {
    margin-top: -.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n5 {
    margin-top: -2rem !important;
  }

  .mt-xl-n6 {
    margin-top: -2.5rem !important;
  }

  .me-xl-n1 {
    margin-right: -.25rem !important;
  }

  .me-xl-n2 {
    margin-right: -.5rem !important;
  }

  .me-xl-n3 {
    margin-right: -1rem !important;
  }

  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n5 {
    margin-right: -2rem !important;
  }

  .me-xl-n6 {
    margin-right: -2.5rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-xl-n6 {
    margin-bottom: -2.5rem !important;
  }

  .ms-xl-n1 {
    margin-left: -.25rem !important;
  }

  .ms-xl-n2 {
    margin-left: -.5rem !important;
  }

  .ms-xl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n5 {
    margin-left: -2rem !important;
  }

  .ms-xl-n6 {
    margin-left: -2.5rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 2rem !important;
  }

  .p-xl-6 {
    padding: 2.5rem !important;
  }

  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xl-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .px-xl-6 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xl-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: .25rem !important;
  }

  .pt-xl-2 {
    padding-top: .5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 2rem !important;
  }

  .pt-xl-6 {
    padding-top: 2.5rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: .25rem !important;
  }

  .pe-xl-2 {
    padding-right: .5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 2rem !important;
  }

  .pe-xl-6 {
    padding-right: 2.5rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 2rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 2.5rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: .25rem !important;
  }

  .ps-xl-2 {
    padding-left: .5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 2rem !important;
  }

  .ps-xl-6 {
    padding-left: 2.5rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: .25rem !important;
  }

  .gap-xl-2 {
    gap: .5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 2rem !important;
  }

  .gap-xl-6 {
    gap: 2.5rem !important;
  }

  .row-gap-xl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xl-1 {
    row-gap: .25rem !important;
  }

  .row-gap-xl-2 {
    row-gap: .5rem !important;
  }

  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xl-5 {
    row-gap: 2rem !important;
  }

  .row-gap-xl-6 {
    row-gap: 2.5rem !important;
  }

  .column-gap-xl-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }

  .column-gap-xl-1 {
    -moz-column-gap: .25rem !important;
    column-gap: .25rem !important;
  }

  .column-gap-xl-2 {
    -moz-column-gap: .5rem !important;
    column-gap: .5rem !important;
  }

  .column-gap-xl-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }

  .column-gap-xl-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }

  .column-gap-xl-5 {
    -moz-column-gap: 2rem !important;
    column-gap: 2rem !important;
  }

  .column-gap-xl-6 {
    -moz-column-gap: 2.5rem !important;
    column-gap: 2.5rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }

  .columns-xl-2 {
    -moz-columns: 2 !important;
    columns: 2 !important;
  }

  .columns-xl-3 {
    -moz-columns: 3 !important;
    columns: 3 !important;
  }

  .columns-xl-4 {
    -moz-columns: 4 !important;
    columns: 4 !important;
  }
}

@media (width >= 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .object-fit-xxl-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }

  .object-fit-xxl-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }

  .object-fit-xxl-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }

  .object-fit-xxl-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }

  .object-fit-xxl-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-inline-grid {
    display: inline-grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: .25rem !important;
  }

  .m-xxl-2 {
    margin: .5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 2rem !important;
  }

  .m-xxl-6 {
    margin: 2.5rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .mx-xxl-6 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xxl-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: .25rem !important;
  }

  .mt-xxl-2 {
    margin-top: .5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 2rem !important;
  }

  .mt-xxl-6 {
    margin-top: 2.5rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: .25rem !important;
  }

  .me-xxl-2 {
    margin-right: .5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 2rem !important;
  }

  .me-xxl-6 {
    margin-right: 2.5rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 2rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: .25rem !important;
  }

  .ms-xxl-2 {
    margin-left: .5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 2rem !important;
  }

  .ms-xxl-6 {
    margin-left: 2.5rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -.25rem !important;
  }

  .m-xxl-n2 {
    margin: -.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -2rem !important;
  }

  .m-xxl-n6 {
    margin: -2.5rem !important;
  }

  .mx-xxl-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-xxl-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-xxl-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-xxl-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-xxl-n5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .mx-xxl-n6 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .my-xxl-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xxl-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -2rem !important;
  }

  .mt-xxl-n6 {
    margin-top: -2.5rem !important;
  }

  .me-xxl-n1 {
    margin-right: -.25rem !important;
  }

  .me-xxl-n2 {
    margin-right: -.5rem !important;
  }

  .me-xxl-n3 {
    margin-right: -1rem !important;
  }

  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n5 {
    margin-right: -2rem !important;
  }

  .me-xxl-n6 {
    margin-right: -2.5rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-xxl-n6 {
    margin-bottom: -2.5rem !important;
  }

  .ms-xxl-n1 {
    margin-left: -.25rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -.5rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -2rem !important;
  }

  .ms-xxl-n6 {
    margin-left: -2.5rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: .25rem !important;
  }

  .p-xxl-2 {
    padding: .5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 2rem !important;
  }

  .p-xxl-6 {
    padding: 2.5rem !important;
  }

  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .px-xxl-6 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xxl-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: .25rem !important;
  }

  .pt-xxl-2 {
    padding-top: .5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 2rem !important;
  }

  .pt-xxl-6 {
    padding-top: 2.5rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: .25rem !important;
  }

  .pe-xxl-2 {
    padding-right: .5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 2rem !important;
  }

  .pe-xxl-6 {
    padding-right: 2.5rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 2rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 2.5rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: .25rem !important;
  }

  .ps-xxl-2 {
    padding-left: .5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 2rem !important;
  }

  .ps-xxl-6 {
    padding-left: 2.5rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: .25rem !important;
  }

  .gap-xxl-2 {
    gap: .5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 2rem !important;
  }

  .gap-xxl-6 {
    gap: 2.5rem !important;
  }

  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xxl-1 {
    row-gap: .25rem !important;
  }

  .row-gap-xxl-2 {
    row-gap: .5rem !important;
  }

  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xxl-5 {
    row-gap: 2rem !important;
  }

  .row-gap-xxl-6 {
    row-gap: 2.5rem !important;
  }

  .column-gap-xxl-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }

  .column-gap-xxl-1 {
    -moz-column-gap: .25rem !important;
    column-gap: .25rem !important;
  }

  .column-gap-xxl-2 {
    -moz-column-gap: .5rem !important;
    column-gap: .5rem !important;
  }

  .column-gap-xxl-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }

  .column-gap-xxl-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }

  .column-gap-xxl-5 {
    -moz-column-gap: 2rem !important;
    column-gap: 2rem !important;
  }

  .column-gap-xxl-6 {
    -moz-column-gap: 2.5rem !important;
    column-gap: 2.5rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }

  .columns-xxl-2 {
    -moz-columns: 2 !important;
    columns: 2 !important;
  }

  .columns-xxl-3 {
    -moz-columns: 3 !important;
    columns: 3 !important;
  }

  .columns-xxl-4 {
    -moz-columns: 4 !important;
    columns: 4 !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-inline-grid {
    display: inline-grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

:root, :host {
  height: 100%;
  font-size: 16px;
}

:root, :host, [data-bs-theme="light"] {
  --tblr-primary: #066fd1;
  --tblr-primary-rgb: 6, 111, 209;
  --tblr-primary-fg: var(--tblr-light);
  --tblr-primary-darken: #0564bc;
  --tblr-primary-lt: #e6f1fa;
  --tblr-primary-lt-rgb: 230, 241, 250;
  --tblr-secondary: #6c7a91;
  --tblr-secondary-rgb: 108, 122, 145;
  --tblr-secondary-fg: var(--tblr-light);
  --tblr-secondary-darken: #616e83;
  --tblr-secondary-lt: #f0f2f4;
  --tblr-secondary-lt-rgb: 240, 242, 244;
  --tblr-success: #2fb344;
  --tblr-success-rgb: 47, 179, 68;
  --tblr-success-fg: var(--tblr-light);
  --tblr-success-darken: #2aa13d;
  --tblr-success-lt: #eaf7ec;
  --tblr-success-lt-rgb: 234, 247, 236;
  --tblr-info: #4299e1;
  --tblr-info-rgb: 66, 153, 225;
  --tblr-info-fg: var(--tblr-light);
  --tblr-info-darken: #3b8acb;
  --tblr-info-lt: #ecf5fc;
  --tblr-info-lt-rgb: 236, 245, 252;
  --tblr-warning: #f76707;
  --tblr-warning-rgb: 247, 103, 7;
  --tblr-warning-fg: var(--tblr-light);
  --tblr-warning-darken: #de5d06;
  --tblr-warning-lt: #fef0e6;
  --tblr-warning-lt-rgb: 254, 240, 230;
  --tblr-danger: #d63939;
  --tblr-danger-rgb: 214, 57, 57;
  --tblr-danger-fg: var(--tblr-light);
  --tblr-danger-darken: #c13333;
  --tblr-danger-lt: #fbebeb;
  --tblr-danger-lt-rgb: 251, 235, 235;
  --tblr-light: #f6f8fb;
  --tblr-light-rgb: 246, 248, 251;
  --tblr-light-fg: var(--tblr-dark);
  --tblr-light-darken: #dddfe2;
  --tblr-light-lt: #fefeff;
  --tblr-light-lt-rgb: 254, 254, 255;
  --tblr-dark: #182433;
  --tblr-dark-rgb: 24, 36, 51;
  --tblr-dark-fg: var(--tblr-light);
  --tblr-dark-darken: #16202e;
  --tblr-dark-lt: #e8e9eb;
  --tblr-dark-lt-rgb: 232, 233, 235;
  --tblr-muted: #6c7a91;
  --tblr-muted-rgb: 108, 122, 145;
  --tblr-muted-fg: var(--tblr-light);
  --tblr-muted-darken: #616e83;
  --tblr-muted-lt: #f0f2f4;
  --tblr-muted-lt-rgb: 240, 242, 244;
  --tblr-blue: #066fd1;
  --tblr-blue-rgb: 6, 111, 209;
  --tblr-blue-fg: var(--tblr-light);
  --tblr-blue-darken: #0564bc;
  --tblr-blue-lt: #e6f1fa;
  --tblr-blue-lt-rgb: 230, 241, 250;
  --tblr-azure: #4299e1;
  --tblr-azure-rgb: 66, 153, 225;
  --tblr-azure-fg: var(--tblr-light);
  --tblr-azure-darken: #3b8acb;
  --tblr-azure-lt: #ecf5fc;
  --tblr-azure-lt-rgb: 236, 245, 252;
  --tblr-indigo: #4263eb;
  --tblr-indigo-rgb: 66, 99, 235;
  --tblr-indigo-fg: var(--tblr-light);
  --tblr-indigo-darken: #3b59d4;
  --tblr-indigo-lt: #eceffd;
  --tblr-indigo-lt-rgb: 236, 239, 253;
  --tblr-purple: #ae3ec9;
  --tblr-purple-rgb: 174, 62, 201;
  --tblr-purple-fg: var(--tblr-light);
  --tblr-purple-darken: #9d38b5;
  --tblr-purple-lt: #f7ecfa;
  --tblr-purple-lt-rgb: 247, 236, 250;
  --tblr-pink: #d6336c;
  --tblr-pink-rgb: 214, 51, 108;
  --tblr-pink-fg: var(--tblr-light);
  --tblr-pink-darken: #c12e61;
  --tblr-pink-lt: #fbebf0;
  --tblr-pink-lt-rgb: 251, 235, 240;
  --tblr-red: #d63939;
  --tblr-red-rgb: 214, 57, 57;
  --tblr-red-fg: var(--tblr-light);
  --tblr-red-darken: #c13333;
  --tblr-red-lt: #fbebeb;
  --tblr-red-lt-rgb: 251, 235, 235;
  --tblr-orange: #f76707;
  --tblr-orange-rgb: 247, 103, 7;
  --tblr-orange-fg: var(--tblr-light);
  --tblr-orange-darken: #de5d06;
  --tblr-orange-lt: #fef0e6;
  --tblr-orange-lt-rgb: 254, 240, 230;
  --tblr-yellow: #f59f00;
  --tblr-yellow-rgb: 245, 159, 0;
  --tblr-yellow-fg: var(--tblr-light);
  --tblr-yellow-darken: #dd8f00;
  --tblr-yellow-lt: #fef5e6;
  --tblr-yellow-lt-rgb: 254, 245, 230;
  --tblr-lime: #74b816;
  --tblr-lime-rgb: 116, 184, 22;
  --tblr-lime-fg: var(--tblr-light);
  --tblr-lime-darken: #68a614;
  --tblr-lime-lt: #f1f8e8;
  --tblr-lime-lt-rgb: 241, 248, 232;
  --tblr-green: #2fb344;
  --tblr-green-rgb: 47, 179, 68;
  --tblr-green-fg: var(--tblr-light);
  --tblr-green-darken: #2aa13d;
  --tblr-green-lt: #eaf7ec;
  --tblr-green-lt-rgb: 234, 247, 236;
  --tblr-teal: #0ca678;
  --tblr-teal-rgb: 12, 166, 120;
  --tblr-teal-fg: var(--tblr-light);
  --tblr-teal-darken: #0b956c;
  --tblr-teal-lt: #e7f6f2;
  --tblr-teal-lt-rgb: 231, 246, 242;
  --tblr-cyan: #17a2b8;
  --tblr-cyan-rgb: 23, 162, 184;
  --tblr-cyan-fg: var(--tblr-light);
  --tblr-cyan-darken: #1592a6;
  --tblr-cyan-lt: #e8f6f8;
  --tblr-cyan-lt-rgb: 232, 246, 248;
  --tblr-x: #000;
  --tblr-x-rgb: 0, 0, 0;
  --tblr-x-fg: var(--tblr-light);
  --tblr-x-darken: black;
  --tblr-x-lt: #e6e6e6;
  --tblr-x-lt-rgb: 230, 230, 230;
  --tblr-facebook: #1877f2;
  --tblr-facebook-rgb: 24, 119, 242;
  --tblr-facebook-fg: var(--tblr-light);
  --tblr-facebook-darken: #166bda;
  --tblr-facebook-lt: #e8f1fe;
  --tblr-facebook-lt-rgb: 232, 241, 254;
  --tblr-twitter: #1da1f2;
  --tblr-twitter-rgb: 29, 161, 242;
  --tblr-twitter-fg: var(--tblr-light);
  --tblr-twitter-darken: #1a91da;
  --tblr-twitter-lt: #e8f6fe;
  --tblr-twitter-lt-rgb: 232, 246, 254;
  --tblr-linkedin: #0a66c2;
  --tblr-linkedin-rgb: 10, 102, 194;
  --tblr-linkedin-fg: var(--tblr-light);
  --tblr-linkedin-darken: #095caf;
  --tblr-linkedin-lt: #e7f0f9;
  --tblr-linkedin-lt-rgb: 231, 240, 249;
  --tblr-google: #dc4e41;
  --tblr-google-rgb: 220, 78, 65;
  --tblr-google-fg: var(--tblr-light);
  --tblr-google-darken: #c6463b;
  --tblr-google-lt: #fcedec;
  --tblr-google-lt-rgb: 252, 237, 236;
  --tblr-youtube: red;
  --tblr-youtube-rgb: 255, 0, 0;
  --tblr-youtube-fg: var(--tblr-light);
  --tblr-youtube-darken: #e60000;
  --tblr-youtube-lt: #ffe6e6;
  --tblr-youtube-lt-rgb: 255, 230, 230;
  --tblr-vimeo: #1ab7ea;
  --tblr-vimeo-rgb: 26, 183, 234;
  --tblr-vimeo-fg: var(--tblr-light);
  --tblr-vimeo-darken: #17a5d3;
  --tblr-vimeo-lt: #e8f8fd;
  --tblr-vimeo-lt-rgb: 232, 248, 253;
  --tblr-dribbble: #ea4c89;
  --tblr-dribbble-rgb: 234, 76, 137;
  --tblr-dribbble-fg: var(--tblr-light);
  --tblr-dribbble-darken: #d3447b;
  --tblr-dribbble-lt: #fdedf3;
  --tblr-dribbble-lt-rgb: 253, 237, 243;
  --tblr-github: #181717;
  --tblr-github-rgb: 24, 23, 23;
  --tblr-github-fg: var(--tblr-light);
  --tblr-github-darken: #161515;
  --tblr-github-lt: #e8e8e8;
  --tblr-github-lt-rgb: 232, 232, 232;
  --tblr-instagram: #e4405f;
  --tblr-instagram-rgb: 228, 64, 95;
  --tblr-instagram-fg: var(--tblr-light);
  --tblr-instagram-darken: #cd3a56;
  --tblr-instagram-lt: #fcecef;
  --tblr-instagram-lt-rgb: 252, 236, 239;
  --tblr-pinterest: #bd081c;
  --tblr-pinterest-rgb: 189, 8, 28;
  --tblr-pinterest-fg: var(--tblr-light);
  --tblr-pinterest-darken: #aa0719;
  --tblr-pinterest-lt: #f8e6e8;
  --tblr-pinterest-lt-rgb: 248, 230, 232;
  --tblr-vk: #6383a8;
  --tblr-vk-rgb: 99, 131, 168;
  --tblr-vk-fg: var(--tblr-light);
  --tblr-vk-darken: #597697;
  --tblr-vk-lt: #eff3f6;
  --tblr-vk-lt-rgb: 239, 243, 246;
  --tblr-rss: orange;
  --tblr-rss-rgb: 255, 165, 0;
  --tblr-rss-fg: var(--tblr-light);
  --tblr-rss-darken: #e69500;
  --tblr-rss-lt: #fff6e6;
  --tblr-rss-lt-rgb: 255, 246, 230;
  --tblr-flickr: #0063dc;
  --tblr-flickr-rgb: 0, 99, 220;
  --tblr-flickr-fg: var(--tblr-light);
  --tblr-flickr-darken: #0059c6;
  --tblr-flickr-lt: #e6effc;
  --tblr-flickr-lt-rgb: 230, 239, 252;
  --tblr-bitbucket: #0052cc;
  --tblr-bitbucket-rgb: 0, 82, 204;
  --tblr-bitbucket-fg: var(--tblr-light);
  --tblr-bitbucket-darken: #004ab8;
  --tblr-bitbucket-lt: #e6eefa;
  --tblr-bitbucket-lt-rgb: 230, 238, 250;
  --tblr-tabler: #066fd1;
  --tblr-tabler-rgb: 6, 111, 209;
  --tblr-tabler-fg: var(--tblr-light);
  --tblr-tabler-darken: #0564bc;
  --tblr-tabler-lt: #e6f1fa;
  --tblr-tabler-lt-rgb: 230, 241, 250;
  --tblr-gray-50: #f6f8fb;
  --tblr-gray-50-rgb: 246, 248, 251;
  --tblr-gray-50-fg: var(--tblr-dark);
  --tblr-gray-50-darken: #dddfe2;
  --tblr-gray-50-lt: #fefeff;
  --tblr-gray-50-lt-rgb: 254, 254, 255;
  --tblr-gray-100: #eef3f6;
  --tblr-gray-100-rgb: 238, 243, 246;
  --tblr-gray-100-fg: var(--tblr-dark);
  --tblr-gray-100-darken: #d6dbdd;
  --tblr-gray-100-lt: #fdfefe;
  --tblr-gray-100-lt-rgb: 253, 254, 254;
  --tblr-gray-200: #dce1e7;
  --tblr-gray-200-rgb: 220, 225, 231;
  --tblr-gray-200-fg: var(--tblr-dark);
  --tblr-gray-200-darken: #c6cbd0;
  --tblr-gray-200-lt: #fcfcfd;
  --tblr-gray-200-lt-rgb: 252, 252, 253;
  --tblr-gray-300: #b8c4d4;
  --tblr-gray-300-rgb: 184, 196, 212;
  --tblr-gray-300-fg: var(--tblr-light);
  --tblr-gray-300-darken: #a6b0bf;
  --tblr-gray-300-lt: #f8f9fb;
  --tblr-gray-300-lt-rgb: 248, 249, 251;
  --tblr-gray-400: #8a97ab;
  --tblr-gray-400-rgb: 138, 151, 171;
  --tblr-gray-400-fg: var(--tblr-light);
  --tblr-gray-400-darken: #7c889a;
  --tblr-gray-400-lt: #f3f5f7;
  --tblr-gray-400-lt-rgb: 243, 245, 247;
  --tblr-gray-500: #6c7a91;
  --tblr-gray-500-rgb: 108, 122, 145;
  --tblr-gray-500-fg: var(--tblr-light);
  --tblr-gray-500-darken: #616e83;
  --tblr-gray-500-lt: #f0f2f4;
  --tblr-gray-500-lt-rgb: 240, 242, 244;
  --tblr-gray-600: #49566c;
  --tblr-gray-600-rgb: 73, 86, 108;
  --tblr-gray-600-fg: var(--tblr-light);
  --tblr-gray-600-darken: #424d61;
  --tblr-gray-600-lt: #edeef0;
  --tblr-gray-600-lt-rgb: 237, 238, 240;
  --tblr-gray-700: #3a4859;
  --tblr-gray-700-rgb: 58, 72, 89;
  --tblr-gray-700-fg: var(--tblr-light);
  --tblr-gray-700-darken: #344150;
  --tblr-gray-700-lt: #ebedee;
  --tblr-gray-700-lt-rgb: 235, 237, 238;
  --tblr-gray-800: #182433;
  --tblr-gray-800-rgb: 24, 36, 51;
  --tblr-gray-800-fg: var(--tblr-light);
  --tblr-gray-800-darken: #16202e;
  --tblr-gray-800-lt: #e8e9eb;
  --tblr-gray-800-lt-rgb: 232, 233, 235;
  --tblr-gray-900: #040a11;
  --tblr-gray-900-rgb: 4, 10, 17;
  --tblr-gray-900-fg: var(--tblr-light);
  --tblr-gray-900-darken: #04090f;
  --tblr-gray-900-lt: #e6e7e7;
  --tblr-gray-900-lt-rgb: 230, 231, 231;
  --tblr-spacer-0: 0;
  --tblr-spacer-1: .25rem;
  --tblr-spacer-2: .5rem;
  --tblr-spacer-3: 1rem;
  --tblr-spacer-4: 1.5rem;
  --tblr-spacer-5: 2rem;
  --tblr-spacer-6: 2.5rem;
  --tblr-spacer: 1rem;
  --tblr-bg-surface: var(--tblr-white);
  --tblr-bg-surface-secondary: var(--tblr-gray-100);
  --tblr-bg-surface-tertiary: var(--tblr-gray-50);
  --tblr-bg-surface-dark: var(--tblr-dark);
  --tblr-bg-forms: var(--tblr-bg-surface);
  --tblr-border-color: #dce1e7;
  --tblr-border-color-translucent: #0420451a;
  --tblr-border-dark-color: #8a97ab;
  --tblr-border-dark-color-translucent: #04204545;
  --tblr-border-active-color: #aab2bf;
  --tblr-icon-color: var(--tblr-gray-400);
  --tblr-active-bg: rgba(var(--tblr-primary-rgb), .04);
  --tblr-disabled-bg: var(--tblr-bg-surface-secondary);
  --tblr-disabled-color: var(--tblr-gray-300);
  --tblr-code-color: var(--tblr-gray-600);
  --tblr-code-bg: var(--tblr-bg-surface-secondary);
  --tblr-dark-mode-border-color: #25384f;
  --tblr-dark-mode-border-color-translucent: #486e9524;
  --tblr-dark-mode-border-active-color: #2c415d;
  --tblr-dark-mode-border-dark-color: #1f2e41;
  --tblr-page-padding: var(--tblr-spacer-3);
  --tblr-page-padding-y: var(--tblr-spacer-4);
  --tblr-font-weight-light: 300;
  --tblr-font-weight-normal: 400;
  --tblr-font-weight-medium: 500;
  --tblr-font-weight-bold: 600;
  --tblr-font-weight-black: 700;
  --tblr-font-weight-headings: var(--tblr-font-weight-bold);
  --tblr-font-size-h1: 1.5rem;
  --tblr-font-size-h2: 1.25rem;
  --tblr-font-size-h3: 1rem;
  --tblr-font-size-h4: .875rem;
  --tblr-font-size-h5: .75rem;
  --tblr-font-size-h6: .625rem;
  --tblr-line-height-h1: 2rem;
  --tblr-line-height-h2: 1.75rem;
  --tblr-line-height-h3: 1.5rem;
  --tblr-line-height-h4: 1.25rem;
  --tblr-line-height-h5: 1rem;
  --tblr-line-height-h6: 1rem;
  --tblr-box-shadow: rgba(var(--tblr-body-color-rgb), .04) 0 2px 4px 0;
  --tblr-box-shadow-border: inset 0 0 0 1px var(--tblr-border-color-translucent);
  --tblr-box-shadow-transparent: 0 0 0 0 transparent;
  --tblr-box-shadow-input: 0 1px 1px rgba(var(--tblr-body-color-rgb), .06);
  --tblr-box-shadow-card: 0 0 4px rgba(var(--tblr-body-color-rgb), .04);
  --tblr-box-shadow-card-hover: rgba(var(--tblr-body-color-rgb), .16) 0 2px 16px 0;
  --tblr-box-shadow-dropdown: 0 16px 24px 2px #00000012, 0 6px 30px 5px #0000000f, 0 8px 10px -5px #0000001a;
}

@media (width <= 991.98px) {
  :root, :host, [data-bs-theme="light"] {
    --tblr-page-padding: var(--tblr-spacer-2);
  }
}

@keyframes pulse {
  from {
    opacity: 1;
    transform: scale3d(.8, .8, .8);
  }

  50% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }

  to {
    opacity: 1;
    transform: scale3d(.8, .8, .8);
  }
}

@keyframes tada {
  0% {
    transform: scale3d(1, 1, 1);
  }

  10%, 5% {
    transform: scale3d(.9, .9, .9)rotate3d(0, 0, 1, -5deg);
  }

  15%, 25%, 35%, 45% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, 5deg);
  }

  20%, 30%, 40% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, -5deg);
  }

  50% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes rotate-360 {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes blink {
  from {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

body {
  letter-spacing: 0;
  touch-action: manipulation;
  text-rendering: optimizeLegibility;
  font-feature-settings: "liga" 0, "cv03", "cv04", "cv11";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  min-height: 100%;
  position: relative;
}

@media print {
  body {
    background: none;
  }
}

* {
  scrollbar-color: rgba(var(--tblr-scrollbar-color, var(--tblr-body-color-rgb)), .16);
}

::-webkit-scrollbar {
  width: 1rem;
  height: 1rem;
  transition: background .3s;
}

@media (prefers-reduced-motion: reduce) {
  ::-webkit-scrollbar {
    transition: none;
  }
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 1rem rgba(var(--tblr-scrollbar-color, var(--tblr-body-color-rgb)), .16);
  border: 5px solid #0000;
  border-radius: 1rem;
}

::-webkit-scrollbar-track {
  background: none;
}

:hover::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 1rem rgba(var(--tblr-scrollbar-color, var(--tblr-body-color-rgb)), .32);
}

::-webkit-scrollbar-corner {
  background: none;
}

.layout-fluid .container, .layout-fluid [class^="container-"], .layout-fluid [class*=" container-"] {
  max-width: 100%;
}

.layout-boxed {
  --tblr-theme-boxed-border-radius: 0;
  --tblr-theme-boxed-width: 1320px;
}

@media (width >= 768px) {
  .layout-boxed {
    --tblr-theme-boxed-border-radius: 4px;
    background: #182433 linear-gradient(to right, #ffffff1a, #0000) fixed;
    padding: 1rem;
  }
}

.layout-boxed .page {
  max-width: var(--tblr-theme-boxed-width);
  border-radius: var(--tblr-theme-boxed-border-radius);
  color: var(--tblr-body-color);
  margin: 0 auto;
}

@media (width >= 768px) {
  .layout-boxed .page {
    border: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color);
    background: var(--tblr-body-bg);
  }
}

.layout-boxed .page > .navbar:first-child {
  border-top-left-radius: var(--tblr-theme-boxed-border-radius);
  border-top-right-radius: var(--tblr-theme-boxed-border-radius);
}

.navbar {
  --tblr-navbar-bg: var(--tblr-bg-surface);
  --tblr-navbar-border-width: var(--tblr-border-width);
  --tblr-navbar-active-border-color: var(--tblr-primary);
  --tblr-navbar-active-bg: #0003;
  --tblr-navbar-border-color: var(--tblr-border-color);
  min-height: 3.5rem;
  box-shadow: inset 0 calc(-1 * var(--tblr-navbar-border-width)) 0 0 var(--tblr-navbar-border-color);
  background: var(--tblr-navbar-bg);
  color: var(--tblr-navbar-color);
  align-items: stretch;
}

.navbar-collapse .navbar {
  flex-grow: 1;
}

.navbar.collapsing {
  min-height: 0;
}

.navbar .dropdown-menu {
  z-index: 1030;
  position: absolute;
}

.navbar .navbar-nav {
  min-height: 3rem;
}

.navbar .navbar-nav .nav-link {
  border-radius: var(--tblr-border-radius);
  justify-content: center;
  min-width: 2rem;
  min-height: 2rem;
  position: relative;
}

.navbar .navbar-nav .nav-link .badge {
  position: absolute;
  top: .375rem;
  right: .375rem;
  transform: translate(50%, -50%);
}

.navbar-nav {
  margin: 0;
  padding: 0;
}

@media (width <= 575.98px) {
  .navbar-expand-sm .navbar-collapse {
    flex-direction: column;
  }

  .navbar-expand-sm .navbar-collapse [class^="container"] {
    flex-direction: column;
    align-items: stretch;
    padding: 0;
  }

  .navbar-expand-sm .navbar-collapse .navbar-nav {
    margin-left: 0;
    margin-right: 0;
  }

  .navbar-expand-sm .navbar-collapse .navbar-nav .nav-link {
    padding: .5rem calc(calc(var(--tblr-page-padding) * 2) / 2);
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-collapse .dropdown-menu-columns {
    flex-direction: column;
  }

  .navbar-expand-sm .navbar-collapse .dropdown-menu {
    color: inherit;
    box-shadow: none;
    background: none;
    border: none;
    min-width: 0;
    margin: 0;
    padding: 0;
    position: static;
  }

  .navbar-expand-sm .navbar-collapse .dropdown-menu .dropdown-item {
    width: auto;
    min-width: 0;
    padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 1.75rem);
    color: inherit;
    display: flex;
  }

  .navbar-expand-sm .navbar-collapse .dropdown-menu .dropdown-item.disabled {
    color: var(--tblr-disabled-color);
    pointer-events: none;
    background-color: #0000;
  }

  .navbar-expand-sm .navbar-collapse .dropdown-menu .dropdown-item.active, .navbar-expand-sm .navbar-collapse .dropdown-menu .dropdown-item:active {
    background: var(--tblr-navbar-active-bg);
  }

  .navbar-expand-sm .navbar-collapse .dropdown-menu .dropdown-menu .dropdown-item {
    padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 3.25rem);
  }

  .navbar-expand-sm .navbar-collapse .dropdown-menu .dropdown-menu .dropdown-menu .dropdown-item {
    padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 4.75rem);
  }

  .navbar-expand-sm .navbar-collapse .dropdown-toggle:after {
    margin-left: auto;
  }

  .navbar-expand-sm .navbar-collapse .nav-item.active:after {
    border-bottom-width: 0;
    border-left-width: 3px;
    top: 0;
    bottom: 0;
    right: auto;
  }
}

@media (width >= 576px) {
  .navbar-expand-sm .navbar-collapse {
    flex: auto;
    width: auto;
  }

  .navbar-expand-sm .nav-item.active {
    position: relative;
  }

  .navbar-expand-sm .nav-item.active:after {
    content: "";
    border: 0 var(--tblr-border-style) var(--tblr-navbar-active-border-color);
    border-bottom-width: 2px;
    position: absolute;
    bottom: -.25rem;
    left: 0;
    right: 0;
  }

  .navbar-expand-sm.navbar-vertical {
    box-shadow: inset calc(-1 * var(--tblr-navbar-border-width)) 0 0 0 var(--tblr-navbar-border-color);
  }

  .navbar-expand-sm.navbar-vertical.navbar-right {
    box-shadow: inset calc(1 * var(--tblr-navbar-border-width)) 0 0 0 var(--tblr-navbar-border-color);
  }

  .navbar-expand-sm.navbar-vertical ~ .navbar, .navbar-expand-sm.navbar-vertical ~ .page-wrapper {
    margin-left: 15rem;
  }

  .navbar-expand-sm.navbar-vertical.navbar-right ~ .navbar, .navbar-expand-sm.navbar-vertical.navbar-right ~ .page-wrapper {
    margin-left: 0;
    margin-right: 15rem;
  }
}

@media (width <= 767.98px) {
  .navbar-expand-md .navbar-collapse {
    flex-direction: column;
  }

  .navbar-expand-md .navbar-collapse [class^="container"] {
    flex-direction: column;
    align-items: stretch;
    padding: 0;
  }

  .navbar-expand-md .navbar-collapse .navbar-nav {
    margin-left: 0;
    margin-right: 0;
  }

  .navbar-expand-md .navbar-collapse .navbar-nav .nav-link {
    padding: .5rem calc(calc(var(--tblr-page-padding) * 2) / 2);
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-collapse .dropdown-menu-columns {
    flex-direction: column;
  }

  .navbar-expand-md .navbar-collapse .dropdown-menu {
    color: inherit;
    box-shadow: none;
    background: none;
    border: none;
    min-width: 0;
    margin: 0;
    padding: 0;
    position: static;
  }

  .navbar-expand-md .navbar-collapse .dropdown-menu .dropdown-item {
    width: auto;
    min-width: 0;
    padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 1.75rem);
    color: inherit;
    display: flex;
  }

  .navbar-expand-md .navbar-collapse .dropdown-menu .dropdown-item.disabled {
    color: var(--tblr-disabled-color);
    pointer-events: none;
    background-color: #0000;
  }

  .navbar-expand-md .navbar-collapse .dropdown-menu .dropdown-item.active, .navbar-expand-md .navbar-collapse .dropdown-menu .dropdown-item:active {
    background: var(--tblr-navbar-active-bg);
  }

  .navbar-expand-md .navbar-collapse .dropdown-menu .dropdown-menu .dropdown-item {
    padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 3.25rem);
  }

  .navbar-expand-md .navbar-collapse .dropdown-menu .dropdown-menu .dropdown-menu .dropdown-item {
    padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 4.75rem);
  }

  .navbar-expand-md .navbar-collapse .dropdown-toggle:after {
    margin-left: auto;
  }

  .navbar-expand-md .navbar-collapse .nav-item.active:after {
    border-bottom-width: 0;
    border-left-width: 3px;
    top: 0;
    bottom: 0;
    right: auto;
  }
}

@media (width >= 768px) {
  .navbar-expand-md .navbar-collapse {
    flex: auto;
    width: auto;
  }

  .navbar-expand-md .nav-item.active {
    position: relative;
  }

  .navbar-expand-md .nav-item.active:after {
    content: "";
    border: 0 var(--tblr-border-style) var(--tblr-navbar-active-border-color);
    border-bottom-width: 2px;
    position: absolute;
    bottom: -.25rem;
    left: 0;
    right: 0;
  }

  .navbar-expand-md.navbar-vertical {
    box-shadow: inset calc(-1 * var(--tblr-navbar-border-width)) 0 0 0 var(--tblr-navbar-border-color);
  }

  .navbar-expand-md.navbar-vertical.navbar-right {
    box-shadow: inset calc(1 * var(--tblr-navbar-border-width)) 0 0 0 var(--tblr-navbar-border-color);
  }

  .navbar-expand-md.navbar-vertical ~ .navbar, .navbar-expand-md.navbar-vertical ~ .page-wrapper {
    margin-left: 15rem;
  }

  .navbar-expand-md.navbar-vertical.navbar-right ~ .navbar, .navbar-expand-md.navbar-vertical.navbar-right ~ .page-wrapper {
    margin-left: 0;
    margin-right: 15rem;
  }
}

@media (width <= 991.98px) {
  .navbar-expand-lg .navbar-collapse {
    flex-direction: column;
  }

  .navbar-expand-lg .navbar-collapse [class^="container"] {
    flex-direction: column;
    align-items: stretch;
    padding: 0;
  }

  .navbar-expand-lg .navbar-collapse .navbar-nav {
    margin-left: 0;
    margin-right: 0;
  }

  .navbar-expand-lg .navbar-collapse .navbar-nav .nav-link {
    padding: .5rem calc(calc(var(--tblr-page-padding) * 2) / 2);
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-collapse .dropdown-menu-columns {
    flex-direction: column;
  }

  .navbar-expand-lg .navbar-collapse .dropdown-menu {
    color: inherit;
    box-shadow: none;
    background: none;
    border: none;
    min-width: 0;
    margin: 0;
    padding: 0;
    position: static;
  }

  .navbar-expand-lg .navbar-collapse .dropdown-menu .dropdown-item {
    width: auto;
    min-width: 0;
    padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 1.75rem);
    color: inherit;
    display: flex;
  }

  .navbar-expand-lg .navbar-collapse .dropdown-menu .dropdown-item.disabled {
    color: var(--tblr-disabled-color);
    pointer-events: none;
    background-color: #0000;
  }

  .navbar-expand-lg .navbar-collapse .dropdown-menu .dropdown-item.active, .navbar-expand-lg .navbar-collapse .dropdown-menu .dropdown-item:active {
    background: var(--tblr-navbar-active-bg);
  }

  .navbar-expand-lg .navbar-collapse .dropdown-menu .dropdown-menu .dropdown-item {
    padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 3.25rem);
  }

  .navbar-expand-lg .navbar-collapse .dropdown-menu .dropdown-menu .dropdown-menu .dropdown-item {
    padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 4.75rem);
  }

  .navbar-expand-lg .navbar-collapse .dropdown-toggle:after {
    margin-left: auto;
  }

  .navbar-expand-lg .navbar-collapse .nav-item.active:after {
    border-bottom-width: 0;
    border-left-width: 3px;
    top: 0;
    bottom: 0;
    right: auto;
  }
}

@media (width >= 992px) {
  .navbar-expand-lg .navbar-collapse {
    flex: auto;
    width: auto;
  }

  .navbar-expand-lg .nav-item.active {
    position: relative;
  }

  .navbar-expand-lg .nav-item.active:after {
    content: "";
    border: 0 var(--tblr-border-style) var(--tblr-navbar-active-border-color);
    border-bottom-width: 2px;
    position: absolute;
    bottom: -.25rem;
    left: 0;
    right: 0;
  }

  .navbar-expand-lg.navbar-vertical {
    box-shadow: inset calc(-1 * var(--tblr-navbar-border-width)) 0 0 0 var(--tblr-navbar-border-color);
  }

  .navbar-expand-lg.navbar-vertical.navbar-right {
    box-shadow: inset calc(1 * var(--tblr-navbar-border-width)) 0 0 0 var(--tblr-navbar-border-color);
  }

  .navbar-expand-lg.navbar-vertical ~ .navbar, .navbar-expand-lg.navbar-vertical ~ .page-wrapper {
    margin-left: 15rem;
  }

  .navbar-expand-lg.navbar-vertical.navbar-right ~ .navbar, .navbar-expand-lg.navbar-vertical.navbar-right ~ .page-wrapper {
    margin-left: 0;
    margin-right: 15rem;
  }
}

@media (width <= 1199.98px) {
  .navbar-expand-xl .navbar-collapse {
    flex-direction: column;
  }

  .navbar-expand-xl .navbar-collapse [class^="container"] {
    flex-direction: column;
    align-items: stretch;
    padding: 0;
  }

  .navbar-expand-xl .navbar-collapse .navbar-nav {
    margin-left: 0;
    margin-right: 0;
  }

  .navbar-expand-xl .navbar-collapse .navbar-nav .nav-link {
    padding: .5rem calc(calc(var(--tblr-page-padding) * 2) / 2);
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-collapse .dropdown-menu-columns {
    flex-direction: column;
  }

  .navbar-expand-xl .navbar-collapse .dropdown-menu {
    color: inherit;
    box-shadow: none;
    background: none;
    border: none;
    min-width: 0;
    margin: 0;
    padding: 0;
    position: static;
  }

  .navbar-expand-xl .navbar-collapse .dropdown-menu .dropdown-item {
    width: auto;
    min-width: 0;
    padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 1.75rem);
    color: inherit;
    display: flex;
  }

  .navbar-expand-xl .navbar-collapse .dropdown-menu .dropdown-item.disabled {
    color: var(--tblr-disabled-color);
    pointer-events: none;
    background-color: #0000;
  }

  .navbar-expand-xl .navbar-collapse .dropdown-menu .dropdown-item.active, .navbar-expand-xl .navbar-collapse .dropdown-menu .dropdown-item:active {
    background: var(--tblr-navbar-active-bg);
  }

  .navbar-expand-xl .navbar-collapse .dropdown-menu .dropdown-menu .dropdown-item {
    padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 3.25rem);
  }

  .navbar-expand-xl .navbar-collapse .dropdown-menu .dropdown-menu .dropdown-menu .dropdown-item {
    padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 4.75rem);
  }

  .navbar-expand-xl .navbar-collapse .dropdown-toggle:after {
    margin-left: auto;
  }

  .navbar-expand-xl .navbar-collapse .nav-item.active:after {
    border-bottom-width: 0;
    border-left-width: 3px;
    top: 0;
    bottom: 0;
    right: auto;
  }
}

@media (width >= 1200px) {
  .navbar-expand-xl .navbar-collapse {
    flex: auto;
    width: auto;
  }

  .navbar-expand-xl .nav-item.active {
    position: relative;
  }

  .navbar-expand-xl .nav-item.active:after {
    content: "";
    border: 0 var(--tblr-border-style) var(--tblr-navbar-active-border-color);
    border-bottom-width: 2px;
    position: absolute;
    bottom: -.25rem;
    left: 0;
    right: 0;
  }

  .navbar-expand-xl.navbar-vertical {
    box-shadow: inset calc(-1 * var(--tblr-navbar-border-width)) 0 0 0 var(--tblr-navbar-border-color);
  }

  .navbar-expand-xl.navbar-vertical.navbar-right {
    box-shadow: inset calc(1 * var(--tblr-navbar-border-width)) 0 0 0 var(--tblr-navbar-border-color);
  }

  .navbar-expand-xl.navbar-vertical ~ .navbar, .navbar-expand-xl.navbar-vertical ~ .page-wrapper {
    margin-left: 15rem;
  }

  .navbar-expand-xl.navbar-vertical.navbar-right ~ .navbar, .navbar-expand-xl.navbar-vertical.navbar-right ~ .page-wrapper {
    margin-left: 0;
    margin-right: 15rem;
  }
}

@media (width <= 1399.98px) {
  .navbar-expand-xxl .navbar-collapse {
    flex-direction: column;
  }

  .navbar-expand-xxl .navbar-collapse [class^="container"] {
    flex-direction: column;
    align-items: stretch;
    padding: 0;
  }

  .navbar-expand-xxl .navbar-collapse .navbar-nav {
    margin-left: 0;
    margin-right: 0;
  }

  .navbar-expand-xxl .navbar-collapse .navbar-nav .nav-link {
    padding: .5rem calc(calc(var(--tblr-page-padding) * 2) / 2);
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-collapse .dropdown-menu-columns {
    flex-direction: column;
  }

  .navbar-expand-xxl .navbar-collapse .dropdown-menu {
    color: inherit;
    box-shadow: none;
    background: none;
    border: none;
    min-width: 0;
    margin: 0;
    padding: 0;
    position: static;
  }

  .navbar-expand-xxl .navbar-collapse .dropdown-menu .dropdown-item {
    width: auto;
    min-width: 0;
    padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 1.75rem);
    color: inherit;
    display: flex;
  }

  .navbar-expand-xxl .navbar-collapse .dropdown-menu .dropdown-item.disabled {
    color: var(--tblr-disabled-color);
    pointer-events: none;
    background-color: #0000;
  }

  .navbar-expand-xxl .navbar-collapse .dropdown-menu .dropdown-item.active, .navbar-expand-xxl .navbar-collapse .dropdown-menu .dropdown-item:active {
    background: var(--tblr-navbar-active-bg);
  }

  .navbar-expand-xxl .navbar-collapse .dropdown-menu .dropdown-menu .dropdown-item {
    padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 3.25rem);
  }

  .navbar-expand-xxl .navbar-collapse .dropdown-menu .dropdown-menu .dropdown-menu .dropdown-item {
    padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 4.75rem);
  }

  .navbar-expand-xxl .navbar-collapse .dropdown-toggle:after {
    margin-left: auto;
  }

  .navbar-expand-xxl .navbar-collapse .nav-item.active:after {
    border-bottom-width: 0;
    border-left-width: 3px;
    top: 0;
    bottom: 0;
    right: auto;
  }
}

@media (width >= 1400px) {
  .navbar-expand-xxl .navbar-collapse {
    flex: auto;
    width: auto;
  }

  .navbar-expand-xxl .nav-item.active {
    position: relative;
  }

  .navbar-expand-xxl .nav-item.active:after {
    content: "";
    border: 0 var(--tblr-border-style) var(--tblr-navbar-active-border-color);
    border-bottom-width: 2px;
    position: absolute;
    bottom: -.25rem;
    left: 0;
    right: 0;
  }

  .navbar-expand-xxl.navbar-vertical {
    box-shadow: inset calc(-1 * var(--tblr-navbar-border-width)) 0 0 0 var(--tblr-navbar-border-color);
  }

  .navbar-expand-xxl.navbar-vertical.navbar-right {
    box-shadow: inset calc(1 * var(--tblr-navbar-border-width)) 0 0 0 var(--tblr-navbar-border-color);
  }

  .navbar-expand-xxl.navbar-vertical ~ .navbar, .navbar-expand-xxl.navbar-vertical ~ .page-wrapper {
    margin-left: 15rem;
  }

  .navbar-expand-xxl.navbar-vertical.navbar-right ~ .navbar, .navbar-expand-xxl.navbar-vertical.navbar-right ~ .page-wrapper {
    margin-left: 0;
    margin-right: 15rem;
  }
}

.navbar-expand .navbar-collapse {
  flex-direction: column;
}

.navbar-expand .navbar-collapse [class^="container"] {
  flex-direction: column;
  align-items: stretch;
  padding: 0;
}

.navbar-expand .navbar-collapse .navbar-nav {
  margin-left: 0;
  margin-right: 0;
}

.navbar-expand .navbar-collapse .navbar-nav .nav-link {
  padding: .5rem calc(calc(var(--tblr-page-padding) * 2) / 2);
  justify-content: flex-start;
}

.navbar-expand .navbar-collapse .dropdown-menu-columns {
  flex-direction: column;
}

.navbar-expand .navbar-collapse .dropdown-menu {
  color: inherit;
  box-shadow: none;
  background: none;
  border: none;
  min-width: 0;
  margin: 0;
  padding: 0;
  position: static;
}

.navbar-expand .navbar-collapse .dropdown-menu .dropdown-item {
  width: auto;
  min-width: 0;
  padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 1.75rem);
  color: inherit;
  display: flex;
}

.navbar-expand .navbar-collapse .dropdown-menu .dropdown-item.disabled {
  color: var(--tblr-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

.navbar-expand .navbar-collapse .dropdown-menu .dropdown-item.active, .navbar-expand .navbar-collapse .dropdown-menu .dropdown-item:active {
  background: var(--tblr-navbar-active-bg);
}

.navbar-expand .navbar-collapse .dropdown-menu .dropdown-menu .dropdown-item {
  padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 3.25rem);
}

.navbar-expand .navbar-collapse .dropdown-menu .dropdown-menu .dropdown-menu .dropdown-item {
  padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 4.75rem);
}

.navbar-expand .navbar-collapse .dropdown-toggle:after {
  margin-left: auto;
}

.navbar-expand .navbar-collapse .nav-item.active:after {
  border-bottom-width: 0;
  border-left-width: 3px;
  top: 0;
  bottom: 0;
  right: auto;
}

.navbar-expand .navbar-collapse {
  flex: auto;
  width: auto;
}

.navbar-expand .nav-item.active {
  position: relative;
}

.navbar-expand .nav-item.active:after {
  content: "";
  border: 0 var(--tblr-border-style) var(--tblr-navbar-active-border-color);
  border-bottom-width: 2px;
  position: absolute;
  bottom: -.25rem;
  left: 0;
  right: 0;
}

.navbar-expand.navbar-vertical {
  box-shadow: inset calc(-1 * var(--tblr-navbar-border-width)) 0 0 0 var(--tblr-navbar-border-color);
}

.navbar-expand.navbar-vertical.navbar-right {
  box-shadow: inset calc(1 * var(--tblr-navbar-border-width)) 0 0 0 var(--tblr-navbar-border-color);
}

.navbar-expand.navbar-vertical ~ .navbar, .navbar-expand.navbar-vertical ~ .page-wrapper {
  margin-left: 15rem;
}

.navbar-expand.navbar-vertical.navbar-right ~ .navbar, .navbar-expand.navbar-vertical.navbar-right ~ .page-wrapper {
  margin-left: 0;
  margin-right: 15rem;
}

.navbar-brand {
  font-weight: var(--tblr-font-weight-bold);
  align-items: center;
  gap: .5rem;
  margin: 0;
  line-height: 1;
  display: inline-flex;
}

.navbar-brand-image {
  width: auto;
  height: 2rem;
}

.navbar-toggler {
  border: 0;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  display: flex;
  position: relative;
}

.navbar-toggler-icon {
  background: currentColor;
  border-radius: 10px;
  width: 1.25em;
  height: 2px;
  transition: top .2s .2s, bottom .2s .2s, transform .2s, opacity 0s .2s;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler-icon {
    transition: none;
  }
}

.navbar-toggler-icon:before, .navbar-toggler-icon:after {
  content: "";
  height: inherit;
  width: inherit;
  border-radius: inherit;
  background: inherit;
  transition: inherit;
  display: block;
  position: absolute;
  left: 0;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler-icon:before, .navbar-toggler-icon:after {
    transition: none;
  }
}

.navbar-toggler-icon:before {
  top: -.45em;
}

.navbar-toggler-icon:after {
  bottom: -.45em;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  transition: top .3s, bottom .3s, transform .3s .3s, opacity 0s .3s;
  transform: rotate(45deg);
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
    transition: none;
  }
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  top: 0;
  transform: rotate(-90deg);
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  opacity: 0;
  bottom: 0;
}

.navbar-transparent {
  --tblr-navbar-border-color: transparent !important;
  background: none !important;
}

.navbar-nav {
  align-items: stretch;
}

.navbar-nav .nav-item {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.navbar-side {
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 0;
  display: flex;
}

@media (width >= 576px) {
  .navbar-vertical.navbar-expand-sm {
    z-index: 1030;
    align-items: flex-start;
    width: 15rem;
    padding: 0;
    transition: transform .3s;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    overflow-y: scroll;
  }
}

@media (width >= 576px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-sm {
    transition: none;
  }
}

@media (width >= 576px) {
  .navbar-vertical.navbar-expand-sm.navbar-right {
    left: auto;
    right: 0;
  }

  .navbar-vertical.navbar-expand-sm .navbar-brand {
    justify-content: center;
    padding: .75rem 0;
  }

  .navbar-vertical.navbar-expand-sm .navbar-collapse {
    align-items: stretch;
  }

  .navbar-vertical.navbar-expand-sm .navbar-nav {
    flex-direction: column;
    flex-grow: 1;
    min-height: auto;
  }

  .navbar-vertical.navbar-expand-sm .navbar-nav .nav-link {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .navbar-vertical.navbar-expand-sm > [class^="container"] {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    min-height: 100%;
    padding: 0;
  }

  .navbar-vertical.navbar-expand-sm ~ .page {
    padding-left: 15rem;
  }

  .navbar-vertical.navbar-expand-sm ~ .page [class^="container"] {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .navbar-vertical.navbar-expand-sm.navbar-right ~ .page {
    padding-left: 0;
    padding-right: 15rem;
  }

  .navbar-vertical.navbar-expand-sm .navbar-collapse {
    flex-direction: column;
  }

  .navbar-vertical.navbar-expand-sm .navbar-collapse [class^="container"] {
    flex-direction: column;
    align-items: stretch;
    padding: 0;
  }

  .navbar-vertical.navbar-expand-sm .navbar-collapse .navbar-nav {
    margin-left: 0;
    margin-right: 0;
  }

  .navbar-vertical.navbar-expand-sm .navbar-collapse .navbar-nav .nav-link {
    padding: .5rem calc(calc(var(--tblr-page-padding) * 2) / 2);
    justify-content: flex-start;
  }

  .navbar-vertical.navbar-expand-sm .navbar-collapse .dropdown-menu-columns {
    flex-direction: column;
  }

  .navbar-vertical.navbar-expand-sm .navbar-collapse .dropdown-menu {
    color: inherit;
    box-shadow: none;
    background: none;
    border: none;
    min-width: 0;
    margin: 0;
    padding: 0;
    position: static;
  }

  .navbar-vertical.navbar-expand-sm .navbar-collapse .dropdown-menu .dropdown-item {
    width: auto;
    min-width: 0;
    padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 1.75rem);
    color: inherit;
    display: flex;
  }

  .navbar-vertical.navbar-expand-sm .navbar-collapse .dropdown-menu .dropdown-item.disabled {
    color: var(--tblr-disabled-color);
    pointer-events: none;
    background-color: #0000;
  }

  .navbar-vertical.navbar-expand-sm .navbar-collapse .dropdown-menu .dropdown-item.active, .navbar-vertical.navbar-expand-sm .navbar-collapse .dropdown-menu .dropdown-item:active {
    background: var(--tblr-navbar-active-bg);
  }

  .navbar-vertical.navbar-expand-sm .navbar-collapse .dropdown-menu .dropdown-menu .dropdown-item {
    padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 3.25rem);
  }

  .navbar-vertical.navbar-expand-sm .navbar-collapse .dropdown-menu .dropdown-menu .dropdown-menu .dropdown-item {
    padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 4.75rem);
  }

  .navbar-vertical.navbar-expand-sm .navbar-collapse .dropdown-toggle:after {
    margin-left: auto;
  }

  .navbar-vertical.navbar-expand-sm .navbar-collapse .nav-item.active:after {
    border-bottom-width: 0;
    border-left-width: 3px;
    top: 0;
    bottom: 0;
    right: auto;
  }
}

@media (width >= 768px) {
  .navbar-vertical.navbar-expand-md {
    z-index: 1030;
    align-items: flex-start;
    width: 15rem;
    padding: 0;
    transition: transform .3s;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    overflow-y: scroll;
  }
}

@media (width >= 768px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-md {
    transition: none;
  }
}

@media (width >= 768px) {
  .navbar-vertical.navbar-expand-md.navbar-right {
    left: auto;
    right: 0;
  }

  .navbar-vertical.navbar-expand-md .navbar-brand {
    justify-content: center;
    padding: .75rem 0;
  }

  .navbar-vertical.navbar-expand-md .navbar-collapse {
    align-items: stretch;
  }

  .navbar-vertical.navbar-expand-md .navbar-nav {
    flex-direction: column;
    flex-grow: 1;
    min-height: auto;
  }

  .navbar-vertical.navbar-expand-md .navbar-nav .nav-link {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .navbar-vertical.navbar-expand-md > [class^="container"] {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    min-height: 100%;
    padding: 0;
  }

  .navbar-vertical.navbar-expand-md ~ .page {
    padding-left: 15rem;
  }

  .navbar-vertical.navbar-expand-md ~ .page [class^="container"] {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .navbar-vertical.navbar-expand-md.navbar-right ~ .page {
    padding-left: 0;
    padding-right: 15rem;
  }

  .navbar-vertical.navbar-expand-md .navbar-collapse {
    flex-direction: column;
  }

  .navbar-vertical.navbar-expand-md .navbar-collapse [class^="container"] {
    flex-direction: column;
    align-items: stretch;
    padding: 0;
  }

  .navbar-vertical.navbar-expand-md .navbar-collapse .navbar-nav {
    margin-left: 0;
    margin-right: 0;
  }

  .navbar-vertical.navbar-expand-md .navbar-collapse .navbar-nav .nav-link {
    padding: .5rem calc(calc(var(--tblr-page-padding) * 2) / 2);
    justify-content: flex-start;
  }

  .navbar-vertical.navbar-expand-md .navbar-collapse .dropdown-menu-columns {
    flex-direction: column;
  }

  .navbar-vertical.navbar-expand-md .navbar-collapse .dropdown-menu {
    color: inherit;
    box-shadow: none;
    background: none;
    border: none;
    min-width: 0;
    margin: 0;
    padding: 0;
    position: static;
  }

  .navbar-vertical.navbar-expand-md .navbar-collapse .dropdown-menu .dropdown-item {
    width: auto;
    min-width: 0;
    padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 1.75rem);
    color: inherit;
    display: flex;
  }

  .navbar-vertical.navbar-expand-md .navbar-collapse .dropdown-menu .dropdown-item.disabled {
    color: var(--tblr-disabled-color);
    pointer-events: none;
    background-color: #0000;
  }

  .navbar-vertical.navbar-expand-md .navbar-collapse .dropdown-menu .dropdown-item.active, .navbar-vertical.navbar-expand-md .navbar-collapse .dropdown-menu .dropdown-item:active {
    background: var(--tblr-navbar-active-bg);
  }

  .navbar-vertical.navbar-expand-md .navbar-collapse .dropdown-menu .dropdown-menu .dropdown-item {
    padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 3.25rem);
  }

  .navbar-vertical.navbar-expand-md .navbar-collapse .dropdown-menu .dropdown-menu .dropdown-menu .dropdown-item {
    padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 4.75rem);
  }

  .navbar-vertical.navbar-expand-md .navbar-collapse .dropdown-toggle:after {
    margin-left: auto;
  }

  .navbar-vertical.navbar-expand-md .navbar-collapse .nav-item.active:after {
    border-bottom-width: 0;
    border-left-width: 3px;
    top: 0;
    bottom: 0;
    right: auto;
  }
}

@media (width >= 992px) {
  .navbar-vertical.navbar-expand-lg {
    z-index: 1030;
    align-items: flex-start;
    width: 15rem;
    padding: 0;
    transition: transform .3s;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    overflow-y: scroll;
  }
}

@media (width >= 992px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-lg {
    transition: none;
  }
}

@media (width >= 992px) {
  .navbar-vertical.navbar-expand-lg.navbar-right {
    left: auto;
    right: 0;
  }

  .navbar-vertical.navbar-expand-lg .navbar-brand {
    justify-content: center;
    padding: .75rem 0;
  }

  .navbar-vertical.navbar-expand-lg .navbar-collapse {
    align-items: stretch;
  }

  .navbar-vertical.navbar-expand-lg .navbar-nav {
    flex-direction: column;
    flex-grow: 1;
    min-height: auto;
  }

  .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .navbar-vertical.navbar-expand-lg > [class^="container"] {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    min-height: 100%;
    padding: 0;
  }

  .navbar-vertical.navbar-expand-lg ~ .page {
    padding-left: 15rem;
  }

  .navbar-vertical.navbar-expand-lg ~ .page [class^="container"] {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .navbar-vertical.navbar-expand-lg.navbar-right ~ .page {
    padding-left: 0;
    padding-right: 15rem;
  }

  .navbar-vertical.navbar-expand-lg .navbar-collapse {
    flex-direction: column;
  }

  .navbar-vertical.navbar-expand-lg .navbar-collapse [class^="container"] {
    flex-direction: column;
    align-items: stretch;
    padding: 0;
  }

  .navbar-vertical.navbar-expand-lg .navbar-collapse .navbar-nav {
    margin-left: 0;
    margin-right: 0;
  }

  .navbar-vertical.navbar-expand-lg .navbar-collapse .navbar-nav .nav-link {
    padding: .5rem calc(calc(var(--tblr-page-padding) * 2) / 2);
    justify-content: flex-start;
  }

  .navbar-vertical.navbar-expand-lg .navbar-collapse .dropdown-menu-columns {
    flex-direction: column;
  }

  .navbar-vertical.navbar-expand-lg .navbar-collapse .dropdown-menu {
    color: inherit;
    box-shadow: none;
    background: none;
    border: none;
    min-width: 0;
    margin: 0;
    padding: 0;
    position: static;
  }

  .navbar-vertical.navbar-expand-lg .navbar-collapse .dropdown-menu .dropdown-item {
    width: auto;
    min-width: 0;
    padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 1.75rem);
    color: inherit;
    display: flex;
  }

  .navbar-vertical.navbar-expand-lg .navbar-collapse .dropdown-menu .dropdown-item.disabled {
    color: var(--tblr-disabled-color);
    pointer-events: none;
    background-color: #0000;
  }

  .navbar-vertical.navbar-expand-lg .navbar-collapse .dropdown-menu .dropdown-item.active, .navbar-vertical.navbar-expand-lg .navbar-collapse .dropdown-menu .dropdown-item:active {
    background: var(--tblr-navbar-active-bg);
  }

  .navbar-vertical.navbar-expand-lg .navbar-collapse .dropdown-menu .dropdown-menu .dropdown-item {
    padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 3.25rem);
  }

  .navbar-vertical.navbar-expand-lg .navbar-collapse .dropdown-menu .dropdown-menu .dropdown-menu .dropdown-item {
    padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 4.75rem);
  }

  .navbar-vertical.navbar-expand-lg .navbar-collapse .dropdown-toggle:after {
    margin-left: auto;
  }

  .navbar-vertical.navbar-expand-lg .navbar-collapse .nav-item.active:after {
    border-bottom-width: 0;
    border-left-width: 3px;
    top: 0;
    bottom: 0;
    right: auto;
  }
}

@media (width >= 1200px) {
  .navbar-vertical.navbar-expand-xl {
    z-index: 1030;
    align-items: flex-start;
    width: 15rem;
    padding: 0;
    transition: transform .3s;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    overflow-y: scroll;
  }
}

@media (width >= 1200px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-xl {
    transition: none;
  }
}

@media (width >= 1200px) {
  .navbar-vertical.navbar-expand-xl.navbar-right {
    left: auto;
    right: 0;
  }

  .navbar-vertical.navbar-expand-xl .navbar-brand {
    justify-content: center;
    padding: .75rem 0;
  }

  .navbar-vertical.navbar-expand-xl .navbar-collapse {
    align-items: stretch;
  }

  .navbar-vertical.navbar-expand-xl .navbar-nav {
    flex-direction: column;
    flex-grow: 1;
    min-height: auto;
  }

  .navbar-vertical.navbar-expand-xl .navbar-nav .nav-link {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .navbar-vertical.navbar-expand-xl > [class^="container"] {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    min-height: 100%;
    padding: 0;
  }

  .navbar-vertical.navbar-expand-xl ~ .page {
    padding-left: 15rem;
  }

  .navbar-vertical.navbar-expand-xl ~ .page [class^="container"] {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .navbar-vertical.navbar-expand-xl.navbar-right ~ .page {
    padding-left: 0;
    padding-right: 15rem;
  }

  .navbar-vertical.navbar-expand-xl .navbar-collapse {
    flex-direction: column;
  }

  .navbar-vertical.navbar-expand-xl .navbar-collapse [class^="container"] {
    flex-direction: column;
    align-items: stretch;
    padding: 0;
  }

  .navbar-vertical.navbar-expand-xl .navbar-collapse .navbar-nav {
    margin-left: 0;
    margin-right: 0;
  }

  .navbar-vertical.navbar-expand-xl .navbar-collapse .navbar-nav .nav-link {
    padding: .5rem calc(calc(var(--tblr-page-padding) * 2) / 2);
    justify-content: flex-start;
  }

  .navbar-vertical.navbar-expand-xl .navbar-collapse .dropdown-menu-columns {
    flex-direction: column;
  }

  .navbar-vertical.navbar-expand-xl .navbar-collapse .dropdown-menu {
    color: inherit;
    box-shadow: none;
    background: none;
    border: none;
    min-width: 0;
    margin: 0;
    padding: 0;
    position: static;
  }

  .navbar-vertical.navbar-expand-xl .navbar-collapse .dropdown-menu .dropdown-item {
    width: auto;
    min-width: 0;
    padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 1.75rem);
    color: inherit;
    display: flex;
  }

  .navbar-vertical.navbar-expand-xl .navbar-collapse .dropdown-menu .dropdown-item.disabled {
    color: var(--tblr-disabled-color);
    pointer-events: none;
    background-color: #0000;
  }

  .navbar-vertical.navbar-expand-xl .navbar-collapse .dropdown-menu .dropdown-item.active, .navbar-vertical.navbar-expand-xl .navbar-collapse .dropdown-menu .dropdown-item:active {
    background: var(--tblr-navbar-active-bg);
  }

  .navbar-vertical.navbar-expand-xl .navbar-collapse .dropdown-menu .dropdown-menu .dropdown-item {
    padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 3.25rem);
  }

  .navbar-vertical.navbar-expand-xl .navbar-collapse .dropdown-menu .dropdown-menu .dropdown-menu .dropdown-item {
    padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 4.75rem);
  }

  .navbar-vertical.navbar-expand-xl .navbar-collapse .dropdown-toggle:after {
    margin-left: auto;
  }

  .navbar-vertical.navbar-expand-xl .navbar-collapse .nav-item.active:after {
    border-bottom-width: 0;
    border-left-width: 3px;
    top: 0;
    bottom: 0;
    right: auto;
  }
}

@media (width >= 1400px) {
  .navbar-vertical.navbar-expand-xxl {
    z-index: 1030;
    align-items: flex-start;
    width: 15rem;
    padding: 0;
    transition: transform .3s;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    overflow-y: scroll;
  }
}

@media (width >= 1400px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-xxl {
    transition: none;
  }
}

@media (width >= 1400px) {
  .navbar-vertical.navbar-expand-xxl.navbar-right {
    left: auto;
    right: 0;
  }

  .navbar-vertical.navbar-expand-xxl .navbar-brand {
    justify-content: center;
    padding: .75rem 0;
  }

  .navbar-vertical.navbar-expand-xxl .navbar-collapse {
    align-items: stretch;
  }

  .navbar-vertical.navbar-expand-xxl .navbar-nav {
    flex-direction: column;
    flex-grow: 1;
    min-height: auto;
  }

  .navbar-vertical.navbar-expand-xxl .navbar-nav .nav-link {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .navbar-vertical.navbar-expand-xxl > [class^="container"] {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    min-height: 100%;
    padding: 0;
  }

  .navbar-vertical.navbar-expand-xxl ~ .page {
    padding-left: 15rem;
  }

  .navbar-vertical.navbar-expand-xxl ~ .page [class^="container"] {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .navbar-vertical.navbar-expand-xxl.navbar-right ~ .page {
    padding-left: 0;
    padding-right: 15rem;
  }

  .navbar-vertical.navbar-expand-xxl .navbar-collapse {
    flex-direction: column;
  }

  .navbar-vertical.navbar-expand-xxl .navbar-collapse [class^="container"] {
    flex-direction: column;
    align-items: stretch;
    padding: 0;
  }

  .navbar-vertical.navbar-expand-xxl .navbar-collapse .navbar-nav {
    margin-left: 0;
    margin-right: 0;
  }

  .navbar-vertical.navbar-expand-xxl .navbar-collapse .navbar-nav .nav-link {
    padding: .5rem calc(calc(var(--tblr-page-padding) * 2) / 2);
    justify-content: flex-start;
  }

  .navbar-vertical.navbar-expand-xxl .navbar-collapse .dropdown-menu-columns {
    flex-direction: column;
  }

  .navbar-vertical.navbar-expand-xxl .navbar-collapse .dropdown-menu {
    color: inherit;
    box-shadow: none;
    background: none;
    border: none;
    min-width: 0;
    margin: 0;
    padding: 0;
    position: static;
  }

  .navbar-vertical.navbar-expand-xxl .navbar-collapse .dropdown-menu .dropdown-item {
    width: auto;
    min-width: 0;
    padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 1.75rem);
    color: inherit;
    display: flex;
  }

  .navbar-vertical.navbar-expand-xxl .navbar-collapse .dropdown-menu .dropdown-item.disabled {
    color: var(--tblr-disabled-color);
    pointer-events: none;
    background-color: #0000;
  }

  .navbar-vertical.navbar-expand-xxl .navbar-collapse .dropdown-menu .dropdown-item.active, .navbar-vertical.navbar-expand-xxl .navbar-collapse .dropdown-menu .dropdown-item:active {
    background: var(--tblr-navbar-active-bg);
  }

  .navbar-vertical.navbar-expand-xxl .navbar-collapse .dropdown-menu .dropdown-menu .dropdown-item {
    padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 3.25rem);
  }

  .navbar-vertical.navbar-expand-xxl .navbar-collapse .dropdown-menu .dropdown-menu .dropdown-menu .dropdown-item {
    padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 4.75rem);
  }

  .navbar-vertical.navbar-expand-xxl .navbar-collapse .dropdown-toggle:after {
    margin-left: auto;
  }

  .navbar-vertical.navbar-expand-xxl .navbar-collapse .nav-item.active:after {
    border-bottom-width: 0;
    border-left-width: 3px;
    top: 0;
    bottom: 0;
    right: auto;
  }
}

.navbar-vertical.navbar-expand {
  z-index: 1030;
  align-items: flex-start;
  width: 15rem;
  padding: 0;
  transition: transform .3s;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand {
    transition: none;
  }
}

.navbar-vertical.navbar-expand.navbar-right {
  left: auto;
  right: 0;
}

.navbar-vertical.navbar-expand .navbar-brand {
  justify-content: center;
  padding: .75rem 0;
}

.navbar-vertical.navbar-expand .navbar-collapse {
  align-items: stretch;
}

.navbar-vertical.navbar-expand .navbar-nav {
  flex-direction: column;
  flex-grow: 1;
  min-height: auto;
}

.navbar-vertical.navbar-expand .navbar-nav .nav-link {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.navbar-vertical.navbar-expand > [class^="container"] {
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  min-height: 100%;
  padding: 0;
}

.navbar-vertical.navbar-expand ~ .page {
  padding-left: 15rem;
}

.navbar-vertical.navbar-expand ~ .page [class^="container"] {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.navbar-vertical.navbar-expand.navbar-right ~ .page {
  padding-left: 0;
  padding-right: 15rem;
}

.navbar-vertical.navbar-expand .navbar-collapse {
  flex-direction: column;
}

.navbar-vertical.navbar-expand .navbar-collapse [class^="container"] {
  flex-direction: column;
  align-items: stretch;
  padding: 0;
}

.navbar-vertical.navbar-expand .navbar-collapse .navbar-nav {
  margin-left: 0;
  margin-right: 0;
}

.navbar-vertical.navbar-expand .navbar-collapse .navbar-nav .nav-link {
  padding: .5rem calc(calc(var(--tblr-page-padding) * 2) / 2);
  justify-content: flex-start;
}

.navbar-vertical.navbar-expand .navbar-collapse .dropdown-menu-columns {
  flex-direction: column;
}

.navbar-vertical.navbar-expand .navbar-collapse .dropdown-menu {
  color: inherit;
  box-shadow: none;
  background: none;
  border: none;
  min-width: 0;
  margin: 0;
  padding: 0;
  position: static;
}

.navbar-vertical.navbar-expand .navbar-collapse .dropdown-menu .dropdown-item {
  width: auto;
  min-width: 0;
  padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 1.75rem);
  color: inherit;
  display: flex;
}

.navbar-vertical.navbar-expand .navbar-collapse .dropdown-menu .dropdown-item.disabled {
  color: var(--tblr-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

.navbar-vertical.navbar-expand .navbar-collapse .dropdown-menu .dropdown-item.active, .navbar-vertical.navbar-expand .navbar-collapse .dropdown-menu .dropdown-item:active {
  background: var(--tblr-navbar-active-bg);
}

.navbar-vertical.navbar-expand .navbar-collapse .dropdown-menu .dropdown-menu .dropdown-item {
  padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 3.25rem);
}

.navbar-vertical.navbar-expand .navbar-collapse .dropdown-menu .dropdown-menu .dropdown-menu .dropdown-item {
  padding-left: calc(calc(calc(var(--tblr-page-padding) * 2) / 2)  + 4.75rem);
}

.navbar-vertical.navbar-expand .navbar-collapse .dropdown-toggle:after {
  margin-left: auto;
}

.navbar-vertical.navbar-expand .navbar-collapse .nav-item.active:after {
  border-bottom-width: 0;
  border-left-width: 3px;
  top: 0;
  bottom: 0;
  right: auto;
}

.navbar-overlap:after {
  content: "";
  background: inherit;
  z-index: -1;
  height: 9rem;
  box-shadow: inherit;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
}

.page {
  flex-direction: column;
  min-height: 100%;
  display: flex;
  position: relative;
}

.page-center {
  justify-content: center;
}

.page-wrapper {
  flex-direction: column;
  flex: 1;
  display: flex;
}

@media print {
  .page-wrapper {
    margin: 0 !important;
  }
}

.page-wrapper-full .page-body:first-child {
  border-top: 0;
  margin: 0;
}

.page-body {
  margin-top: var(--tblr-page-padding-y);
  margin-bottom: var(--tblr-page-padding-y);
  flex-direction: column;
  flex: 1;
  display: flex;
}

.page-body-card {
  background: var(--tblr-bg-surface);
  border-top: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent);
  padding: var(--tblr-page-padding) 0;
  flex: 1;
  margin-bottom: 0;
}

.page-body ~ .page-body-card {
  margin-top: 0;
}

.page-cover {
  background: center / cover no-repeat;
  min-height: 9rem;
}

@media (width >= 768px) {
  .page-cover {
    min-height: 12rem;
  }
}

@media (width >= 992px) {
  .page-cover {
    min-height: 15rem;
  }
}

.page-cover-overlay {
  position: relative;
}

.page-cover-overlay:after {
  content: "";
  background-image: linear-gradient(#0000 0%, #0009 100%);
  position: absolute;
  inset: 0;
}

.page-header {
  flex-flow: column wrap;
  justify-content: center;
  min-height: 2.25rem;
  display: flex;
}

.page-wrapper .page-header {
  margin: var(--tblr-page-padding-y) 0 0;
}

.page-header-border {
  border-bottom: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color);
  padding: var(--tblr-page-padding-y) 0;
  background-color: var(--tblr-bg-surface);
  margin: 0 !important;
}

.page-pretitle {
  font-size: .75rem;
  font-weight: var(--tblr-font-weight-bold);
  text-transform: uppercase;
  letter-spacing: .04em;
  color: var(--tblr-secondary);
  line-height: 1rem;
}

.page-title {
  font-size: var(--tblr-font-size-h2);
  line-height: var(--tblr-line-height-h2);
  font-weight: var(--tblr-font-weight-headings);
  color: inherit;
  align-items: center;
  margin: 0;
  display: flex;
}

.page-title svg {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: .25rem;
}

.page-title-lg {
  font-size: 1.5rem;
  line-height: 2rem;
}

.page-subtitle {
  color: var(--tblr-secondary);
  margin-top: .25rem;
}

.page-cover {
  --tblr-page-cover-blur: 20px;
  --tblr-page-cover-padding: 1rem;
  min-height: 6rem;
  padding: var(--tblr-page-cover-padding) 0;
  position: relative;
  overflow: hidden;
}

.page-cover-img {
  top: calc(-2 * var(--tblr-page-cover-blur, 0));
  left: calc(-2 * var(--tblr-page-cover-blur, 0));
  right: calc(-2 * var(--tblr-page-cover-blur, 0));
  bottom: calc(-2 * var(--tblr-page-cover-blur, 0));
  pointer-events: none;
  filter: blur(var(--tblr-page-cover-blur));
  -o-object-fit: cover;
  object-fit: cover;
  z-index: -1;
  background-position: center;
  background-size: cover;
  position: absolute;
}

.page-tabs {
  margin-top: .5rem;
  position: relative;
}

.page-header-tabs .nav-bordered {
  border: 0;
}

.page-header-tabs + .page-body-card {
  margin-top: 0;
}

.footer {
  border-top: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color);
  color: var(--tblr-gray-500);
  background-color: #fff;
  margin-top: auto;
  padding: 2rem 0;
}

.footer-transparent {
  background-color: #0000;
  border-top: 0;
}

body:not(.theme-dark):not([data-bs-theme="dark"]) .hide-theme-light, body:not(.theme-dark):not([data-bs-theme="dark"]) .img-dark, body.theme-dark .hide-theme-dark, body[data-bs-theme="dark"] .hide-theme-dark, body.theme-dark .img-light, body[data-bs-theme="dark"] .img-light {
  display: none !important;
}

[data-bs-theme="dark"], body[data-bs-theme="dark"] [data-bs-theme="light"] {
  --tblr-body-color: #dce1e7;
  --tblr-body-color-rgb: 220, 225, 231;
  --tblr-muted: #49566c;
  --tblr-body-bg: #151f2c;
  --tblr-body-bg-rgb: 21, 31, 44;
  --tblr-emphasis-color: #fff;
  --tblr-emphasis-color-rgb: 255, 255, 255;
  --tblr-bg-forms: #151f2c;
  --tblr-bg-surface: #182433;
  --tblr-bg-surface-dark: #151f2c;
  --tblr-bg-surface-secondary: #1b293a;
  --tblr-bg-surface-tertiary: #151f2c;
  --tblr-link-color: #0884f8;
  --tblr-link-hover-color: #066fd1;
  --tblr-active-bg: #1b293a;
  --tblr-disabled-color: var(--tblr-gray-700);
  --tblr-border-color: var(--tblr-dark-mode-border-color);
  --tblr-border-color-translucent: var(--tblr-dark-mode-border-color-translucent);
  --tblr-border-dark-color: var(--tblr-dark-mode-border-dark-color);
  --tblr-border-active-color: var(--tblr-dark-mode-border-active-color);
  --tblr-btn-color: #151f2c;
  --tblr-code-color: var(--tblr-body-color);
  --tblr-code-bg: #1f2e41;
  --tblr-primary-lt: #162c43;
  --tblr-primary-lt-rgb: 22, 44, 67;
  --tblr-secondary-lt: #202d3c;
  --tblr-secondary-lt-rgb: 32, 45, 60;
  --tblr-success-lt: #1a3235;
  --tblr-success-lt-rgb: 26, 50, 53;
  --tblr-info-lt: #1c3044;
  --tblr-info-lt-rgb: 28, 48, 68;
  --tblr-warning-lt: #2e2b2f;
  --tblr-warning-lt-rgb: 46, 43, 47;
  --tblr-danger-lt: #2b2634;
  --tblr-danger-lt-rgb: 43, 38, 52;
  --tblr-light-lt: #2e3947;
  --tblr-light-lt-rgb: 46, 57, 71;
  --tblr-dark-lt: #182433;
  --tblr-dark-lt-rgb: 24, 36, 51;
  --tblr-muted-lt: #202d3c;
  --tblr-muted-lt-rgb: 32, 45, 60;
  --tblr-blue-lt: #162c43;
  --tblr-blue-lt-rgb: 22, 44, 67;
  --tblr-azure-lt: #1c3044;
  --tblr-azure-lt-rgb: 28, 48, 68;
  --tblr-indigo-lt: #1c2a45;
  --tblr-indigo-lt-rgb: 28, 42, 69;
  --tblr-purple-lt: #272742;
  --tblr-purple-lt-rgb: 39, 39, 66;
  --tblr-pink-lt: #2b2639;
  --tblr-pink-lt-rgb: 43, 38, 57;
  --tblr-red-lt: #2b2634;
  --tblr-red-lt-rgb: 43, 38, 52;
  --tblr-orange-lt: #2e2b2f;
  --tblr-orange-lt-rgb: 46, 43, 47;
  --tblr-yellow-lt: #2e302e;
  --tblr-yellow-lt-rgb: 46, 48, 46;
  --tblr-lime-lt: #213330;
  --tblr-lime-lt-rgb: 33, 51, 48;
  --tblr-green-lt: #1a3235;
  --tblr-green-lt-rgb: 26, 50, 53;
  --tblr-teal-lt: #17313a;
  --tblr-teal-lt-rgb: 23, 49, 58;
  --tblr-cyan-lt: #183140;
  --tblr-cyan-lt-rgb: 24, 49, 64;
  --tblr-x-lt: #16202e;
  --tblr-x-lt-rgb: 22, 32, 46;
  --tblr-facebook-lt: #182c46;
  --tblr-facebook-lt-rgb: 24, 44, 70;
  --tblr-twitter-lt: #193146;
  --tblr-twitter-lt-rgb: 25, 49, 70;
  --tblr-linkedin-lt: #172b41;
  --tblr-linkedin-lt-rgb: 23, 43, 65;
  --tblr-google-lt: #2c2834;
  --tblr-google-lt-rgb: 44, 40, 52;
  --tblr-youtube-lt: #2f202e;
  --tblr-youtube-lt-rgb: 47, 32, 46;
  --tblr-vimeo-lt: #183345;
  --tblr-vimeo-lt-rgb: 24, 51, 69;
  --tblr-dribbble-lt: #2d283c;
  --tblr-dribbble-lt-rgb: 45, 40, 60;
  --tblr-github-lt: #182330;
  --tblr-github-lt-rgb: 24, 35, 48;
  --tblr-instagram-lt: #2c2737;
  --tblr-instagram-lt-rgb: 44, 39, 55;
  --tblr-pinterest-lt: #292131;
  --tblr-pinterest-lt-rgb: 41, 33, 49;
  --tblr-vk-lt: #202e3f;
  --tblr-vk-lt-rgb: 32, 46, 63;
  --tblr-rss-lt: #2f312e;
  --tblr-rss-lt-rgb: 47, 49, 46;
  --tblr-flickr-lt: #162a44;
  --tblr-flickr-lt-rgb: 22, 42, 68;
  --tblr-bitbucket-lt: #162942;
  --tblr-bitbucket-lt-rgb: 22, 41, 66;
  --tblr-tabler-lt: #162c43;
  --tblr-tabler-lt-rgb: 22, 44, 67;
}

[data-bs-theme="dark"] .navbar-brand-autodark .navbar-brand-image {
  filter: brightness(0) invert();
}

.accordion {
  --tblr-accordion-color: var(--tblr-body-color);
}

.accordion-button:focus:not(:focus-visible) {
  box-shadow: none;
  outline: none;
}

.accordion-button:after {
  opacity: .7;
}

.accordion-button:not(.collapsed) {
  font-weight: var(--tblr-font-weight-bold);
  box-shadow: none;
  border-bottom-color: #0000;
}

.accordion-button:not(.collapsed):after {
  opacity: 1;
}

.alert {
  --tblr-alert-color: var(--tblr-secondary);
  --tblr-alert-bg: var(--tblr-bg-surface);
  border: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent);
  border-left: .25rem var(--tblr-border-style) var(--tblr-alert-color);
  box-shadow: 0 2px 4px #1824330a;
}

.alert > :last-child {
  margin-bottom: 0;
}

.alert-important {
  background: var(--tblr-alert-color);
  color: #fff;
  border-color: #0000;
}

.alert-important .alert-icon, .alert-important .alert-link, .alert-important .alert-title, .alert-important .alert-link:hover {
  color: inherit;
}

.alert-important .btn-close {
  filter: var(--tblr-btn-close-white-filter);
}

.alert-link, .alert-link:hover {
  color: var(--tblr-alert-color);
}

.alert-primary {
  --tblr-alert-color: var(--tblr-primary);
}

.alert-secondary {
  --tblr-alert-color: var(--tblr-secondary);
}

.alert-success {
  --tblr-alert-color: var(--tblr-success);
}

.alert-info {
  --tblr-alert-color: var(--tblr-info);
}

.alert-warning {
  --tblr-alert-color: var(--tblr-warning);
}

.alert-danger {
  --tblr-alert-color: var(--tblr-danger);
}

.alert-light {
  --tblr-alert-color: var(--tblr-light);
}

.alert-dark {
  --tblr-alert-color: var(--tblr-dark);
}

.alert-muted {
  --tblr-alert-color: var(--tblr-muted);
}

.alert-blue {
  --tblr-alert-color: var(--tblr-blue);
}

.alert-azure {
  --tblr-alert-color: var(--tblr-azure);
}

.alert-indigo {
  --tblr-alert-color: var(--tblr-indigo);
}

.alert-purple {
  --tblr-alert-color: var(--tblr-purple);
}

.alert-pink {
  --tblr-alert-color: var(--tblr-pink);
}

.alert-red {
  --tblr-alert-color: var(--tblr-red);
}

.alert-orange {
  --tblr-alert-color: var(--tblr-orange);
}

.alert-yellow {
  --tblr-alert-color: var(--tblr-yellow);
}

.alert-lime {
  --tblr-alert-color: var(--tblr-lime);
}

.alert-green {
  --tblr-alert-color: var(--tblr-green);
}

.alert-teal {
  --tblr-alert-color: var(--tblr-teal);
}

.alert-cyan {
  --tblr-alert-color: var(--tblr-cyan);
}

.alert-x {
  --tblr-alert-color: var(--tblr-x);
}

.alert-facebook {
  --tblr-alert-color: var(--tblr-facebook);
}

.alert-twitter {
  --tblr-alert-color: var(--tblr-twitter);
}

.alert-linkedin {
  --tblr-alert-color: var(--tblr-linkedin);
}

.alert-google {
  --tblr-alert-color: var(--tblr-google);
}

.alert-youtube {
  --tblr-alert-color: var(--tblr-youtube);
}

.alert-vimeo {
  --tblr-alert-color: var(--tblr-vimeo);
}

.alert-dribbble {
  --tblr-alert-color: var(--tblr-dribbble);
}

.alert-github {
  --tblr-alert-color: var(--tblr-github);
}

.alert-instagram {
  --tblr-alert-color: var(--tblr-instagram);
}

.alert-pinterest {
  --tblr-alert-color: var(--tblr-pinterest);
}

.alert-vk {
  --tblr-alert-color: var(--tblr-vk);
}

.alert-rss {
  --tblr-alert-color: var(--tblr-rss);
}

.alert-flickr {
  --tblr-alert-color: var(--tblr-flickr);
}

.alert-bitbucket {
  --tblr-alert-color: var(--tblr-bitbucket);
}

.alert-tabler {
  --tblr-alert-color: var(--tblr-tabler);
}

.alert-icon {
  color: var(--tblr-alert-color);
  margin: -.125rem 1rem -.125rem 0;
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.alert-title {
  font-size: .875rem;
  line-height: 1.25rem;
  font-weight: var(--tblr-font-weight-bold);
  color: var(--tblr-alert-color);
  margin-bottom: .25rem;
}

.avatar {
  --tblr-avatar-size: 2.5rem;
  --tblr-avatar-status-size: .75rem;
  --tblr-avatar-bg: var(--tblr-bg-surface-secondary);
  --tblr-avatar-box-shadow: var(--tblr-box-shadow-border);
  --tblr-avatar-font-size: 1rem;
  --tblr-avatar-icon-size: 1.5rem;
  width: var(--tblr-avatar-size);
  height: var(--tblr-avatar-size);
  font-size: var(--tblr-avatar-font-size);
  font-weight: var(--tblr-font-weight-medium);
  color: var(--tblr-secondary);
  text-align: center;
  text-transform: uppercase;
  vertical-align: bottom;
  -webkit-user-select: none;
  user-select: none;
  background: var(--tblr-avatar-bg) no-repeat center / cover;
  border-radius: var(--tblr-border-radius);
  box-shadow: var(--tblr-avatar-box-shadow);
  justify-content: center;
  align-items: center;
  line-height: 1;
  display: inline-flex;
  position: relative;
}

.avatar .icon {
  width: var(--tblr-avatar-icon-size);
  height: var(--tblr-avatar-icon-size);
}

.avatar .badge {
  box-shadow: 0 0 0 calc(var(--tblr-avatar-status-size) / 4) var(--tblr-bg-surface);
  border-radius: 100rem;
  position: absolute;
  bottom: 0;
  right: 0;
}

a.avatar {
  cursor: pointer;
}

.avatar-rounded {
  border-radius: 100rem;
}

.avatar-xxs {
  --tblr-avatar-size: 1rem;
  --tblr-avatar-status-size: .25rem;
  --tblr-avatar-font-size: .5rem;
  --tblr-avatar-icon-size: .75rem;
}

.avatar-xxs .badge:empty {
  width: .25rem;
  height: .25rem;
}

.avatar-xs {
  --tblr-avatar-size: 1.25rem;
  --tblr-avatar-status-size: .375rem;
  --tblr-avatar-font-size: .625rem;
  --tblr-avatar-icon-size: .75rem;
}

.avatar-xs .badge:empty {
  width: .375rem;
  height: .375rem;
}

.avatar-sm {
  --tblr-avatar-size: 2rem;
  --tblr-avatar-status-size: .5rem;
  --tblr-avatar-font-size: .75rem;
  --tblr-avatar-icon-size: 1.5rem;
}

.avatar-sm .badge:empty {
  width: .5rem;
  height: .5rem;
}

.avatar-md {
  --tblr-avatar-size: 2.5rem;
  --tblr-avatar-status-size: .75rem;
  --tblr-avatar-font-size: .875rem;
  --tblr-avatar-icon-size: 1.5rem;
}

.avatar-md .badge:empty {
  width: .75rem;
  height: .75rem;
}

.avatar-lg {
  --tblr-avatar-size: 3rem;
  --tblr-avatar-status-size: .75rem;
  --tblr-avatar-font-size: 1.25rem;
  --tblr-avatar-icon-size: 2rem;
}

.avatar-lg .badge:empty {
  width: .75rem;
  height: .75rem;
}

.avatar-xl {
  --tblr-avatar-size: 5rem;
  --tblr-avatar-status-size: 1rem;
  --tblr-avatar-font-size: 2rem;
  --tblr-avatar-icon-size: 3rem;
}

.avatar-xl .badge:empty {
  width: 1rem;
  height: 1rem;
}

.avatar-2xl {
  --tblr-avatar-size: 7rem;
  --tblr-avatar-status-size: 1rem;
  --tblr-avatar-font-size: 3rem;
  --tblr-avatar-icon-size: 5rem;
}

.avatar-2xl .badge:empty {
  width: 1rem;
  height: 1rem;
}

.avatar-list {
  --tblr-list-gap: .5rem;
  gap: var(--tblr-list-gap);
  flex-wrap: wrap;
  display: flex;
}

.avatar-list a.avatar:hover {
  z-index: 1;
}

.avatar-list-stacked {
  --tblr-list-gap: 0;
  display: block;
}

.avatar-list-stacked .avatar {
  box-shadow: var(--tblr-avatar-box-shadow), 0 0 0 2px var(--tblr-card-cap-bg, var(--tblr-card-bg, var(--tblr-bg-surface)));
  margin-right: calc(-.5 * var(--tblr-avatar-size)) !important;
}

.avatar-upload {
  border: var(--tblr-border-width) dashed var(--tblr-border-color);
  background: var(--tblr-bg-forms);
  flex-direction: column;
  width: 4rem;
  height: 4rem;
  transition: color .3s, background-color .3s;
}

@media (prefers-reduced-motion: reduce) {
  .avatar-upload {
    transition: none;
  }
}

.avatar-upload svg {
  stroke-width: 1px;
  width: 1.5rem;
  height: 1.5rem;
}

.avatar-upload:hover {
  border-color: var(--tblr-primary);
  color: var(--tblr-primary);
  text-decoration: none;
}

.avatar-upload-text {
  margin-top: .25rem;
  font-size: .625rem;
  line-height: 1;
}

.avatar-cover {
  margin-top: calc(-.5 * var(--tblr-avatar-size));
  box-shadow: 0 0 0 .25rem var(--tblr-card-bg, var(--tblr-body-bg));
}

.badge {
  background: var(--tblr-bg-surface-secondary);
  -webkit-user-select: none;
  user-select: none;
  border: var(--tblr-border-width) var(--tblr-border-style) transparent;
  min-width: 1.35714em;
  font-weight: var(--tblr-font-weight-bold);
  letter-spacing: .04em;
  vertical-align: bottom;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

a.badge {
  color: var(--tblr-bg-surface);
}

.badge .avatar {
  box-sizing: content-box;
  width: 1.25rem;
  height: 1.25rem;
  margin: 0 .5rem 0 -.5rem;
}

.badge .icon {
  stroke-width: 2px;
  width: 1em;
  height: 1em;
  font-size: 1rem;
}

.badge:empty, .badge-empty {
  vertical-align: baseline;
  border-radius: 100rem;
  width: .5rem;
  min-width: 0;
  height: .5rem;
  min-height: auto;
  padding: 0;
  display: inline-block;
}

.badge-outline {
  border: var(--tblr-border-width) var(--tblr-border-style) currentColor;
  background-color: #0000;
}

.badge-pill {
  border-radius: 100rem;
}

.badges-list {
  --tblr-list-gap: .5rem;
  gap: var(--tblr-list-gap);
  flex-wrap: wrap;
  display: flex;
}

.badge-notification {
  z-index: 1;
  transform: translate(50%, -50%);
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
}

.badge-blink {
  animation: 2s infinite blink;
}

.breadcrumb {
  --tblr-breadcrumb-item-active-font-weight: var(--tblr-font-weight-bold);
  --tblr-breadcrumb-item-disabled-color: var(--tblr-disabled-color);
  --tblr-breadcrumb-link-color: var(--tblr-link-color);
  background: none;
  margin: 0;
  padding: 0;
}

.breadcrumb a {
  color: var(--tblr-breadcrumb-link-color);
}

.breadcrumb a:hover {
  text-decoration: underline;
}

.breadcrumb-muted {
  --tblr-breadcrumb-link-color: var(--tblr-secondary);
}

.breadcrumb-item.active {
  font-weight: var(--tblr-breadcrumb-item-active-font-weight);
}

.breadcrumb-item.active a {
  color: inherit;
  pointer-events: none;
}

.breadcrumb-item.disabled {
  color: var(--tblr-breadcrumb-item-disabled-color);
}

.breadcrumb-item.disabled:before {
  color: inherit;
}

.breadcrumb-item.disabled a {
  color: inherit;
  pointer-events: none;
}

.breadcrumb-dots {
  --tblr-breadcrumb-divider: "·";
}

.breadcrumb-arrows {
  --tblr-breadcrumb-divider: "›";
}

.breadcrumb-bullets {
  --tblr-breadcrumb-divider: "•";
}

.btn {
  --tblr-btn-icon-size: 1.25rem;
  --tblr-btn-bg: var(--tblr-bg-surface);
  --tblr-btn-color: var(--tblr-body-color);
  --tblr-btn-border-color: var(--tblr-border-color);
  --tblr-btn-hover-bg: var(--tblr-btn-bg);
  --tblr-btn-hover-border-color: var(--tblr-border-active-color);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
  --tblr-btn-active-color: var(--tblr-primary);
  --tblr-btn-active-bg: rgba(var(--tblr-primary-rgb), .04);
  --tblr-btn-active-border-color: var(--tblr-primary);
  white-space: nowrap;
  box-shadow: var(--tblr-btn-box-shadow);
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.btn .icon {
  width: var(--tblr-btn-icon-size);
  height: var(--tblr-btn-icon-size);
  min-width: var(--tblr-btn-icon-size);
  font-size: var(--tblr-btn-icon-size);
  margin: 0 calc(var(--tblr-btn-padding-x) / 2) 0 calc(var(--tblr-btn-padding-x) / -4);
  vertical-align: bottom;
  color: inherit;
}

.btn .avatar {
  width: var(--tblr-btn-icon-size);
  height: var(--tblr-btn-icon-size);
  margin: 0 calc(var(--tblr-btn-padding-x) / 2) 0 calc(var(--tblr-btn-padding-x) / -4);
}

.btn .icon-right {
  margin: 0 calc(var(--tblr-btn-padding-x) / -4) 0 calc(var(--tblr-btn-padding-x) / 2);
}

.btn .badge {
  top: auto;
}

.btn-check + .btn:hover {
  color: var(--tblr-btn-hover-color);
  background-color: var(--tblr-btn-hover-bg);
  border-color: var(--tblr-btn-hover-border-color);
}

.btn-link {
  color: #077cea;
  box-shadow: none;
  background-color: #0000;
  border-color: #0000;
}

.btn-link .icon {
  color: inherit;
}

.btn-link:hover {
  color: #0559a7;
  border-color: #0000;
}

.btn-primary {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-primary-fg);
  --tblr-btn-bg: var(--tblr-primary);
  --tblr-btn-hover-color: var(--tblr-primary-fg);
  --tblr-btn-hover-bg: var(--tblr-primary-darken);
  --tblr-btn-active-color: var(--tblr-primary-fg);
  --tblr-btn-active-bg: var(--tblr-primary-darken);
  --tblr-btn-disabled-bg: var(--tblr-primary);
  --tblr-btn-disabled-color: var(--tblr-primary-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-primary {
  --tblr-btn-color: var(--tblr-primary);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-primary);
  --tblr-btn-hover-color: var(--tblr-primary-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-primary);
  --tblr-btn-active-color: var(--tblr-primary-fg);
  --tblr-btn-active-bg: var(--tblr-primary);
  --tblr-btn-disabled-color: var(--tblr-primary);
  --tblr-btn-disabled-border-color: var(--tblr-primary);
}

.btn-secondary {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-secondary-fg);
  --tblr-btn-bg: var(--tblr-secondary);
  --tblr-btn-hover-color: var(--tblr-secondary-fg);
  --tblr-btn-hover-bg: var(--tblr-secondary-darken);
  --tblr-btn-active-color: var(--tblr-secondary-fg);
  --tblr-btn-active-bg: var(--tblr-secondary-darken);
  --tblr-btn-disabled-bg: var(--tblr-secondary);
  --tblr-btn-disabled-color: var(--tblr-secondary-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-secondary {
  --tblr-btn-color: var(--tblr-secondary);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-secondary);
  --tblr-btn-hover-color: var(--tblr-secondary-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-secondary);
  --tblr-btn-active-color: var(--tblr-secondary-fg);
  --tblr-btn-active-bg: var(--tblr-secondary);
  --tblr-btn-disabled-color: var(--tblr-secondary);
  --tblr-btn-disabled-border-color: var(--tblr-secondary);
}

.btn-success {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-success-fg);
  --tblr-btn-bg: var(--tblr-success);
  --tblr-btn-hover-color: var(--tblr-success-fg);
  --tblr-btn-hover-bg: var(--tblr-success-darken);
  --tblr-btn-active-color: var(--tblr-success-fg);
  --tblr-btn-active-bg: var(--tblr-success-darken);
  --tblr-btn-disabled-bg: var(--tblr-success);
  --tblr-btn-disabled-color: var(--tblr-success-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-success {
  --tblr-btn-color: var(--tblr-success);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-success);
  --tblr-btn-hover-color: var(--tblr-success-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-success);
  --tblr-btn-active-color: var(--tblr-success-fg);
  --tblr-btn-active-bg: var(--tblr-success);
  --tblr-btn-disabled-color: var(--tblr-success);
  --tblr-btn-disabled-border-color: var(--tblr-success);
}

.btn-info {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-info-fg);
  --tblr-btn-bg: var(--tblr-info);
  --tblr-btn-hover-color: var(--tblr-info-fg);
  --tblr-btn-hover-bg: var(--tblr-info-darken);
  --tblr-btn-active-color: var(--tblr-info-fg);
  --tblr-btn-active-bg: var(--tblr-info-darken);
  --tblr-btn-disabled-bg: var(--tblr-info);
  --tblr-btn-disabled-color: var(--tblr-info-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-info {
  --tblr-btn-color: var(--tblr-info);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-info);
  --tblr-btn-hover-color: var(--tblr-info-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-info);
  --tblr-btn-active-color: var(--tblr-info-fg);
  --tblr-btn-active-bg: var(--tblr-info);
  --tblr-btn-disabled-color: var(--tblr-info);
  --tblr-btn-disabled-border-color: var(--tblr-info);
}

.btn-warning {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-warning-fg);
  --tblr-btn-bg: var(--tblr-warning);
  --tblr-btn-hover-color: var(--tblr-warning-fg);
  --tblr-btn-hover-bg: var(--tblr-warning-darken);
  --tblr-btn-active-color: var(--tblr-warning-fg);
  --tblr-btn-active-bg: var(--tblr-warning-darken);
  --tblr-btn-disabled-bg: var(--tblr-warning);
  --tblr-btn-disabled-color: var(--tblr-warning-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-warning {
  --tblr-btn-color: var(--tblr-warning);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-warning);
  --tblr-btn-hover-color: var(--tblr-warning-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-warning);
  --tblr-btn-active-color: var(--tblr-warning-fg);
  --tblr-btn-active-bg: var(--tblr-warning);
  --tblr-btn-disabled-color: var(--tblr-warning);
  --tblr-btn-disabled-border-color: var(--tblr-warning);
}

.btn-danger {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-danger-fg);
  --tblr-btn-bg: var(--tblr-danger);
  --tblr-btn-hover-color: var(--tblr-danger-fg);
  --tblr-btn-hover-bg: var(--tblr-danger-darken);
  --tblr-btn-active-color: var(--tblr-danger-fg);
  --tblr-btn-active-bg: var(--tblr-danger-darken);
  --tblr-btn-disabled-bg: var(--tblr-danger);
  --tblr-btn-disabled-color: var(--tblr-danger-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-danger {
  --tblr-btn-color: var(--tblr-danger);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-danger);
  --tblr-btn-hover-color: var(--tblr-danger-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-danger);
  --tblr-btn-active-color: var(--tblr-danger-fg);
  --tblr-btn-active-bg: var(--tblr-danger);
  --tblr-btn-disabled-color: var(--tblr-danger);
  --tblr-btn-disabled-border-color: var(--tblr-danger);
}

.btn-light {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-light-fg);
  --tblr-btn-bg: var(--tblr-light);
  --tblr-btn-hover-color: var(--tblr-light-fg);
  --tblr-btn-hover-bg: var(--tblr-light-darken);
  --tblr-btn-active-color: var(--tblr-light-fg);
  --tblr-btn-active-bg: var(--tblr-light-darken);
  --tblr-btn-disabled-bg: var(--tblr-light);
  --tblr-btn-disabled-color: var(--tblr-light-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-light {
  --tblr-btn-color: var(--tblr-light);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-light);
  --tblr-btn-hover-color: var(--tblr-light-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-light);
  --tblr-btn-active-color: var(--tblr-light-fg);
  --tblr-btn-active-bg: var(--tblr-light);
  --tblr-btn-disabled-color: var(--tblr-light);
  --tblr-btn-disabled-border-color: var(--tblr-light);
}

.btn-dark {
  --tblr-btn-border-color: var(--tblr-dark-mode-border-color);
  --tblr-btn-hover-border-color: var(--tblr-dark-mode-border-active-color);
  --tblr-btn-active-border-color: var(--tblr-dark-mode-border-active-color);
  --tblr-btn-color: var(--tblr-dark-fg);
  --tblr-btn-bg: var(--tblr-dark);
  --tblr-btn-hover-color: var(--tblr-dark-fg);
  --tblr-btn-hover-bg: var(--tblr-dark-darken);
  --tblr-btn-active-color: var(--tblr-dark-fg);
  --tblr-btn-active-bg: var(--tblr-dark-darken);
  --tblr-btn-disabled-bg: var(--tblr-dark);
  --tblr-btn-disabled-color: var(--tblr-dark-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-dark {
  --tblr-btn-color: var(--tblr-dark);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-dark);
  --tblr-btn-hover-color: var(--tblr-dark-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-dark);
  --tblr-btn-active-color: var(--tblr-dark-fg);
  --tblr-btn-active-bg: var(--tblr-dark);
  --tblr-btn-disabled-color: var(--tblr-dark);
  --tblr-btn-disabled-border-color: var(--tblr-dark);
}

.btn-muted {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-muted-fg);
  --tblr-btn-bg: var(--tblr-muted);
  --tblr-btn-hover-color: var(--tblr-muted-fg);
  --tblr-btn-hover-bg: var(--tblr-muted-darken);
  --tblr-btn-active-color: var(--tblr-muted-fg);
  --tblr-btn-active-bg: var(--tblr-muted-darken);
  --tblr-btn-disabled-bg: var(--tblr-muted);
  --tblr-btn-disabled-color: var(--tblr-muted-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-muted {
  --tblr-btn-color: var(--tblr-muted);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-muted);
  --tblr-btn-hover-color: var(--tblr-muted-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-muted);
  --tblr-btn-active-color: var(--tblr-muted-fg);
  --tblr-btn-active-bg: var(--tblr-muted);
  --tblr-btn-disabled-color: var(--tblr-muted);
  --tblr-btn-disabled-border-color: var(--tblr-muted);
}

.btn-blue {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-blue-fg);
  --tblr-btn-bg: var(--tblr-blue);
  --tblr-btn-hover-color: var(--tblr-blue-fg);
  --tblr-btn-hover-bg: var(--tblr-blue-darken);
  --tblr-btn-active-color: var(--tblr-blue-fg);
  --tblr-btn-active-bg: var(--tblr-blue-darken);
  --tblr-btn-disabled-bg: var(--tblr-blue);
  --tblr-btn-disabled-color: var(--tblr-blue-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-blue {
  --tblr-btn-color: var(--tblr-blue);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-blue);
  --tblr-btn-hover-color: var(--tblr-blue-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-blue);
  --tblr-btn-active-color: var(--tblr-blue-fg);
  --tblr-btn-active-bg: var(--tblr-blue);
  --tblr-btn-disabled-color: var(--tblr-blue);
  --tblr-btn-disabled-border-color: var(--tblr-blue);
}

.btn-azure {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-azure-fg);
  --tblr-btn-bg: var(--tblr-azure);
  --tblr-btn-hover-color: var(--tblr-azure-fg);
  --tblr-btn-hover-bg: var(--tblr-azure-darken);
  --tblr-btn-active-color: var(--tblr-azure-fg);
  --tblr-btn-active-bg: var(--tblr-azure-darken);
  --tblr-btn-disabled-bg: var(--tblr-azure);
  --tblr-btn-disabled-color: var(--tblr-azure-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-azure {
  --tblr-btn-color: var(--tblr-azure);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-azure);
  --tblr-btn-hover-color: var(--tblr-azure-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-azure);
  --tblr-btn-active-color: var(--tblr-azure-fg);
  --tblr-btn-active-bg: var(--tblr-azure);
  --tblr-btn-disabled-color: var(--tblr-azure);
  --tblr-btn-disabled-border-color: var(--tblr-azure);
}

.btn-indigo {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-indigo-fg);
  --tblr-btn-bg: var(--tblr-indigo);
  --tblr-btn-hover-color: var(--tblr-indigo-fg);
  --tblr-btn-hover-bg: var(--tblr-indigo-darken);
  --tblr-btn-active-color: var(--tblr-indigo-fg);
  --tblr-btn-active-bg: var(--tblr-indigo-darken);
  --tblr-btn-disabled-bg: var(--tblr-indigo);
  --tblr-btn-disabled-color: var(--tblr-indigo-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-indigo {
  --tblr-btn-color: var(--tblr-indigo);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-indigo);
  --tblr-btn-hover-color: var(--tblr-indigo-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-indigo);
  --tblr-btn-active-color: var(--tblr-indigo-fg);
  --tblr-btn-active-bg: var(--tblr-indigo);
  --tblr-btn-disabled-color: var(--tblr-indigo);
  --tblr-btn-disabled-border-color: var(--tblr-indigo);
}

.btn-purple {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-purple-fg);
  --tblr-btn-bg: var(--tblr-purple);
  --tblr-btn-hover-color: var(--tblr-purple-fg);
  --tblr-btn-hover-bg: var(--tblr-purple-darken);
  --tblr-btn-active-color: var(--tblr-purple-fg);
  --tblr-btn-active-bg: var(--tblr-purple-darken);
  --tblr-btn-disabled-bg: var(--tblr-purple);
  --tblr-btn-disabled-color: var(--tblr-purple-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-purple {
  --tblr-btn-color: var(--tblr-purple);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-purple);
  --tblr-btn-hover-color: var(--tblr-purple-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-purple);
  --tblr-btn-active-color: var(--tblr-purple-fg);
  --tblr-btn-active-bg: var(--tblr-purple);
  --tblr-btn-disabled-color: var(--tblr-purple);
  --tblr-btn-disabled-border-color: var(--tblr-purple);
}

.btn-pink {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-pink-fg);
  --tblr-btn-bg: var(--tblr-pink);
  --tblr-btn-hover-color: var(--tblr-pink-fg);
  --tblr-btn-hover-bg: var(--tblr-pink-darken);
  --tblr-btn-active-color: var(--tblr-pink-fg);
  --tblr-btn-active-bg: var(--tblr-pink-darken);
  --tblr-btn-disabled-bg: var(--tblr-pink);
  --tblr-btn-disabled-color: var(--tblr-pink-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-pink {
  --tblr-btn-color: var(--tblr-pink);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-pink);
  --tblr-btn-hover-color: var(--tblr-pink-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-pink);
  --tblr-btn-active-color: var(--tblr-pink-fg);
  --tblr-btn-active-bg: var(--tblr-pink);
  --tblr-btn-disabled-color: var(--tblr-pink);
  --tblr-btn-disabled-border-color: var(--tblr-pink);
}

.btn-red {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-red-fg);
  --tblr-btn-bg: var(--tblr-red);
  --tblr-btn-hover-color: var(--tblr-red-fg);
  --tblr-btn-hover-bg: var(--tblr-red-darken);
  --tblr-btn-active-color: var(--tblr-red-fg);
  --tblr-btn-active-bg: var(--tblr-red-darken);
  --tblr-btn-disabled-bg: var(--tblr-red);
  --tblr-btn-disabled-color: var(--tblr-red-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-red {
  --tblr-btn-color: var(--tblr-red);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-red);
  --tblr-btn-hover-color: var(--tblr-red-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-red);
  --tblr-btn-active-color: var(--tblr-red-fg);
  --tblr-btn-active-bg: var(--tblr-red);
  --tblr-btn-disabled-color: var(--tblr-red);
  --tblr-btn-disabled-border-color: var(--tblr-red);
}

.btn-orange {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-orange-fg);
  --tblr-btn-bg: var(--tblr-orange);
  --tblr-btn-hover-color: var(--tblr-orange-fg);
  --tblr-btn-hover-bg: var(--tblr-orange-darken);
  --tblr-btn-active-color: var(--tblr-orange-fg);
  --tblr-btn-active-bg: var(--tblr-orange-darken);
  --tblr-btn-disabled-bg: var(--tblr-orange);
  --tblr-btn-disabled-color: var(--tblr-orange-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-orange {
  --tblr-btn-color: var(--tblr-orange);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-orange);
  --tblr-btn-hover-color: var(--tblr-orange-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-orange);
  --tblr-btn-active-color: var(--tblr-orange-fg);
  --tblr-btn-active-bg: var(--tblr-orange);
  --tblr-btn-disabled-color: var(--tblr-orange);
  --tblr-btn-disabled-border-color: var(--tblr-orange);
}

.btn-yellow {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-yellow-fg);
  --tblr-btn-bg: var(--tblr-yellow);
  --tblr-btn-hover-color: var(--tblr-yellow-fg);
  --tblr-btn-hover-bg: var(--tblr-yellow-darken);
  --tblr-btn-active-color: var(--tblr-yellow-fg);
  --tblr-btn-active-bg: var(--tblr-yellow-darken);
  --tblr-btn-disabled-bg: var(--tblr-yellow);
  --tblr-btn-disabled-color: var(--tblr-yellow-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-yellow {
  --tblr-btn-color: var(--tblr-yellow);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-yellow);
  --tblr-btn-hover-color: var(--tblr-yellow-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-yellow);
  --tblr-btn-active-color: var(--tblr-yellow-fg);
  --tblr-btn-active-bg: var(--tblr-yellow);
  --tblr-btn-disabled-color: var(--tblr-yellow);
  --tblr-btn-disabled-border-color: var(--tblr-yellow);
}

.btn-lime {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-lime-fg);
  --tblr-btn-bg: var(--tblr-lime);
  --tblr-btn-hover-color: var(--tblr-lime-fg);
  --tblr-btn-hover-bg: var(--tblr-lime-darken);
  --tblr-btn-active-color: var(--tblr-lime-fg);
  --tblr-btn-active-bg: var(--tblr-lime-darken);
  --tblr-btn-disabled-bg: var(--tblr-lime);
  --tblr-btn-disabled-color: var(--tblr-lime-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-lime {
  --tblr-btn-color: var(--tblr-lime);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-lime);
  --tblr-btn-hover-color: var(--tblr-lime-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-lime);
  --tblr-btn-active-color: var(--tblr-lime-fg);
  --tblr-btn-active-bg: var(--tblr-lime);
  --tblr-btn-disabled-color: var(--tblr-lime);
  --tblr-btn-disabled-border-color: var(--tblr-lime);
}

.btn-green {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-green-fg);
  --tblr-btn-bg: var(--tblr-green);
  --tblr-btn-hover-color: var(--tblr-green-fg);
  --tblr-btn-hover-bg: var(--tblr-green-darken);
  --tblr-btn-active-color: var(--tblr-green-fg);
  --tblr-btn-active-bg: var(--tblr-green-darken);
  --tblr-btn-disabled-bg: var(--tblr-green);
  --tblr-btn-disabled-color: var(--tblr-green-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-green {
  --tblr-btn-color: var(--tblr-green);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-green);
  --tblr-btn-hover-color: var(--tblr-green-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-green);
  --tblr-btn-active-color: var(--tblr-green-fg);
  --tblr-btn-active-bg: var(--tblr-green);
  --tblr-btn-disabled-color: var(--tblr-green);
  --tblr-btn-disabled-border-color: var(--tblr-green);
}

.btn-teal {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-teal-fg);
  --tblr-btn-bg: var(--tblr-teal);
  --tblr-btn-hover-color: var(--tblr-teal-fg);
  --tblr-btn-hover-bg: var(--tblr-teal-darken);
  --tblr-btn-active-color: var(--tblr-teal-fg);
  --tblr-btn-active-bg: var(--tblr-teal-darken);
  --tblr-btn-disabled-bg: var(--tblr-teal);
  --tblr-btn-disabled-color: var(--tblr-teal-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-teal {
  --tblr-btn-color: var(--tblr-teal);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-teal);
  --tblr-btn-hover-color: var(--tblr-teal-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-teal);
  --tblr-btn-active-color: var(--tblr-teal-fg);
  --tblr-btn-active-bg: var(--tblr-teal);
  --tblr-btn-disabled-color: var(--tblr-teal);
  --tblr-btn-disabled-border-color: var(--tblr-teal);
}

.btn-cyan {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-cyan-fg);
  --tblr-btn-bg: var(--tblr-cyan);
  --tblr-btn-hover-color: var(--tblr-cyan-fg);
  --tblr-btn-hover-bg: var(--tblr-cyan-darken);
  --tblr-btn-active-color: var(--tblr-cyan-fg);
  --tblr-btn-active-bg: var(--tblr-cyan-darken);
  --tblr-btn-disabled-bg: var(--tblr-cyan);
  --tblr-btn-disabled-color: var(--tblr-cyan-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-cyan {
  --tblr-btn-color: var(--tblr-cyan);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-cyan);
  --tblr-btn-hover-color: var(--tblr-cyan-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-cyan);
  --tblr-btn-active-color: var(--tblr-cyan-fg);
  --tblr-btn-active-bg: var(--tblr-cyan);
  --tblr-btn-disabled-color: var(--tblr-cyan);
  --tblr-btn-disabled-border-color: var(--tblr-cyan);
}

.btn-x {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-x-fg);
  --tblr-btn-bg: var(--tblr-x);
  --tblr-btn-hover-color: var(--tblr-x-fg);
  --tblr-btn-hover-bg: var(--tblr-x-darken);
  --tblr-btn-active-color: var(--tblr-x-fg);
  --tblr-btn-active-bg: var(--tblr-x-darken);
  --tblr-btn-disabled-bg: var(--tblr-x);
  --tblr-btn-disabled-color: var(--tblr-x-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-x {
  --tblr-btn-color: var(--tblr-x);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-x);
  --tblr-btn-hover-color: var(--tblr-x-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-x);
  --tblr-btn-active-color: var(--tblr-x-fg);
  --tblr-btn-active-bg: var(--tblr-x);
  --tblr-btn-disabled-color: var(--tblr-x);
  --tblr-btn-disabled-border-color: var(--tblr-x);
}

.btn-facebook {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-facebook-fg);
  --tblr-btn-bg: var(--tblr-facebook);
  --tblr-btn-hover-color: var(--tblr-facebook-fg);
  --tblr-btn-hover-bg: var(--tblr-facebook-darken);
  --tblr-btn-active-color: var(--tblr-facebook-fg);
  --tblr-btn-active-bg: var(--tblr-facebook-darken);
  --tblr-btn-disabled-bg: var(--tblr-facebook);
  --tblr-btn-disabled-color: var(--tblr-facebook-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-facebook {
  --tblr-btn-color: var(--tblr-facebook);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-facebook);
  --tblr-btn-hover-color: var(--tblr-facebook-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-facebook);
  --tblr-btn-active-color: var(--tblr-facebook-fg);
  --tblr-btn-active-bg: var(--tblr-facebook);
  --tblr-btn-disabled-color: var(--tblr-facebook);
  --tblr-btn-disabled-border-color: var(--tblr-facebook);
}

.btn-twitter {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-twitter-fg);
  --tblr-btn-bg: var(--tblr-twitter);
  --tblr-btn-hover-color: var(--tblr-twitter-fg);
  --tblr-btn-hover-bg: var(--tblr-twitter-darken);
  --tblr-btn-active-color: var(--tblr-twitter-fg);
  --tblr-btn-active-bg: var(--tblr-twitter-darken);
  --tblr-btn-disabled-bg: var(--tblr-twitter);
  --tblr-btn-disabled-color: var(--tblr-twitter-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-twitter {
  --tblr-btn-color: var(--tblr-twitter);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-twitter);
  --tblr-btn-hover-color: var(--tblr-twitter-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-twitter);
  --tblr-btn-active-color: var(--tblr-twitter-fg);
  --tblr-btn-active-bg: var(--tblr-twitter);
  --tblr-btn-disabled-color: var(--tblr-twitter);
  --tblr-btn-disabled-border-color: var(--tblr-twitter);
}

.btn-linkedin {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-linkedin-fg);
  --tblr-btn-bg: var(--tblr-linkedin);
  --tblr-btn-hover-color: var(--tblr-linkedin-fg);
  --tblr-btn-hover-bg: var(--tblr-linkedin-darken);
  --tblr-btn-active-color: var(--tblr-linkedin-fg);
  --tblr-btn-active-bg: var(--tblr-linkedin-darken);
  --tblr-btn-disabled-bg: var(--tblr-linkedin);
  --tblr-btn-disabled-color: var(--tblr-linkedin-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-linkedin {
  --tblr-btn-color: var(--tblr-linkedin);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-linkedin);
  --tblr-btn-hover-color: var(--tblr-linkedin-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-linkedin);
  --tblr-btn-active-color: var(--tblr-linkedin-fg);
  --tblr-btn-active-bg: var(--tblr-linkedin);
  --tblr-btn-disabled-color: var(--tblr-linkedin);
  --tblr-btn-disabled-border-color: var(--tblr-linkedin);
}

.btn-google {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-google-fg);
  --tblr-btn-bg: var(--tblr-google);
  --tblr-btn-hover-color: var(--tblr-google-fg);
  --tblr-btn-hover-bg: var(--tblr-google-darken);
  --tblr-btn-active-color: var(--tblr-google-fg);
  --tblr-btn-active-bg: var(--tblr-google-darken);
  --tblr-btn-disabled-bg: var(--tblr-google);
  --tblr-btn-disabled-color: var(--tblr-google-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-google {
  --tblr-btn-color: var(--tblr-google);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-google);
  --tblr-btn-hover-color: var(--tblr-google-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-google);
  --tblr-btn-active-color: var(--tblr-google-fg);
  --tblr-btn-active-bg: var(--tblr-google);
  --tblr-btn-disabled-color: var(--tblr-google);
  --tblr-btn-disabled-border-color: var(--tblr-google);
}

.btn-youtube {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-youtube-fg);
  --tblr-btn-bg: var(--tblr-youtube);
  --tblr-btn-hover-color: var(--tblr-youtube-fg);
  --tblr-btn-hover-bg: var(--tblr-youtube-darken);
  --tblr-btn-active-color: var(--tblr-youtube-fg);
  --tblr-btn-active-bg: var(--tblr-youtube-darken);
  --tblr-btn-disabled-bg: var(--tblr-youtube);
  --tblr-btn-disabled-color: var(--tblr-youtube-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-youtube {
  --tblr-btn-color: var(--tblr-youtube);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-youtube);
  --tblr-btn-hover-color: var(--tblr-youtube-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-youtube);
  --tblr-btn-active-color: var(--tblr-youtube-fg);
  --tblr-btn-active-bg: var(--tblr-youtube);
  --tblr-btn-disabled-color: var(--tblr-youtube);
  --tblr-btn-disabled-border-color: var(--tblr-youtube);
}

.btn-vimeo {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-vimeo-fg);
  --tblr-btn-bg: var(--tblr-vimeo);
  --tblr-btn-hover-color: var(--tblr-vimeo-fg);
  --tblr-btn-hover-bg: var(--tblr-vimeo-darken);
  --tblr-btn-active-color: var(--tblr-vimeo-fg);
  --tblr-btn-active-bg: var(--tblr-vimeo-darken);
  --tblr-btn-disabled-bg: var(--tblr-vimeo);
  --tblr-btn-disabled-color: var(--tblr-vimeo-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-vimeo {
  --tblr-btn-color: var(--tblr-vimeo);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-vimeo);
  --tblr-btn-hover-color: var(--tblr-vimeo-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-vimeo);
  --tblr-btn-active-color: var(--tblr-vimeo-fg);
  --tblr-btn-active-bg: var(--tblr-vimeo);
  --tblr-btn-disabled-color: var(--tblr-vimeo);
  --tblr-btn-disabled-border-color: var(--tblr-vimeo);
}

.btn-dribbble {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-dribbble-fg);
  --tblr-btn-bg: var(--tblr-dribbble);
  --tblr-btn-hover-color: var(--tblr-dribbble-fg);
  --tblr-btn-hover-bg: var(--tblr-dribbble-darken);
  --tblr-btn-active-color: var(--tblr-dribbble-fg);
  --tblr-btn-active-bg: var(--tblr-dribbble-darken);
  --tblr-btn-disabled-bg: var(--tblr-dribbble);
  --tblr-btn-disabled-color: var(--tblr-dribbble-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-dribbble {
  --tblr-btn-color: var(--tblr-dribbble);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-dribbble);
  --tblr-btn-hover-color: var(--tblr-dribbble-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-dribbble);
  --tblr-btn-active-color: var(--tblr-dribbble-fg);
  --tblr-btn-active-bg: var(--tblr-dribbble);
  --tblr-btn-disabled-color: var(--tblr-dribbble);
  --tblr-btn-disabled-border-color: var(--tblr-dribbble);
}

.btn-github {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-github-fg);
  --tblr-btn-bg: var(--tblr-github);
  --tblr-btn-hover-color: var(--tblr-github-fg);
  --tblr-btn-hover-bg: var(--tblr-github-darken);
  --tblr-btn-active-color: var(--tblr-github-fg);
  --tblr-btn-active-bg: var(--tblr-github-darken);
  --tblr-btn-disabled-bg: var(--tblr-github);
  --tblr-btn-disabled-color: var(--tblr-github-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-github {
  --tblr-btn-color: var(--tblr-github);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-github);
  --tblr-btn-hover-color: var(--tblr-github-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-github);
  --tblr-btn-active-color: var(--tblr-github-fg);
  --tblr-btn-active-bg: var(--tblr-github);
  --tblr-btn-disabled-color: var(--tblr-github);
  --tblr-btn-disabled-border-color: var(--tblr-github);
}

.btn-instagram {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-instagram-fg);
  --tblr-btn-bg: var(--tblr-instagram);
  --tblr-btn-hover-color: var(--tblr-instagram-fg);
  --tblr-btn-hover-bg: var(--tblr-instagram-darken);
  --tblr-btn-active-color: var(--tblr-instagram-fg);
  --tblr-btn-active-bg: var(--tblr-instagram-darken);
  --tblr-btn-disabled-bg: var(--tblr-instagram);
  --tblr-btn-disabled-color: var(--tblr-instagram-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-instagram {
  --tblr-btn-color: var(--tblr-instagram);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-instagram);
  --tblr-btn-hover-color: var(--tblr-instagram-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-instagram);
  --tblr-btn-active-color: var(--tblr-instagram-fg);
  --tblr-btn-active-bg: var(--tblr-instagram);
  --tblr-btn-disabled-color: var(--tblr-instagram);
  --tblr-btn-disabled-border-color: var(--tblr-instagram);
}

.btn-pinterest {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-pinterest-fg);
  --tblr-btn-bg: var(--tblr-pinterest);
  --tblr-btn-hover-color: var(--tblr-pinterest-fg);
  --tblr-btn-hover-bg: var(--tblr-pinterest-darken);
  --tblr-btn-active-color: var(--tblr-pinterest-fg);
  --tblr-btn-active-bg: var(--tblr-pinterest-darken);
  --tblr-btn-disabled-bg: var(--tblr-pinterest);
  --tblr-btn-disabled-color: var(--tblr-pinterest-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-pinterest {
  --tblr-btn-color: var(--tblr-pinterest);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-pinterest);
  --tblr-btn-hover-color: var(--tblr-pinterest-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-pinterest);
  --tblr-btn-active-color: var(--tblr-pinterest-fg);
  --tblr-btn-active-bg: var(--tblr-pinterest);
  --tblr-btn-disabled-color: var(--tblr-pinterest);
  --tblr-btn-disabled-border-color: var(--tblr-pinterest);
}

.btn-vk {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-vk-fg);
  --tblr-btn-bg: var(--tblr-vk);
  --tblr-btn-hover-color: var(--tblr-vk-fg);
  --tblr-btn-hover-bg: var(--tblr-vk-darken);
  --tblr-btn-active-color: var(--tblr-vk-fg);
  --tblr-btn-active-bg: var(--tblr-vk-darken);
  --tblr-btn-disabled-bg: var(--tblr-vk);
  --tblr-btn-disabled-color: var(--tblr-vk-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-vk {
  --tblr-btn-color: var(--tblr-vk);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-vk);
  --tblr-btn-hover-color: var(--tblr-vk-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-vk);
  --tblr-btn-active-color: var(--tblr-vk-fg);
  --tblr-btn-active-bg: var(--tblr-vk);
  --tblr-btn-disabled-color: var(--tblr-vk);
  --tblr-btn-disabled-border-color: var(--tblr-vk);
}

.btn-rss {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-rss-fg);
  --tblr-btn-bg: var(--tblr-rss);
  --tblr-btn-hover-color: var(--tblr-rss-fg);
  --tblr-btn-hover-bg: var(--tblr-rss-darken);
  --tblr-btn-active-color: var(--tblr-rss-fg);
  --tblr-btn-active-bg: var(--tblr-rss-darken);
  --tblr-btn-disabled-bg: var(--tblr-rss);
  --tblr-btn-disabled-color: var(--tblr-rss-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-rss {
  --tblr-btn-color: var(--tblr-rss);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-rss);
  --tblr-btn-hover-color: var(--tblr-rss-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-rss);
  --tblr-btn-active-color: var(--tblr-rss-fg);
  --tblr-btn-active-bg: var(--tblr-rss);
  --tblr-btn-disabled-color: var(--tblr-rss);
  --tblr-btn-disabled-border-color: var(--tblr-rss);
}

.btn-flickr {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-flickr-fg);
  --tblr-btn-bg: var(--tblr-flickr);
  --tblr-btn-hover-color: var(--tblr-flickr-fg);
  --tblr-btn-hover-bg: var(--tblr-flickr-darken);
  --tblr-btn-active-color: var(--tblr-flickr-fg);
  --tblr-btn-active-bg: var(--tblr-flickr-darken);
  --tblr-btn-disabled-bg: var(--tblr-flickr);
  --tblr-btn-disabled-color: var(--tblr-flickr-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-flickr {
  --tblr-btn-color: var(--tblr-flickr);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-flickr);
  --tblr-btn-hover-color: var(--tblr-flickr-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-flickr);
  --tblr-btn-active-color: var(--tblr-flickr-fg);
  --tblr-btn-active-bg: var(--tblr-flickr);
  --tblr-btn-disabled-color: var(--tblr-flickr);
  --tblr-btn-disabled-border-color: var(--tblr-flickr);
}

.btn-bitbucket {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-bitbucket-fg);
  --tblr-btn-bg: var(--tblr-bitbucket);
  --tblr-btn-hover-color: var(--tblr-bitbucket-fg);
  --tblr-btn-hover-bg: var(--tblr-bitbucket-darken);
  --tblr-btn-active-color: var(--tblr-bitbucket-fg);
  --tblr-btn-active-bg: var(--tblr-bitbucket-darken);
  --tblr-btn-disabled-bg: var(--tblr-bitbucket);
  --tblr-btn-disabled-color: var(--tblr-bitbucket-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-bitbucket {
  --tblr-btn-color: var(--tblr-bitbucket);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-bitbucket);
  --tblr-btn-hover-color: var(--tblr-bitbucket-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-bitbucket);
  --tblr-btn-active-color: var(--tblr-bitbucket-fg);
  --tblr-btn-active-bg: var(--tblr-bitbucket);
  --tblr-btn-disabled-color: var(--tblr-bitbucket);
  --tblr-btn-disabled-border-color: var(--tblr-bitbucket);
}

.btn-tabler {
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-color: var(--tblr-tabler-fg);
  --tblr-btn-bg: var(--tblr-tabler);
  --tblr-btn-hover-color: var(--tblr-tabler-fg);
  --tblr-btn-hover-bg: var(--tblr-tabler-darken);
  --tblr-btn-active-color: var(--tblr-tabler-fg);
  --tblr-btn-active-bg: var(--tblr-tabler-darken);
  --tblr-btn-disabled-bg: var(--tblr-tabler);
  --tblr-btn-disabled-color: var(--tblr-tabler-fg);
  --tblr-btn-box-shadow: var(--tblr-box-shadow-input);
}

.btn-outline-tabler {
  --tblr-btn-color: var(--tblr-tabler);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: var(--tblr-tabler);
  --tblr-btn-hover-color: var(--tblr-tabler-fg);
  --tblr-btn-hover-border-color: transparent;
  --tblr-btn-hover-bg: var(--tblr-tabler);
  --tblr-btn-active-color: var(--tblr-tabler-fg);
  --tblr-btn-active-bg: var(--tblr-tabler);
  --tblr-btn-disabled-color: var(--tblr-tabler);
  --tblr-btn-disabled-border-color: var(--tblr-tabler);
}

.btn-ghost-primary {
  --tblr-btn-color: var(--tblr-primary);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-primary-fg);
  --tblr-btn-hover-bg: var(--tblr-primary);
  --tblr-btn-hover-border-color: var(--tblr-primary);
  --tblr-btn-active-color: var(--tblr-primary-fg);
  --tblr-btn-active-bg: var(--tblr-primary);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-primary);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-secondary {
  --tblr-btn-color: var(--tblr-secondary);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-secondary-fg);
  --tblr-btn-hover-bg: var(--tblr-secondary);
  --tblr-btn-hover-border-color: var(--tblr-secondary);
  --tblr-btn-active-color: var(--tblr-secondary-fg);
  --tblr-btn-active-bg: var(--tblr-secondary);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-secondary);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-success {
  --tblr-btn-color: var(--tblr-success);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-success-fg);
  --tblr-btn-hover-bg: var(--tblr-success);
  --tblr-btn-hover-border-color: var(--tblr-success);
  --tblr-btn-active-color: var(--tblr-success-fg);
  --tblr-btn-active-bg: var(--tblr-success);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-success);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-info {
  --tblr-btn-color: var(--tblr-info);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-info-fg);
  --tblr-btn-hover-bg: var(--tblr-info);
  --tblr-btn-hover-border-color: var(--tblr-info);
  --tblr-btn-active-color: var(--tblr-info-fg);
  --tblr-btn-active-bg: var(--tblr-info);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-info);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-warning {
  --tblr-btn-color: var(--tblr-warning);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-warning-fg);
  --tblr-btn-hover-bg: var(--tblr-warning);
  --tblr-btn-hover-border-color: var(--tblr-warning);
  --tblr-btn-active-color: var(--tblr-warning-fg);
  --tblr-btn-active-bg: var(--tblr-warning);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-warning);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-danger {
  --tblr-btn-color: var(--tblr-danger);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-danger-fg);
  --tblr-btn-hover-bg: var(--tblr-danger);
  --tblr-btn-hover-border-color: var(--tblr-danger);
  --tblr-btn-active-color: var(--tblr-danger-fg);
  --tblr-btn-active-bg: var(--tblr-danger);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-danger);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-light {
  --tblr-btn-color: var(--tblr-light);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-light-fg);
  --tblr-btn-hover-bg: var(--tblr-light);
  --tblr-btn-hover-border-color: var(--tblr-light);
  --tblr-btn-active-color: var(--tblr-light-fg);
  --tblr-btn-active-bg: var(--tblr-light);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-light);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-dark {
  --tblr-btn-color: var(--tblr-dark);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-dark-fg);
  --tblr-btn-hover-bg: var(--tblr-dark);
  --tblr-btn-hover-border-color: var(--tblr-dark);
  --tblr-btn-active-color: var(--tblr-dark-fg);
  --tblr-btn-active-bg: var(--tblr-dark);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-dark);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-muted {
  --tblr-btn-color: var(--tblr-muted);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-muted-fg);
  --tblr-btn-hover-bg: var(--tblr-muted);
  --tblr-btn-hover-border-color: var(--tblr-muted);
  --tblr-btn-active-color: var(--tblr-muted-fg);
  --tblr-btn-active-bg: var(--tblr-muted);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-muted);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-blue {
  --tblr-btn-color: var(--tblr-blue);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-blue-fg);
  --tblr-btn-hover-bg: var(--tblr-blue);
  --tblr-btn-hover-border-color: var(--tblr-blue);
  --tblr-btn-active-color: var(--tblr-blue-fg);
  --tblr-btn-active-bg: var(--tblr-blue);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-blue);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-azure {
  --tblr-btn-color: var(--tblr-azure);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-azure-fg);
  --tblr-btn-hover-bg: var(--tblr-azure);
  --tblr-btn-hover-border-color: var(--tblr-azure);
  --tblr-btn-active-color: var(--tblr-azure-fg);
  --tblr-btn-active-bg: var(--tblr-azure);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-azure);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-indigo {
  --tblr-btn-color: var(--tblr-indigo);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-indigo-fg);
  --tblr-btn-hover-bg: var(--tblr-indigo);
  --tblr-btn-hover-border-color: var(--tblr-indigo);
  --tblr-btn-active-color: var(--tblr-indigo-fg);
  --tblr-btn-active-bg: var(--tblr-indigo);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-indigo);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-purple {
  --tblr-btn-color: var(--tblr-purple);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-purple-fg);
  --tblr-btn-hover-bg: var(--tblr-purple);
  --tblr-btn-hover-border-color: var(--tblr-purple);
  --tblr-btn-active-color: var(--tblr-purple-fg);
  --tblr-btn-active-bg: var(--tblr-purple);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-purple);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-pink {
  --tblr-btn-color: var(--tblr-pink);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-pink-fg);
  --tblr-btn-hover-bg: var(--tblr-pink);
  --tblr-btn-hover-border-color: var(--tblr-pink);
  --tblr-btn-active-color: var(--tblr-pink-fg);
  --tblr-btn-active-bg: var(--tblr-pink);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-pink);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-red {
  --tblr-btn-color: var(--tblr-red);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-red-fg);
  --tblr-btn-hover-bg: var(--tblr-red);
  --tblr-btn-hover-border-color: var(--tblr-red);
  --tblr-btn-active-color: var(--tblr-red-fg);
  --tblr-btn-active-bg: var(--tblr-red);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-red);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-orange {
  --tblr-btn-color: var(--tblr-orange);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-orange-fg);
  --tblr-btn-hover-bg: var(--tblr-orange);
  --tblr-btn-hover-border-color: var(--tblr-orange);
  --tblr-btn-active-color: var(--tblr-orange-fg);
  --tblr-btn-active-bg: var(--tblr-orange);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-orange);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-yellow {
  --tblr-btn-color: var(--tblr-yellow);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-yellow-fg);
  --tblr-btn-hover-bg: var(--tblr-yellow);
  --tblr-btn-hover-border-color: var(--tblr-yellow);
  --tblr-btn-active-color: var(--tblr-yellow-fg);
  --tblr-btn-active-bg: var(--tblr-yellow);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-yellow);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-lime {
  --tblr-btn-color: var(--tblr-lime);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-lime-fg);
  --tblr-btn-hover-bg: var(--tblr-lime);
  --tblr-btn-hover-border-color: var(--tblr-lime);
  --tblr-btn-active-color: var(--tblr-lime-fg);
  --tblr-btn-active-bg: var(--tblr-lime);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-lime);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-green {
  --tblr-btn-color: var(--tblr-green);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-green-fg);
  --tblr-btn-hover-bg: var(--tblr-green);
  --tblr-btn-hover-border-color: var(--tblr-green);
  --tblr-btn-active-color: var(--tblr-green-fg);
  --tblr-btn-active-bg: var(--tblr-green);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-green);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-teal {
  --tblr-btn-color: var(--tblr-teal);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-teal-fg);
  --tblr-btn-hover-bg: var(--tblr-teal);
  --tblr-btn-hover-border-color: var(--tblr-teal);
  --tblr-btn-active-color: var(--tblr-teal-fg);
  --tblr-btn-active-bg: var(--tblr-teal);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-teal);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-cyan {
  --tblr-btn-color: var(--tblr-cyan);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-cyan-fg);
  --tblr-btn-hover-bg: var(--tblr-cyan);
  --tblr-btn-hover-border-color: var(--tblr-cyan);
  --tblr-btn-active-color: var(--tblr-cyan-fg);
  --tblr-btn-active-bg: var(--tblr-cyan);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-cyan);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-x {
  --tblr-btn-color: var(--tblr-x);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-x-fg);
  --tblr-btn-hover-bg: var(--tblr-x);
  --tblr-btn-hover-border-color: var(--tblr-x);
  --tblr-btn-active-color: var(--tblr-x-fg);
  --tblr-btn-active-bg: var(--tblr-x);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-x);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-facebook {
  --tblr-btn-color: var(--tblr-facebook);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-facebook-fg);
  --tblr-btn-hover-bg: var(--tblr-facebook);
  --tblr-btn-hover-border-color: var(--tblr-facebook);
  --tblr-btn-active-color: var(--tblr-facebook-fg);
  --tblr-btn-active-bg: var(--tblr-facebook);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-facebook);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-twitter {
  --tblr-btn-color: var(--tblr-twitter);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-twitter-fg);
  --tblr-btn-hover-bg: var(--tblr-twitter);
  --tblr-btn-hover-border-color: var(--tblr-twitter);
  --tblr-btn-active-color: var(--tblr-twitter-fg);
  --tblr-btn-active-bg: var(--tblr-twitter);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-twitter);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-linkedin {
  --tblr-btn-color: var(--tblr-linkedin);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-linkedin-fg);
  --tblr-btn-hover-bg: var(--tblr-linkedin);
  --tblr-btn-hover-border-color: var(--tblr-linkedin);
  --tblr-btn-active-color: var(--tblr-linkedin-fg);
  --tblr-btn-active-bg: var(--tblr-linkedin);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-linkedin);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-google {
  --tblr-btn-color: var(--tblr-google);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-google-fg);
  --tblr-btn-hover-bg: var(--tblr-google);
  --tblr-btn-hover-border-color: var(--tblr-google);
  --tblr-btn-active-color: var(--tblr-google-fg);
  --tblr-btn-active-bg: var(--tblr-google);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-google);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-youtube {
  --tblr-btn-color: var(--tblr-youtube);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-youtube-fg);
  --tblr-btn-hover-bg: var(--tblr-youtube);
  --tblr-btn-hover-border-color: var(--tblr-youtube);
  --tblr-btn-active-color: var(--tblr-youtube-fg);
  --tblr-btn-active-bg: var(--tblr-youtube);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-youtube);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-vimeo {
  --tblr-btn-color: var(--tblr-vimeo);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-vimeo-fg);
  --tblr-btn-hover-bg: var(--tblr-vimeo);
  --tblr-btn-hover-border-color: var(--tblr-vimeo);
  --tblr-btn-active-color: var(--tblr-vimeo-fg);
  --tblr-btn-active-bg: var(--tblr-vimeo);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-vimeo);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-dribbble {
  --tblr-btn-color: var(--tblr-dribbble);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-dribbble-fg);
  --tblr-btn-hover-bg: var(--tblr-dribbble);
  --tblr-btn-hover-border-color: var(--tblr-dribbble);
  --tblr-btn-active-color: var(--tblr-dribbble-fg);
  --tblr-btn-active-bg: var(--tblr-dribbble);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-dribbble);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-github {
  --tblr-btn-color: var(--tblr-github);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-github-fg);
  --tblr-btn-hover-bg: var(--tblr-github);
  --tblr-btn-hover-border-color: var(--tblr-github);
  --tblr-btn-active-color: var(--tblr-github-fg);
  --tblr-btn-active-bg: var(--tblr-github);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-github);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-instagram {
  --tblr-btn-color: var(--tblr-instagram);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-instagram-fg);
  --tblr-btn-hover-bg: var(--tblr-instagram);
  --tblr-btn-hover-border-color: var(--tblr-instagram);
  --tblr-btn-active-color: var(--tblr-instagram-fg);
  --tblr-btn-active-bg: var(--tblr-instagram);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-instagram);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-pinterest {
  --tblr-btn-color: var(--tblr-pinterest);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-pinterest-fg);
  --tblr-btn-hover-bg: var(--tblr-pinterest);
  --tblr-btn-hover-border-color: var(--tblr-pinterest);
  --tblr-btn-active-color: var(--tblr-pinterest-fg);
  --tblr-btn-active-bg: var(--tblr-pinterest);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-pinterest);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-vk {
  --tblr-btn-color: var(--tblr-vk);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-vk-fg);
  --tblr-btn-hover-bg: var(--tblr-vk);
  --tblr-btn-hover-border-color: var(--tblr-vk);
  --tblr-btn-active-color: var(--tblr-vk-fg);
  --tblr-btn-active-bg: var(--tblr-vk);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-vk);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-rss {
  --tblr-btn-color: var(--tblr-rss);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-rss-fg);
  --tblr-btn-hover-bg: var(--tblr-rss);
  --tblr-btn-hover-border-color: var(--tblr-rss);
  --tblr-btn-active-color: var(--tblr-rss-fg);
  --tblr-btn-active-bg: var(--tblr-rss);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-rss);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-flickr {
  --tblr-btn-color: var(--tblr-flickr);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-flickr-fg);
  --tblr-btn-hover-bg: var(--tblr-flickr);
  --tblr-btn-hover-border-color: var(--tblr-flickr);
  --tblr-btn-active-color: var(--tblr-flickr-fg);
  --tblr-btn-active-bg: var(--tblr-flickr);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-flickr);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-bitbucket {
  --tblr-btn-color: var(--tblr-bitbucket);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-bitbucket-fg);
  --tblr-btn-hover-bg: var(--tblr-bitbucket);
  --tblr-btn-hover-border-color: var(--tblr-bitbucket);
  --tblr-btn-active-color: var(--tblr-bitbucket-fg);
  --tblr-btn-active-bg: var(--tblr-bitbucket);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-bitbucket);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-ghost-tabler {
  --tblr-btn-color: var(--tblr-tabler);
  --tblr-btn-bg: transparent;
  --tblr-btn-border-color: transparent;
  --tblr-btn-hover-color: var(--tblr-tabler-fg);
  --tblr-btn-hover-bg: var(--tblr-tabler);
  --tblr-btn-hover-border-color: var(--tblr-tabler);
  --tblr-btn-active-color: var(--tblr-tabler-fg);
  --tblr-btn-active-bg: var(--tblr-tabler);
  --tblr-btn-active-border-color: transparent;
  --tblr-btn-active-shadow: inset 0 3px 5px #00000020;
  --tblr-btn-disabled-color: var(--tblr-tabler);
  --tblr-btn-disabled-bg: transparent;
  --tblr-btn-disabled-border-color: transparent;
  --tblr-gradient: none;
  --tblr-btn-box-shadow: none;
}

.btn-sm, .btn-group-sm > .btn {
  --tblr-btn-line-height: 1rem;
  --tblr-btn-icon-size: 1rem;
}

.btn-lg, .btn-group-lg > .btn {
  --tblr-btn-line-height: 2rem;
  --tblr-btn-icon-size: 2rem;
}

.btn-pill {
  border-radius: 10rem;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.btn-pill[class*="btn-icon"] {
  padding: .375rem 15px;
}

.btn-square {
  border-radius: 0;
}

.btn-icon {
  min-width: calc(var(--tblr-btn-line-height) * var(--tblr-btn-font-size)  + var(--tblr-btn-padding-y) * 2 + var(--tblr-btn-border-width) * 2);
  min-height: calc(var(--tblr-btn-line-height) * var(--tblr-btn-font-size)  + var(--tblr-btn-padding-y) * 2 + var(--tblr-btn-border-width) * 2);
  padding-left: 0;
  padding-right: 0;
}

.btn-icon .icon {
  margin: calc(-1 * var(--tblr-btn-padding-x));
}

.btn-list {
  --tblr-list-gap: .5rem;
  gap: var(--tblr-list-gap);
  flex-wrap: wrap;
  display: flex;
}

.btn-floating {
  z-index: 1030;
  border-radius: 100rem;
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
}

.btn-loading {
  pointer-events: none;
  position: relative;
  color: #0000 !important;
  text-shadow: none !important;
}

.btn-loading > * {
  opacity: 0;
}

.btn-loading:after {
  content: "";
  vertical-align: text-bottom;
  border: 2px var(--tblr-border-style) currentColor;
  color: var(--tblr-btn-color);
  width: var(--tblr-btn-icon-size);
  height: var(--tblr-btn-icon-size);
  left: calc(50% - var(--tblr-btn-icon-size) / 2);
  top: calc(50% - var(--tblr-btn-icon-size) / 2);
  border-right-color: #0000;
  border-radius: 100rem;
  animation: .75s linear infinite spinner-border;
  display: inline-block;
  position: absolute;
}

.btn-action {
  color: var(--tblr-secondary);
  border-radius: var(--tblr-border-radius);
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  padding: 0;
  display: inline-flex;
}

.btn-action:after {
  content: none;
}

.btn-action:focus {
  box-shadow: none;
  outline: none;
}

.btn-action:hover, .btn-action.show {
  color: var(--tblr-body-color);
  background: var(--tblr-active-bg);
}

.btn-action.show {
  color: var(--tblr-primary);
}

.btn-action .icon {
  stroke-width: 1px;
  width: 1.25rem;
  height: 1.25rem;
  margin: 0;
  font-size: 1.25rem;
}

.btn-actions {
  display: flex;
}

.btn-group, .btn-group-vertical {
  box-shadow: var(--tblr-box-shadow-input);
}

.btn-group > .btn-check:checked + .btn, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn-check:checked + .btn, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active {
  z-index: 5;
}

.btn-group > .btn-check:focus + .btn, .btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group-vertical > .btn-check:focus + .btn, .btn-group-vertical > .btn:hover, .btn-group-vertical > .btn:focus {
  z-index: 1;
}

.calendar {
  border: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color);
  border-radius: var(--tblr-border-radius);
  font-size: .765625rem;
  display: block;
}

.calendar-nav {
  align-items: center;
  display: flex;
}

.calendar-title {
  text-align: center;
  flex: 1;
}

.calendar-body, .calendar-header {
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: .5rem 0;
  display: flex;
}

.calendar-header {
  color: var(--tblr-secondary);
}

.calendar-date {
  text-align: center;
  border: 0;
  flex: 0 0 14.2857%;
  max-width: 14.2857%;
  padding: .2rem;
}

.calendar-date.prev-month, .calendar-date.next-month {
  opacity: .25;
}

.calendar-date .date-item {
  color: #66758c;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border: var(--tblr-border-width) var(--tblr-border-style) transparent;
  background: none;
  border-radius: 100rem;
  outline: 0;
  width: 1.4rem;
  height: 1.4rem;
  line-height: 1.4rem;
  text-decoration: none;
  transition: background .3s, border .3s, box-shadow .32s, color .3s;
  display: inline-block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .calendar-date .date-item {
    transition: none;
  }
}

.calendar-date .date-item:hover {
  color: var(--tblr-primary);
  border-color: var(--tblr-border-color);
  background: #fefeff;
  text-decoration: none;
}

.calendar-date .date-today {
  color: var(--tblr-primary);
  border-color: var(--tblr-border-color);
}

.calendar-range {
  position: relative;
}

.calendar-range:before {
  content: "";
  background: rgba(var(--tblr-primary-rgb), .1);
  height: 1.4rem;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.calendar-range.range-start .date-item, .calendar-range.range-end .date-item {
  color: #fff;
  background: var(--tblr-primary);
  border-color: var(--tblr-primary);
}

.calendar-range.range-start:before {
  left: 50%;
}

.calendar-range.range-end:before {
  right: 50%;
}

.carousel-indicators-vertical {
  flex-direction: column;
  margin: 0 1rem 0 0;
  top: 0;
  left: auto;
}

.carousel-indicators-vertical [data-bs-target] {
  border: 0;
  border-left: 10px var(--tblr-border-style) transparent;
  border-right: 10px var(--tblr-border-style) transparent;
  width: 3px;
  height: 30px;
  margin: 3px 0;
}

.carousel-indicators-dot [data-bs-target] {
  border: 10px var(--tblr-border-style) transparent;
  border-radius: 100rem;
  width: .5rem;
  height: .5rem;
  margin: 0;
}

.carousel-indicators-thumb [data-bs-target] {
  border-radius: var(--tblr-border-radius);
  width: 2rem;
  height: auto;
  box-shadow: rgba(var(--tblr-body-color-rgb), .04) 0 2px 4px 0;
  opacity: .75;
  background: center / cover no-repeat;
  border: 0;
  margin: 0 3px;
}

@media (width >= 992px) {
  .carousel-indicators-thumb [data-bs-target] {
    width: 4rem;
  }
}

.carousel-indicators-thumb [data-bs-target]:before {
  content: "";
  padding-top: var(--tblr-aspect-ratio, 100%);
  display: block;
}

.carousel-indicators-thumb.carousel-indicators-vertical [data-bs-target] {
  margin: 3px 0;
}

.carousel-caption-background {
  background: linear-gradient(0deg, #182433e6, #18243300);
  height: 90%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.card {
  transition: transform .3s ease-out, opacity .3s ease-out, box-shadow .3s ease-out;
}

@media (prefers-reduced-motion: reduce) {
  .card {
    transition: none;
  }
}

@media print {
  .card {
    box-shadow: none;
    border: none;
  }
}

a.card {
  color: inherit;
}

a.card:hover {
  box-shadow: rgba(var(--tblr-body-color-rgb), .16) 0 2px 16px 0;
  text-decoration: none;
}

.card .card {
  box-shadow: none;
}

.card-borderless, .card-borderless .card-header, .card-borderless .card-footer {
  border-color: #0000;
}

.card-stamp {
  --tblr-stamp-size: 7rem;
  width: calc(var(--tblr-stamp-size) * 1);
  height: calc(var(--tblr-stamp-size) * 1);
  opacity: .2;
  pointer-events: none;
  border-top-right-radius: 4px;
  max-height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
}

.card-stamp-lg {
  --tblr-stamp-size: 13rem;
}

.card-stamp-icon {
  background: var(--tblr-secondary);
  color: var(--tblr-card-bg, var(--tblr-bg-surface));
  width: calc(var(--tblr-stamp-size) * 1);
  height: calc(var(--tblr-stamp-size) * 1);
  top: calc(var(--tblr-stamp-size) * -.25);
  right: calc(var(--tblr-stamp-size) * -.25);
  font-size: calc(var(--tblr-stamp-size) * .75);
  border-radius: 100rem;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  transform: rotate(10deg);
}

.card-stamp-icon .icon {
  stroke-width: 2px;
  width: calc(var(--tblr-stamp-size) * .75);
  height: calc(var(--tblr-stamp-size) * .75);
}

.card-img, .card-img-start {
  border-top-left-radius: calc(var(--tblr-border-radius)  - (var(--tblr-border-width)));
  border-bottom-left-radius: calc(var(--tblr-border-radius)  - (var(--tblr-border-width)));
}

.card-img, .card-img-end {
  border-top-right-radius: calc(var(--tblr-border-radius)  - (var(--tblr-border-width)));
  border-bottom-right-radius: calc(var(--tblr-border-radius)  - (var(--tblr-border-width)));
}

.card-img-overlay {
  flex-direction: column;
  justify-content: flex-end;
  display: flex;
}

.card-img-overlay-dark {
  background-image: linear-gradient(#0000 0%, #0009 100%);
}

.card-inactive {
  pointer-events: none;
  box-shadow: none;
}

.card-inactive .card-body {
  opacity: .64;
}

.card-active {
  --tblr-card-border-color: var(--tblr-primary);
  --tblr-card-bg: var(--tblr-active-bg);
}

.card-btn {
  text-align: center;
  border-top: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color);
  color: inherit;
  font-weight: var(--tblr-font-weight-medium);
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.25rem;
  transition: background .3s;
  display: flex;
}

@media (prefers-reduced-motion: reduce) {
  .card-btn {
    transition: none;
  }
}

.card-btn:hover {
  background: rgba(var(--tblr-primary-rgb), .04);
  text-decoration: none;
}

.card-btn + .card-btn {
  border-left: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color);
}

.card-stacked {
  --tblr-card-stacked-offset: .25rem;
  position: relative;
}

.card-stacked:after {
  top: calc(-1 * var(--tblr-card-stacked-offset));
  right: var(--tblr-card-stacked-offset);
  left: var(--tblr-card-stacked-offset);
  height: var(--tblr-card-stacked-offset);
  content: "";
  background: var(--tblr-card-bg, var(--tblr-bg-surface));
  border: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-card-border-color);
  border-radius: var(--tblr-card-border-radius) var(--tblr-card-border-radius) 0 0;
  position: absolute;
}

.card-cover {
  background: #666 center / cover no-repeat;
  padding: 1rem 1.25rem;
  position: relative;
}

.card-cover:before {
  content: "";
  background: #1824337a;
  position: absolute;
  inset: 0;
}

.card-cover:first-child, .card-cover:first-child:before {
  border-radius: 4px 4px 0 0;
}

.card-cover-blurred:before {
  backdrop-filter: blur(2px);
}

.card-actions {
  margin: -.5rem -.5rem -.5rem auto;
  padding-left: .5rem;
}

.card-actions a {
  text-decoration: none;
}

.card-header {
  color: inherit;
  background: none;
  align-items: center;
  display: flex;
}

.card-header:first-child {
  border-radius: var(--tblr-card-border-radius) var(--tblr-card-border-radius) 0 0;
}

.card-header-light {
  background: var(--tblr-bg-surface-tertiary);
  border-bottom-color: #0000;
}

.card-header-tabs {
  background: var(--tblr-bg-surface-tertiary);
  margin: calc(var(--tblr-card-cap-padding-y) * -1) calc(var(--tblr-card-cap-padding-x) * -1) calc(var(--tblr-card-cap-padding-y) * -1);
  padding: calc(var(--tblr-card-cap-padding-y) * .5) calc(var(--tblr-card-cap-padding-x) * .5) 0;
  flex: 1;
}

.card-header-pills {
  flex: 1;
  margin-top: -.5rem;
  margin-bottom: -.5rem;
}

.card-rotate-left {
  transform: rotate(-1.5deg);
}

.card-rotate-right {
  transform: rotate(1.5deg);
}

.card-link {
  color: inherit;
}

.card-link:hover {
  color: inherit;
  text-decoration: none;
  box-shadow: 0 1px 6px #00000014;
}

.card-link-rotate:hover {
  opacity: 1;
  transform: rotate(1.5deg);
}

.card-link-pop:hover {
  opacity: 1;
  transform: translateY(-2px);
}

.card-footer {
  margin-top: auto;
}

.card-footer:last-child {
  border-radius: 0 0 var(--tblr-card-border-radius) var(--tblr-card-border-radius);
}

.card-footer-transparent {
  background: none;
  border-color: #0000;
  padding-top: 0;
}

.card-footer-borderless {
  border-top: none;
}

.card-progress {
  height: .25rem;
}

.card-progress:last-child {
  border-radius: 0 0 2px 2px;
}

.card-progress:first-child {
  border-radius: 2px 2px 0 0;
}

.card-meta {
  color: var(--tblr-secondary);
}

.card-title {
  font-size: 1rem;
  font-weight: var(--tblr-font-weight-medium);
  color: inherit;
  margin: 0 0 1rem;
  line-height: 1.5rem;
  display: block;
}

a.card-title:hover {
  color: inherit;
}

.card-header .card-title {
  margin: 0;
}

.card-subtitle {
  color: var(--tblr-secondary);
  margin-bottom: 1.25rem;
  font-weight: normal;
}

.card-header .card-subtitle {
  margin: 0;
}

.card-title .card-subtitle {
  margin: 0 0 0 .25rem;
  font-size: .875rem;
}

.card-body {
  position: relative;
}

.card-body > :last-child {
  margin-bottom: 0;
}

.card-sm > .card-body {
  padding: 1rem;
}

@media (width >= 768px) {
  .card-md > .card-body {
    padding: 2.5rem;
  }

  .card-lg > .card-body {
    padding: 2rem;
  }
}

@media (width >= 992px) {
  .card-lg > .card-body {
    padding: 4rem;
  }
}

@media print {
  .card-body {
    padding: 0;
  }
}

.card-body + .card-body {
  border-top: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color);
}

.card-body-scrollable {
  overflow: auto;
}

.card-options {
  margin-left: auto;
  display: flex;
  top: 1.5rem;
  right: .75rem;
}

.card-options-link {
  min-width: 1rem;
  color: var(--tblr-secondary);
  margin-left: .25rem;
  display: inline-block;
}

.card-status-top {
  border-radius: var(--tblr-card-border-radius) var(--tblr-card-border-radius) 0 0;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.card-status-start {
  border-radius: var(--tblr-card-border-radius) 0 0 var(--tblr-card-border-radius);
  width: 2px;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: auto;
}

.card-status-bottom {
  top: initial;
  border-radius: 0 0 var(--tblr-card-border-radius) var(--tblr-card-border-radius);
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
}

.card-table {
  margin-bottom: 0 !important;
}

.card-table tr td:first-child, .card-table tr th:first-child {
  border-left: 0;
  padding-left: 1.25rem;
}

.card-table tr td:last-child, .card-table tr th:last-child {
  border-right: 0;
  padding-right: 1.25rem;
}

.card-table thead tr:first-child, .card-table tbody tr:first-child, .card-table tfoot tr:first-child, .card-table thead tr:first-child td, .card-table thead tr:first-child th, .card-table tbody tr:first-child td, .card-table tbody tr:first-child th, .card-table tfoot tr:first-child td, .card-table tfoot tr:first-child th {
  border-top: 0;
}

.card-body + .card-table {
  border-top: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-table-border-color);
}

.card-code {
  padding: 0;
}

.card-code .highlight {
  border: 0;
  margin: 0;
}

.card-code pre {
  border: 0 !important;
  margin: 0 !important;
}

.card-chart {
  z-index: 1;
  height: 3.5rem;
  position: relative;
}

.card-avatar {
  box-shadow: 0 0 0 .25rem var(--tblr-card-bg, var(--tblr-bg-surface));
  margin-left: auto;
  margin-right: auto;
  margin-top: calc(-1 * var(--tblr-avatar-size) * .5);
}

.card-body + .card-list-group {
  border-top: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color);
}

.card-list-group .list-group-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.card-list-group .list-group-item:last-child {
  border-bottom: 0;
}

.card-list-group .list-group-item:first-child {
  border-top: 0;
}

.card-tabs .nav-tabs {
  z-index: 1000;
  border-bottom: 0;
  position: relative;
}

.card-tabs .nav-tabs .nav-link {
  background: var(--tblr-bg-surface-tertiary);
  border: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent);
}

.card-tabs .nav-tabs .nav-link.active, .card-tabs .nav-tabs .nav-link:active, .card-tabs .nav-tabs .nav-link:hover {
  border-color: var(--tblr-border-color-translucent);
  color: var(--tblr-body-color);
}

.card-tabs .nav-tabs .nav-link.active {
  color: inherit;
  background: var(--tblr-card-bg, var(--tblr-bg-surface));
  border-bottom-color: #0000;
}

.card-tabs .nav-tabs .nav-item:not(:first-child) .nav-link {
  border-top-left-radius: 0;
}

.card-tabs .nav-tabs .nav-item:not(:last-child) .nav-link {
  border-top-right-radius: 0;
}

.card-tabs .nav-tabs .nav-item + .nav-item {
  margin-left: calc(-1 * var(--tblr-border-width));
}

.card-tabs .nav-tabs-bottom, .card-tabs .nav-tabs-bottom .nav-link {
  margin-bottom: 0;
}

.card-tabs .nav-tabs-bottom .nav-link.active {
  border-top-color: #0000;
}

.card-tabs .nav-tabs-bottom .nav-item {
  margin-top: calc(-1 * var(--tblr-border-width));
  margin-bottom: 0;
}

.card-tabs .nav-tabs-bottom .nav-item .nav-link {
  border-bottom: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent);
  border-radius: 0 0 var(--tblr-border-radius) var(--tblr-border-radius);
}

.card-tabs .nav-tabs-bottom .nav-item:not(:first-child) .nav-link {
  border-bottom-left-radius: 0;
}

.card-tabs .nav-tabs-bottom .nav-item:not(:last-child) .nav-link {
  border-bottom-right-radius: 0;
}

.card-tabs .card {
  border-bottom-left-radius: 0;
}

.card-tabs .nav-tabs + .tab-content .card {
  border-bottom-left-radius: var(--tblr-card-border-radius);
  border-top-left-radius: 0;
}

.card-note {
  --tblr-card-bg: #fff7dd;
  --tblr-card-border-color: #fff1c9;
}

.btn-close {
  cursor: pointer;
}

.btn-close:focus {
  outline: none;
}

.dropdown-menu {
  -webkit-user-select: none;
  user-select: none;
  background-clip: border-box;
}

.dropdown-menu.card {
  min-width: 25rem;
  padding: 0;
  display: none;
}

.dropdown-menu.card.show {
  display: flex;
}

.dropdown-item {
  align-items: center;
  min-width: 11rem;
  margin: 0;
  line-height: 1.42857;
  display: flex;
}

.dropdown-item-icon {
  color: var(--tblr-secondary);
  opacity: .7;
  text-align: center;
  margin-right: .5rem;
  width: 1.25rem !important;
  height: 1.25rem !important;
}

.dropdown-item-indicator {
  vertical-align: bottom;
  align-items: center;
  height: 1.25rem;
  margin-left: -.25rem;
  margin-right: .5rem;
  line-height: 1;
  display: inline-flex;
}

.dropdown-header {
  font-size: .75rem;
  font-weight: var(--tblr-font-weight-bold);
  text-transform: uppercase;
  letter-spacing: .04em;
  color: var(--tblr-secondary);
  pointer-events: none;
  padding-bottom: .25rem;
  line-height: 1rem;
}

.dropdown-menu-scrollable {
  height: auto;
  max-height: 13rem;
  overflow-x: hidden;
}

.dropdown-menu-column {
  min-width: 11rem;
}

.dropdown-menu-column .dropdown-item {
  min-width: 0;
}

.dropdown-menu-columns {
  flex: 0 .25rem;
  display: flex;
}

.dropdown-menu-arrow:before {
  content: "";
  background: inherit;
  transform-origin: center;
  border: 1px solid;
  border-color: inherit;
  z-index: -1;
  clip: rect(0px, 9px, 9px, 0px);
  width: 14px;
  height: 14px;
  display: block;
  position: absolute;
  top: -.25rem;
  left: .75rem;
  transform: rotate(45deg);
}

.dropdown-menu-arrow.dropdown-menu-end:before {
  left: auto;
  right: .75rem;
}

.dropend > .dropdown-menu {
  margin-top: calc(-.25rem - 1px);
  margin-left: -.25rem;
}

.dropend .dropdown-toggle:after {
  margin-left: auto;
}

.dropdown-menu-card {
  padding: 0;
}

.dropdown-menu-card > .card {
  box-shadow: none;
  border: 0;
  margin: 0;
}

.datagrid {
  --tblr-datagrid-padding: 1.5rem;
  --tblr-datagrid-item-width: 15rem;
  grid-gap: var(--tblr-datagrid-padding);
  grid-template-columns: repeat(auto-fit, minmax(var(--tblr-datagrid-item-width), 1fr));
  display: grid;
}

.datagrid-title {
  font-size: .75rem;
  font-weight: var(--tblr-font-weight-bold);
  text-transform: uppercase;
  letter-spacing: .04em;
  color: var(--tblr-secondary);
  margin-bottom: .25rem;
  line-height: 1rem;
}

.empty {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 1rem;
  display: flex;
}

@media (width >= 768px) {
  .empty {
    padding: 3rem;
  }
}

.empty-icon {
  width: 3rem;
  height: 3rem;
  color: var(--tblr-secondary);
  margin: 0 0 1rem;
  line-height: 1;
}

.empty-icon svg {
  width: 100%;
  height: 100%;
}

.empty-img {
  margin: 0 0 2rem;
  line-height: 1;
}

.empty-header {
  font-size: 4rem;
  font-weight: var(--tblr-font-weight-light);
  color: var(--tblr-secondary);
  margin: 0 0 1rem;
  line-height: 1;
}

.empty-title {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: var(--tblr-font-weight-bold);
}

.empty-title, .empty-subtitle {
  margin: 0 0 .5rem;
}

.empty-action {
  margin-top: 1.5rem;
}

.empty-bordered {
  border: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color);
  border-radius: var(--tblr-border-radius);
}

.row > * {
  min-width: 0;
}

.col-separator {
  border-left: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color);
}

.container-slim {
  --tblr-gutter-x: calc(var(--tblr-page-padding) * 2);
  --tblr-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--tblr-gutter-x) * .5);
  padding-left: calc(var(--tblr-gutter-x) * .5);
  max-width: 16rem;
  margin-left: auto;
  margin-right: auto;
}

.container-tight {
  --tblr-gutter-x: calc(var(--tblr-page-padding) * 2);
  --tblr-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--tblr-gutter-x) * .5);
  padding-left: calc(var(--tblr-gutter-x) * .5);
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
}

.container-narrow {
  --tblr-gutter-x: calc(var(--tblr-page-padding) * 2);
  --tblr-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--tblr-gutter-x) * .5);
  padding-left: calc(var(--tblr-gutter-x) * .5);
  max-width: 61.875rem;
  margin-left: auto;
  margin-right: auto;
}

.row-0 {
  margin-left: 0;
  margin-right: 0;
}

.row-0 > .col, .row-0 > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.row-0 .card {
  margin-bottom: 0;
}

.row-sm {
  margin-left: -.375rem;
  margin-right: -.375rem;
}

.row-sm > .col, .row-sm > [class*="col-"] {
  padding-left: .375rem;
  padding-right: .375rem;
}

.row-sm .card {
  margin-bottom: .75rem;
}

.row-md {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.row-md > .col, .row-md > [class*="col-"] {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.row-md .card {
  margin-bottom: 3rem;
}

.row-lg {
  margin-left: -3rem;
  margin-right: -3rem;
}

.row-lg > .col, .row-lg > [class*="col-"] {
  padding-left: 3rem;
  padding-right: 3rem;
}

.row-lg .card {
  margin-bottom: 6rem;
}

.row-deck > .col, .row-deck > [class*="col-"] {
  align-items: stretch;
  display: flex;
}

.row-deck > .col .card, .row-deck > [class*="col-"] .card {
  flex: auto;
}

.row-cards {
  --tblr-gutter-x: var(--tblr-page-padding);
  --tblr-gutter-y: var(--tblr-page-padding);
  min-width: 0;
}

.row-cards .row-cards {
  flex: 1;
}

.space-y {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.space-x {
  gap: 1rem;
  display: flex;
}

.space-y-0 {
  flex-direction: column;
  gap: 0;
  display: flex;
}

.space-x-0 {
  gap: 0;
  display: flex;
}

.space-y-1 {
  flex-direction: column;
  gap: .25rem;
  display: flex;
}

.space-x-1 {
  gap: .25rem;
  display: flex;
}

.space-y-2 {
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

.space-x-2 {
  gap: .5rem;
  display: flex;
}

.space-y-3 {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.space-x-3 {
  gap: 1rem;
  display: flex;
}

.space-y-4 {
  flex-direction: column;
  gap: 1.5rem;
  display: flex;
}

.space-x-4 {
  gap: 1.5rem;
  display: flex;
}

.space-y-5 {
  flex-direction: column;
  gap: 2rem;
  display: flex;
}

.space-x-5 {
  gap: 2rem;
  display: flex;
}

.space-y-6 {
  flex-direction: column;
  gap: 2.5rem;
  display: flex;
}

.space-x-6 {
  gap: 2.5rem;
  display: flex;
}

.divide-y > :not(template) ~ :not(template) {
  border-top: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent) !important;
}

.divide-y > :not(template):not(:first-child) {
  padding-top: 1rem !important;
}

.divide-y > :not(template):not(:last-child) {
  padding-bottom: 1rem !important;
}

.divide-x > :not(template) ~ :not(template) {
  border-left: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent) !important;
}

.divide-x > :not(template):not(:first-child) {
  padding-left: 1rem !important;
}

.divide-x > :not(template):not(:last-child) {
  padding-right: 1rem !important;
}

.divide-y-0 > :not(template) ~ :not(template) {
  border-top: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent) !important;
}

.divide-y-0 > :not(template):not(:first-child) {
  padding-top: 0 !important;
}

.divide-y-0 > :not(template):not(:last-child) {
  padding-bottom: 0 !important;
}

.divide-x-0 > :not(template) ~ :not(template) {
  border-left: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent) !important;
}

.divide-x-0 > :not(template):not(:first-child) {
  padding-left: 0 !important;
}

.divide-x-0 > :not(template):not(:last-child) {
  padding-right: 0 !important;
}

.divide-y-1 > :not(template) ~ :not(template) {
  border-top: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent) !important;
}

.divide-y-1 > :not(template):not(:first-child) {
  padding-top: .25rem !important;
}

.divide-y-1 > :not(template):not(:last-child) {
  padding-bottom: .25rem !important;
}

.divide-x-1 > :not(template) ~ :not(template) {
  border-left: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent) !important;
}

.divide-x-1 > :not(template):not(:first-child) {
  padding-left: .25rem !important;
}

.divide-x-1 > :not(template):not(:last-child) {
  padding-right: .25rem !important;
}

.divide-y-2 > :not(template) ~ :not(template) {
  border-top: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent) !important;
}

.divide-y-2 > :not(template):not(:first-child) {
  padding-top: .5rem !important;
}

.divide-y-2 > :not(template):not(:last-child) {
  padding-bottom: .5rem !important;
}

.divide-x-2 > :not(template) ~ :not(template) {
  border-left: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent) !important;
}

.divide-x-2 > :not(template):not(:first-child) {
  padding-left: .5rem !important;
}

.divide-x-2 > :not(template):not(:last-child) {
  padding-right: .5rem !important;
}

.divide-y-3 > :not(template) ~ :not(template) {
  border-top: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent) !important;
}

.divide-y-3 > :not(template):not(:first-child) {
  padding-top: 1rem !important;
}

.divide-y-3 > :not(template):not(:last-child) {
  padding-bottom: 1rem !important;
}

.divide-x-3 > :not(template) ~ :not(template) {
  border-left: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent) !important;
}

.divide-x-3 > :not(template):not(:first-child) {
  padding-left: 1rem !important;
}

.divide-x-3 > :not(template):not(:last-child) {
  padding-right: 1rem !important;
}

.divide-y-4 > :not(template) ~ :not(template) {
  border-top: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent) !important;
}

.divide-y-4 > :not(template):not(:first-child) {
  padding-top: 1.5rem !important;
}

.divide-y-4 > :not(template):not(:last-child) {
  padding-bottom: 1.5rem !important;
}

.divide-x-4 > :not(template) ~ :not(template) {
  border-left: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent) !important;
}

.divide-x-4 > :not(template):not(:first-child) {
  padding-left: 1.5rem !important;
}

.divide-x-4 > :not(template):not(:last-child) {
  padding-right: 1.5rem !important;
}

.divide-y-5 > :not(template) ~ :not(template) {
  border-top: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent) !important;
}

.divide-y-5 > :not(template):not(:first-child) {
  padding-top: 2rem !important;
}

.divide-y-5 > :not(template):not(:last-child) {
  padding-bottom: 2rem !important;
}

.divide-x-5 > :not(template) ~ :not(template) {
  border-left: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent) !important;
}

.divide-x-5 > :not(template):not(:first-child) {
  padding-left: 2rem !important;
}

.divide-x-5 > :not(template):not(:last-child) {
  padding-right: 2rem !important;
}

.divide-y-6 > :not(template) ~ :not(template) {
  border-top: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent) !important;
}

.divide-y-6 > :not(template):not(:first-child) {
  padding-top: 2.5rem !important;
}

.divide-y-6 > :not(template):not(:last-child) {
  padding-bottom: 2.5rem !important;
}

.divide-x-6 > :not(template) ~ :not(template) {
  border-left: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent) !important;
}

.divide-x-6 > :not(template):not(:first-child) {
  padding-left: 2.5rem !important;
}

.divide-x-6 > :not(template):not(:last-child) {
  padding-right: 2.5rem !important;
}

.divide-y-fill {
  flex-direction: column;
  height: 100%;
  display: flex;
}

.divide-y-fill > :not(template) {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  display: flex;
}

.icon {
  --tblr-icon-size: 1.25rem;
  width: var(--tblr-icon-size);
  height: var(--tblr-icon-size);
  font-size: var(--tblr-icon-size);
  vertical-align: bottom;
  stroke-width: 1.5px;
}

.icon:hover {
  text-decoration: none;
}

.icon-inline {
  --tblr-icon-size: 1rem;
  vertical-align: -.2rem;
}

.icon-filled {
  fill: currentColor;
}

.icon-sm {
  --tblr-icon-size: 1rem;
  stroke-width: 1px;
}

.icon-md {
  --tblr-icon-size: 2.5rem;
  stroke-width: 1px;
}

.icon-lg {
  --tblr-icon-size: 3.5rem;
  stroke-width: 1px;
}

.icon-pulse {
  transition: all .15s;
  animation: 2s infinite both pulse;
}

.icon-tada {
  transition: all .15s;
  animation: 3s infinite both tada;
}

.icon-rotate {
  transition: all .15s;
  animation: 3s linear infinite both rotate-360;
}

.img-responsive {
  --tblr-img-responsive-ratio: 75%;
  padding-top: var(--tblr-img-responsive-ratio);
  background: center / cover no-repeat;
}

.img-responsive-grid {
  padding-top: calc(var(--tblr-img-responsive-ratio)  - var(--tblr-gutter-y) / 2);
}

.img-responsive-1x1 {
  --tblr-img-responsive-ratio: 100%;
}

.img-responsive-2x1 {
  --tblr-img-responsive-ratio: 50%;
}

.img-responsive-1x2 {
  --tblr-img-responsive-ratio: 200%;
}

.img-responsive-3x1 {
  --tblr-img-responsive-ratio: 33.3333%;
}

.img-responsive-1x3 {
  --tblr-img-responsive-ratio: 300%;
}

.img-responsive-4x1 {
  --tblr-img-responsive-ratio: 25%;
}

.img-responsive-1x4 {
  --tblr-img-responsive-ratio: 400%;
}

.img-responsive-4x3 {
  --tblr-img-responsive-ratio: 75%;
}

.img-responsive-3x4 {
  --tblr-img-responsive-ratio: 133.333%;
}

.img-responsive-16x9 {
  --tblr-img-responsive-ratio: 56.25%;
}

.img-responsive-9x16 {
  --tblr-img-responsive-ratio: 177.778%;
}

.img-responsive-21x9 {
  --tblr-img-responsive-ratio: 42.8571%;
}

.img-responsive-9x21 {
  --tblr-img-responsive-ratio: 233.333%;
}

.img-bg {
  background: center / cover no-repeat;
}

textarea[cols] {
  height: auto;
}

.col-form-label, .form-label {
  font-weight: var(--tblr-font-weight-medium);
  display: block;
}

.col-form-label.required:after, .form-label.required:after {
  content: "*";
  color: #d63939;
  margin-left: .25rem;
}

.form-label-description {
  float: right;
  font-weight: var(--tblr-font-weight-normal);
  color: var(--tblr-gray-500);
}

.form-hint {
  color: var(--tblr-gray-500);
  display: block;
}

.form-hint:last-child {
  margin-bottom: 0;
}

.form-hint + .form-control {
  margin-top: .25rem;
}

.form-label + .form-hint {
  margin-top: -.25rem;
}

.input-group + .form-hint, .form-control + .form-hint, .form-select + .form-hint {
  color: var(--tblr-gray-500);
  margin-top: .5rem;
}

.form-select:-moz-focusring {
  color: var(--tblr-body-color);
}

.form-control:-webkit-autofill {
  box-shadow: 0 0 0 1000px var(--tblr-body-bg) inset;
  color: var(--tblr-body-color);
  -webkit-text-fill-color: var(--tblr-body-color);
}

.form-control:disabled, .form-control.disabled {
  color: var(--tblr-gray-500);
  -webkit-user-select: none;
  user-select: none;
}

.form-control[size] {
  width: auto;
}

.form-control-light {
  background-color: var(--tblr-gray-100);
  border-color: #0000;
}

.form-control-dark {
  color: #fff;
  background-color: #0000001a;
  border-color: #0000;
}

.form-control-dark:focus {
  box-shadow: none;
  background-color: #0000001a;
  border-color: #ffffff3d;
}

.form-control-dark::-webkit-input-placeholder {
  color: #fff9;
}

.form-control-dark:-ms-placeholder-shown {
  color: #fff9;
}

.form-control-dark::placeholder {
  color: #fff9;
}

.form-control-rounded {
  border-radius: 10rem;
}

.form-control-flush {
  resize: none;
  line-height: inherit;
  padding: 0;
  box-shadow: none !important;
  background: none !important;
  border-color: #0000 !important;
}

.form-footer {
  margin-top: 2rem;
}

.form-fieldset {
  background: var(--tblr-body-bg);
  border: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color);
  border-radius: var(--tblr-border-radius);
  margin-bottom: 1rem;
  padding: 1rem;
}

fieldset:empty {
  display: none;
}

.form-help {
  font-weight: var(--tblr-font-weight-bold);
  width: 1.125rem;
  height: 1.125rem;
  color: var(--tblr-gray-500);
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background: var(--tblr-gray-100);
  border-radius: 100rem;
  justify-content: center;
  align-items: center;
  font-size: .75rem;
  text-decoration: none;
  transition: background-color .3s, color .3s;
  display: inline-flex;
}

@media (prefers-reduced-motion: reduce) {
  .form-help {
    transition: none;
  }
}

.form-help:hover, .form-help[aria-describedby] {
  color: #fff;
  background: var(--tblr-primary);
}

.input-group {
  box-shadow: var(--tblr-box-shadow-input);
  border-radius: var(--tblr-border-radius);
}

.input-group .form-control, .input-group .btn {
  box-shadow: none;
}

.input-group-link {
  font-size: .75rem;
}

.input-group-flat:focus-within {
  box-shadow: 0 0 0 .25rem rgba(var(--tblr-primary-rgb), .25);
  border-radius: var(--tblr-border-radius);
}

.input-group-flat:focus-within .form-control, .input-group-flat:focus-within .input-group-text {
  border-color: #83b7e8 !important;
}

.input-group-flat .form-control:focus {
  border-color: var(--tblr-border-color);
  box-shadow: none;
}

.input-group-flat .form-control:not(:last-child) {
  border-right: 0;
}

.input-group-flat .form-control:not(:first-child) {
  border-left: 0;
}

.input-group-flat .input-group-text {
  background: var(--tblr-bg-forms);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .input-group-flat .input-group-text {
    transition: none;
  }
}

.input-group-flat .input-group-text:first-child {
  padding-right: 0;
}

.input-group-flat .input-group-text:last-child {
  padding-left: 0;
}

.form-file-button {
  border-left: 0;
  margin-left: 0;
}

.input-icon {
  position: relative;
}

.input-icon .form-control:not(:last-child), .input-icon .form-select:not(:last-child) {
  padding-right: 2.5rem;
}

.input-icon .form-control:not(:first-child), .input-icon .form-select:not(:last-child) {
  padding-left: 2.5rem;
}

.input-icon-addon {
  min-width: 2.5rem;
  color: var(--tblr-icon-color);
  pointer-events: none;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.input-icon-addon:last-child {
  left: auto;
  right: 0;
}

.form-colorinput {
  cursor: pointer;
  margin: 0;
  line-height: 1;
  display: inline-block;
  position: relative;
}

.form-colorinput-input {
  z-index: -1;
  opacity: 0;
  position: absolute;
}

.form-colorinput-color {
  color: #fff;
  border: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent);
  border-radius: 3px;
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  box-shadow: 0 1px 2px #0000000d;
}

.form-colorinput-color:before {
  content: "";
  opacity: 0;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8.5l2.5 2.5l5.5 -5.5'/%3e%3c/svg%3e") center / 1.25rem no-repeat;
  width: 100%;
  height: 100%;
  transition: opacity .3s;
  position: absolute;
  top: 0;
  left: 0;
}

@media (prefers-reduced-motion: reduce) {
  .form-colorinput-color:before {
    transition: none;
  }
}

.form-colorinput-input:checked ~ .form-colorinput-color:before {
  opacity: 1;
}

.form-colorinput-input:focus ~ .form-colorinput-color {
  border-color: var(--tblr-primary);
  box-shadow: 0 0 0 .25rem rgba(var(--tblr-primary-rgb), .25);
}

.form-colorinput-light .form-colorinput-color:before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16'%3e%3cpath fill='none' stroke='%23182433' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8.5l2.5 2.5l5.5 -5.5'/%3e%3c/svg%3e");
}

.form-imagecheck {
  cursor: pointer;
  margin: 0;
  position: relative;
}

.form-imagecheck-input {
  z-index: -1;
  opacity: 0;
  position: absolute;
}

.form-imagecheck-figure {
  -webkit-user-select: none;
  user-select: none;
  border: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color);
  border-radius: 3px;
  margin: 0;
  display: block;
  position: relative;
}

.form-imagecheck-input:focus ~ .form-imagecheck-figure {
  border-color: var(--tblr-primary);
  box-shadow: 0 0 0 .25rem rgba(var(--tblr-primary-rgb), .25);
}

.form-imagecheck-input:checked ~ .form-imagecheck-figure {
  border-color: var(--tblr-primary);
}

.form-imagecheck-figure:before {
  z-index: 1;
  color: #fff;
  pointer-events: none;
  content: "";
  -webkit-user-select: none;
  user-select: none;
  background: var(--tblr-bg-forms);
  border: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color);
  border-radius: var(--tblr-border-radius);
  width: 1.25rem;
  height: 1.25rem;
  transition: opacity .3s;
  display: block;
  position: absolute;
  top: .25rem;
  left: .25rem;
}

@media (prefers-reduced-motion: reduce) {
  .form-imagecheck-figure:before {
    transition: none;
  }
}

.form-imagecheck-input:checked ~ .form-imagecheck-figure:before {
  background-color: var(--tblr-primary);
  border-color: var(--tblr-border-color-translucent);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8.5l2.5 2.5l5.5 -5.5'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: repeat;
  background-size: 1.25rem;
}

.form-imagecheck-input[type="radio"] ~ .form-imagecheck-figure:before {
  border-radius: 50%;
}

.form-imagecheck-input[type="radio"]:checked ~ .form-imagecheck-figure:before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3ccircle r='3' fill='%23ffffff' cx='8' cy='8' /%3e%3c/svg%3e");
}

.form-imagecheck-image {
  opacity: .64;
  max-width: 100%;
  transition: opacity .3s;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-imagecheck-image {
    transition: none;
  }
}

.form-imagecheck-image:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.form-imagecheck-image:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.form-imagecheck:hover .form-imagecheck-image, .form-imagecheck-input:focus ~ .form-imagecheck-figure .form-imagecheck-image, .form-imagecheck-input:checked ~ .form-imagecheck-figure .form-imagecheck-image {
  opacity: 1;
}

.form-imagecheck-caption {
  color: var(--tblr-secondary);
  text-align: center;
  padding: .25rem;
  font-size: .765625rem;
  transition: color .3s;
}

@media (prefers-reduced-motion: reduce) {
  .form-imagecheck-caption {
    transition: none;
  }
}

.form-imagecheck:hover .form-imagecheck-caption, .form-imagecheck-input:focus ~ .form-imagecheck-figure .form-imagecheck-caption, .form-imagecheck-input:checked ~ .form-imagecheck-figure .form-imagecheck-caption {
  color: var(--tblr-body-color);
}

.form-selectgroup {
  flex-wrap: wrap;
  margin: 0 -.5rem -.5rem 0;
  display: inline-flex;
}

.form-selectgroup .form-selectgroup-item {
  margin: 0 .5rem .5rem 0;
}

.form-selectgroup-vertical {
  flex-direction: column;
}

.form-selectgroup-item {
  display: block;
  position: relative;
}

.form-selectgroup-input {
  z-index: -1;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.form-selectgroup-label {
  min-width: calc(1.42857em + .875rem + calc(var(--tblr-border-width) * 2));
  color: var(--tblr-secondary);
  background: var(--tblr-bg-forms);
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color);
  box-shadow: var(--tblr-box-shadow-input);
  border-radius: 3px;
  margin: 0;
  padding: .4375rem .75rem;
  font-size: .875rem;
  line-height: 1.42857;
  transition: border-color .3s, background .3s, color .3s;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .form-selectgroup-label {
    transition: none;
  }
}

.form-selectgroup-label .icon:only-child {
  margin: 0 -.25rem;
}

.form-selectgroup-label:hover {
  color: var(--tblr-body-color);
}

.form-selectgroup-check {
  border: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent);
  vertical-align: middle;
  width: 1.25rem;
  height: 1.25rem;
  box-shadow: var(--tblr-box-shadow-input);
  display: inline-block;
}

.form-selectgroup-input[type="checkbox"] + .form-selectgroup-label .form-selectgroup-check {
  border-radius: var(--tblr-border-radius);
}

.form-selectgroup-input[type="radio"] + .form-selectgroup-label .form-selectgroup-check {
  border-radius: 50%;
}

.form-selectgroup-input:checked + .form-selectgroup-label .form-selectgroup-check {
  background-color: var(--tblr-primary);
  border-color: var(--tblr-border-color-translucent);
  background-position: center;
  background-repeat: repeat;
  background-size: 1.25rem;
}

.form-selectgroup-input[type="checkbox"]:checked + .form-selectgroup-label .form-selectgroup-check {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8.5l2.5 2.5l5.5 -5.5'/%3e%3c/svg%3e");
}

.form-selectgroup-input[type="radio"]:checked + .form-selectgroup-label .form-selectgroup-check {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3ccircle r='3' fill='%23ffffff' cx='8' cy='8' /%3e%3c/svg%3e");
}

.form-selectgroup-check-floated {
  position: absolute;
  top: .4375rem;
  right: .4375rem;
}

.form-selectgroup-input:checked + .form-selectgroup-label {
  z-index: 1;
  color: var(--tblr-primary);
  background: rgba(var(--tblr-primary-rgb), .04);
  border-color: var(--tblr-primary);
}

.form-selectgroup-input:focus + .form-selectgroup-label {
  z-index: 2;
  color: var(--tblr-primary);
  border-color: var(--tblr-primary);
  box-shadow: 0 0 0 .25rem rgba(var(--tblr-primary-rgb), .25);
}

.form-selectgroup-boxes .form-selectgroup-label {
  text-align: left;
  color: inherit;
  padding: 1.25rem 1rem;
}

.form-selectgroup-boxes .form-selectgroup-input:checked + .form-selectgroup-label {
  color: inherit;
}

.form-selectgroup-boxes .form-selectgroup-input:checked + .form-selectgroup-label .form-selectgroup-title {
  color: var(--tblr-primary);
}

.form-selectgroup-boxes .form-selectgroup-input:checked + .form-selectgroup-label .form-selectgroup-label-content {
  opacity: 1;
}

.form-selectgroup-pills {
  flex-wrap: wrap;
  align-items: flex-start;
}

.form-selectgroup-pills .form-selectgroup-item {
  flex-grow: 0;
}

.form-selectgroup-pills .form-selectgroup-label {
  border-radius: 50px;
}

.form-control-color::-webkit-color-swatch {
  border: none;
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.form-control::file-selector-button {
  background-color: var(--tblr-btn-color, var(--tblr-tertiary-bg));
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: var(--tblr-btn-color, var(--tblr-secondary-bg));
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--tblr-btn-color, var(--tblr-secondary-bg));
}

.form-check {
  -webkit-user-select: none;
  user-select: none;
}

.form-check.form-check-highlight .form-check-input:not(:checked) ~ .form-check-label, .form-check .form-check-label-off {
  color: var(--tblr-secondary);
}

.form-check .form-check-input:checked ~ .form-check-label-off, .form-check .form-check-input:not(:checked) ~ .form-check-label-on {
  display: none;
}

.form-check-input {
  box-shadow: var(--tblr-box-shadow-input);
  background-size: 1.25rem;
  margin-top: 0;
}

.form-switch .form-check-input {
  transition: background-color .3s, background-position .3s;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-check-label {
  display: block;
}

.form-check-label.required:after {
  content: "*";
  color: #d63939;
  margin-left: .25rem;
}

.form-check-description {
  color: var(--tblr-secondary);
  margin-top: .25rem;
  font-size: .75rem;
  display: block;
}

.form-check-single, .form-check-single .form-check-input {
  margin: 0;
}

.form-switch .form-check-input {
  height: 1.25rem;
  margin-top: 0;
}

.form-switch-lg {
  min-height: 1.5rem;
  padding-left: 3.5rem;
}

.form-switch-lg .form-check-input {
  background-size: 1.5rem;
  width: 2.75rem;
  height: 1.5rem;
  margin-left: -3.5rem;
}

.form-switch-lg .form-check-label {
  padding-top: .125rem;
}

.form-check-input:checked {
  border: none;
}

.form-select.is-invalid-lite, .form-control.is-invalid-lite, .form-select.is-valid-lite, .form-control.is-valid-lite {
  border-color: var(--tblr-border-color) !important;
}

.legend {
  --tblr-legend-size: .75em;
  background: var(--tblr-border-color);
  width: var(--tblr-legend-size);
  height: var(--tblr-legend-size);
  border-radius: var(--tblr-border-radius-sm);
  border: 1px solid var(--tblr-border-color-translucent);
  display: inline-block;
}

.list-group {
  margin-left: 0;
  margin-right: 0;
}

.list-group-header {
  background: var(--tblr-bg-surface-tertiary);
  font-size: .75rem;
  font-weight: var(--tblr-font-weight-medium);
  text-transform: uppercase;
  color: var(--tblr-gray-500);
  border-bottom: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color);
  padding: .5rem 1.25rem;
  line-height: 1;
}

.list-group-flush > .list-group-header:last-child {
  border-bottom-width: 0;
}

.list-group-item {
  background-color: inherit;
}

.list-group-item.active {
  background-color: rgba(var(--tblr-secondary-rgb), .08);
  border-left-width: 2px;
  border-left-color: #066fd1;
}

.list-group-item:active, .list-group-item:focus, .list-group-item:hover {
  background-color: rgba(var(--tblr-secondary-rgb), .08);
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c7a91;
  background-color: rgba(var(--tblr-secondary-rgb), .08);
}

.list-bordered .list-item {
  border-top: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color);
  margin-top: -1px;
}

.list-bordered .list-item:first-child {
  border-top: none;
}

.list-group-hoverable .list-group-item-actions {
  opacity: 0;
  transition: opacity .3s;
}

@media (prefers-reduced-motion: reduce) {
  .list-group-hoverable .list-group-item-actions {
    transition: none;
  }
}

.list-group-hoverable .list-group-item:hover .list-group-item-actions, .list-group-hoverable .list-group-item-actions.show {
  opacity: 1;
}

.list-group-transparent {
  --tblr-list-group-border-radius: 0;
  margin: 0 -1.25rem;
}

.list-group-transparent .list-group-item {
  background: none;
  border: 0;
}

.list-group-transparent .list-group-item .icon {
  color: var(--tblr-secondary);
}

.list-group-transparent .list-group-item.active {
  font-weight: var(--tblr-font-weight-bold);
  color: inherit;
  background: var(--tblr-active-bg);
}

.list-group-transparent .list-group-item.active .icon {
  color: inherit;
}

.list-separated {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.list-inline {
  margin: 0;
}

.list-inline-item:not(:last-child) {
  -webkit-margin-end: .5rem;
  margin-right: auto;
  margin-inline-end: .5rem;
}

.list-inline-dots .list-inline-item + .list-inline-item:before {
  content: " · ";
  -webkit-margin-end: .5rem;
  margin-inline-end: .5rem;
}

.loader {
  color: #066fd1;
  vertical-align: middle;
  width: 2.5rem;
  height: 2.5rem;
  display: block;
  position: relative;
}

.loader:after {
  content: "";
  border: 1px var(--tblr-border-style);
  border-color: currentColor #0000 #0000 currentColor;
  border-radius: 100rem;
  width: 100%;
  height: 100%;
  animation: .6s linear infinite rotate-360;
  position: absolute;
  top: 0;
  left: 0;
}

.dimmer {
  position: relative;
}

.dimmer .loader {
  margin: 0 auto;
  display: none;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.dimmer.active .loader {
  display: block;
}

.dimmer.active .dimmer-content {
  pointer-events: none;
  opacity: .1;
}

@keyframes animated-dots {
  0% {
    transform: translateX(-100%);
  }
}

.animated-dots {
  vertical-align: bottom;
  display: inline-block;
  overflow: hidden;
}

.animated-dots:after {
  content: "...";
  animation: 1.2s steps(4, jump-none) infinite animated-dots;
  display: inline-block;
}

.modal-content > .btn-close, .modal-header > .btn-close {
  z-index: 10;
  width: 3.5rem;
  height: 3.5rem;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.modal-body {
  scrollbar-color: rgba(var(--tblr-scrollbar-color, var(--tblr-body-color-rgb)), .16);
}

.modal-body::-webkit-scrollbar {
  width: 1rem;
  height: 1rem;
  transition: background .3s;
}

@media (prefers-reduced-motion: reduce) {
  .modal-body::-webkit-scrollbar {
    transition: none;
  }
}

.modal-body::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 1rem rgba(var(--tblr-scrollbar-color, var(--tblr-body-color-rgb)), .16);
  border: 5px solid #0000;
  border-radius: 1rem;
}

.modal-body::-webkit-scrollbar-track {
  background: none;
}

.modal-body:hover::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 1rem rgba(var(--tblr-scrollbar-color, var(--tblr-body-color-rgb)), .32);
}

.modal-body::-webkit-scrollbar-corner {
  background: none;
}

.modal-body .modal-title {
  margin-bottom: 1rem;
}

.modal-body + .modal-body {
  border-top: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color);
}

.modal-status {
  background: var(--tblr-secondary);
  border-radius: var(--tblr-border-radius-lg) var(--tblr-border-radius-lg) 0 0;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.modal-header {
  background: none;
  align-items: center;
  min-height: 3.5rem;
  padding: 0 3.5rem 0 1.5rem;
}

.modal-title {
  font-size: 1rem;
  font-weight: var(--tblr-font-weight-bold);
  color: inherit;
  line-height: 1.42857;
}

.modal-footer {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.modal-blur {
  backdrop-filter: blur(4px);
}

.modal-full-width {
  max-width: none;
  margin: 0 .5rem;
}

.nav-vertical, .nav-vertical .nav {
  flex-flow: column;
}

.nav-vertical .nav {
  border-left: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color);
  margin-left: 1.25rem;
  padding-left: .5rem;
}

.nav-vertical .nav-link.active, .nav-vertical .nav-item.show .nav-link {
  font-weight: var(--tblr-font-weight-bold);
}

.nav-vertical.nav-pills {
  margin: 0 -.75rem;
}

.nav-bordered {
  border-bottom: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color);
}

.nav-bordered .nav-item + .nav-item {
  margin-left: 1.25rem;
}

.nav-bordered .nav-link {
  margin: 0 0 -var(--tblr-border-width);
  border: 0;
  border-bottom: 2px var(--tblr-border-style) transparent;
  padding-left: 0;
  padding-right: 0;
}

.nav-bordered .nav-link.active, .nav-bordered .nav-item.show .nav-link {
  color: var(--tblr-primary);
  border-color: var(--tblr-primary);
}

.nav-link {
  align-items: center;
  transition: color .3s;
  display: flex;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link-toggle {
  margin-left: auto;
  padding: 0 .25rem;
  transition: transform .3s;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link-toggle {
    transition: none;
  }
}

.nav-link-toggle:after {
  content: "";
  vertical-align: .306em;
  border-bottom: 1px var(--tblr-border-style);
  border-left: 1px var(--tblr-border-style);
  width: .36em;
  height: .36em;
  margin: 0;
  display: inline-block;
  transform: rotate(-45deg);
}

.nav-link[aria-expanded="true"] .nav-link-toggle {
  transform: rotate(180deg);
}

.nav-link-icon {
  width: 1.25rem;
  height: 1.25rem;
  color: var(--tblr-icon-color);
  margin-right: .5rem;
}

.nav-link-icon svg {
  height: 100%;
  display: block;
}

.nav-fill .nav-item .nav-link {
  justify-content: center;
}

.stars {
  color: #8a97ab;
  font-size: .75rem;
  display: inline-flex;
}

.stars .star:not(:first-child) {
  margin-left: .25rem;
}

.pagination {
  -webkit-user-select: none;
  user-select: none;
}

.page-link {
  border-radius: var(--tblr-border-radius);
  min-width: 1.75rem;
}

.page-item {
  text-align: center;
}

.page-item:not(.active) .page-link:hover {
  background: none;
}

.page-item.page-prev, .page-item.page-next {
  text-align: left;
  flex: 0 0 50%;
}

.page-item.page-next {
  text-align: right;
  margin-left: auto;
}

.page-item-subtitle {
  color: var(--tblr-secondary);
  text-transform: uppercase;
  margin-bottom: 2px;
  font-size: 12px;
}

.page-item.disabled .page-item-subtitle {
  color: var(--tblr-disabled-color);
}

.page-item-title {
  font-size: 1rem;
  font-weight: var(--tblr-font-weight-normal);
  color: var(--tblr-body-color);
}

.page-link:hover .page-item-title {
  color: #066fd1;
}

.page-item.disabled .page-item-title {
  color: var(--tblr-disabled-color);
}

@keyframes progress-indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }

  100%, 60% {
    left: 100%;
    right: -90%;
  }
}

.progress {
  appearance: none;
  width: 100%;
  line-height: .5rem;
  position: relative;
}

.progress::-webkit-progress-bar {
  background: var(--tblr-progress-bg);
}

.progress::-webkit-progress-value {
  background-color: var(--tblr-primary);
}

.progress::-moz-progress-bar {
  background-color: var(--tblr-primary);
}

.progress::-ms-fill {
  background-color: var(--tblr-primary);
  border: none;
}

.progress-sm {
  height: .25rem;
}

.progress-bar {
  height: 100%;
}

.progress-bar-indeterminate:after, .progress-bar-indeterminate:before {
  content: "";
  background-color: inherit;
  will-change: left, right;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.progress-bar-indeterminate:before {
  animation: 1.5s cubic-bezier(.65, .815, .735, .395) infinite progress-indeterminate;
}

.progress-separated .progress-bar {
  box-shadow: 0 0 0 2px var(--tblr-card-bg, var(--tblr-bg-surface));
}

.progressbg {
  padding: .25rem .5rem;
  display: flex;
  position: relative;
}

.progressbg-text {
  z-index: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
}

.progressbg-progress {
  z-index: 0;
  pointer-events: none;
  background: none;
  height: 100%;
  position: absolute;
  inset: 0;
}

.progressbg-value {
  font-weight: var(--tblr-font-weight-medium);
  margin-left: auto;
  padding-left: 2rem;
}

.ribbon {
  --tblr-ribbon-margin: .25rem;
  --tblr-ribbon-border-radius: var(--tblr-border-radius);
  top: .75rem;
  right: calc(-1 * var(--tblr-ribbon-margin));
  z-index: 1;
  font-size: .625rem;
  font-weight: var(--tblr-font-weight-bold);
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  background: var(--tblr-primary);
  border-color: var(--tblr-primary);
  border-radius: var(--tblr-ribbon-border-radius) 0 var(--tblr-ribbon-border-radius) var(--tblr-ribbon-border-radius);
  justify-content: center;
  align-items: center;
  min-width: 2rem;
  min-height: 2rem;
  padding: .25rem .75rem;
  line-height: 1;
  display: inline-flex;
  position: absolute;
}

.ribbon:before {
  content: "";
  filter: brightness(70%);
  border: calc(var(--tblr-ribbon-margin) * .5) var(--tblr-border-style);
  border-color: inherit;
  border-top-color: #0000;
  border-right-color: #0000;
  width: 0;
  height: 0;
  position: absolute;
  bottom: 100%;
  right: 0;
}

.ribbon.bg-blue {
  border-color: var(--tblr-blue);
}

.ribbon.bg-blue-lt {
  border-color: rgba(var(--tblr-blue-rgb), .1) !important;
}

.ribbon.bg-azure {
  border-color: var(--tblr-azure);
}

.ribbon.bg-azure-lt {
  border-color: rgba(var(--tblr-azure-rgb), .1) !important;
}

.ribbon.bg-indigo {
  border-color: var(--tblr-indigo);
}

.ribbon.bg-indigo-lt {
  border-color: rgba(var(--tblr-indigo-rgb), .1) !important;
}

.ribbon.bg-purple {
  border-color: var(--tblr-purple);
}

.ribbon.bg-purple-lt {
  border-color: rgba(var(--tblr-purple-rgb), .1) !important;
}

.ribbon.bg-pink {
  border-color: var(--tblr-pink);
}

.ribbon.bg-pink-lt {
  border-color: rgba(var(--tblr-pink-rgb), .1) !important;
}

.ribbon.bg-red {
  border-color: var(--tblr-red);
}

.ribbon.bg-red-lt {
  border-color: rgba(var(--tblr-red-rgb), .1) !important;
}

.ribbon.bg-orange {
  border-color: var(--tblr-orange);
}

.ribbon.bg-orange-lt {
  border-color: rgba(var(--tblr-orange-rgb), .1) !important;
}

.ribbon.bg-yellow {
  border-color: var(--tblr-yellow);
}

.ribbon.bg-yellow-lt {
  border-color: rgba(var(--tblr-yellow-rgb), .1) !important;
}

.ribbon.bg-lime {
  border-color: var(--tblr-lime);
}

.ribbon.bg-lime-lt {
  border-color: rgba(var(--tblr-lime-rgb), .1) !important;
}

.ribbon.bg-green {
  border-color: var(--tblr-green);
}

.ribbon.bg-green-lt {
  border-color: rgba(var(--tblr-green-rgb), .1) !important;
}

.ribbon.bg-teal {
  border-color: var(--tblr-teal);
}

.ribbon.bg-teal-lt {
  border-color: rgba(var(--tblr-teal-rgb), .1) !important;
}

.ribbon.bg-cyan {
  border-color: var(--tblr-cyan);
}

.ribbon.bg-cyan-lt {
  border-color: rgba(var(--tblr-cyan-rgb), .1) !important;
}

.ribbon .icon {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}

.ribbon-top {
  top: calc(-1 * var(--tblr-ribbon-margin));
  border-radius: 0 var(--tblr-ribbon-border-radius) var(--tblr-ribbon-border-radius) var(--tblr-ribbon-border-radius);
  width: 2rem;
  padding: .5rem 0;
  right: .75rem;
}

.ribbon-top:before {
  border-color: inherit;
  border-top-color: #0000;
  border-left-color: #0000;
  top: 0;
  bottom: auto;
  right: 100%;
}

.ribbon-top.ribbon-start {
  left: .75rem;
  right: auto;
}

.ribbon-top.ribbon-start:before {
  top: 0;
  left: auto;
  right: 100%;
}

.ribbon-start {
  right: auto;
  left: calc(-1 * var(--tblr-ribbon-margin));
  border-radius: 0 var(--tblr-ribbon-border-radius) var(--tblr-ribbon-border-radius) var(--tblr-ribbon-border-radius);
}

.ribbon-start:before {
  border-color: inherit;
  border-top-color: #0000;
  border-left-color: #0000;
  top: auto;
  bottom: 100%;
  left: 0;
}

.ribbon-bottom {
  top: auto;
  bottom: .75rem;
}

.ribbon-bookmark {
  border-radius: 0 0 var(--tblr-ribbon-border-radius) 0;
  padding-left: .25rem;
}

.ribbon-bookmark:after {
  content: "";
  border: 1rem var(--tblr-border-style);
  border-color: inherit;
  border-left-width: .5rem;
  border-left-color: #0000;
  border-right-width: 0;
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  top: 0;
  right: 100%;
}

.ribbon-bookmark.ribbon-left {
  padding-right: .5rem;
}

.ribbon-bookmark.ribbon-left:after {
  border-left-width: 0;
  border-right-width: .5rem;
  border-right-color: #0000;
  left: 100%;
  right: auto;
}

.ribbon-bookmark.ribbon-top {
  border-radius: 0 var(--tblr-ribbon-border-radius) 0 0;
  padding-bottom: .25rem;
  padding-left: 0;
  padding-right: 0;
}

.ribbon-bookmark.ribbon-top:after {
  border-color: inherit;
  border-width: 0 1rem .5rem;
  border-bottom-color: #0000;
  top: 100%;
  left: 0;
  right: 0;
}

.markdown {
  line-height: 1.71429;
}

.markdown > :first-child {
  margin-top: 0;
}

.markdown > :last-child, .markdown > :last-child .highlight {
  margin-bottom: 0;
}

@media (width >= 768px) {
  .markdown > hr, .markdown > .hr {
    margin-top: 3em;
    margin-bottom: 3em;
  }
}

.markdown > h1, .markdown > .h1, .markdown > h2, .markdown > .h2, .markdown > h3, .markdown > .h3, .markdown > h4, .markdown > .h4, .markdown > h5, .markdown > .h5, .markdown > h6, .markdown > .h6 {
  font-weight: var(--tblr-font-weight-bold);
}

.markdown > blockquote {
  margin: 1.5rem 0;
  padding: .5rem 1.5rem;
  font-size: 1rem;
}

.markdown > img {
  border-radius: var(--tblr-border-radius);
}

.placeholder:not(.btn):not([class*="bg-"]) {
  background-color: currentColor !important;
}

.placeholder:not(.avatar):not([class*="card-img-"]) {
  border-radius: var(--tblr-border-radius);
}

.steps {
  --tblr-steps-color: var(--tblr-primary);
  --tblr-steps-inactive-color: var(--tblr-border-color);
  --tblr-steps-dot-size: .5rem;
  --tblr-steps-border-width: 2px;
  flex-wrap: nowrap;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.steps-blue {
  --tblr-steps-color: var(--tblr-blue);
}

.steps-azure {
  --tblr-steps-color: var(--tblr-azure);
}

.steps-indigo {
  --tblr-steps-color: var(--tblr-indigo);
}

.steps-purple {
  --tblr-steps-color: var(--tblr-purple);
}

.steps-pink {
  --tblr-steps-color: var(--tblr-pink);
}

.steps-red {
  --tblr-steps-color: var(--tblr-red);
}

.steps-orange {
  --tblr-steps-color: var(--tblr-orange);
}

.steps-yellow {
  --tblr-steps-color: var(--tblr-yellow);
}

.steps-lime {
  --tblr-steps-color: var(--tblr-lime);
}

.steps-green {
  --tblr-steps-color: var(--tblr-green);
}

.steps-teal {
  --tblr-steps-color: var(--tblr-teal);
}

.steps-cyan {
  --tblr-steps-color: var(--tblr-cyan);
}

.step-item {
  min-height: 1rem;
  color: inherit;
  text-align: center;
  cursor: default;
  padding-top: calc(var(--tblr-steps-dot-size));
  flex: 1 1 0;
  margin-top: 0;
  position: relative;
}

a.step-item {
  cursor: pointer;
}

a.step-item:hover {
  color: inherit;
}

.step-item:after, .step-item:before {
  background: var(--tblr-steps-color);
}

.step-item:not(:last-child):after {
  content: "";
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translateY(-50%);
}

.step-item:after {
  top: calc(var(--tblr-steps-dot-size) * .5);
  height: var(--tblr-steps-border-width);
}

.step-item:before {
  content: "";
  z-index: 1;
  box-sizing: content-box;
  color: var(--tblr-white);
  width: var(--tblr-steps-dot-size);
  height: var(--tblr-steps-dot-size);
  border-radius: 100rem;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.step-item.active {
  font-weight: var(--tblr-font-weight-bold);
}

.step-item.active:after {
  background: var(--tblr-steps-inactive-color);
}

.step-item.active ~ .step-item {
  color: var(--tblr-disabled-color);
}

.step-item.active ~ .step-item:after, .step-item.active ~ .step-item:before {
  background: var(--tblr-steps-inactive-color);
}

.steps-counter {
  --tblr-steps-dot-size: 1.5rem;
  counter-reset: steps;
}

.steps-counter .step-item {
  counter-increment: steps;
}

.steps-counter .step-item:before {
  content: counter(steps);
}

.steps-vertical {
  --tblr-steps-dot-offset: 6px;
  flex-direction: column;
}

.steps-vertical.steps-counter {
  --tblr-steps-dot-offset: -2px;
}

.steps-vertical .step-item {
  text-align: left;
  padding-top: 0;
  padding-left: calc(var(--tblr-steps-dot-size)  + 1rem);
  min-height: auto;
}

.steps-vertical .step-item:not(:first-child) {
  margin-top: 1rem;
}

.steps-vertical .step-item:before {
  top: var(--tblr-steps-dot-offset);
  left: 0;
  transform: translate(0);
}

.steps-vertical .step-item:not(:last-child):after {
  content: "";
  top: var(--tblr-steps-dot-offset);
  left: calc(var(--tblr-steps-dot-size) * .5);
  width: var(--tblr-steps-border-width);
  height: calc(100% + 1rem);
  position: absolute;
  transform: translateX(-50%);
}

@keyframes status-pulsate-main {
  40% {
    transform: scale(1.25);
  }

  60% {
    transform: scale(1.25);
  }
}

@keyframes status-pulsate-secondary {
  10% {
    transform: scale(1);
  }

  30% {
    transform: scale(3);
  }

  80% {
    transform: scale(3);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes status-pulsate-tertiary {
  25% {
    transform: scale(1);
  }

  80% {
    opacity: 0;
    transform: scale(3);
  }

  100% {
    opacity: 0;
    transform: scale(3);
  }
}

.status {
  --tblr-status-height: 1.5rem;
  --tblr-status-color: #6c7a91;
  --tblr-status-color-rgb: 108, 122, 145;
  height: var(--tblr-status-height);
  color: var(--tblr-status-color);
  background: rgba(var(--tblr-status-color-rgb), .1);
  text-transform: none;
  letter-spacing: normal;
  font-size: .875rem;
  font-weight: var(--tblr-font-weight-medium);
  border-radius: 100rem;
  align-items: center;
  gap: .5rem;
  margin: 0;
  padding: .25rem .75rem;
  line-height: 1;
  display: inline-flex;
}

.status .status-dot {
  background: var(--tblr-status-color);
}

.status .icon {
  font-size: 1.25rem;
}

.status-lite {
  border: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color) !important;
  color: var(--tblr-body-color) !important;
  background: none !important;
}

.status-primary {
  --tblr-status-color: #066fd1;
  --tblr-status-color-rgb: 6, 111, 209;
}

.status-secondary {
  --tblr-status-color: #6c7a91;
  --tblr-status-color-rgb: 108, 122, 145;
}

.status-success {
  --tblr-status-color: #2fb344;
  --tblr-status-color-rgb: 47, 179, 68;
}

.status-info {
  --tblr-status-color: #4299e1;
  --tblr-status-color-rgb: 66, 153, 225;
}

.status-warning {
  --tblr-status-color: #f76707;
  --tblr-status-color-rgb: 247, 103, 7;
}

.status-danger {
  --tblr-status-color: #d63939;
  --tblr-status-color-rgb: 214, 57, 57;
}

.status-light {
  --tblr-status-color: #f6f8fb;
  --tblr-status-color-rgb: 246, 248, 251;
}

.status-dark {
  --tblr-status-color: #182433;
  --tblr-status-color-rgb: 24, 36, 51;
}

.status-muted {
  --tblr-status-color: #6c7a91;
  --tblr-status-color-rgb: 108, 122, 145;
}

.status-blue {
  --tblr-status-color: #066fd1;
  --tblr-status-color-rgb: 6, 111, 209;
}

.status-azure {
  --tblr-status-color: #4299e1;
  --tblr-status-color-rgb: 66, 153, 225;
}

.status-indigo {
  --tblr-status-color: #4263eb;
  --tblr-status-color-rgb: 66, 99, 235;
}

.status-purple {
  --tblr-status-color: #ae3ec9;
  --tblr-status-color-rgb: 174, 62, 201;
}

.status-pink {
  --tblr-status-color: #d6336c;
  --tblr-status-color-rgb: 214, 51, 108;
}

.status-red {
  --tblr-status-color: #d63939;
  --tblr-status-color-rgb: 214, 57, 57;
}

.status-orange {
  --tblr-status-color: #f76707;
  --tblr-status-color-rgb: 247, 103, 7;
}

.status-yellow {
  --tblr-status-color: #f59f00;
  --tblr-status-color-rgb: 245, 159, 0;
}

.status-lime {
  --tblr-status-color: #74b816;
  --tblr-status-color-rgb: 116, 184, 22;
}

.status-green {
  --tblr-status-color: #2fb344;
  --tblr-status-color-rgb: 47, 179, 68;
}

.status-teal {
  --tblr-status-color: #0ca678;
  --tblr-status-color-rgb: 12, 166, 120;
}

.status-cyan {
  --tblr-status-color: #17a2b8;
  --tblr-status-color-rgb: 23, 162, 184;
}

.status-x {
  --tblr-status-color: #000;
  --tblr-status-color-rgb: 0, 0, 0;
}

.status-facebook {
  --tblr-status-color: #1877f2;
  --tblr-status-color-rgb: 24, 119, 242;
}

.status-twitter {
  --tblr-status-color: #1da1f2;
  --tblr-status-color-rgb: 29, 161, 242;
}

.status-linkedin {
  --tblr-status-color: #0a66c2;
  --tblr-status-color-rgb: 10, 102, 194;
}

.status-google {
  --tblr-status-color: #dc4e41;
  --tblr-status-color-rgb: 220, 78, 65;
}

.status-youtube {
  --tblr-status-color: red;
  --tblr-status-color-rgb: 255, 0, 0;
}

.status-vimeo {
  --tblr-status-color: #1ab7ea;
  --tblr-status-color-rgb: 26, 183, 234;
}

.status-dribbble {
  --tblr-status-color: #ea4c89;
  --tblr-status-color-rgb: 234, 76, 137;
}

.status-github {
  --tblr-status-color: #181717;
  --tblr-status-color-rgb: 24, 23, 23;
}

.status-instagram {
  --tblr-status-color: #e4405f;
  --tblr-status-color-rgb: 228, 64, 95;
}

.status-pinterest {
  --tblr-status-color: #bd081c;
  --tblr-status-color-rgb: 189, 8, 28;
}

.status-vk {
  --tblr-status-color: #6383a8;
  --tblr-status-color-rgb: 99, 131, 168;
}

.status-rss {
  --tblr-status-color: orange;
  --tblr-status-color-rgb: 255, 165, 0;
}

.status-flickr {
  --tblr-status-color: #0063dc;
  --tblr-status-color-rgb: 0, 99, 220;
}

.status-bitbucket {
  --tblr-status-color: #0052cc;
  --tblr-status-color-rgb: 0, 82, 204;
}

.status-tabler {
  --tblr-status-color: #066fd1;
  --tblr-status-color-rgb: 6, 111, 209;
}

.status-dot {
  --tblr-status-dot-color: var(--tblr-status-color, #6c7a91);
  --tblr-status-size: .5rem;
  width: var(--tblr-status-size);
  height: var(--tblr-status-size);
  background: var(--tblr-status-dot-color);
  border-radius: 100rem;
  display: inline-block;
  position: relative;
}

.status-dot-animated:before {
  content: "";
  z-index: 0;
  background: inherit;
  border-radius: inherit;
  opacity: .6;
  animation: 1s linear 2s infinite backwards status-pulsate-tertiary;
  position: absolute;
  inset: 0;
}

.status-indicator {
  --tblr-status-indicator-size: 2.5rem;
  --tblr-status-indicator-color: var(--tblr-status-color, #6c7a91);
  width: var(--tblr-status-indicator-size);
  height: var(--tblr-status-indicator-size);
  display: block;
  position: relative;
}

.status-indicator-circle {
  --tblr-status-circle-size: .75rem;
  margin: calc(var(--tblr-status-circle-size) / -2) 0 0 calc(var(--tblr-status-circle-size) / -2);
  width: var(--tblr-status-circle-size);
  height: var(--tblr-status-circle-size);
  background: var(--tblr-status-color);
  border-radius: 100rem;
  position: absolute;
  top: 50%;
  left: 50%;
}

.status-indicator-circle:first-child {
  z-index: 3;
}

.status-indicator-circle:nth-child(2) {
  z-index: 2;
  opacity: .1;
}

.status-indicator-circle:nth-child(3) {
  z-index: 1;
  opacity: .3;
}

.status-indicator-animated .status-indicator-circle:first-child {
  animation: 2s linear 1s infinite backwards status-pulsate-main;
}

.status-indicator-animated .status-indicator-circle:nth-child(2) {
  animation: 2s linear 1s infinite backwards status-pulsate-secondary;
}

.status-indicator-animated .status-indicator-circle:nth-child(3) {
  animation: 2s linear 1s infinite backwards status-pulsate-tertiary;
}

.switch-icon {
  vertical-align: bottom;
  cursor: pointer;
  background: none;
  border: 0;
  width: 1.25rem;
  height: 1.25rem;
  padding: 0;
  line-height: 1;
  display: inline-block;
  position: relative;
}

.switch-icon.disabled {
  pointer-events: none;
  opacity: .4;
}

.switch-icon:focus {
  outline: none;
}

.switch-icon svg, .switch-icon .switch-icon-a, .switch-icon .switch-icon-b {
  width: 100%;
  height: 100%;
  display: block;
}

.switch-icon .switch-icon-a {
  opacity: 1;
}

.switch-icon .switch-icon-b {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.switch-icon.active .switch-icon-a {
  opacity: 0;
}

.switch-icon.active .switch-icon-b {
  opacity: 1;
}

.switch-icon-fade .switch-icon-a, .switch-icon-fade .switch-icon-b {
  transition: opacity .5s;
}

@media (prefers-reduced-motion: reduce) {
  .switch-icon-fade .switch-icon-a, .switch-icon-fade .switch-icon-b {
    transition: none;
  }
}

.switch-icon-scale .switch-icon-a, .switch-icon-scale .switch-icon-b {
  transition: opacity .5s, transform 0s .5s;
}

@media (prefers-reduced-motion: reduce) {
  .switch-icon-scale .switch-icon-a, .switch-icon-scale .switch-icon-b {
    transition: none;
  }
}

.switch-icon-scale .switch-icon-b {
  transform: scale(1.5);
}

.switch-icon-scale.active .switch-icon-a, .switch-icon-scale.active .switch-icon-b {
  transition: opacity, transform .5s;
}

@media (prefers-reduced-motion: reduce) {
  .switch-icon-scale.active .switch-icon-a, .switch-icon-scale.active .switch-icon-b {
    transition: none;
  }
}

.switch-icon-scale.active .switch-icon-b {
  transform: scale(1);
}

.switch-icon-flip {
  perspective: 10em;
}

.switch-icon-flip .switch-icon-a, .switch-icon-flip .switch-icon-b {
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: opacity 0s .2s, transform .4s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .switch-icon-flip .switch-icon-a, .switch-icon-flip .switch-icon-b {
    transition: none;
  }
}

.switch-icon-flip .switch-icon-a {
  opacity: 1;
  transform: rotateY(0);
}

.switch-icon-flip .switch-icon-b {
  opacity: 1;
  transform: rotateY(-180deg);
}

.switch-icon-flip.active .switch-icon-a {
  opacity: 1;
  transform: rotateY(180deg);
}

.switch-icon-flip.active .switch-icon-b {
  opacity: 1;
  transform: rotateY(0);
}

.switch-icon-slide-up, .switch-icon-slide-left, .switch-icon-slide-right, .switch-icon-slide-down {
  overflow: hidden;
}

.switch-icon-slide-up .switch-icon-a, .switch-icon-slide-up .switch-icon-b, .switch-icon-slide-left .switch-icon-a, .switch-icon-slide-left .switch-icon-b, .switch-icon-slide-right .switch-icon-a, .switch-icon-slide-right .switch-icon-b, .switch-icon-slide-down .switch-icon-a, .switch-icon-slide-down .switch-icon-b {
  transition: opacity .3s, transform .3s;
}

@media (prefers-reduced-motion: reduce) {
  .switch-icon-slide-up .switch-icon-a, .switch-icon-slide-up .switch-icon-b, .switch-icon-slide-left .switch-icon-a, .switch-icon-slide-left .switch-icon-b, .switch-icon-slide-right .switch-icon-a, .switch-icon-slide-right .switch-icon-b, .switch-icon-slide-down .switch-icon-a, .switch-icon-slide-down .switch-icon-b {
    transition: none;
  }
}

.switch-icon-slide-up .switch-icon-a, .switch-icon-slide-left .switch-icon-a, .switch-icon-slide-right .switch-icon-a, .switch-icon-slide-down .switch-icon-a {
  transform: translateY(0);
}

.switch-icon-slide-up .switch-icon-b, .switch-icon-slide-left .switch-icon-b, .switch-icon-slide-right .switch-icon-b, .switch-icon-slide-down .switch-icon-b {
  transform: translateY(100%);
}

.switch-icon-slide-up.active .switch-icon-a, .switch-icon-slide-left.active .switch-icon-a, .switch-icon-slide-right.active .switch-icon-a, .switch-icon-slide-down.active .switch-icon-a {
  transform: translateY(-100%);
}

.switch-icon-slide-up.active .switch-icon-b, .switch-icon-slide-left.active .switch-icon-b, .switch-icon-slide-right.active .switch-icon-b, .switch-icon-slide-down.active .switch-icon-b {
  transform: translateY(0);
}

.switch-icon-slide-left .switch-icon-a {
  transform: translateX(0);
}

.switch-icon-slide-left .switch-icon-b {
  transform: translateX(100%);
}

.switch-icon-slide-left.active .switch-icon-a {
  transform: translateX(-100%);
}

.switch-icon-slide-left.active .switch-icon-b, .switch-icon-slide-right .switch-icon-a {
  transform: translateX(0);
}

.switch-icon-slide-right .switch-icon-b {
  transform: translateX(-100%);
}

.switch-icon-slide-right.active .switch-icon-a {
  transform: translateX(100%);
}

.switch-icon-slide-right.active .switch-icon-b {
  transform: translateX(0);
}

.switch-icon-slide-down .switch-icon-a {
  transform: translateY(0);
}

.switch-icon-slide-down .switch-icon-b {
  transform: translateY(-100%);
}

.switch-icon-slide-down.active .switch-icon-a {
  transform: translateY(100%);
}

.switch-icon-slide-down.active .switch-icon-b {
  transform: translateY(0);
}

.table thead th, .markdown > table thead th {
  background: var(--tblr-bg-surface-tertiary);
  font-size: .75rem;
  font-weight: var(--tblr-font-weight-bold);
  text-transform: uppercase;
  letter-spacing: .04em;
  color: var(--tblr-secondary);
  white-space: nowrap;
  padding-top: .5rem;
  padding-bottom: .5rem;
  line-height: 1rem;
}

@media print {
  .table thead th, .markdown > table thead th {
    background: none;
  }
}

.table-responsive .table, .table-responsive .markdown > table {
  margin-bottom: 0;
}

.table-responsive + .card-footer {
  border-top: 0;
}

.table-transparent thead th {
  background: none;
}

.table-nowrap > :not(caption) > * > * {
  white-space: nowrap;
}

.table-vcenter > :not(caption) > * > * {
  vertical-align: middle;
}

.table-center > :not(caption) > * > * {
  text-align: center;
}

.td-truncate {
  width: 100%;
  max-width: 1px;
}

.table-mobile {
  display: block;
}

.table-mobile thead {
  display: none;
}

.table-mobile tbody, .table-mobile tr {
  flex-direction: column;
  display: flex;
}

.table-mobile td {
  border: none;
  display: block;
  color: var(--tblr-body-color) !important;
  padding: .75rem !important;
}

.table-mobile td[data-label]:before {
  font-size: .75rem;
  font-weight: var(--tblr-font-weight-bold);
  text-transform: uppercase;
  letter-spacing: .04em;
  color: var(--tblr-secondary);
  content: attr(data-label);
  line-height: 1rem;
  display: block;
}

.table-mobile tr {
  border-bottom: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent);
}

.table-mobile .btn {
  display: block;
}

@media (width <= 575.98px) {
  .table-mobile-sm {
    display: block;
  }

  .table-mobile-sm thead {
    display: none;
  }

  .table-mobile-sm tbody, .table-mobile-sm tr {
    flex-direction: column;
    display: flex;
  }

  .table-mobile-sm td {
    border: none;
    display: block;
    color: var(--tblr-body-color) !important;
    padding: .75rem !important;
  }

  .table-mobile-sm td[data-label]:before {
    font-size: .75rem;
    font-weight: var(--tblr-font-weight-bold);
    text-transform: uppercase;
    letter-spacing: .04em;
    color: var(--tblr-secondary);
    content: attr(data-label);
    line-height: 1rem;
    display: block;
  }

  .table-mobile-sm tr {
    border-bottom: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent);
  }

  .table-mobile-sm .btn {
    display: block;
  }
}

@media (width <= 767.98px) {
  .table-mobile-md {
    display: block;
  }

  .table-mobile-md thead {
    display: none;
  }

  .table-mobile-md tbody, .table-mobile-md tr {
    flex-direction: column;
    display: flex;
  }

  .table-mobile-md td {
    border: none;
    display: block;
    color: var(--tblr-body-color) !important;
    padding: .75rem !important;
  }

  .table-mobile-md td[data-label]:before {
    font-size: .75rem;
    font-weight: var(--tblr-font-weight-bold);
    text-transform: uppercase;
    letter-spacing: .04em;
    color: var(--tblr-secondary);
    content: attr(data-label);
    line-height: 1rem;
    display: block;
  }

  .table-mobile-md tr {
    border-bottom: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent);
  }

  .table-mobile-md .btn {
    display: block;
  }
}

@media (width <= 991.98px) {
  .table-mobile-lg {
    display: block;
  }

  .table-mobile-lg thead {
    display: none;
  }

  .table-mobile-lg tbody, .table-mobile-lg tr {
    flex-direction: column;
    display: flex;
  }

  .table-mobile-lg td {
    border: none;
    display: block;
    color: var(--tblr-body-color) !important;
    padding: .75rem !important;
  }

  .table-mobile-lg td[data-label]:before {
    font-size: .75rem;
    font-weight: var(--tblr-font-weight-bold);
    text-transform: uppercase;
    letter-spacing: .04em;
    color: var(--tblr-secondary);
    content: attr(data-label);
    line-height: 1rem;
    display: block;
  }

  .table-mobile-lg tr {
    border-bottom: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent);
  }

  .table-mobile-lg .btn {
    display: block;
  }
}

@media (width <= 1199.98px) {
  .table-mobile-xl {
    display: block;
  }

  .table-mobile-xl thead {
    display: none;
  }

  .table-mobile-xl tbody, .table-mobile-xl tr {
    flex-direction: column;
    display: flex;
  }

  .table-mobile-xl td {
    border: none;
    display: block;
    color: var(--tblr-body-color) !important;
    padding: .75rem !important;
  }

  .table-mobile-xl td[data-label]:before {
    font-size: .75rem;
    font-weight: var(--tblr-font-weight-bold);
    text-transform: uppercase;
    letter-spacing: .04em;
    color: var(--tblr-secondary);
    content: attr(data-label);
    line-height: 1rem;
    display: block;
  }

  .table-mobile-xl tr {
    border-bottom: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent);
  }

  .table-mobile-xl .btn {
    display: block;
  }
}

@media (width <= 1399.98px) {
  .table-mobile-xxl {
    display: block;
  }

  .table-mobile-xxl thead {
    display: none;
  }

  .table-mobile-xxl tbody, .table-mobile-xxl tr {
    flex-direction: column;
    display: flex;
  }

  .table-mobile-xxl td {
    border: none;
    display: block;
    color: var(--tblr-body-color) !important;
    padding: .75rem !important;
  }

  .table-mobile-xxl td[data-label]:before {
    font-size: .75rem;
    font-weight: var(--tblr-font-weight-bold);
    text-transform: uppercase;
    letter-spacing: .04em;
    color: var(--tblr-secondary);
    content: attr(data-label);
    line-height: 1rem;
    display: block;
  }

  .table-mobile-xxl tr {
    border-bottom: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent);
  }

  .table-mobile-xxl .btn {
    display: block;
  }
}

.table-sort {
  font: inherit;
  color: inherit;
  text-transform: inherit;
  letter-spacing: inherit;
  background: inherit;
  width: 100%;
  text-align: inherit;
  border: 0;
  margin: -.5rem -.75rem;
  padding: .5rem .75rem;
  transition: color .3s;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .table-sort {
    transition: none;
  }
}

.table-sort:hover, .table-sort.asc, .table-sort.desc {
  color: var(--tblr-body-color);
}

.table-sort:after {
  content: "";
  vertical-align: bottom;
  background: currentColor;
  width: 1rem;
  height: 1rem;
  margin-left: .25rem;
  display: inline-flex;
  mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'><path d='M5 7l3 -3l3 3'/><path d='M5 10l3 3l3 -3'/></svg>");
}

.table-sort.asc:after {
  mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16'><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M5 10l3 -3l3 3'/></svg>");
}

.table-sort.desc:after {
  mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16'><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M5 7l3 3l3 -3'/></svg>");
}

.table-borderless thead th {
  background: none;
}

.tag {
  --tblr-tag-height: 1.5rem;
  border: 1px solid var(--tblr-border-color);
  height: var(--tblr-tag-height);
  border-radius: var(--tblr-border-radius);
  background: var(--tblr-bg-surface);
  box-shadow: var(--tblr-box-shadow-input);
  align-items: center;
  gap: .5rem;
  padding: 0 .5rem;
  display: inline-flex;
}

.tag .btn-close {
  width: 1rem;
  height: 1rem;
  margin-left: -.125rem;
  margin-right: -.25rem;
  padding: 0;
  font-size: .5rem;
}

.tag-badge {
  --tblr-badge-font-size: .625rem;
  --tblr-badge-padding-x: .25rem;
  --tblr-badge-padding-y: .125rem;
  margin-right: -.25rem;
}

.tag-avatar, .tag-flag, .tag-payment, .tag-icon, .tag-check {
  margin-left: -.25rem;
}

.tag-icon {
  color: var(--tblr-secondary);
  width: 1rem;
  height: 1rem;
  margin-right: -.125rem;
}

.tag-check {
  background-size: 1rem;
  width: 1rem;
  height: 1rem;
}

.tags-list {
  --tblr-list-gap: .5rem;
  gap: var(--tblr-list-gap);
  flex-wrap: wrap;
  display: flex;
}

.toast {
  border: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color-translucent);
  box-shadow: 0 2px 4px #1824330a;
}

.toast .toast-header {
  -webkit-user-select: none;
  user-select: none;
}

.toast button[data-bs-dismiss="toast"] {
  outline: none;
}

.toast-primary {
  --tblr-toast-color: #066fd1;
}

.toast-secondary {
  --tblr-toast-color: #6c7a91;
}

.toast-success {
  --tblr-toast-color: #2fb344;
}

.toast-info {
  --tblr-toast-color: #4299e1;
}

.toast-warning {
  --tblr-toast-color: #f76707;
}

.toast-danger {
  --tblr-toast-color: #d63939;
}

.toast-light {
  --tblr-toast-color: #f6f8fb;
}

.toast-dark {
  --tblr-toast-color: #182433;
}

.toast-muted {
  --tblr-toast-color: #6c7a91;
}

.toast-blue {
  --tblr-toast-color: #066fd1;
}

.toast-azure {
  --tblr-toast-color: #4299e1;
}

.toast-indigo {
  --tblr-toast-color: #4263eb;
}

.toast-purple {
  --tblr-toast-color: #ae3ec9;
}

.toast-pink {
  --tblr-toast-color: #d6336c;
}

.toast-red {
  --tblr-toast-color: #d63939;
}

.toast-orange {
  --tblr-toast-color: #f76707;
}

.toast-yellow {
  --tblr-toast-color: #f59f00;
}

.toast-lime {
  --tblr-toast-color: #74b816;
}

.toast-green {
  --tblr-toast-color: #2fb344;
}

.toast-teal {
  --tblr-toast-color: #0ca678;
}

.toast-cyan {
  --tblr-toast-color: #17a2b8;
}

.toast-x {
  --tblr-toast-color: #000;
}

.toast-facebook {
  --tblr-toast-color: #1877f2;
}

.toast-twitter {
  --tblr-toast-color: #1da1f2;
}

.toast-linkedin {
  --tblr-toast-color: #0a66c2;
}

.toast-google {
  --tblr-toast-color: #dc4e41;
}

.toast-youtube {
  --tblr-toast-color: red;
}

.toast-vimeo {
  --tblr-toast-color: #1ab7ea;
}

.toast-dribbble {
  --tblr-toast-color: #ea4c89;
}

.toast-github {
  --tblr-toast-color: #181717;
}

.toast-instagram {
  --tblr-toast-color: #e4405f;
}

.toast-pinterest {
  --tblr-toast-color: #bd081c;
}

.toast-vk {
  --tblr-toast-color: #6383a8;
}

.toast-rss {
  --tblr-toast-color: orange;
}

.toast-flickr {
  --tblr-toast-color: #0063dc;
}

.toast-bitbucket {
  --tblr-toast-color: #0052cc;
}

.toast-tabler {
  --tblr-toast-color: #066fd1;
}

.toolbar {
  flex-wrap: nowrap;
  flex-shrink: 0;
  margin: 0 -.5rem;
  display: flex;
}

.toolbar > * {
  margin: 0 .5rem;
}

.tracking {
  --tblr-tracking-height: 1.5rem;
  --tblr-tracking-gap-width: .125rem;
  --tblr-tracking-block-border-radius: var(--tblr-border-radius);
  gap: var(--tblr-tracking-gap-width);
  display: flex;
}

.tracking-squares {
  --tblr-tracking-block-border-radius: var(--tblr-border-radius-sm);
}

.tracking-squares .tracking-block {
  height: auto;
}

.tracking-squares .tracking-block:before {
  content: "";
  padding-top: 100%;
  display: block;
}

.tracking-block {
  border-radius: var(--tblr-tracking-block-border-radius);
  height: var(--tblr-tracking-height);
  background: var(--tblr-border-color);
  flex: 1;
  min-width: .25rem;
}

.timeline {
  --tblr-timeline-icon-size: 2.5rem;
  padding: 0;
  list-style: none;
  position: relative;
}

.timeline-event {
  position: relative;
}

.timeline-event:not(:last-child) {
  margin-bottom: var(--tblr-page-padding);
}

.timeline-event:not(:last-child):before {
  content: "";
  top: var(--tblr-timeline-icon-size);
  left: calc(var(--tblr-timeline-icon-size) / 2);
  bottom: calc(-1 * var(--tblr-page-padding));
  width: var(--tblr-border-width);
  background-color: var(--tblr-border-color);
  border-radius: var(--tblr-border-radius);
  position: absolute;
}

.timeline-event-icon {
  width: var(--tblr-timeline-icon-size, 2.5rem);
  height: var(--tblr-timeline-icon-size, 2.5rem);
  background: var(--tblr-gray-200);
  color: var(--tblr-secondary);
  border-radius: var(--tblr-border-radius);
  z-index: 5;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.timeline-event-card {
  margin-left: calc(var(--tblr-timeline-icon-size, 2.5rem)  + var(--tblr-page-padding));
}

.timeline-simple .timeline-event-icon {
  display: none;
}

.timeline-simple .timeline-event-card {
  margin-left: 0;
}

.hr-text {
  font-size: .75rem;
  font-weight: var(--tblr-font-weight-bold);
  text-transform: uppercase;
  letter-spacing: .04em;
  color: var(--tblr-secondary);
  align-items: center;
  height: 1px;
  margin: 2rem 0;
  line-height: 1rem;
  display: flex;
}

.hr-text:after, .hr-text:before {
  background-color: var(--tblr-border-color);
  flex: auto;
  height: 1px;
}

.hr-text:before {
  content: "";
  margin-right: .5rem;
}

.hr-text:after {
  content: "";
  margin-left: .5rem;
}

.hr-text > :first-child {
  color: var(--tblr-secondary);
  padding-left: 0;
  padding-right: .5rem;
}

.hr-text.hr-text-left:before, .hr-text.hr-text-start:before {
  content: none;
}

.hr-text.hr-text-left > :first-child, .hr-text.hr-text-start > :first-child {
  padding-left: .5rem;
  padding-right: .5rem;
}

.hr-text.hr-text-right:before, .hr-text.hr-text-end:before {
  content: "";
}

.hr-text.hr-text-right:after, .hr-text.hr-text-end:after {
  content: none;
}

.hr-text.hr-text-right > :first-child, .hr-text.hr-text-end > :first-child {
  padding-left: .5rem;
  padding-right: 0;
}

.card > .hr-text {
  margin: 0;
}

.hr-text-spaceless {
  margin: -.5rem 0;
}

.lead {
  line-height: 1.4;
}

a {
  -webkit-text-decoration-skip: ink;
  text-decoration-skip-ink: auto;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, .h1 a, .h2 a, .h3 a, .h4 a, .h5 a, .h6 a, h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover, .h1 a:hover, .h2 a:hover, .h3 a:hover, .h4 a:hover, .h5 a:hover, .h6 a:hover {
  color: inherit;
}

h1, .h1 {
  font-size: var(--tblr-font-size-h1);
  line-height: var(--tblr-line-height-h1);
}

h2, .h2 {
  font-size: var(--tblr-font-size-h2);
  line-height: var(--tblr-line-height-h2);
}

h3, .h3 {
  font-size: var(--tblr-font-size-h3);
  line-height: var(--tblr-line-height-h3);
}

h4, .h4 {
  font-size: var(--tblr-font-size-h4);
  line-height: var(--tblr-line-height-h4);
}

h5, .h5 {
  font-size: var(--tblr-font-size-h5);
  line-height: var(--tblr-line-height-h5);
}

h6, .h6 {
  font-size: var(--tblr-font-size-h6);
  line-height: var(--tblr-line-height-h6);
}

strong, .strong, b {
  font-weight: var(--tblr-font-weight-bold);
}

blockquote {
  border-left: 2px var(--tblr-border-style) var(--tblr-border-color);
  padding-left: 1rem;
}

blockquote p {
  margin-bottom: 1rem;
}

blockquote cite {
  text-align: right;
  display: block;
}

blockquote cite:before {
  content: "— ";
}

ul, ol {
  padding-left: 1.5rem;
}

hr, .hr {
  margin: 2rem 0;
}

dl dd:last-child {
  margin-bottom: 0;
}

pre {
  background: var(--tblr-bg-surface-dark);
  color: var(--tblr-light);
  border-radius: var(--tblr-border-radius);
  padding: 1rem;
}

pre code {
  background: none;
  padding: 0;
}

code {
  background: var(--tblr-code-bg);
  border-radius: var(--tblr-border-radius);
  padding: 2px 4px;
}

kbd, .kbd {
  border: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color);
  box-sizing: border-box;
  max-width: 100%;
  font-size: var(--tblr-font-size-h5);
  font-weight: var(--tblr-font-weight-medium);
  vertical-align: baseline;
  border-radius: var(--tblr-border-radius);
  line-height: 1;
  display: inline-block;
}

img {
  max-width: 100%;
  height: auto;
}

.list-unstyled {
  margin-left: 0;
}

::selection {
  background-color: rgba(var(--tblr-primary-rgb), .16);
}

[class^="link-"].disabled, [class*=" link-"].disabled {
  pointer-events: none;
  color: var(--tblr-disabled-color) !important;
}

a:hover:has(.icon) {
  text-decoration: none;
}

.subheader {
  font-size: .75rem;
  font-weight: var(--tblr-font-weight-bold);
  text-transform: uppercase;
  letter-spacing: .04em;
  color: var(--tblr-secondary);
  line-height: 1rem;
}

.chart {
  min-height: 10rem;
  display: block;
}

.chart text {
  font-family: inherit;
}

.chart-sm {
  height: 2.5rem;
}

.chart-lg {
  height: 15rem;
}

.chart-square {
  height: 5.75rem;
}

.chart-sparkline {
  width: 4rem;
  height: 2.5rem;
  line-height: 1;
  position: relative;
  min-height: 0 !important;
}

.chart-sparkline-sm {
  height: 1.5rem;
}

.chart-sparkline-square {
  width: 2.5rem;
}

.chart-sparkline-wide {
  width: 6rem;
}

.chart-sparkline-label {
  justify-content: center;
  align-items: center;
  font-size: .625rem;
  display: flex;
  position: absolute;
  inset: 0;
}

.chart-sparkline-label .icon {
  width: 1rem;
  height: 1rem;
  font-size: 1rem;
}

.offcanvas-header {
  border-bottom: var(--tblr-border-width) var(--tblr-border-style) #0420451a;
}

.offcanvas-footer {
  padding: 1.5rem;
}

.offcanvas-title {
  font-size: 1rem;
  font-weight: var(--tblr-font-weight-medium);
  line-height: 1.5rem;
}

.offcanvas-narrow {
  width: 20rem;
}

.chat-bubbles {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.chat-bubble {
  background: var(--tblr-bg-surface-secondary);
  border-radius: var(--tblr-border-radius-lg);
  padding: 1rem;
  position: relative;
}

.chat-bubble-me {
  background-color: var(--tblr-primary-lt);
  box-shadow: none;
}

.chat-bubble-title {
  margin-bottom: .25rem;
}

.chat-bubble-author {
  font-weight: 600;
}

.chat-bubble-date {
  color: var(--tblr-secondary);
}

.chat-bubble-body > :last-child {
  margin-bottom: 0;
}

.bg-white-overlay {
  color: #fff;
  background-color: #f6f8fb3d;
}

.bg-dark-overlay {
  color: #fff;
  background-color: #1824333d;
}

.bg-cover {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-primary {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-primary-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-primary-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-primary-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-primary-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-primary {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-primary-rgb), var(--tblr-border-opacity)) !important;
}

.bg-secondary {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-secondary-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-secondary-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-secondary-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-secondary-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-secondary {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-secondary-rgb), var(--tblr-border-opacity)) !important;
}

.bg-success {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-success-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-success-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-success-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-success-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-success {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-success-rgb), var(--tblr-border-opacity)) !important;
}

.bg-info {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-info-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-info-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-info-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-info-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-info {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-info-rgb), var(--tblr-border-opacity)) !important;
}

.bg-warning {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-warning-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-warning-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-warning-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-warning-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-warning {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-warning-rgb), var(--tblr-border-opacity)) !important;
}

.bg-danger {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-danger-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-danger-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-danger-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-danger-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-danger {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-danger-rgb), var(--tblr-border-opacity)) !important;
}

.bg-light {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-light-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-light-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-light-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-light-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-light {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-light-rgb), var(--tblr-border-opacity)) !important;
}

.bg-dark {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-dark-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-dark-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-dark-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-dark-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-dark {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-dark-rgb), var(--tblr-border-opacity)) !important;
}

.bg-muted {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-muted-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-muted-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-muted-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-muted-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-muted {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-muted-rgb), var(--tblr-border-opacity)) !important;
}

.bg-blue {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-blue-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-blue-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-blue-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-blue-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-blue {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-blue-rgb), var(--tblr-border-opacity)) !important;
}

.bg-azure {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-azure-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-azure-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-azure-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-azure-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-azure {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-azure-rgb), var(--tblr-border-opacity)) !important;
}

.bg-indigo {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-indigo-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-indigo-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-indigo-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-indigo-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-indigo {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-indigo-rgb), var(--tblr-border-opacity)) !important;
}

.bg-purple {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-purple-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-purple-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-purple-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-purple-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-purple {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-purple-rgb), var(--tblr-border-opacity)) !important;
}

.bg-pink {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-pink-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-pink-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-pink-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-pink-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-pink {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-pink-rgb), var(--tblr-border-opacity)) !important;
}

.bg-red {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-red-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-red-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-red-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-red-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-red {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-red-rgb), var(--tblr-border-opacity)) !important;
}

.bg-orange {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-orange-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-orange-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-orange-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-orange-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-orange {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-orange-rgb), var(--tblr-border-opacity)) !important;
}

.bg-yellow {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-yellow-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-yellow-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-yellow-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-yellow-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-yellow {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-yellow-rgb), var(--tblr-border-opacity)) !important;
}

.bg-lime {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-lime-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-lime-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-lime-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-lime-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-lime {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-lime-rgb), var(--tblr-border-opacity)) !important;
}

.bg-green {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-green-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-green-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-green-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-green-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-green {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-green-rgb), var(--tblr-border-opacity)) !important;
}

.bg-teal {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-teal-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-teal-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-teal-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-teal-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-teal {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-teal-rgb), var(--tblr-border-opacity)) !important;
}

.bg-cyan {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-cyan-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-cyan-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-cyan-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-cyan-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-cyan {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-cyan-rgb), var(--tblr-border-opacity)) !important;
}

.bg-x {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-x-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-x-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-x-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-x-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-x {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-x-rgb), var(--tblr-border-opacity)) !important;
}

.bg-facebook {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-facebook-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-facebook-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-facebook-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-facebook-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-facebook {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-facebook-rgb), var(--tblr-border-opacity)) !important;
}

.bg-twitter {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-twitter-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-twitter-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-twitter-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-twitter-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-twitter {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-twitter-rgb), var(--tblr-border-opacity)) !important;
}

.bg-linkedin {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-linkedin-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-linkedin-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-linkedin-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-linkedin-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-linkedin {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-linkedin-rgb), var(--tblr-border-opacity)) !important;
}

.bg-google {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-google-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-google-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-google-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-google-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-google {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-google-rgb), var(--tblr-border-opacity)) !important;
}

.bg-youtube {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-youtube-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-youtube-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-youtube-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-youtube-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-youtube {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-youtube-rgb), var(--tblr-border-opacity)) !important;
}

.bg-vimeo {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-vimeo-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-vimeo-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-vimeo-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-vimeo-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-vimeo {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-vimeo-rgb), var(--tblr-border-opacity)) !important;
}

.bg-dribbble {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-dribbble-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-dribbble-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-dribbble-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-dribbble-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-dribbble {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-dribbble-rgb), var(--tblr-border-opacity)) !important;
}

.bg-github {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-github-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-github-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-github-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-github-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-github {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-github-rgb), var(--tblr-border-opacity)) !important;
}

.bg-instagram {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-instagram-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-instagram-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-instagram-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-instagram-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-instagram {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-instagram-rgb), var(--tblr-border-opacity)) !important;
}

.bg-pinterest {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-pinterest-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-pinterest-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-pinterest-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-pinterest-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-pinterest {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-pinterest-rgb), var(--tblr-border-opacity)) !important;
}

.bg-vk {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-vk-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-vk-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-vk-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-vk-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-vk {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-vk-rgb), var(--tblr-border-opacity)) !important;
}

.bg-rss {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-rss-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-rss-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-rss-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-rss-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-rss {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-rss-rgb), var(--tblr-border-opacity)) !important;
}

.bg-flickr {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-flickr-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-flickr-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-flickr-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-flickr-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-flickr {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-flickr-rgb), var(--tblr-border-opacity)) !important;
}

.bg-bitbucket {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-bitbucket-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-bitbucket-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-bitbucket-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-bitbucket-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-bitbucket {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-bitbucket-rgb), var(--tblr-border-opacity)) !important;
}

.bg-tabler {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-tabler-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-tabler-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-tabler-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-tabler-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-tabler {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-tabler-rgb), var(--tblr-border-opacity)) !important;
}

.bg-white {
  --tblr-bg-opacity: 1;
  background-color: rgba(var(--tblr-white-rgb), var(--tblr-bg-opacity)) !important;
}

.bg-white-lt {
  --tblr-bg-opacity: 1;
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-white-rgb), var(--tblr-text-opacity)) !important;
  background-color: rgba(var(--tblr-white-lt-rgb), var(--tblr-bg-opacity)) !important;
}

.border-white {
  --tblr-border-opacity: 1;
  border-color: rgba(var(--tblr-white-rgb), var(--tblr-border-opacity)) !important;
}

.text-primary {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-primary-rgb), var(--tblr-text-opacity)) !important;
}

.text-primary-fg {
  color: var(--tblr-primary-fg) !important;
}

.text-secondary {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-secondary-rgb), var(--tblr-text-opacity)) !important;
}

.text-secondary-fg {
  color: var(--tblr-secondary-fg) !important;
}

.text-success {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-success-rgb), var(--tblr-text-opacity)) !important;
}

.text-success-fg {
  color: var(--tblr-success-fg) !important;
}

.text-info {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-info-rgb), var(--tblr-text-opacity)) !important;
}

.text-info-fg {
  color: var(--tblr-info-fg) !important;
}

.text-warning {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-warning-rgb), var(--tblr-text-opacity)) !important;
}

.text-warning-fg {
  color: var(--tblr-warning-fg) !important;
}

.text-danger {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-danger-rgb), var(--tblr-text-opacity)) !important;
}

.text-danger-fg {
  color: var(--tblr-danger-fg) !important;
}

.text-light {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-light-rgb), var(--tblr-text-opacity)) !important;
}

.text-light-fg {
  color: var(--tblr-light-fg) !important;
}

.text-dark {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-dark-rgb), var(--tblr-text-opacity)) !important;
}

.text-dark-fg {
  color: var(--tblr-dark-fg) !important;
}

.text-muted {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-muted-rgb), var(--tblr-text-opacity)) !important;
}

.text-muted-fg {
  color: var(--tblr-muted-fg) !important;
}

.text-blue {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-blue-rgb), var(--tblr-text-opacity)) !important;
}

.text-blue-fg {
  color: var(--tblr-blue-fg) !important;
}

.text-azure {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-azure-rgb), var(--tblr-text-opacity)) !important;
}

.text-azure-fg {
  color: var(--tblr-azure-fg) !important;
}

.text-indigo {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-indigo-rgb), var(--tblr-text-opacity)) !important;
}

.text-indigo-fg {
  color: var(--tblr-indigo-fg) !important;
}

.text-purple {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-purple-rgb), var(--tblr-text-opacity)) !important;
}

.text-purple-fg {
  color: var(--tblr-purple-fg) !important;
}

.text-pink {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-pink-rgb), var(--tblr-text-opacity)) !important;
}

.text-pink-fg {
  color: var(--tblr-pink-fg) !important;
}

.text-red {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-red-rgb), var(--tblr-text-opacity)) !important;
}

.text-red-fg {
  color: var(--tblr-red-fg) !important;
}

.text-orange {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-orange-rgb), var(--tblr-text-opacity)) !important;
}

.text-orange-fg {
  color: var(--tblr-orange-fg) !important;
}

.text-yellow {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-yellow-rgb), var(--tblr-text-opacity)) !important;
}

.text-yellow-fg {
  color: var(--tblr-yellow-fg) !important;
}

.text-lime {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-lime-rgb), var(--tblr-text-opacity)) !important;
}

.text-lime-fg {
  color: var(--tblr-lime-fg) !important;
}

.text-green {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-green-rgb), var(--tblr-text-opacity)) !important;
}

.text-green-fg {
  color: var(--tblr-green-fg) !important;
}

.text-teal {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-teal-rgb), var(--tblr-text-opacity)) !important;
}

.text-teal-fg {
  color: var(--tblr-teal-fg) !important;
}

.text-cyan {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-cyan-rgb), var(--tblr-text-opacity)) !important;
}

.text-cyan-fg {
  color: var(--tblr-cyan-fg) !important;
}

.text-x {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-x-rgb), var(--tblr-text-opacity)) !important;
}

.text-x-fg {
  color: var(--tblr-x-fg) !important;
}

.text-facebook {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-facebook-rgb), var(--tblr-text-opacity)) !important;
}

.text-facebook-fg {
  color: var(--tblr-facebook-fg) !important;
}

.text-twitter {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-twitter-rgb), var(--tblr-text-opacity)) !important;
}

.text-twitter-fg {
  color: var(--tblr-twitter-fg) !important;
}

.text-linkedin {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-linkedin-rgb), var(--tblr-text-opacity)) !important;
}

.text-linkedin-fg {
  color: var(--tblr-linkedin-fg) !important;
}

.text-google {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-google-rgb), var(--tblr-text-opacity)) !important;
}

.text-google-fg {
  color: var(--tblr-google-fg) !important;
}

.text-youtube {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-youtube-rgb), var(--tblr-text-opacity)) !important;
}

.text-youtube-fg {
  color: var(--tblr-youtube-fg) !important;
}

.text-vimeo {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-vimeo-rgb), var(--tblr-text-opacity)) !important;
}

.text-vimeo-fg {
  color: var(--tblr-vimeo-fg) !important;
}

.text-dribbble {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-dribbble-rgb), var(--tblr-text-opacity)) !important;
}

.text-dribbble-fg {
  color: var(--tblr-dribbble-fg) !important;
}

.text-github {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-github-rgb), var(--tblr-text-opacity)) !important;
}

.text-github-fg {
  color: var(--tblr-github-fg) !important;
}

.text-instagram {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-instagram-rgb), var(--tblr-text-opacity)) !important;
}

.text-instagram-fg {
  color: var(--tblr-instagram-fg) !important;
}

.text-pinterest {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-pinterest-rgb), var(--tblr-text-opacity)) !important;
}

.text-pinterest-fg {
  color: var(--tblr-pinterest-fg) !important;
}

.text-vk {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-vk-rgb), var(--tblr-text-opacity)) !important;
}

.text-vk-fg {
  color: var(--tblr-vk-fg) !important;
}

.text-rss {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-rss-rgb), var(--tblr-text-opacity)) !important;
}

.text-rss-fg {
  color: var(--tblr-rss-fg) !important;
}

.text-flickr {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-flickr-rgb), var(--tblr-text-opacity)) !important;
}

.text-flickr-fg {
  color: var(--tblr-flickr-fg) !important;
}

.text-bitbucket {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-bitbucket-rgb), var(--tblr-text-opacity)) !important;
}

.text-bitbucket-fg {
  color: var(--tblr-bitbucket-fg) !important;
}

.text-tabler {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-tabler-rgb), var(--tblr-text-opacity)) !important;
}

.text-tabler-fg {
  color: var(--tblr-tabler-fg) !important;
}

.bg-gray-50 {
  --tblr-bg-opacity: .1;
  background-color: rgba(var(--tblr-gray-50-rgb), var(--tblr-bg-opacity)) !important;
}

.text-gray-50-fg {
  color: #f6f8fb !important;
}

.bg-gray-100 {
  --tblr-bg-opacity: .1;
  background-color: rgba(var(--tblr-gray-100-rgb), var(--tblr-bg-opacity)) !important;
}

.text-gray-100-fg {
  color: #eef3f6 !important;
}

.bg-gray-200 {
  --tblr-bg-opacity: .1;
  background-color: rgba(var(--tblr-gray-200-rgb), var(--tblr-bg-opacity)) !important;
}

.text-gray-200-fg {
  color: #dce1e7 !important;
}

.bg-gray-300 {
  --tblr-bg-opacity: .1;
  background-color: rgba(var(--tblr-gray-300-rgb), var(--tblr-bg-opacity)) !important;
}

.text-gray-300-fg {
  color: #b8c4d4 !important;
}

.bg-gray-400 {
  --tblr-bg-opacity: .1;
  background-color: rgba(var(--tblr-gray-400-rgb), var(--tblr-bg-opacity)) !important;
}

.text-gray-400-fg {
  color: #8a97ab !important;
}

.bg-gray-500 {
  --tblr-bg-opacity: .1;
  background-color: rgba(var(--tblr-gray-500-rgb), var(--tblr-bg-opacity)) !important;
}

.text-gray-500-fg {
  color: #6c7a91 !important;
}

.bg-gray-600 {
  --tblr-bg-opacity: .1;
  background-color: rgba(var(--tblr-gray-600-rgb), var(--tblr-bg-opacity)) !important;
}

.text-gray-600-fg {
  color: #49566c !important;
}

.bg-gray-700 {
  --tblr-bg-opacity: .1;
  background-color: rgba(var(--tblr-gray-700-rgb), var(--tblr-bg-opacity)) !important;
}

.text-gray-700-fg {
  color: #3a4859 !important;
}

.bg-gray-800 {
  --tblr-bg-opacity: .1;
  background-color: rgba(var(--tblr-gray-800-rgb), var(--tblr-bg-opacity)) !important;
}

.text-gray-800-fg {
  color: #182433 !important;
}

.bg-gray-900 {
  --tblr-bg-opacity: .1;
  background-color: rgba(var(--tblr-gray-900-rgb), var(--tblr-bg-opacity)) !important;
}

.text-gray-900-fg {
  color: #040a11 !important;
}

.scrollable {
  -webkit-overflow-scrolling: touch;
  overflow: hidden auto;
}

.scrollable.hover {
  overflow-y: hidden;
}

.scrollable.hover > * {
  margin-top: -1px;
}

.scrollable.hover:hover, .scrollable.hover:focus, .scrollable.hover:active {
  overflow: visible auto;
}

.touch .scrollable {
  overflow-y: auto !important;
}

.scroll-x, .scroll-y {
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
}

.scroll-y {
  overflow-y: auto;
}

.scroll-x {
  overflow-x: auto;
}

.no-scroll {
  overflow: hidden;
}

.w-0 {
  width: 0 !important;
}

.h-0 {
  height: 0 !important;
}

.w-1 {
  width: .25rem !important;
}

.h-1 {
  height: .25rem !important;
}

.w-2 {
  width: .5rem !important;
}

.h-2 {
  height: .5rem !important;
}

.w-3 {
  width: 1rem !important;
}

.h-3 {
  height: 1rem !important;
}

.w-4 {
  width: 1.5rem !important;
}

.h-4 {
  height: 1.5rem !important;
}

.w-5 {
  width: 2rem !important;
}

.h-5 {
  height: 2rem !important;
}

.w-6 {
  width: 2.5rem !important;
}

.h-6 {
  height: 2.5rem !important;
}

.w-auto {
  width: auto !important;
}

.h-auto {
  height: auto !important;
}

.w-px {
  width: 1px !important;
}

.h-px {
  height: 1px !important;
}

.w-full {
  width: 100% !important;
}

.h-full {
  height: 100% !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-5 {
  opacity: .05 !important;
}

.opacity-10 {
  opacity: .1 !important;
}

.opacity-15 {
  opacity: .15 !important;
}

.opacity-20 {
  opacity: .2 !important;
}

.opacity-25 {
  opacity: .25 !important;
}

.opacity-30 {
  opacity: .3 !important;
}

.opacity-35 {
  opacity: .35 !important;
}

.opacity-40 {
  opacity: .4 !important;
}

.opacity-45 {
  opacity: .45 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-55 {
  opacity: .55 !important;
}

.opacity-60 {
  opacity: .6 !important;
}

.opacity-65 {
  opacity: .65 !important;
}

.opacity-70 {
  opacity: .7 !important;
}

.opacity-75 {
  opacity: .75 !important;
}

.opacity-80 {
  opacity: .8 !important;
}

.opacity-85 {
  opacity: .85 !important;
}

.opacity-90 {
  opacity: .9 !important;
}

.opacity-95 {
  opacity: .95 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.hover-shadow-sm:hover {
  box-shadow: 0 .125rem .25rem #00000013 !important;
}

.hover-shadow:hover {
  box-shadow: rgba(var(--tblr-body-color-rgb), .04) 0 2px 4px 0 !important;
}

.hover-shadow-lg:hover {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

.hover-shadow-none:hover {
  box-shadow: none !important;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.subpixel-antialiased {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900);
}

.fa, .fa-classic, .fa-sharp, .fas, .fa-solid, .far, .fa-regular, .fasr, .fal, .fa-light, .fat, .fa-thin, .fad, .fa-duotone, .fass, .fa-sharp-solid, .fab, .fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-variant: normal;
  text-rendering: auto;
  font-style: normal;
  line-height: 1;
}

.fas, .fa-classic, .fa-solid, .far, .fa-regular, .fal, .fa-light, .fat, .fa-thin {
  font-family: "Font Awesome 6 Pro";
}

.fab, .fa-brands {
  font-family: "Font Awesome 6 Brands";
}

.fad, .fa-classic.fa-duotone, .fa-duotone {
  font-family: "Font Awesome 6 Duotone";
}

.fass, .fasr, .fa-sharp {
  font-family: "Font Awesome 6 Sharp";
}

.fass, .fa-sharp {
  font-weight: 900;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-2xs {
  vertical-align: .225em;
  font-size: .625em;
  line-height: .1em;
}

.fa-xs {
  vertical-align: .125em;
  font-size: .75em;
  line-height: .08333em;
}

.fa-sm {
  vertical-align: .05357em;
  font-size: .875em;
  line-height: .07143em;
}

.fa-lg {
  vertical-align: -.075em;
  font-size: 1.25em;
  line-height: .05em;
}

.fa-xl {
  vertical-align: -.125em;
  font-size: 1.5em;
  line-height: .04167em;
}

.fa-2xl {
  vertical-align: -.1875em;
  font-size: 2em;
  line-height: .03125em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  left: calc(var(--fa-li-width, 2em) * -1);
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit;
  position: absolute;
}

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, .1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, .08em);
  padding: var(--fa-border-padding, .2em .25em .15em);
}

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, .3em);
}

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, .3em);
}

.fa-beat {
  animation-name: fa-beat;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-bounce {
  animation-name: fa-bounce;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(.28, .84, .42, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.28, .84, .42, 1));
}

.fa-fade {
  animation-name: fa-fade;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
}

.fa-beat-fade {
  animation-name: fa-beat-fade;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
}

.fa-flip {
  animation-name: fa-flip;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-shake {
  animation-name: fa-shake;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin {
  animation-name: fa-spin;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 2s);
  animation-duration: var(--fa-animation-duration, 2s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin-reverse {
  --fa-animation-direction: reverse;
}

.fa-pulse, .fa-spin-pulse {
  animation-name: fa-spin;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, steps(8));
  animation-timing-function: var(--fa-animation-timing, steps(8));
}

@media (prefers-reduced-motion: reduce) {
  .fa-beat, .fa-bounce, .fa-fade, .fa-beat-fade, .fa-flip, .fa-pulse, .fa-shake, .fa-spin, .fa-spin-pulse {
    transition-duration: 0s;
    transition-delay: 0s;
    animation-duration: 1ms;
    animation-iteration-count: 1;
    animation-delay: -1ms;
  }
}

@keyframes fa-beat {
  0%, 90% {
    transform: scale(1);
  }

  45% {
    -webkit-transform: scale(var(--fa-beat-scale, 1.25));
    transform: scale(var(--fa-beat-scale, 1.25));
  }
}

@keyframes fa-bounce {
  0% {
    transform: scale(1)translateY(0);
  }

  10% {
    -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, .9)) translateY(0);
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, .9)) translateY(0);
  }

  30% {
    -webkit-transform: scale(var(--fa-bounce-jump-scale-x, .9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -.5em));
    transform: scale(var(--fa-bounce-jump-scale-x, .9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -.5em));
  }

  50% {
    -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, .95)) translateY(0);
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, .95)) translateY(0);
  }

  57% {
    -webkit-transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -.125em));
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -.125em));
  }

  64% {
    transform: scale(1)translateY(0);
  }

  100% {
    transform: scale(1)translateY(0);
  }
}

@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, .4);
  }
}

@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, .4);
    transform: scale(1);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
    transform: scale(var(--fa-beat-fade-scale, 1.125));
  }
}

@keyframes fa-flip {
  50% {
    -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}

@keyframes fa-shake {
  0% {
    transform: rotate(-15deg);
  }

  4% {
    transform: rotate(15deg);
  }

  8%, 24% {
    transform: rotate(-18deg);
  }

  12%, 28% {
    transform: rotate(18deg);
  }

  16% {
    transform: rotate(-22deg);
  }

  20% {
    transform: rotate(22deg);
  }

  32% {
    transform: rotate(-12deg);
  }

  36% {
    transform: rotate(12deg);
  }

  40%, 100% {
    transform: rotate(0);
  }
}

@keyframes fa-spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fa-rotate-90 {
  transform: rotate(90deg);
}

.fa-rotate-180 {
  transform: rotate(180deg);
}

.fa-rotate-270 {
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  transform: scale(1, -1);
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1);
}

.fa-rotate-by {
  -webkit-transform: rotate(var(--fa-rotate-angle, none));
  transform: rotate(var(--fa-rotate-angle, none));
}

.fa-stack {
  vertical-align: middle;
  width: 2.5em;
  height: 2em;
  line-height: 2em;
  display: inline-block;
  position: relative;
}

.fa-stack-1x, .fa-stack-2x {
  text-align: center;
  width: 100%;
  z-index: var(--fa-stack-z-index, auto);
  position: absolute;
  left: 0;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: var(--fa-inverse, #fff);
}

.fa-0:before {
  content: "0";
}

.fa-1:before {
  content: "1";
}

.fa-2:before {
  content: "2";
}

.fa-3:before {
  content: "3";
}

.fa-4:before {
  content: "4";
}

.fa-5:before {
  content: "5";
}

.fa-6:before {
  content: "6";
}

.fa-7:before {
  content: "7";
}

.fa-8:before {
  content: "8";
}

.fa-9:before {
  content: "9";
}

.fa-fill-drip:before {
  content: "";
}

.fa-arrows-to-circle:before {
  content: "";
}

.fa-circle-chevron-right:before, .fa-chevron-circle-right:before {
  content: "";
}

.fa-wagon-covered:before {
  content: "";
}

.fa-line-height:before {
  content: "";
}

.fa-bagel:before {
  content: "";
}

.fa-transporter-7:before {
  content: "";
}

.fa-at:before {
  content: "@";
}

.fa-rectangles-mixed:before {
  content: "";
}

.fa-phone-arrow-up-right:before, .fa-phone-arrow-up:before, .fa-phone-outgoing:before {
  content: "";
}

.fa-trash-can:before, .fa-trash-alt:before {
  content: "";
}

.fa-circle-l:before {
  content: "";
}

.fa-head-side-goggles:before, .fa-head-vr:before {
  content: "";
}

.fa-text-height:before {
  content: "";
}

.fa-user-xmark:before, .fa-user-times:before {
  content: "";
}

.fa-face-hand-yawn:before {
  content: "";
}

.fa-gauge-simple-min:before, .fa-tachometer-slowest:before {
  content: "";
}

.fa-stethoscope:before {
  content: "";
}

.fa-coffin:before {
  content: "";
}

.fa-message:before, .fa-comment-alt:before {
  content: "";
}

.fa-salad:before, .fa-bowl-salad:before {
  content: "";
}

.fa-info:before {
  content: "";
}

.fa-robot-astromech:before {
  content: "";
}

.fa-ring-diamond:before {
  content: "";
}

.fa-fondue-pot:before {
  content: "";
}

.fa-theta:before {
  content: "";
}

.fa-face-hand-peeking:before {
  content: "";
}

.fa-square-user:before {
  content: "";
}

.fa-down-left-and-up-right-to-center:before, .fa-compress-alt:before {
  content: "";
}

.fa-explosion:before {
  content: "";
}

.fa-file-lines:before, .fa-file-alt:before, .fa-file-text:before {
  content: "";
}

.fa-wave-square:before {
  content: "";
}

.fa-ring:before {
  content: "";
}

.fa-building-un:before {
  content: "";
}

.fa-dice-three:before {
  content: "";
}

.fa-tire-pressure-warning:before {
  content: "";
}

.fa-wifi-fair:before, .fa-wifi-2:before {
  content: "";
}

.fa-calendar-days:before, .fa-calendar-alt:before {
  content: "";
}

.fa-mp3-player:before {
  content: "";
}

.fa-anchor-circle-check:before {
  content: "";
}

.fa-tally-4:before {
  content: "";
}

.fa-rectangle-history:before {
  content: "";
}

.fa-building-circle-arrow-right:before {
  content: "";
}

.fa-volleyball:before, .fa-volleyball-ball:before {
  content: "";
}

.fa-sun-haze:before {
  content: "";
}

.fa-text-size:before {
  content: "";
}

.fa-ufo:before {
  content: "";
}

.fa-fork:before, .fa-utensil-fork:before {
  content: "";
}

.fa-arrows-up-to-line:before {
  content: "";
}

.fa-mobile-signal:before {
  content: "";
}

.fa-barcode-scan:before {
  content: "";
}

.fa-sort-down:before, .fa-sort-desc:before {
  content: "";
}

.fa-folder-arrow-down:before, .fa-folder-download:before {
  content: "";
}

.fa-circle-minus:before, .fa-minus-circle:before {
  content: "";
}

.fa-face-icicles:before {
  content: "";
}

.fa-shovel:before {
  content: "";
}

.fa-door-open:before {
  content: "";
}

.fa-films:before {
  content: "";
}

.fa-right-from-bracket:before, .fa-sign-out-alt:before {
  content: "";
}

.fa-face-glasses:before {
  content: "";
}

.fa-nfc:before {
  content: "";
}

.fa-atom:before {
  content: "";
}

.fa-soap:before {
  content: "";
}

.fa-icons:before, .fa-heart-music-camera-bolt:before {
  content: "";
}

.fa-microphone-lines-slash:before, .fa-microphone-alt-slash:before {
  content: "";
}

.fa-closed-captioning-slash:before {
  content: "";
}

.fa-calculator-simple:before, .fa-calculator-alt:before {
  content: "";
}

.fa-bridge-circle-check:before {
  content: "";
}

.fa-sliders-up:before, .fa-sliders-v:before {
  content: "";
}

.fa-location-minus:before, .fa-map-marker-minus:before {
  content: "";
}

.fa-pump-medical:before {
  content: "";
}

.fa-fingerprint:before {
  content: "";
}

.fa-ski-boot:before {
  content: "";
}

.fa-standard-definition:before, .fa-rectangle-sd:before {
  content: "";
}

.fa-h1:before {
  content: "";
}

.fa-hand-point-right:before {
  content: "";
}

.fa-magnifying-glass-location:before, .fa-search-location:before {
  content: "";
}

.fa-message-bot:before {
  content: "";
}

.fa-forward-step:before, .fa-step-forward:before {
  content: "";
}

.fa-face-smile-beam:before, .fa-smile-beam:before {
  content: "";
}

.fa-light-ceiling:before {
  content: "";
}

.fa-message-exclamation:before, .fa-comment-alt-exclamation:before {
  content: "";
}

.fa-bowl-scoop:before, .fa-bowl-shaved-ice:before {
  content: "";
}

.fa-square-x:before {
  content: "";
}

.fa-utility-pole-double:before {
  content: "";
}

.fa-flag-checkered:before {
  content: "";
}

.fa-chevrons-up:before, .fa-chevron-double-up:before {
  content: "";
}

.fa-football:before, .fa-football-ball:before {
  content: "";
}

.fa-user-vneck:before {
  content: "";
}

.fa-school-circle-exclamation:before {
  content: "";
}

.fa-crop:before {
  content: "";
}

.fa-angles-down:before, .fa-angle-double-down:before {
  content: "";
}

.fa-users-rectangle:before {
  content: "";
}

.fa-people-roof:before {
  content: "";
}

.fa-square-arrow-right:before, .fa-arrow-square-right:before {
  content: "";
}

.fa-location-plus:before, .fa-map-marker-plus:before {
  content: "";
}

.fa-lightbulb-exclamation-on:before {
  content: "";
}

.fa-people-line:before {
  content: "";
}

.fa-beer-mug-empty:before, .fa-beer:before {
  content: "";
}

.fa-crate-empty:before {
  content: "";
}

.fa-diagram-predecessor:before {
  content: "";
}

.fa-transporter:before {
  content: "";
}

.fa-calendar-circle-user:before {
  content: "";
}

.fa-arrow-up-long:before, .fa-long-arrow-up:before {
  content: "";
}

.fa-person-carry-box:before, .fa-person-carry:before {
  content: "";
}

.fa-fire-flame-simple:before, .fa-burn:before {
  content: "";
}

.fa-person:before, .fa-male:before {
  content: "";
}

.fa-laptop:before {
  content: "";
}

.fa-file-csv:before {
  content: "";
}

.fa-menorah:before {
  content: "";
}

.fa-union:before {
  content: "";
}

.fa-chevrons-left:before, .fa-chevron-double-left:before {
  content: "";
}

.fa-circle-heart:before, .fa-heart-circle:before {
  content: "";
}

.fa-truck-plane:before {
  content: "";
}

.fa-record-vinyl:before {
  content: "";
}

.fa-bring-forward:before {
  content: "";
}

.fa-square-p:before {
  content: "";
}

.fa-face-grin-stars:before, .fa-grin-stars:before {
  content: "";
}

.fa-sigma:before {
  content: "";
}

.fa-camera-movie:before {
  content: "";
}

.fa-bong:before {
  content: "";
}

.fa-clarinet:before {
  content: "";
}

.fa-truck-flatbed:before {
  content: "";
}

.fa-spaghetti-monster-flying:before, .fa-pastafarianism:before {
  content: "";
}

.fa-arrow-down-up-across-line:before {
  content: "";
}

.fa-leaf-heart:before {
  content: "";
}

.fa-house-building:before {
  content: "";
}

.fa-cheese-swiss:before {
  content: "";
}

.fa-spoon:before, .fa-utensil-spoon:before {
  content: "";
}

.fa-jar-wheat:before {
  content: "";
}

.fa-envelopes-bulk:before, .fa-mail-bulk:before {
  content: "";
}

.fa-file-circle-exclamation:before {
  content: "";
}

.fa-bow-arrow:before {
  content: "";
}

.fa-cart-xmark:before {
  content: "";
}

.fa-hexagon-xmark:before, .fa-times-hexagon:before, .fa-xmark-hexagon:before {
  content: "";
}

.fa-circle-h:before, .fa-hospital-symbol:before {
  content: "";
}

.fa-merge:before {
  content: "";
}

.fa-pager:before {
  content: "";
}

.fa-cart-minus:before {
  content: "";
}

.fa-address-book:before, .fa-contact-book:before {
  content: "";
}

.fa-pan-frying:before {
  content: "";
}

.fa-grid:before, .fa-grid-3:before {
  content: "";
}

.fa-football-helmet:before {
  content: "";
}

.fa-hand-love:before {
  content: "";
}

.fa-trees:before {
  content: "";
}

.fa-strikethrough:before {
  content: "";
}

.fa-page:before {
  content: "";
}

.fa-k:before {
  content: "K";
}

.fa-diagram-previous:before {
  content: "";
}

.fa-gauge-min:before, .fa-tachometer-alt-slowest:before {
  content: "";
}

.fa-folder-grid:before {
  content: "";
}

.fa-eggplant:before {
  content: "";
}

.fa-ram:before {
  content: "";
}

.fa-landmark-flag:before {
  content: "";
}

.fa-lips:before {
  content: "";
}

.fa-pencil:before, .fa-pencil-alt:before {
  content: "";
}

.fa-backward:before {
  content: "";
}

.fa-caret-right:before {
  content: "";
}

.fa-comments:before {
  content: "";
}

.fa-paste:before, .fa-file-clipboard:before {
  content: "";
}

.fa-desktop-arrow-down:before {
  content: "";
}

.fa-code-pull-request:before {
  content: "";
}

.fa-pumpkin:before {
  content: "";
}

.fa-clipboard-list:before {
  content: "";
}

.fa-pen-field:before {
  content: "";
}

.fa-blueberries:before {
  content: "";
}

.fa-truck-ramp-box:before, .fa-truck-loading:before {
  content: "";
}

.fa-note:before {
  content: "";
}

.fa-arrow-down-to-square:before {
  content: "";
}

.fa-user-check:before {
  content: "";
}

.fa-cloud-xmark:before {
  content: "";
}

.fa-vial-virus:before {
  content: "";
}

.fa-book-blank:before, .fa-book-alt:before {
  content: "";
}

.fa-golf-flag-hole:before {
  content: "";
}

.fa-message-arrow-down:before, .fa-comment-alt-arrow-down:before {
  content: "";
}

.fa-face-unamused:before {
  content: "";
}

.fa-sheet-plastic:before {
  content: "";
}

.fa-circle-9:before {
  content: "";
}

.fa-blog:before {
  content: "";
}

.fa-user-ninja:before {
  content: "";
}

.fa-pencil-slash:before {
  content: "";
}

.fa-bowling-pins:before {
  content: "";
}

.fa-person-arrow-up-from-line:before {
  content: "";
}

.fa-down-right:before {
  content: "";
}

.fa-scroll-torah:before, .fa-torah:before {
  content: "";
}

.fa-webhook:before {
  content: "";
}

.fa-blinds-open:before {
  content: "";
}

.fa-fence:before {
  content: "";
}

.fa-up:before, .fa-arrow-alt-up:before {
  content: "";
}

.fa-broom-ball:before, .fa-quidditch:before, .fa-quidditch-broom-ball:before {
  content: "";
}

.fa-drumstick:before {
  content: "";
}

.fa-square-v:before {
  content: "";
}

.fa-face-awesome:before, .fa-gave-dandy:before {
  content: "";
}

.fa-dial-off:before {
  content: "";
}

.fa-toggle-off:before {
  content: "";
}

.fa-face-smile-horns:before {
  content: "";
}

.fa-box-archive:before, .fa-archive:before {
  content: "";
}

.fa-grapes:before {
  content: "";
}

.fa-person-drowning:before {
  content: "";
}

.fa-dial-max:before {
  content: "";
}

.fa-circle-m:before {
  content: "";
}

.fa-calendar-image:before {
  content: "";
}

.fa-circle-caret-down:before, .fa-caret-circle-down:before {
  content: "";
}

.fa-arrow-down-9-1:before, .fa-sort-numeric-desc:before, .fa-sort-numeric-down-alt:before {
  content: "";
}

.fa-face-grin-tongue-squint:before, .fa-grin-tongue-squint:before {
  content: "";
}

.fa-shish-kebab:before {
  content: "";
}

.fa-spray-can:before {
  content: "";
}

.fa-alarm-snooze:before {
  content: "";
}

.fa-scarecrow:before {
  content: "";
}

.fa-truck-monster:before {
  content: "";
}

.fa-gift-card:before {
  content: "";
}

.fa-w:before {
  content: "W";
}

.fa-code-pull-request-draft:before {
  content: "";
}

.fa-square-b:before {
  content: "";
}

.fa-elephant:before {
  content: "";
}

.fa-earth-africa:before, .fa-globe-africa:before {
  content: "";
}

.fa-rainbow:before {
  content: "";
}

.fa-circle-notch:before {
  content: "";
}

.fa-tablet-screen-button:before, .fa-tablet-alt:before {
  content: "";
}

.fa-paw:before {
  content: "";
}

.fa-message-question:before {
  content: "";
}

.fa-cloud:before {
  content: "";
}

.fa-trowel-bricks:before {
  content: "";
}

.fa-square-3:before {
  content: "";
}

.fa-face-flushed:before, .fa-flushed:before {
  content: "";
}

.fa-hospital-user:before {
  content: "";
}

.fa-microwave:before {
  content: "";
}

.fa-tent-arrow-left-right:before {
  content: "";
}

.fa-cart-circle-arrow-up:before {
  content: "";
}

.fa-trash-clock:before {
  content: "";
}

.fa-gavel:before, .fa-legal:before {
  content: "";
}

.fa-sprinkler-ceiling:before {
  content: "";
}

.fa-browsers:before {
  content: "";
}

.fa-trillium:before {
  content: "";
}

.fa-music-slash:before {
  content: "";
}

.fa-truck-ramp:before {
  content: "";
}

.fa-binoculars:before {
  content: "";
}

.fa-microphone-slash:before {
  content: "";
}

.fa-box-tissue:before {
  content: "";
}

.fa-circle-c:before {
  content: "";
}

.fa-star-christmas:before {
  content: "";
}

.fa-chart-bullet:before {
  content: "";
}

.fa-motorcycle:before {
  content: "";
}

.fa-tree-christmas:before {
  content: "";
}

.fa-tire-flat:before {
  content: "";
}

.fa-sunglasses:before {
  content: "";
}

.fa-badge:before {
  content: "";
}

.fa-message-pen:before, .fa-comment-alt-edit:before, .fa-message-edit:before {
  content: "";
}

.fa-bell-concierge:before, .fa-concierge-bell:before {
  content: "";
}

.fa-pen-ruler:before, .fa-pencil-ruler:before {
  content: "";
}

.fa-chess-rook-piece:before, .fa-chess-rook-alt:before {
  content: "";
}

.fa-square-root:before {
  content: "";
}

.fa-album-collection-circle-plus:before {
  content: "";
}

.fa-people-arrows:before, .fa-people-arrows-left-right:before {
  content: "";
}

.fa-face-angry-horns:before {
  content: "";
}

.fa-mars-and-venus-burst:before {
  content: "";
}

.fa-tombstone:before {
  content: "";
}

.fa-square-caret-right:before, .fa-caret-square-right:before {
  content: "";
}

.fa-scissors:before, .fa-cut:before {
  content: "";
}

.fa-list-music:before {
  content: "";
}

.fa-sun-plant-wilt:before {
  content: "";
}

.fa-toilets-portable:before {
  content: "";
}

.fa-hockey-puck:before {
  content: "";
}

.fa-mustache:before {
  content: "";
}

.fa-hyphen:before {
  content: "-";
}

.fa-table:before {
  content: "";
}

.fa-user-chef:before {
  content: "";
}

.fa-message-image:before, .fa-comment-alt-image:before {
  content: "";
}

.fa-users-medical:before {
  content: "";
}

.fa-sensor-triangle-exclamation:before, .fa-sensor-alert:before {
  content: "";
}

.fa-magnifying-glass-arrow-right:before {
  content: "";
}

.fa-tachograph-digital:before, .fa-digital-tachograph:before {
  content: "";
}

.fa-face-mask:before {
  content: "";
}

.fa-pickleball:before {
  content: "";
}

.fa-star-sharp-half:before {
  content: "";
}

.fa-users-slash:before {
  content: "";
}

.fa-clover:before {
  content: "";
}

.fa-meat:before {
  content: "";
}

.fa-reply:before, .fa-mail-reply:before {
  content: "";
}

.fa-star-and-crescent:before {
  content: "";
}

.fa-empty-set:before {
  content: "";
}

.fa-house-fire:before {
  content: "";
}

.fa-square-minus:before, .fa-minus-square:before {
  content: "";
}

.fa-helicopter:before {
  content: "";
}

.fa-bird:before {
  content: "";
}

.fa-compass:before {
  content: "";
}

.fa-square-caret-down:before, .fa-caret-square-down:before {
  content: "";
}

.fa-heart-half-stroke:before, .fa-heart-half-alt:before {
  content: "";
}

.fa-file-circle-question:before {
  content: "";
}

.fa-laptop-code:before {
  content: "";
}

.fa-joystick:before {
  content: "";
}

.fa-grill-fire:before {
  content: "";
}

.fa-rectangle-vertical-history:before {
  content: "";
}

.fa-swatchbook:before {
  content: "";
}

.fa-prescription-bottle:before {
  content: "";
}

.fa-bars:before, .fa-navicon:before {
  content: "";
}

.fa-keyboard-left:before {
  content: "";
}

.fa-people-group:before {
  content: "";
}

.fa-hourglass-end:before, .fa-hourglass-3:before {
  content: "";
}

.fa-heart-crack:before, .fa-heart-broken:before {
  content: "";
}

.fa-face-beam-hand-over-mouth:before {
  content: "";
}

.fa-droplet-percent:before, .fa-humidity:before {
  content: "";
}

.fa-square-up-right:before, .fa-external-link-square-alt:before {
  content: "";
}

.fa-face-kiss-beam:before, .fa-kiss-beam:before {
  content: "";
}

.fa-corn:before {
  content: "";
}

.fa-roller-coaster:before {
  content: "";
}

.fa-photo-film-music:before {
  content: "";
}

.fa-radar:before {
  content: "";
}

.fa-sickle:before {
  content: "";
}

.fa-film:before {
  content: "";
}

.fa-coconut:before {
  content: "";
}

.fa-ruler-horizontal:before {
  content: "";
}

.fa-shield-cross:before {
  content: "";
}

.fa-cassette-tape:before {
  content: "";
}

.fa-square-terminal:before {
  content: "";
}

.fa-people-robbery:before {
  content: "";
}

.fa-lightbulb:before {
  content: "";
}

.fa-caret-left:before {
  content: "";
}

.fa-comment-middle:before {
  content: "";
}

.fa-trash-can-list:before {
  content: "";
}

.fa-block:before {
  content: "";
}

.fa-circle-exclamation:before, .fa-exclamation-circle:before {
  content: "";
}

.fa-school-circle-xmark:before {
  content: "";
}

.fa-arrow-right-from-bracket:before, .fa-sign-out:before {
  content: "";
}

.fa-face-frown-slight:before {
  content: "";
}

.fa-circle-chevron-down:before, .fa-chevron-circle-down:before {
  content: "";
}

.fa-sidebar-flip:before {
  content: "";
}

.fa-unlock-keyhole:before, .fa-unlock-alt:before {
  content: "";
}

.fa-temperature-list:before {
  content: "";
}

.fa-cloud-showers-heavy:before {
  content: "";
}

.fa-headphones-simple:before, .fa-headphones-alt:before {
  content: "";
}

.fa-sitemap:before {
  content: "";
}

.fa-pipe-section:before {
  content: "";
}

.fa-space-station-moon-construction:before, .fa-space-station-moon-alt:before {
  content: "";
}

.fa-circle-dollar-to-slot:before, .fa-donate:before {
  content: "";
}

.fa-memory:before {
  content: "";
}

.fa-face-sleeping:before {
  content: "";
}

.fa-road-spikes:before {
  content: "";
}

.fa-fire-burner:before {
  content: "";
}

.fa-squirrel:before {
  content: "";
}

.fa-arrow-up-to-line:before, .fa-arrow-to-top:before {
  content: "";
}

.fa-flag:before {
  content: "";
}

.fa-face-cowboy-hat:before {
  content: "";
}

.fa-hanukiah:before {
  content: "";
}

.fa-chart-scatter-3d:before {
  content: "";
}

.fa-square-code:before {
  content: "";
}

.fa-feather:before {
  content: "";
}

.fa-volume-low:before, .fa-volume-down:before {
  content: "";
}

.fa-xmark-to-slot:before, .fa-times-to-slot:before, .fa-vote-nay:before {
  content: "";
}

.fa-box-taped:before, .fa-box-alt:before {
  content: "";
}

.fa-comment-slash:before {
  content: "";
}

.fa-swords:before {
  content: "";
}

.fa-cloud-sun-rain:before {
  content: "";
}

.fa-album:before {
  content: "";
}

.fa-circle-n:before {
  content: "";
}

.fa-compress:before {
  content: "";
}

.fa-wheat-awn:before, .fa-wheat-alt:before {
  content: "";
}

.fa-ankh:before {
  content: "";
}

.fa-hands-holding-child:before {
  content: "";
}

.fa-asterisk:before {
  content: "*";
}

.fa-key-skeleton-left-right:before {
  content: "";
}

.fa-comment-lines:before {
  content: "";
}

.fa-luchador-mask:before, .fa-luchador:before, .fa-mask-luchador:before {
  content: "";
}

.fa-square-check:before, .fa-check-square:before {
  content: "";
}

.fa-shredder:before {
  content: "";
}

.fa-book-open-cover:before, .fa-book-open-alt:before {
  content: "";
}

.fa-sandwich:before {
  content: "";
}

.fa-peseta-sign:before {
  content: "";
}

.fa-square-parking-slash:before, .fa-parking-slash:before {
  content: "";
}

.fa-train-tunnel:before {
  content: "";
}

.fa-heading:before, .fa-header:before {
  content: "";
}

.fa-ghost:before {
  content: "";
}

.fa-face-anguished:before {
  content: "";
}

.fa-hockey-sticks:before {
  content: "";
}

.fa-abacus:before {
  content: "";
}

.fa-film-simple:before, .fa-film-alt:before {
  content: "";
}

.fa-list:before, .fa-list-squares:before {
  content: "";
}

.fa-tree-palm:before {
  content: "";
}

.fa-square-phone-flip:before, .fa-phone-square-alt:before {
  content: "";
}

.fa-cart-plus:before {
  content: "";
}

.fa-gamepad:before {
  content: "";
}

.fa-border-center-v:before {
  content: "";
}

.fa-circle-dot:before, .fa-dot-circle:before {
  content: "";
}

.fa-clipboard-medical:before {
  content: "";
}

.fa-face-dizzy:before, .fa-dizzy:before {
  content: "";
}

.fa-egg:before {
  content: "";
}

.fa-up-to-line:before, .fa-arrow-alt-to-top:before {
  content: "";
}

.fa-house-medical-circle-xmark:before {
  content: "";
}

.fa-watch-fitness:before {
  content: "";
}

.fa-clock-nine-thirty:before {
  content: "";
}

.fa-campground:before {
  content: "";
}

.fa-folder-plus:before {
  content: "";
}

.fa-jug:before {
  content: "";
}

.fa-futbol:before, .fa-futbol-ball:before, .fa-soccer-ball:before {
  content: "";
}

.fa-snow-blowing:before {
  content: "";
}

.fa-paintbrush:before, .fa-paint-brush:before {
  content: "";
}

.fa-lock:before {
  content: "";
}

.fa-arrow-down-from-line:before, .fa-arrow-from-top:before {
  content: "";
}

.fa-gas-pump:before {
  content: "";
}

.fa-signal-bars-slash:before, .fa-signal-alt-slash:before {
  content: "";
}

.fa-monkey:before {
  content: "";
}

.fa-rectangle-pro:before, .fa-pro:before {
  content: "";
}

.fa-house-night:before {
  content: "";
}

.fa-hot-tub-person:before, .fa-hot-tub:before {
  content: "";
}

.fa-blanket:before {
  content: "";
}

.fa-map-location:before, .fa-map-marked:before {
  content: "";
}

.fa-house-flood-water:before {
  content: "";
}

.fa-comments-question-check:before {
  content: "";
}

.fa-tree:before {
  content: "";
}

.fa-arrows-cross:before {
  content: "";
}

.fa-backpack:before {
  content: "";
}

.fa-square-small:before {
  content: "";
}

.fa-folder-arrow-up:before, .fa-folder-upload:before {
  content: "";
}

.fa-bridge-lock:before {
  content: "";
}

.fa-crosshairs-simple:before {
  content: "";
}

.fa-sack-dollar:before {
  content: "";
}

.fa-pen-to-square:before, .fa-edit:before {
  content: "";
}

.fa-square-sliders:before, .fa-sliders-h-square:before {
  content: "";
}

.fa-car-side:before {
  content: "";
}

.fa-message-middle-top:before, .fa-comment-middle-top-alt:before {
  content: "";
}

.fa-lightbulb-on:before {
  content: "";
}

.fa-knife:before, .fa-utensil-knife:before {
  content: "";
}

.fa-share-nodes:before, .fa-share-alt:before {
  content: "";
}

.fa-wave-sine:before {
  content: "";
}

.fa-heart-circle-minus:before {
  content: "";
}

.fa-circle-w:before {
  content: "";
}

.fa-circle-calendar:before, .fa-calendar-circle:before {
  content: "";
}

.fa-hourglass-half:before, .fa-hourglass-2:before {
  content: "";
}

.fa-microscope:before {
  content: "";
}

.fa-sunset:before {
  content: "";
}

.fa-sink:before {
  content: "";
}

.fa-calendar-exclamation:before {
  content: "";
}

.fa-truck-container-empty:before {
  content: "";
}

.fa-hand-heart:before {
  content: "";
}

.fa-bag-shopping:before, .fa-shopping-bag:before {
  content: "";
}

.fa-arrow-down-z-a:before, .fa-sort-alpha-desc:before, .fa-sort-alpha-down-alt:before {
  content: "";
}

.fa-mitten:before {
  content: "";
}

.fa-reply-clock:before, .fa-reply-time:before {
  content: "";
}

.fa-person-rays:before {
  content: "";
}

.fa-right:before, .fa-arrow-alt-right:before {
  content: "";
}

.fa-circle-f:before {
  content: "";
}

.fa-users:before {
  content: "";
}

.fa-face-pleading:before {
  content: "";
}

.fa-eye-slash:before {
  content: "";
}

.fa-flask-vial:before {
  content: "";
}

.fa-police-box:before {
  content: "";
}

.fa-cucumber:before {
  content: "";
}

.fa-head-side-brain:before {
  content: "";
}

.fa-hand:before, .fa-hand-paper:before {
  content: "";
}

.fa-person-biking-mountain:before, .fa-biking-mountain:before {
  content: "";
}

.fa-utensils-slash:before {
  content: "";
}

.fa-print-magnifying-glass:before, .fa-print-search:before {
  content: "";
}

.fa-folder-bookmark:before {
  content: "";
}

.fa-om:before {
  content: "";
}

.fa-pi:before {
  content: "";
}

.fa-flask-round-potion:before, .fa-flask-potion:before {
  content: "";
}

.fa-face-shush:before {
  content: "";
}

.fa-worm:before {
  content: "";
}

.fa-house-circle-xmark:before {
  content: "";
}

.fa-plug:before {
  content: "";
}

.fa-calendar-circle-exclamation:before {
  content: "";
}

.fa-square-i:before {
  content: "";
}

.fa-chevron-up:before {
  content: "";
}

.fa-face-saluting:before {
  content: "";
}

.fa-gauge-simple-low:before, .fa-tachometer-slow:before {
  content: "";
}

.fa-face-persevering:before {
  content: "";
}

.fa-circle-camera:before, .fa-camera-circle:before {
  content: "";
}

.fa-hand-spock:before {
  content: "";
}

.fa-spider-web:before {
  content: "";
}

.fa-circle-microphone:before, .fa-microphone-circle:before {
  content: "";
}

.fa-book-arrow-up:before {
  content: "";
}

.fa-popsicle:before {
  content: "";
}

.fa-command:before {
  content: "";
}

.fa-blinds:before {
  content: "";
}

.fa-stopwatch:before {
  content: "";
}

.fa-saxophone:before {
  content: "";
}

.fa-square-2:before {
  content: "";
}

.fa-field-hockey-stick-ball:before, .fa-field-hockey:before {
  content: "";
}

.fa-arrow-up-square-triangle:before, .fa-sort-shapes-up-alt:before {
  content: "";
}

.fa-face-scream:before {
  content: "";
}

.fa-square-m:before {
  content: "";
}

.fa-camera-web:before, .fa-webcam:before {
  content: "";
}

.fa-comment-arrow-down:before {
  content: "";
}

.fa-lightbulb-cfl:before {
  content: "";
}

.fa-window-frame-open:before {
  content: "";
}

.fa-face-kiss:before, .fa-kiss:before {
  content: "";
}

.fa-bridge-circle-xmark:before {
  content: "";
}

.fa-period:before {
  content: ".";
}

.fa-face-grin-tongue:before, .fa-grin-tongue:before {
  content: "";
}

.fa-up-to-dotted-line:before {
  content: "";
}

.fa-thought-bubble:before {
  content: "";
}

.fa-skeleton-ribs:before {
  content: "";
}

.fa-raygun:before {
  content: "";
}

.fa-flute:before {
  content: "";
}

.fa-acorn:before {
  content: "";
}

.fa-video-arrow-up-right:before {
  content: "";
}

.fa-grate-droplet:before {
  content: "";
}

.fa-seal-exclamation:before {
  content: "";
}

.fa-chess-bishop:before {
  content: "";
}

.fa-message-sms:before {
  content: "";
}

.fa-coffee-beans:before {
  content: "";
}

.fa-hat-witch:before {
  content: "";
}

.fa-face-grin-wink:before, .fa-grin-wink:before {
  content: "";
}

.fa-clock-three-thirty:before {
  content: "";
}

.fa-ear-deaf:before, .fa-deaf:before, .fa-deafness:before, .fa-hard-of-hearing:before {
  content: "";
}

.fa-alarm-clock:before {
  content: "";
}

.fa-eclipse:before {
  content: "";
}

.fa-face-relieved:before {
  content: "";
}

.fa-road-circle-check:before {
  content: "";
}

.fa-dice-five:before {
  content: "";
}

.fa-octagon-minus:before, .fa-minus-octagon:before {
  content: "";
}

.fa-square-rss:before, .fa-rss-square:before {
  content: "";
}

.fa-face-zany:before {
  content: "";
}

.fa-tricycle:before {
  content: "";
}

.fa-land-mine-on:before {
  content: "";
}

.fa-square-arrow-up-left:before {
  content: "";
}

.fa-i-cursor:before {
  content: "";
}

.fa-salt-shaker:before {
  content: "";
}

.fa-stamp:before {
  content: "";
}

.fa-file-plus:before {
  content: "";
}

.fa-draw-square:before {
  content: "";
}

.fa-toilet-paper-under-slash:before, .fa-toilet-paper-reverse-slash:before {
  content: "";
}

.fa-stairs:before {
  content: "";
}

.fa-drone-front:before, .fa-drone-alt:before {
  content: "";
}

.fa-glass-empty:before {
  content: "";
}

.fa-dial-high:before {
  content: "";
}

.fa-user-helmet-safety:before, .fa-user-construction:before, .fa-user-hard-hat:before {
  content: "";
}

.fa-i:before {
  content: "I";
}

.fa-hryvnia-sign:before, .fa-hryvnia:before {
  content: "";
}

.fa-arrow-down-left-and-arrow-up-right-to-center:before {
  content: "";
}

.fa-pills:before {
  content: "";
}

.fa-face-grin-wide:before, .fa-grin-alt:before {
  content: "";
}

.fa-tooth:before {
  content: "";
}

.fa-basketball-hoop:before {
  content: "";
}

.fa-objects-align-bottom:before {
  content: "";
}

.fa-v:before {
  content: "V";
}

.fa-sparkles:before {
  content: "";
}

.fa-squid:before {
  content: "";
}

.fa-leafy-green:before {
  content: "";
}

.fa-circle-arrow-up-right:before {
  content: "";
}

.fa-calendars:before {
  content: "";
}

.fa-bangladeshi-taka-sign:before {
  content: "";
}

.fa-bicycle:before {
  content: "";
}

.fa-hammer-war:before {
  content: "";
}

.fa-circle-d:before {
  content: "";
}

.fa-spider-black-widow:before {
  content: "";
}

.fa-staff-snake:before, .fa-rod-asclepius:before, .fa-rod-snake:before, .fa-staff-aesculapius:before {
  content: "";
}

.fa-pear:before {
  content: "";
}

.fa-head-side-cough-slash:before {
  content: "";
}

.fa-triangle:before {
  content: "";
}

.fa-apartment:before {
  content: "";
}

.fa-truck-medical:before, .fa-ambulance:before {
  content: "";
}

.fa-pepper:before {
  content: "";
}

.fa-piano:before {
  content: "";
}

.fa-gun-squirt:before {
  content: "";
}

.fa-wheat-awn-circle-exclamation:before {
  content: "";
}

.fa-snowman:before {
  content: "";
}

.fa-user-alien:before {
  content: "";
}

.fa-shield-check:before {
  content: "";
}

.fa-mortar-pestle:before {
  content: "";
}

.fa-road-barrier:before {
  content: "";
}

.fa-chart-candlestick:before {
  content: "";
}

.fa-briefcase-blank:before {
  content: "";
}

.fa-school:before {
  content: "";
}

.fa-igloo:before {
  content: "";
}

.fa-bracket-round:before, .fa-parenthesis:before {
  content: "(";
}

.fa-joint:before {
  content: "";
}

.fa-horse-saddle:before {
  content: "";
}

.fa-mug-marshmallows:before {
  content: "";
}

.fa-filters:before {
  content: "";
}

.fa-bell-on:before {
  content: "";
}

.fa-angle-right:before {
  content: "";
}

.fa-dial-med:before {
  content: "";
}

.fa-horse:before {
  content: "";
}

.fa-q:before {
  content: "Q";
}

.fa-monitor-waveform:before, .fa-monitor-heart-rate:before {
  content: "";
}

.fa-link-simple:before {
  content: "";
}

.fa-whistle:before {
  content: "";
}

.fa-g:before {
  content: "G";
}

.fa-wine-glass-crack:before, .fa-fragile:before {
  content: "";
}

.fa-slot-machine:before {
  content: "";
}

.fa-notes-medical:before {
  content: "";
}

.fa-car-wash:before {
  content: "";
}

.fa-escalator:before {
  content: "";
}

.fa-comment-image:before {
  content: "";
}

.fa-temperature-half:before, .fa-temperature-2:before, .fa-thermometer-2:before, .fa-thermometer-half:before {
  content: "";
}

.fa-dong-sign:before {
  content: "";
}

.fa-donut:before, .fa-doughnut:before {
  content: "";
}

.fa-capsules:before {
  content: "";
}

.fa-poo-storm:before, .fa-poo-bolt:before {
  content: "";
}

.fa-tally-1:before {
  content: "";
}

.fa-face-frown-open:before, .fa-frown-open:before {
  content: "";
}

.fa-square-dashed:before {
  content: "";
}

.fa-square-j:before {
  content: "";
}

.fa-hand-point-up:before {
  content: "";
}

.fa-money-bill:before {
  content: "";
}

.fa-arrow-up-big-small:before, .fa-sort-size-up:before {
  content: "";
}

.fa-barcode-read:before {
  content: "";
}

.fa-baguette:before {
  content: "";
}

.fa-bowl-soft-serve:before {
  content: "";
}

.fa-face-holding-back-tears:before {
  content: "";
}

.fa-square-up:before, .fa-arrow-alt-square-up:before {
  content: "";
}

.fa-train-subway-tunnel:before, .fa-subway-tunnel:before {
  content: "";
}

.fa-square-exclamation:before, .fa-exclamation-square:before {
  content: "";
}

.fa-semicolon:before {
  content: ";";
}

.fa-bookmark:before {
  content: "";
}

.fa-fan-table:before {
  content: "";
}

.fa-align-justify:before {
  content: "";
}

.fa-battery-low:before, .fa-battery-1:before {
  content: "";
}

.fa-credit-card-front:before {
  content: "";
}

.fa-brain-arrow-curved-right:before, .fa-mind-share:before {
  content: "";
}

.fa-umbrella-beach:before {
  content: "";
}

.fa-helmet-un:before {
  content: "";
}

.fa-location-smile:before, .fa-map-marker-smile:before {
  content: "";
}

.fa-arrow-left-to-line:before, .fa-arrow-to-left:before {
  content: "";
}

.fa-bullseye:before {
  content: "";
}

.fa-sushi:before, .fa-nigiri:before {
  content: "";
}

.fa-message-captions:before, .fa-comment-alt-captions:before {
  content: "";
}

.fa-trash-list:before {
  content: "";
}

.fa-bacon:before {
  content: "";
}

.fa-option:before {
  content: "";
}

.fa-hand-point-down:before {
  content: "";
}

.fa-arrow-up-from-bracket:before {
  content: "";
}

.fa-trash-plus:before {
  content: "";
}

.fa-objects-align-top:before {
  content: "";
}

.fa-folder:before, .fa-folder-blank:before {
  content: "";
}

.fa-face-anxious-sweat:before {
  content: "";
}

.fa-credit-card-blank:before {
  content: "";
}

.fa-file-waveform:before, .fa-file-medical-alt:before {
  content: "";
}

.fa-microchip-ai:before {
  content: "";
}

.fa-mug:before {
  content: "";
}

.fa-plane-up-slash:before {
  content: "";
}

.fa-radiation:before {
  content: "";
}

.fa-pen-circle:before {
  content: "";
}

.fa-chart-simple:before {
  content: "";
}

.fa-crutches:before {
  content: "";
}

.fa-circle-parking:before, .fa-parking-circle:before {
  content: "";
}

.fa-mars-stroke:before {
  content: "";
}

.fa-leaf-oak:before {
  content: "";
}

.fa-square-bolt:before {
  content: "";
}

.fa-vial:before {
  content: "";
}

.fa-gauge:before, .fa-dashboard:before, .fa-gauge-med:before, .fa-tachometer-alt-average:before {
  content: "";
}

.fa-wand-magic-sparkles:before, .fa-magic-wand-sparkles:before {
  content: "";
}

.fa-lambda:before {
  content: "";
}

.fa-e:before {
  content: "E";
}

.fa-pizza:before {
  content: "";
}

.fa-bowl-chopsticks-noodles:before {
  content: "";
}

.fa-h3:before {
  content: "";
}

.fa-pen-clip:before, .fa-pen-alt:before {
  content: "";
}

.fa-bridge-circle-exclamation:before {
  content: "";
}

.fa-badge-percent:before {
  content: "";
}

.fa-user:before {
  content: "";
}

.fa-sensor:before {
  content: "";
}

.fa-comma:before {
  content: ",";
}

.fa-school-circle-check:before {
  content: "";
}

.fa-toilet-paper-under:before, .fa-toilet-paper-reverse:before {
  content: "";
}

.fa-light-emergency:before {
  content: "";
}

.fa-arrow-down-to-arc:before {
  content: "";
}

.fa-dumpster:before {
  content: "";
}

.fa-van-shuttle:before, .fa-shuttle-van:before {
  content: "";
}

.fa-building-user:before {
  content: "";
}

.fa-light-switch:before {
  content: "";
}

.fa-square-caret-left:before, .fa-caret-square-left:before {
  content: "";
}

.fa-highlighter:before {
  content: "";
}

.fa-wave-pulse:before, .fa-heart-rate:before {
  content: "";
}

.fa-key:before {
  content: "";
}

.fa-hat-santa:before {
  content: "";
}

.fa-tamale:before {
  content: "";
}

.fa-box-check:before {
  content: "";
}

.fa-bullhorn:before {
  content: "";
}

.fa-steak:before {
  content: "";
}

.fa-location-crosshairs-slash:before, .fa-location-slash:before {
  content: "";
}

.fa-person-dolly:before {
  content: "";
}

.fa-globe:before {
  content: "";
}

.fa-synagogue:before {
  content: "";
}

.fa-file-chart-column:before, .fa-file-chart-line:before {
  content: "";
}

.fa-person-half-dress:before {
  content: "";
}

.fa-folder-image:before {
  content: "";
}

.fa-calendar-pen:before, .fa-calendar-edit:before {
  content: "";
}

.fa-road-bridge:before {
  content: "";
}

.fa-face-smile-tear:before {
  content: "";
}

.fa-message-plus:before, .fa-comment-alt-plus:before {
  content: "";
}

.fa-location-arrow:before {
  content: "";
}

.fa-c:before {
  content: "C";
}

.fa-tablet-button:before {
  content: "";
}

.fa-rectangle-history-circle-user:before {
  content: "";
}

.fa-building-lock:before {
  content: "";
}

.fa-chart-line-up:before {
  content: "";
}

.fa-mailbox:before {
  content: "";
}

.fa-truck-bolt:before {
  content: "";
}

.fa-pizza-slice:before {
  content: "";
}

.fa-money-bill-wave:before {
  content: "";
}

.fa-chart-area:before, .fa-area-chart:before {
  content: "";
}

.fa-house-flag:before {
  content: "";
}

.fa-circle-three-quarters-stroke:before {
  content: "";
}

.fa-person-circle-minus:before {
  content: "";
}

.fa-scalpel:before {
  content: "";
}

.fa-ban:before, .fa-cancel:before {
  content: "";
}

.fa-bell-exclamation:before {
  content: "";
}

.fa-circle-bookmark:before, .fa-bookmark-circle:before {
  content: "";
}

.fa-egg-fried:before {
  content: "";
}

.fa-face-weary:before {
  content: "";
}

.fa-uniform-martial-arts:before {
  content: "";
}

.fa-camera-rotate:before {
  content: "";
}

.fa-sun-dust:before {
  content: "";
}

.fa-comment-text:before {
  content: "";
}

.fa-spray-can-sparkles:before, .fa-air-freshener:before {
  content: "";
}

.fa-signal-bars:before, .fa-signal-alt:before, .fa-signal-alt-4:before, .fa-signal-bars-strong:before {
  content: "";
}

.fa-diamond-exclamation:before {
  content: "";
}

.fa-star:before {
  content: "";
}

.fa-dial-min:before {
  content: "";
}

.fa-repeat:before {
  content: "";
}

.fa-cross:before {
  content: "";
}

.fa-page-caret-down:before, .fa-file-caret-down:before {
  content: "";
}

.fa-box:before {
  content: "";
}

.fa-venus-mars:before {
  content: "";
}

.fa-clock-seven-thirty:before {
  content: "";
}

.fa-arrow-pointer:before, .fa-mouse-pointer:before {
  content: "";
}

.fa-clock-four-thirty:before {
  content: "";
}

.fa-signal-bars-good:before, .fa-signal-alt-3:before {
  content: "";
}

.fa-cactus:before {
  content: "";
}

.fa-maximize:before, .fa-expand-arrows-alt:before {
  content: "";
}

.fa-charging-station:before {
  content: "";
}

.fa-shapes:before, .fa-triangle-circle-square:before {
  content: "";
}

.fa-plane-tail:before {
  content: "";
}

.fa-gauge-simple-max:before, .fa-tachometer-fastest:before {
  content: "";
}

.fa-circle-u:before {
  content: "";
}

.fa-shield-slash:before {
  content: "";
}

.fa-square-phone-hangup:before, .fa-phone-square-down:before {
  content: "";
}

.fa-arrow-up-left:before {
  content: "";
}

.fa-transporter-1:before {
  content: "";
}

.fa-peanuts:before {
  content: "";
}

.fa-shuffle:before, .fa-random:before {
  content: "";
}

.fa-person-running:before, .fa-running:before {
  content: "";
}

.fa-mobile-retro:before {
  content: "";
}

.fa-grip-lines-vertical:before {
  content: "";
}

.fa-arrow-up-from-square:before {
  content: "";
}

.fa-file-dashed-line:before, .fa-page-break:before {
  content: "";
}

.fa-bracket-curly-right:before {
  content: "}";
}

.fa-spider:before {
  content: "";
}

.fa-clock-three:before {
  content: "";
}

.fa-hands-bound:before {
  content: "";
}

.fa-scalpel-line-dashed:before, .fa-scalpel-path:before {
  content: "";
}

.fa-file-invoice-dollar:before {
  content: "";
}

.fa-pipe-smoking:before {
  content: "";
}

.fa-face-astonished:before {
  content: "";
}

.fa-window:before {
  content: "";
}

.fa-plane-circle-exclamation:before {
  content: "";
}

.fa-ear:before {
  content: "";
}

.fa-file-lock:before {
  content: "";
}

.fa-diagram-venn:before {
  content: "";
}

.fa-x-ray:before {
  content: "";
}

.fa-goal-net:before {
  content: "";
}

.fa-coffin-cross:before {
  content: "";
}

.fa-spell-check:before {
  content: "";
}

.fa-location-xmark:before, .fa-map-marker-times:before, .fa-map-marker-xmark:before {
  content: "";
}

.fa-circle-quarter-stroke:before {
  content: "";
}

.fa-lasso:before {
  content: "";
}

.fa-slash:before {
  content: "";
}

.fa-person-to-portal:before, .fa-portal-enter:before {
  content: "";
}

.fa-calendar-star:before {
  content: "";
}

.fa-computer-mouse:before, .fa-mouse:before {
  content: "";
}

.fa-arrow-right-to-bracket:before, .fa-sign-in:before {
  content: "";
}

.fa-pegasus:before {
  content: "";
}

.fa-files-medical:before {
  content: "";
}

.fa-nfc-lock:before {
  content: "";
}

.fa-person-ski-lift:before, .fa-ski-lift:before {
  content: "";
}

.fa-square-6:before {
  content: "";
}

.fa-shop-slash:before, .fa-store-alt-slash:before {
  content: "";
}

.fa-wind-turbine:before {
  content: "";
}

.fa-sliders-simple:before {
  content: "";
}

.fa-badge-sheriff:before {
  content: "";
}

.fa-server:before {
  content: "";
}

.fa-virus-covid-slash:before {
  content: "";
}

.fa-intersection:before {
  content: "";
}

.fa-shop-lock:before {
  content: "";
}

.fa-family:before {
  content: "";
}

.fa-hourglass-start:before, .fa-hourglass-1:before {
  content: "";
}

.fa-user-hair-buns:before {
  content: "";
}

.fa-blender-phone:before {
  content: "";
}

.fa-hourglass-clock:before {
  content: "";
}

.fa-person-seat-reclined:before {
  content: "";
}

.fa-paper-plane-top:before, .fa-paper-plane-alt:before, .fa-send:before {
  content: "";
}

.fa-message-arrow-up:before, .fa-comment-alt-arrow-up:before {
  content: "";
}

.fa-lightbulb-exclamation:before {
  content: "";
}

.fa-layer-minus:before, .fa-layer-group-minus:before {
  content: "";
}

.fa-circle-e:before {
  content: "";
}

.fa-building-wheat:before {
  content: "";
}

.fa-gauge-max:before, .fa-tachometer-alt-fastest:before {
  content: "";
}

.fa-person-breastfeeding:before {
  content: "";
}

.fa-apostrophe:before {
  content: "'";
}

.fa-fire-hydrant:before {
  content: "";
}

.fa-right-to-bracket:before, .fa-sign-in-alt:before {
  content: "";
}

.fa-video-plus:before {
  content: "";
}

.fa-square-right:before, .fa-arrow-alt-square-right:before {
  content: "";
}

.fa-comment-smile:before {
  content: "";
}

.fa-venus:before {
  content: "";
}

.fa-passport:before {
  content: "";
}

.fa-inbox-in:before, .fa-inbox-arrow-down:before {
  content: "";
}

.fa-heart-pulse:before, .fa-heartbeat:before {
  content: "";
}

.fa-circle-8:before {
  content: "";
}

.fa-clouds-moon:before {
  content: "";
}

.fa-clock-ten-thirty:before {
  content: "";
}

.fa-people-carry-box:before, .fa-people-carry:before {
  content: "";
}

.fa-folder-user:before {
  content: "";
}

.fa-trash-can-xmark:before {
  content: "";
}

.fa-temperature-high:before {
  content: "";
}

.fa-microchip:before {
  content: "";
}

.fa-left-long-to-line:before {
  content: "";
}

.fa-crown:before {
  content: "";
}

.fa-weight-hanging:before {
  content: "";
}

.fa-xmarks-lines:before {
  content: "";
}

.fa-file-prescription:before {
  content: "";
}

.fa-calendar-range:before {
  content: "";
}

.fa-flower-daffodil:before {
  content: "";
}

.fa-hand-back-point-up:before {
  content: "";
}

.fa-weight-scale:before, .fa-weight:before {
  content: "";
}

.fa-star-exclamation:before {
  content: "";
}

.fa-books:before {
  content: "";
}

.fa-user-group:before, .fa-user-friends:before {
  content: "";
}

.fa-arrow-up-a-z:before, .fa-sort-alpha-up:before {
  content: "";
}

.fa-layer-plus:before, .fa-layer-group-plus:before {
  content: "";
}

.fa-play-pause:before {
  content: "";
}

.fa-block-question:before {
  content: "";
}

.fa-snooze:before, .fa-zzz:before {
  content: "";
}

.fa-scanner-image:before {
  content: "";
}

.fa-tv-retro:before {
  content: "";
}

.fa-square-t:before {
  content: "";
}

.fa-farm:before, .fa-barn-silo:before {
  content: "";
}

.fa-chess-knight:before {
  content: "";
}

.fa-bars-sort:before {
  content: "";
}

.fa-pallet-boxes:before, .fa-palette-boxes:before, .fa-pallet-alt:before {
  content: "";
}

.fa-face-laugh-squint:before, .fa-laugh-squint:before {
  content: "";
}

.fa-code-simple:before {
  content: "";
}

.fa-bolt-slash:before {
  content: "";
}

.fa-panel-fire:before {
  content: "";
}

.fa-binary-circle-check:before {
  content: "";
}

.fa-comment-minus:before {
  content: "";
}

.fa-burrito:before {
  content: "";
}

.fa-violin:before {
  content: "";
}

.fa-objects-column:before {
  content: "";
}

.fa-square-chevron-down:before, .fa-chevron-square-down:before {
  content: "";
}

.fa-comment-plus:before {
  content: "";
}

.fa-triangle-instrument:before, .fa-triangle-music:before {
  content: "";
}

.fa-wheelchair:before {
  content: "";
}

.fa-user-pilot-tie:before {
  content: "";
}

.fa-piano-keyboard:before {
  content: "";
}

.fa-bed-empty:before {
  content: "";
}

.fa-circle-arrow-up:before, .fa-arrow-circle-up:before {
  content: "";
}

.fa-toggle-on:before {
  content: "";
}

.fa-rectangle-vertical:before, .fa-rectangle-portrait:before {
  content: "";
}

.fa-person-walking:before, .fa-walking:before {
  content: "";
}

.fa-l:before {
  content: "L";
}

.fa-signal-stream:before {
  content: "";
}

.fa-down-to-bracket:before {
  content: "";
}

.fa-circle-z:before {
  content: "";
}

.fa-stars:before {
  content: "";
}

.fa-fire:before {
  content: "";
}

.fa-bed-pulse:before, .fa-procedures:before {
  content: "";
}

.fa-house-day:before {
  content: "";
}

.fa-shuttle-space:before, .fa-space-shuttle:before {
  content: "";
}

.fa-shirt-long-sleeve:before {
  content: "";
}

.fa-chart-pie-simple:before, .fa-chart-pie-alt:before {
  content: "";
}

.fa-face-laugh:before, .fa-laugh:before {
  content: "";
}

.fa-folder-open:before {
  content: "";
}

.fa-album-collection-circle-user:before {
  content: "";
}

.fa-candy:before {
  content: "";
}

.fa-bowl-hot:before, .fa-soup:before {
  content: "";
}

.fa-flatbread:before {
  content: "";
}

.fa-heart-circle-plus:before {
  content: "";
}

.fa-code-fork:before {
  content: "";
}

.fa-city:before {
  content: "";
}

.fa-signal-bars-weak:before, .fa-signal-alt-1:before {
  content: "";
}

.fa-microphone-lines:before, .fa-microphone-alt:before {
  content: "";
}

.fa-clock-twelve:before {
  content: "";
}

.fa-pepper-hot:before {
  content: "";
}

.fa-citrus-slice:before {
  content: "";
}

.fa-sheep:before {
  content: "";
}

.fa-unlock:before {
  content: "";
}

.fa-colon-sign:before {
  content: "";
}

.fa-headset:before {
  content: "";
}

.fa-badger-honey:before {
  content: "";
}

.fa-h4:before {
  content: "";
}

.fa-store-slash:before {
  content: "";
}

.fa-road-circle-xmark:before {
  content: "";
}

.fa-signal-slash:before {
  content: "";
}

.fa-user-minus:before {
  content: "";
}

.fa-mars-stroke-up:before, .fa-mars-stroke-v:before {
  content: "";
}

.fa-champagne-glasses:before, .fa-glass-cheers:before {
  content: "";
}

.fa-taco:before {
  content: "";
}

.fa-hexagon-plus:before, .fa-plus-hexagon:before {
  content: "";
}

.fa-clipboard:before {
  content: "";
}

.fa-house-circle-exclamation:before {
  content: "";
}

.fa-file-arrow-up:before, .fa-file-upload:before {
  content: "";
}

.fa-wifi:before, .fa-wifi-3:before, .fa-wifi-strong:before {
  content: "";
}

.fa-messages:before, .fa-comments-alt:before {
  content: "";
}

.fa-bath:before, .fa-bathtub:before {
  content: "";
}

.fa-umbrella-simple:before, .fa-umbrella-alt:before {
  content: "";
}

.fa-rectangle-history-circle-plus:before {
  content: "";
}

.fa-underline:before {
  content: "";
}

.fa-prescription-bottle-pill:before {
  content: "";
}

.fa-user-pen:before, .fa-user-edit:before {
  content: "";
}

.fa-binary-slash:before {
  content: "";
}

.fa-square-o:before {
  content: "";
}

.fa-signature:before {
  content: "";
}

.fa-stroopwafel:before {
  content: "";
}

.fa-bold:before {
  content: "";
}

.fa-anchor-lock:before {
  content: "";
}

.fa-building-ngo:before {
  content: "";
}

.fa-transporter-3:before {
  content: "";
}

.fa-engine-warning:before, .fa-engine-exclamation:before {
  content: "";
}

.fa-circle-down-right:before {
  content: "";
}

.fa-square-k:before {
  content: "";
}

.fa-manat-sign:before {
  content: "";
}

.fa-money-check-pen:before, .fa-money-check-edit:before {
  content: "";
}

.fa-not-equal:before {
  content: "";
}

.fa-border-top-left:before, .fa-border-style:before {
  content: "";
}

.fa-map-location-dot:before, .fa-map-marked-alt:before {
  content: "";
}

.fa-tilde:before {
  content: "~";
}

.fa-jedi:before {
  content: "";
}

.fa-square-poll-vertical:before, .fa-poll:before {
  content: "";
}

.fa-arrow-down-square-triangle:before, .fa-sort-shapes-down-alt:before {
  content: "";
}

.fa-mug-hot:before {
  content: "";
}

.fa-dog-leashed:before {
  content: "";
}

.fa-car-battery:before, .fa-battery-car:before {
  content: "";
}

.fa-face-downcast-sweat:before {
  content: "";
}

.fa-mailbox-flag-up:before {
  content: "";
}

.fa-memo-circle-info:before {
  content: "";
}

.fa-gift:before {
  content: "";
}

.fa-dice-two:before {
  content: "";
}

.fa-volume:before, .fa-volume-medium:before {
  content: "";
}

.fa-transporter-5:before {
  content: "";
}

.fa-gauge-circle-bolt:before {
  content: "";
}

.fa-coin-front:before {
  content: "";
}

.fa-file-slash:before {
  content: "";
}

.fa-message-arrow-up-right:before {
  content: "";
}

.fa-treasure-chest:before {
  content: "";
}

.fa-chess-queen:before {
  content: "";
}

.fa-paintbrush-fine:before, .fa-paint-brush-alt:before, .fa-paint-brush-fine:before, .fa-paintbrush-alt:before {
  content: "";
}

.fa-glasses:before {
  content: "";
}

.fa-hood-cloak:before {
  content: "";
}

.fa-square-quote:before {
  content: "";
}

.fa-up-left:before {
  content: "";
}

.fa-bring-front:before {
  content: "";
}

.fa-chess-board:before {
  content: "";
}

.fa-burger-cheese:before, .fa-cheeseburger:before {
  content: "";
}

.fa-building-circle-check:before {
  content: "";
}

.fa-repeat-1:before {
  content: "";
}

.fa-arrow-down-to-line:before, .fa-arrow-to-bottom:before {
  content: "";
}

.fa-grid-5:before {
  content: "";
}

.fa-right-long-to-line:before {
  content: "";
}

.fa-person-chalkboard:before {
  content: "";
}

.fa-mars-stroke-right:before, .fa-mars-stroke-h:before {
  content: "";
}

.fa-hand-back-fist:before, .fa-hand-rock:before {
  content: "";
}

.fa-tally:before, .fa-tally-5:before {
  content: "";
}

.fa-square-caret-up:before, .fa-caret-square-up:before {
  content: "";
}

.fa-cloud-showers-water:before {
  content: "";
}

.fa-chart-bar:before, .fa-bar-chart:before {
  content: "";
}

.fa-hands-bubbles:before, .fa-hands-wash:before {
  content: "";
}

.fa-less-than-equal:before {
  content: "";
}

.fa-train:before {
  content: "";
}

.fa-up-from-dotted-line:before {
  content: "";
}

.fa-eye-low-vision:before, .fa-low-vision:before {
  content: "";
}

.fa-traffic-light-go:before {
  content: "";
}

.fa-face-exhaling:before {
  content: "";
}

.fa-sensor-fire:before {
  content: "";
}

.fa-user-unlock:before {
  content: "";
}

.fa-hexagon-divide:before {
  content: "";
}

.fa-00:before {
  content: "";
}

.fa-crow:before {
  content: "";
}

.fa-cassette-betamax:before, .fa-betamax:before {
  content: "";
}

.fa-sailboat:before {
  content: "";
}

.fa-window-restore:before {
  content: "";
}

.fa-nfc-magnifying-glass:before {
  content: "";
}

.fa-file-binary:before {
  content: "";
}

.fa-circle-v:before {
  content: "";
}

.fa-square-plus:before, .fa-plus-square:before {
  content: "";
}

.fa-bowl-scoops:before {
  content: "";
}

.fa-mistletoe:before {
  content: "";
}

.fa-custard:before {
  content: "";
}

.fa-lacrosse-stick:before {
  content: "";
}

.fa-hockey-mask:before {
  content: "";
}

.fa-sunrise:before {
  content: "";
}

.fa-panel-ews:before {
  content: "";
}

.fa-torii-gate:before {
  content: "";
}

.fa-cloud-exclamation:before {
  content: "";
}

.fa-message-lines:before, .fa-comment-alt-lines:before {
  content: "";
}

.fa-frog:before {
  content: "";
}

.fa-bucket:before {
  content: "";
}

.fa-floppy-disk-pen:before {
  content: "";
}

.fa-image:before {
  content: "";
}

.fa-window-frame:before {
  content: "";
}

.fa-microphone:before {
  content: "";
}

.fa-cow:before {
  content: "";
}

.fa-square-ring:before {
  content: "";
}

.fa-down-from-line:before, .fa-arrow-alt-from-top:before {
  content: "";
}

.fa-caret-up:before {
  content: "";
}

.fa-shield-xmark:before, .fa-shield-times:before {
  content: "";
}

.fa-screwdriver:before {
  content: "";
}

.fa-circle-sort-down:before, .fa-sort-circle-down:before {
  content: "";
}

.fa-folder-closed:before {
  content: "";
}

.fa-house-tsunami:before {
  content: "";
}

.fa-square-nfi:before {
  content: "";
}

.fa-forklift:before {
  content: "";
}

.fa-arrow-up-from-ground-water:before {
  content: "";
}

.fa-bracket-square-right:before {
  content: "]";
}

.fa-martini-glass:before, .fa-glass-martini-alt:before {
  content: "";
}

.fa-rotate-left:before, .fa-rotate-back:before, .fa-rotate-backward:before, .fa-undo-alt:before {
  content: "";
}

.fa-table-columns:before, .fa-columns:before {
  content: "";
}

.fa-square-a:before {
  content: "";
}

.fa-tick:before {
  content: "";
}

.fa-lemon:before {
  content: "";
}

.fa-head-side-mask:before {
  content: "";
}

.fa-handshake:before {
  content: "";
}

.fa-gem:before {
  content: "";
}

.fa-dolly:before, .fa-dolly-box:before {
  content: "";
}

.fa-smoking:before {
  content: "";
}

.fa-minimize:before, .fa-compress-arrows-alt:before {
  content: "";
}

.fa-refrigerator:before {
  content: "";
}

.fa-monument:before {
  content: "";
}

.fa-octagon-xmark:before, .fa-times-octagon:before, .fa-xmark-octagon:before {
  content: "";
}

.fa-align-slash:before {
  content: "";
}

.fa-snowplow:before {
  content: "";
}

.fa-angles-right:before, .fa-angle-double-right:before {
  content: "";
}

.fa-truck-ramp-couch:before, .fa-truck-couch:before {
  content: "";
}

.fa-cannabis:before {
  content: "";
}

.fa-circle-play:before, .fa-play-circle:before {
  content: "";
}

.fa-arrow-up-right-and-arrow-down-left-from-center:before {
  content: "";
}

.fa-tablets:before {
  content: "";
}

.fa-360-degrees:before {
  content: "";
}

.fa-ethernet:before {
  content: "";
}

.fa-euro-sign:before, .fa-eur:before, .fa-euro:before {
  content: "";
}

.fa-chair:before {
  content: "";
}

.fa-circle-check:before, .fa-check-circle:before {
  content: "";
}

.fa-square-dashed-circle-plus:before {
  content: "";
}

.fa-money-simple-from-bracket:before {
  content: "";
}

.fa-bat:before {
  content: "";
}

.fa-circle-stop:before, .fa-stop-circle:before {
  content: "";
}

.fa-head-side-headphones:before {
  content: "";
}

.fa-phone-rotary:before {
  content: "";
}

.fa-compass-drafting:before, .fa-drafting-compass:before {
  content: "";
}

.fa-plate-wheat:before {
  content: "";
}

.fa-calendar-circle-minus:before {
  content: "";
}

.fa-chopsticks:before {
  content: "";
}

.fa-car-wrench:before, .fa-car-mechanic:before {
  content: "";
}

.fa-icicles:before {
  content: "";
}

.fa-person-shelter:before {
  content: "";
}

.fa-neuter:before {
  content: "";
}

.fa-id-badge:before {
  content: "";
}

.fa-kazoo:before {
  content: "";
}

.fa-marker:before {
  content: "";
}

.fa-face-laugh-beam:before, .fa-laugh-beam:before {
  content: "";
}

.fa-square-arrow-down-left:before {
  content: "";
}

.fa-battery-bolt:before {
  content: "";
}

.fa-tree-large:before {
  content: "";
}

.fa-helicopter-symbol:before {
  content: "";
}

.fa-aperture:before {
  content: "";
}

.fa-universal-access:before {
  content: "";
}

.fa-file-magnifying-glass:before, .fa-file-search:before {
  content: "";
}

.fa-up-right:before {
  content: "";
}

.fa-circle-chevron-up:before, .fa-chevron-circle-up:before {
  content: "";
}

.fa-user-police:before {
  content: "";
}

.fa-lari-sign:before {
  content: "";
}

.fa-volcano:before {
  content: "";
}

.fa-teddy-bear:before {
  content: "";
}

.fa-stocking:before {
  content: "";
}

.fa-person-walking-dashed-line-arrow-right:before {
  content: "";
}

.fa-image-slash:before {
  content: "";
}

.fa-mask-snorkel:before {
  content: "";
}

.fa-smoke:before {
  content: "";
}

.fa-sterling-sign:before, .fa-gbp:before, .fa-pound-sign:before {
  content: "";
}

.fa-battery-exclamation:before {
  content: "";
}

.fa-viruses:before {
  content: "";
}

.fa-square-person-confined:before {
  content: "";
}

.fa-user-tie:before {
  content: "";
}

.fa-arrow-down-long:before, .fa-long-arrow-down:before {
  content: "";
}

.fa-tent-arrow-down-to-line:before {
  content: "";
}

.fa-certificate:before {
  content: "";
}

.fa-crystal-ball:before {
  content: "";
}

.fa-reply-all:before, .fa-mail-reply-all:before {
  content: "";
}

.fa-suitcase:before {
  content: "";
}

.fa-person-skating:before, .fa-skating:before {
  content: "";
}

.fa-star-shooting:before {
  content: "";
}

.fa-binary-lock:before {
  content: "";
}

.fa-filter-circle-dollar:before, .fa-funnel-dollar:before {
  content: "";
}

.fa-camera-retro:before {
  content: "";
}

.fa-circle-arrow-down:before, .fa-arrow-circle-down:before {
  content: "";
}

.fa-comment-pen:before, .fa-comment-edit:before {
  content: "";
}

.fa-file-import:before, .fa-arrow-right-to-file:before {
  content: "";
}

.fa-banjo:before {
  content: "";
}

.fa-square-arrow-up-right:before, .fa-external-link-square:before {
  content: "";
}

.fa-light-emergency-on:before {
  content: "";
}

.fa-kerning:before {
  content: "";
}

.fa-box-open:before {
  content: "";
}

.fa-square-f:before {
  content: "";
}

.fa-scroll:before {
  content: "";
}

.fa-spa:before {
  content: "";
}

.fa-arrow-left-from-line:before, .fa-arrow-from-right:before {
  content: "";
}

.fa-strawberry:before {
  content: "";
}

.fa-location-pin-lock:before {
  content: "";
}

.fa-pause:before {
  content: "";
}

.fa-clock-eight-thirty:before {
  content: "";
}

.fa-plane-engines:before, .fa-plane-alt:before {
  content: "";
}

.fa-hill-avalanche:before {
  content: "";
}

.fa-temperature-empty:before, .fa-temperature-0:before, .fa-thermometer-0:before, .fa-thermometer-empty:before {
  content: "";
}

.fa-bomb:before {
  content: "";
}

.fa-gauge-low:before, .fa-tachometer-alt-slow:before {
  content: "";
}

.fa-registered:before {
  content: "";
}

.fa-trash-can-plus:before {
  content: "";
}

.fa-address-card:before, .fa-contact-card:before, .fa-vcard:before {
  content: "";
}

.fa-scale-unbalanced-flip:before, .fa-balance-scale-right:before {
  content: "";
}

.fa-globe-snow:before {
  content: "";
}

.fa-subscript:before {
  content: "";
}

.fa-diamond-turn-right:before, .fa-directions:before {
  content: "";
}

.fa-integral:before {
  content: "";
}

.fa-burst:before {
  content: "";
}

.fa-house-laptop:before, .fa-laptop-house:before {
  content: "";
}

.fa-face-tired:before, .fa-tired:before {
  content: "";
}

.fa-money-bills:before {
  content: "";
}

.fa-blinds-raised:before {
  content: "";
}

.fa-smog:before {
  content: "";
}

.fa-ufo-beam:before {
  content: "";
}

.fa-circle-caret-up:before, .fa-caret-circle-up:before {
  content: "";
}

.fa-user-vneck-hair-long:before {
  content: "";
}

.fa-square-a-lock:before {
  content: "";
}

.fa-crutch:before {
  content: "";
}

.fa-gas-pump-slash:before {
  content: "";
}

.fa-cloud-arrow-up:before, .fa-cloud-upload:before, .fa-cloud-upload-alt:before {
  content: "";
}

.fa-palette:before {
  content: "";
}

.fa-transporter-4:before {
  content: "";
}

.fa-objects-align-right:before {
  content: "";
}

.fa-arrows-turn-right:before {
  content: "";
}

.fa-vest:before {
  content: "";
}

.fa-pig:before {
  content: "";
}

.fa-inbox-full:before {
  content: "";
}

.fa-circle-envelope:before, .fa-envelope-circle:before {
  content: "";
}

.fa-triangle-person-digging:before, .fa-construction:before {
  content: "";
}

.fa-ferry:before {
  content: "";
}

.fa-bullseye-arrow:before {
  content: "";
}

.fa-arrows-down-to-people:before {
  content: "";
}

.fa-seedling:before, .fa-sprout:before {
  content: "";
}

.fa-clock-seven:before {
  content: "";
}

.fa-left-right:before, .fa-arrows-alt-h:before {
  content: "";
}

.fa-boxes-packing:before {
  content: "";
}

.fa-circle-arrow-left:before, .fa-arrow-circle-left:before {
  content: "";
}

.fa-flashlight:before {
  content: "";
}

.fa-group-arrows-rotate:before {
  content: "";
}

.fa-bowl-food:before {
  content: "";
}

.fa-square-9:before {
  content: "";
}

.fa-candy-cane:before {
  content: "";
}

.fa-arrow-down-wide-short:before, .fa-sort-amount-asc:before, .fa-sort-amount-down:before {
  content: "";
}

.fa-square-dollar:before, .fa-dollar-square:before, .fa-usd-square:before {
  content: "";
}

.fa-phone-arrow-right:before {
  content: "";
}

.fa-hand-holding-seedling:before {
  content: "";
}

.fa-message-check:before, .fa-comment-alt-check:before {
  content: "";
}

.fa-cloud-bolt:before, .fa-thunderstorm:before {
  content: "";
}

.fa-text-slash:before, .fa-remove-format:before {
  content: "";
}

.fa-watch:before {
  content: "";
}

.fa-circle-down-left:before {
  content: "";
}

.fa-text:before {
  content: "";
}

.fa-projector:before {
  content: "";
}

.fa-face-smile-wink:before, .fa-smile-wink:before {
  content: "";
}

.fa-tombstone-blank:before, .fa-tombstone-alt:before {
  content: "";
}

.fa-chess-king-piece:before, .fa-chess-king-alt:before {
  content: "";
}

.fa-circle-6:before {
  content: "";
}

.fa-left:before, .fa-arrow-alt-left:before {
  content: "";
}

.fa-file-word:before {
  content: "";
}

.fa-file-powerpoint:before {
  content: "";
}

.fa-square-down:before, .fa-arrow-alt-square-down:before {
  content: "";
}

.fa-objects-align-center-vertical:before {
  content: "";
}

.fa-arrows-left-right:before, .fa-arrows-h:before {
  content: "";
}

.fa-house-lock:before {
  content: "";
}

.fa-cloud-arrow-down:before, .fa-cloud-download:before, .fa-cloud-download-alt:before {
  content: "";
}

.fa-wreath:before {
  content: "";
}

.fa-children:before {
  content: "";
}

.fa-meter-droplet:before {
  content: "";
}

.fa-chalkboard:before, .fa-blackboard:before {
  content: "";
}

.fa-user-large-slash:before, .fa-user-alt-slash:before {
  content: "";
}

.fa-signal-strong:before, .fa-signal-4:before {
  content: "";
}

.fa-lollipop:before, .fa-lollypop:before {
  content: "";
}

.fa-list-tree:before {
  content: "";
}

.fa-envelope-open:before {
  content: "";
}

.fa-draw-circle:before {
  content: "";
}

.fa-cat-space:before {
  content: "";
}

.fa-handshake-simple-slash:before, .fa-handshake-alt-slash:before {
  content: "";
}

.fa-rabbit-running:before, .fa-rabbit-fast:before {
  content: "";
}

.fa-memo-pad:before {
  content: "";
}

.fa-mattress-pillow:before {
  content: "";
}

.fa-alarm-plus:before {
  content: "";
}

.fa-alicorn:before {
  content: "";
}

.fa-comment-question:before {
  content: "";
}

.fa-gingerbread-man:before {
  content: "";
}

.fa-guarani-sign:before {
  content: "";
}

.fa-burger-fries:before {
  content: "";
}

.fa-mug-tea:before {
  content: "";
}

.fa-border-top:before {
  content: "";
}

.fa-arrows-rotate:before, .fa-refresh:before, .fa-sync:before {
  content: "";
}

.fa-circle-book-open:before, .fa-book-circle:before {
  content: "";
}

.fa-arrows-to-dotted-line:before {
  content: "";
}

.fa-fire-extinguisher:before {
  content: "";
}

.fa-garage-open:before {
  content: "";
}

.fa-shelves-empty:before {
  content: "";
}

.fa-cruzeiro-sign:before {
  content: "";
}

.fa-watch-apple:before {
  content: "";
}

.fa-watch-calculator:before {
  content: "";
}

.fa-list-dropdown:before {
  content: "";
}

.fa-cabinet-filing:before {
  content: "";
}

.fa-burger-soda:before {
  content: "";
}

.fa-square-arrow-up:before, .fa-arrow-square-up:before {
  content: "";
}

.fa-greater-than-equal:before {
  content: "";
}

.fa-pallet-box:before {
  content: "";
}

.fa-face-confounded:before {
  content: "";
}

.fa-shield-halved:before, .fa-shield-alt:before {
  content: "";
}

.fa-truck-plow:before {
  content: "";
}

.fa-book-atlas:before, .fa-atlas:before {
  content: "";
}

.fa-virus:before {
  content: "";
}

.fa-comment-middle-top:before {
  content: "";
}

.fa-envelope-circle-check:before {
  content: "";
}

.fa-layer-group:before {
  content: "";
}

.fa-restroom-simple:before {
  content: "";
}

.fa-arrows-to-dot:before {
  content: "";
}

.fa-border-outer:before {
  content: "";
}

.fa-hashtag-lock:before {
  content: "";
}

.fa-clock-two-thirty:before {
  content: "";
}

.fa-archway:before {
  content: "";
}

.fa-heart-circle-check:before {
  content: "";
}

.fa-house-chimney-crack:before, .fa-house-damage:before {
  content: "";
}

.fa-file-zipper:before, .fa-file-archive:before {
  content: "";
}

.fa-heart-half:before {
  content: "";
}

.fa-comment-check:before {
  content: "";
}

.fa-square:before {
  content: "";
}

.fa-memo:before {
  content: "";
}

.fa-martini-glass-empty:before, .fa-glass-martini:before {
  content: "";
}

.fa-couch:before {
  content: "";
}

.fa-cedi-sign:before {
  content: "";
}

.fa-italic:before {
  content: "";
}

.fa-glass-citrus:before {
  content: "";
}

.fa-calendar-lines-pen:before {
  content: "";
}

.fa-church:before {
  content: "";
}

.fa-person-snowmobiling:before, .fa-snowmobile:before {
  content: "";
}

.fa-face-hushed:before {
  content: "";
}

.fa-comments-dollar:before {
  content: "";
}

.fa-pickaxe:before {
  content: "";
}

.fa-link-simple-slash:before {
  content: "";
}

.fa-democrat:before {
  content: "";
}

.fa-face-confused:before {
  content: "";
}

.fa-pinball:before {
  content: "";
}

.fa-z:before {
  content: "Z";
}

.fa-person-skiing:before, .fa-skiing:before {
  content: "";
}

.fa-deer:before {
  content: "";
}

.fa-input-pipe:before {
  content: "";
}

.fa-road-lock:before {
  content: "";
}

.fa-a:before {
  content: "A";
}

.fa-bookmark-slash:before {
  content: "";
}

.fa-temperature-arrow-down:before, .fa-temperature-down:before {
  content: "";
}

.fa-mace:before {
  content: "";
}

.fa-feather-pointed:before, .fa-feather-alt:before {
  content: "";
}

.fa-sausage:before {
  content: "";
}

.fa-trash-can-clock:before {
  content: "";
}

.fa-p:before {
  content: "P";
}

.fa-broom-wide:before {
  content: "";
}

.fa-snowflake:before {
  content: "";
}

.fa-stomach:before {
  content: "";
}

.fa-newspaper:before {
  content: "";
}

.fa-rectangle-ad:before, .fa-ad:before {
  content: "";
}

.fa-guitar-electric:before {
  content: "";
}

.fa-arrow-turn-down-right:before {
  content: "";
}

.fa-moon-cloud:before {
  content: "";
}

.fa-bread-slice-butter:before {
  content: "";
}

.fa-circle-arrow-right:before, .fa-arrow-circle-right:before {
  content: "";
}

.fa-user-group-crown:before, .fa-users-crown:before {
  content: "";
}

.fa-circle-i:before {
  content: "";
}

.fa-toilet-paper-check:before {
  content: "";
}

.fa-filter-circle-xmark:before {
  content: "";
}

.fa-locust:before {
  content: "";
}

.fa-sort:before, .fa-unsorted:before {
  content: "";
}

.fa-list-ol:before, .fa-list-1-2:before, .fa-list-numeric:before {
  content: "";
}

.fa-chart-waterfall:before {
  content: "";
}

.fa-sparkle:before {
  content: "";
}

.fa-face-party:before {
  content: "";
}

.fa-kidneys:before {
  content: "";
}

.fa-wifi-exclamation:before {
  content: "";
}

.fa-chart-network:before {
  content: "";
}

.fa-person-dress-burst:before {
  content: "";
}

.fa-dice-d4:before {
  content: "";
}

.fa-money-check-dollar:before, .fa-money-check-alt:before {
  content: "";
}

.fa-vector-square:before {
  content: "";
}

.fa-bread-slice:before {
  content: "";
}

.fa-language:before {
  content: "";
}

.fa-wheat-awn-slash:before {
  content: "";
}

.fa-face-kiss-wink-heart:before, .fa-kiss-wink-heart:before {
  content: "";
}

.fa-dagger:before {
  content: "";
}

.fa-podium:before {
  content: "";
}

.fa-memo-circle-check:before {
  content: "";
}

.fa-route-highway:before {
  content: "";
}

.fa-down-to-line:before, .fa-arrow-alt-to-bottom:before {
  content: "";
}

.fa-filter:before {
  content: "";
}

.fa-square-g:before {
  content: "";
}

.fa-circle-phone:before, .fa-phone-circle:before {
  content: "";
}

.fa-clipboard-prescription:before {
  content: "";
}

.fa-user-nurse-hair:before {
  content: "";
}

.fa-question:before {
  content: "?";
}

.fa-file-signature:before {
  content: "";
}

.fa-toggle-large-on:before {
  content: "";
}

.fa-up-down-left-right:before, .fa-arrows-alt:before {
  content: "";
}

.fa-dryer-heat:before, .fa-dryer-alt:before {
  content: "";
}

.fa-house-chimney-user:before {
  content: "";
}

.fa-hand-holding-heart:before {
  content: "";
}

.fa-arrow-up-small-big:before, .fa-sort-size-up-alt:before {
  content: "";
}

.fa-train-track:before {
  content: "";
}

.fa-puzzle-piece:before {
  content: "";
}

.fa-money-check:before {
  content: "";
}

.fa-star-half-stroke:before, .fa-star-half-alt:before {
  content: "";
}

.fa-file-exclamation:before {
  content: "";
}

.fa-code:before {
  content: "";
}

.fa-whiskey-glass:before, .fa-glass-whiskey:before {
  content: "";
}

.fa-moon-stars:before {
  content: "";
}

.fa-building-circle-exclamation:before {
  content: "";
}

.fa-clothes-hanger:before {
  content: "";
}

.fa-mobile-notch:before, .fa-mobile-iphone:before {
  content: "";
}

.fa-magnifying-glass-chart:before {
  content: "";
}

.fa-arrow-up-right-from-square:before, .fa-external-link:before {
  content: "";
}

.fa-cubes-stacked:before {
  content: "";
}

.fa-images-user:before {
  content: "";
}

.fa-won-sign:before, .fa-krw:before, .fa-won:before {
  content: "";
}

.fa-image-polaroid-user:before {
  content: "";
}

.fa-virus-covid:before {
  content: "";
}

.fa-square-ellipsis:before {
  content: "";
}

.fa-pie:before {
  content: "";
}

.fa-chess-knight-piece:before, .fa-chess-knight-alt:before {
  content: "";
}

.fa-austral-sign:before {
  content: "";
}

.fa-cloud-plus:before {
  content: "";
}

.fa-f:before {
  content: "F";
}

.fa-leaf:before {
  content: "";
}

.fa-bed-bunk:before {
  content: "";
}

.fa-road:before {
  content: "";
}

.fa-taxi:before, .fa-cab:before {
  content: "";
}

.fa-person-circle-plus:before {
  content: "";
}

.fa-chart-pie:before, .fa-pie-chart:before {
  content: "";
}

.fa-bolt-lightning:before {
  content: "";
}

.fa-clock-eight:before {
  content: "";
}

.fa-sack-xmark:before {
  content: "";
}

.fa-file-excel:before {
  content: "";
}

.fa-file-contract:before {
  content: "";
}

.fa-fish-fins:before {
  content: "";
}

.fa-circle-q:before {
  content: "";
}

.fa-building-flag:before {
  content: "";
}

.fa-face-grin-beam:before, .fa-grin-beam:before {
  content: "";
}

.fa-object-ungroup:before {
  content: "";
}

.fa-face-disguise:before {
  content: "";
}

.fa-circle-arrow-down-right:before {
  content: "";
}

.fa-alien-8bit:before, .fa-alien-monster:before {
  content: "";
}

.fa-hand-point-ribbon:before {
  content: "";
}

.fa-poop:before {
  content: "";
}

.fa-object-exclude:before {
  content: "";
}

.fa-telescope:before {
  content: "";
}

.fa-location-pin:before, .fa-map-marker:before {
  content: "";
}

.fa-square-list:before {
  content: "";
}

.fa-kaaba:before {
  content: "";
}

.fa-toilet-paper:before {
  content: "";
}

.fa-helmet-safety:before, .fa-hard-hat:before, .fa-hat-hard:before {
  content: "";
}

.fa-comment-code:before {
  content: "";
}

.fa-sim-cards:before {
  content: "";
}

.fa-starship:before {
  content: "";
}

.fa-eject:before {
  content: "";
}

.fa-circle-right:before, .fa-arrow-alt-circle-right:before {
  content: "";
}

.fa-plane-circle-check:before {
  content: "";
}

.fa-seal:before {
  content: "";
}

.fa-user-cowboy:before {
  content: "";
}

.fa-hexagon-vertical-nft:before {
  content: "";
}

.fa-face-rolling-eyes:before, .fa-meh-rolling-eyes:before {
  content: "";
}

.fa-bread-loaf:before {
  content: "";
}

.fa-rings-wedding:before {
  content: "";
}

.fa-object-group:before {
  content: "";
}

.fa-french-fries:before {
  content: "";
}

.fa-chart-line:before, .fa-line-chart:before {
  content: "";
}

.fa-calendar-arrow-down:before, .fa-calendar-download:before {
  content: "";
}

.fa-send-back:before {
  content: "";
}

.fa-mask-ventilator:before {
  content: "";
}

.fa-signature-lock:before {
  content: "";
}

.fa-arrow-right:before {
  content: "";
}

.fa-signs-post:before, .fa-map-signs:before {
  content: "";
}

.fa-octagon-plus:before, .fa-plus-octagon:before {
  content: "";
}

.fa-cash-register:before {
  content: "";
}

.fa-person-circle-question:before {
  content: "";
}

.fa-melon-slice:before {
  content: "";
}

.fa-space-station-moon:before {
  content: "";
}

.fa-message-smile:before, .fa-comment-alt-smile:before {
  content: "";
}

.fa-cup-straw:before {
  content: "";
}

.fa-left-from-line:before, .fa-arrow-alt-from-right:before {
  content: "";
}

.fa-h:before {
  content: "H";
}

.fa-basket-shopping-simple:before, .fa-shopping-basket-alt:before {
  content: "";
}

.fa-hands-holding-heart:before, .fa-hands-heart:before {
  content: "";
}

.fa-clock-nine:before {
  content: "";
}

.fa-tarp:before {
  content: "";
}

.fa-face-sleepy:before {
  content: "";
}

.fa-hand-horns:before {
  content: "";
}

.fa-screwdriver-wrench:before, .fa-tools:before {
  content: "";
}

.fa-arrows-to-eye:before {
  content: "";
}

.fa-circle-three-quarters:before {
  content: "";
}

.fa-trophy-star:before, .fa-trophy-alt:before {
  content: "";
}

.fa-plug-circle-bolt:before {
  content: "";
}

.fa-face-thermometer:before {
  content: "";
}

.fa-shirt-running:before {
  content: "";
}

.fa-book-circle-arrow-up:before {
  content: "";
}

.fa-face-nauseated:before {
  content: "";
}

.fa-heart:before {
  content: "";
}

.fa-file-chart-pie:before {
  content: "";
}

.fa-mars-and-venus:before {
  content: "";
}

.fa-house-user:before, .fa-home-user:before {
  content: "";
}

.fa-circle-arrow-down-left:before {
  content: "";
}

.fa-dumpster-fire:before {
  content: "";
}

.fa-hexagon-minus:before, .fa-minus-hexagon:before {
  content: "";
}

.fa-left-to-line:before, .fa-arrow-alt-to-left:before {
  content: "";
}

.fa-house-crack:before {
  content: "";
}

.fa-paw-simple:before, .fa-paw-alt:before {
  content: "";
}

.fa-arrow-left-long-to-line:before {
  content: "";
}

.fa-brackets-round:before, .fa-parentheses:before {
  content: "";
}

.fa-martini-glass-citrus:before, .fa-cocktail:before {
  content: "";
}

.fa-user-shakespeare:before {
  content: "";
}

.fa-arrow-right-to-arc:before {
  content: "";
}

.fa-face-surprise:before, .fa-surprise:before {
  content: "";
}

.fa-bottle-water:before {
  content: "";
}

.fa-circle-pause:before, .fa-pause-circle:before {
  content: "";
}

.fa-gauge-circle-plus:before {
  content: "";
}

.fa-folders:before {
  content: "";
}

.fa-angel:before {
  content: "";
}

.fa-value-absolute:before {
  content: "";
}

.fa-rabbit:before {
  content: "";
}

.fa-toilet-paper-slash:before {
  content: "";
}

.fa-circle-euro:before {
  content: "";
}

.fa-apple-whole:before, .fa-apple-alt:before {
  content: "";
}

.fa-kitchen-set:before {
  content: "";
}

.fa-diamond-half:before {
  content: "";
}

.fa-lock-keyhole:before, .fa-lock-alt:before {
  content: "";
}

.fa-r:before {
  content: "R";
}

.fa-temperature-quarter:before, .fa-temperature-1:before, .fa-thermometer-1:before, .fa-thermometer-quarter:before {
  content: "";
}

.fa-square-info:before, .fa-info-square:before {
  content: "";
}

.fa-wifi-slash:before {
  content: "";
}

.fa-toilet-paper-xmark:before {
  content: "";
}

.fa-hands-holding-dollar:before, .fa-hands-usd:before {
  content: "";
}

.fa-cube:before {
  content: "";
}

.fa-arrow-down-triangle-square:before, .fa-sort-shapes-down:before {
  content: "";
}

.fa-bitcoin-sign:before {
  content: "";
}

.fa-shutters:before {
  content: "";
}

.fa-shield-dog:before {
  content: "";
}

.fa-solar-panel:before {
  content: "";
}

.fa-lock-open:before {
  content: "";
}

.fa-table-tree:before {
  content: "";
}

.fa-house-chimney-heart:before {
  content: "";
}

.fa-tally-3:before {
  content: "";
}

.fa-elevator:before {
  content: "";
}

.fa-money-bill-transfer:before {
  content: "";
}

.fa-money-bill-trend-up:before {
  content: "";
}

.fa-house-flood-water-circle-arrow-right:before {
  content: "";
}

.fa-square-poll-horizontal:before, .fa-poll-h:before {
  content: "";
}

.fa-circle:before {
  content: "";
}

.fa-cart-circle-exclamation:before {
  content: "";
}

.fa-sword:before {
  content: "";
}

.fa-backward-fast:before, .fa-fast-backward:before {
  content: "";
}

.fa-recycle:before {
  content: "";
}

.fa-user-astronaut:before {
  content: "";
}

.fa-interrobang:before {
  content: "";
}

.fa-plane-slash:before {
  content: "";
}

.fa-circle-dashed:before {
  content: "";
}

.fa-trademark:before {
  content: "";
}

.fa-basketball:before, .fa-basketball-ball:before {
  content: "";
}

.fa-fork-knife:before, .fa-utensils-alt:before {
  content: "";
}

.fa-satellite-dish:before {
  content: "";
}

.fa-badge-check:before {
  content: "";
}

.fa-circle-up:before, .fa-arrow-alt-circle-up:before {
  content: "";
}

.fa-slider:before {
  content: "";
}

.fa-mobile-screen-button:before, .fa-mobile-alt:before {
  content: "";
}

.fa-clock-one-thirty:before {
  content: "";
}

.fa-inbox-out:before, .fa-inbox-arrow-up:before {
  content: "";
}

.fa-cloud-slash:before {
  content: "";
}

.fa-volume-high:before, .fa-volume-up:before {
  content: "";
}

.fa-users-rays:before {
  content: "";
}

.fa-wallet:before {
  content: "";
}

.fa-octagon-check:before {
  content: "";
}

.fa-flatbread-stuffed:before {
  content: "";
}

.fa-clipboard-check:before {
  content: "";
}

.fa-cart-circle-plus:before {
  content: "";
}

.fa-truck-clock:before, .fa-shipping-timed:before {
  content: "";
}

.fa-pool-8-ball:before {
  content: "";
}

.fa-file-audio:before {
  content: "";
}

.fa-turn-down-left:before {
  content: "";
}

.fa-lock-hashtag:before {
  content: "";
}

.fa-chart-radar:before {
  content: "";
}

.fa-staff:before {
  content: "";
}

.fa-burger:before, .fa-hamburger:before {
  content: "";
}

.fa-utility-pole:before {
  content: "";
}

.fa-transporter-6:before {
  content: "";
}

.fa-wrench:before {
  content: "";
}

.fa-bugs:before {
  content: "";
}

.fa-vector-polygon:before {
  content: "";
}

.fa-diagram-nested:before {
  content: "";
}

.fa-rupee-sign:before, .fa-rupee:before {
  content: "";
}

.fa-file-image:before {
  content: "";
}

.fa-circle-question:before, .fa-question-circle:before {
  content: "";
}

.fa-image-user:before {
  content: "";
}

.fa-buoy:before {
  content: "";
}

.fa-plane-departure:before {
  content: "";
}

.fa-handshake-slash:before {
  content: "";
}

.fa-book-bookmark:before {
  content: "";
}

.fa-border-center-h:before {
  content: "";
}

.fa-can-food:before {
  content: "";
}

.fa-typewriter:before {
  content: "";
}

.fa-arrow-right-from-arc:before {
  content: "";
}

.fa-circle-k:before {
  content: "";
}

.fa-face-hand-over-mouth:before {
  content: "";
}

.fa-popcorn:before {
  content: "";
}

.fa-house-water:before, .fa-house-flood:before {
  content: "";
}

.fa-object-subtract:before {
  content: "";
}

.fa-code-branch:before {
  content: "";
}

.fa-warehouse-full:before, .fa-warehouse-alt:before {
  content: "";
}

.fa-hat-cowboy:before {
  content: "";
}

.fa-bridge:before {
  content: "";
}

.fa-phone-flip:before, .fa-phone-alt:before {
  content: "";
}

.fa-arrow-down-from-dotted-line:before {
  content: "";
}

.fa-square-quarters:before {
  content: "";
}

.fa-truck-front:before {
  content: "";
}

.fa-cat:before {
  content: "";
}

.fa-trash-xmark:before {
  content: "";
}

.fa-circle-caret-left:before, .fa-caret-circle-left:before {
  content: "";
}

.fa-files:before {
  content: "";
}

.fa-anchor-circle-exclamation:before {
  content: "";
}

.fa-face-clouds:before {
  content: "";
}

.fa-user-crown:before {
  content: "";
}

.fa-truck-field:before {
  content: "";
}

.fa-route:before {
  content: "";
}

.fa-cart-circle-check:before {
  content: "";
}

.fa-clipboard-question:before {
  content: "";
}

.fa-panorama:before {
  content: "";
}

.fa-comment-medical:before {
  content: "";
}

.fa-teeth-open:before {
  content: "";
}

.fa-user-tie-hair-long:before {
  content: "";
}

.fa-file-circle-minus:before {
  content: "";
}

.fa-head-side-medical:before {
  content: "";
}

.fa-tags:before {
  content: "";
}

.fa-wine-glass:before {
  content: "";
}

.fa-forward-fast:before, .fa-fast-forward:before {
  content: "";
}

.fa-face-meh-blank:before, .fa-meh-blank:before {
  content: "";
}

.fa-user-robot:before {
  content: "";
}

.fa-square-parking:before, .fa-parking:before {
  content: "";
}

.fa-card-diamond:before {
  content: "";
}

.fa-face-zipper:before {
  content: "";
}

.fa-face-raised-eyebrow:before {
  content: "";
}

.fa-house-signal:before {
  content: "";
}

.fa-square-chevron-up:before, .fa-chevron-square-up:before {
  content: "";
}

.fa-bars-progress:before, .fa-tasks-alt:before {
  content: "";
}

.fa-faucet-drip:before {
  content: "";
}

.fa-arrows-to-line:before {
  content: "";
}

.fa-dolphin:before {
  content: "";
}

.fa-arrow-up-right:before {
  content: "";
}

.fa-circle-r:before {
  content: "";
}

.fa-cart-flatbed:before, .fa-dolly-flatbed:before {
  content: "";
}

.fa-ban-smoking:before, .fa-smoking-ban:before {
  content: "";
}

.fa-circle-sort-up:before, .fa-sort-circle-up:before {
  content: "";
}

.fa-terminal:before {
  content: "";
}

.fa-mobile-button:before {
  content: "";
}

.fa-house-medical-flag:before {
  content: "";
}

.fa-basket-shopping:before, .fa-shopping-basket:before {
  content: "";
}

.fa-tape:before {
  content: "";
}

.fa-chestnut:before {
  content: "";
}

.fa-bus-simple:before, .fa-bus-alt:before {
  content: "";
}

.fa-eye:before {
  content: "";
}

.fa-face-sad-cry:before, .fa-sad-cry:before {
  content: "";
}

.fa-heat:before {
  content: "";
}

.fa-ticket-airline:before {
  content: "";
}

.fa-boot-heeled:before {
  content: "";
}

.fa-arrows-minimize:before, .fa-compress-arrows:before {
  content: "";
}

.fa-audio-description:before {
  content: "";
}

.fa-person-military-to-person:before {
  content: "";
}

.fa-file-shield:before {
  content: "";
}

.fa-hexagon:before {
  content: "";
}

.fa-manhole:before {
  content: "";
}

.fa-user-slash:before {
  content: "";
}

.fa-pen:before {
  content: "";
}

.fa-tower-observation:before {
  content: "";
}

.fa-floppy-disks:before {
  content: "";
}

.fa-toilet-paper-blank-under:before, .fa-toilet-paper-reverse-alt:before {
  content: "";
}

.fa-file-code:before {
  content: "";
}

.fa-signal:before, .fa-signal-5:before, .fa-signal-perfect:before {
  content: "";
}

.fa-pump:before {
  content: "";
}

.fa-bus:before {
  content: "";
}

.fa-heart-circle-xmark:before {
  content: "";
}

.fa-arrow-up-left-from-circle:before {
  content: "";
}

.fa-house-chimney:before, .fa-home-lg:before {
  content: "";
}

.fa-window-maximize:before {
  content: "";
}

.fa-dryer:before {
  content: "";
}

.fa-face-frown:before, .fa-frown:before {
  content: "";
}

.fa-chess-bishop-piece:before, .fa-chess-bishop-alt:before {
  content: "";
}

.fa-shirt-tank-top:before {
  content: "";
}

.fa-diploma:before, .fa-scroll-ribbon:before {
  content: "";
}

.fa-screencast:before {
  content: "";
}

.fa-walker:before {
  content: "";
}

.fa-prescription:before {
  content: "";
}

.fa-shop:before, .fa-store-alt:before {
  content: "";
}

.fa-floppy-disk:before, .fa-save:before {
  content: "";
}

.fa-vihara:before {
  content: "";
}

.fa-face-kiss-closed-eyes:before {
  content: "";
}

.fa-scale-unbalanced:before, .fa-balance-scale-left:before {
  content: "";
}

.fa-file-user:before {
  content: "";
}

.fa-user-police-tie:before {
  content: "";
}

.fa-face-tongue-money:before {
  content: "";
}

.fa-tennis-ball:before {
  content: "";
}

.fa-square-l:before {
  content: "";
}

.fa-sort-up:before, .fa-sort-asc:before {
  content: "";
}

.fa-calendar-arrow-up:before, .fa-calendar-upload:before {
  content: "";
}

.fa-comment-dots:before, .fa-commenting:before {
  content: "";
}

.fa-plant-wilt:before {
  content: "";
}

.fa-scarf:before {
  content: "";
}

.fa-album-circle-plus:before {
  content: "";
}

.fa-user-nurse-hair-long:before {
  content: "";
}

.fa-diamond:before {
  content: "";
}

.fa-square-left:before, .fa-arrow-alt-square-left:before {
  content: "";
}

.fa-face-grin-squint:before, .fa-grin-squint:before {
  content: "";
}

.fa-circle-ellipsis-vertical:before {
  content: "";
}

.fa-hand-holding-dollar:before, .fa-hand-holding-usd:before {
  content: "";
}

.fa-grid-dividers:before {
  content: "";
}

.fa-bacterium:before {
  content: "";
}

.fa-hand-pointer:before {
  content: "";
}

.fa-drum-steelpan:before {
  content: "";
}

.fa-hand-scissors:before {
  content: "";
}

.fa-hands-praying:before, .fa-praying-hands:before {
  content: "";
}

.fa-face-pensive:before {
  content: "";
}

.fa-user-music:before {
  content: "";
}

.fa-arrow-rotate-right:before, .fa-arrow-right-rotate:before, .fa-arrow-rotate-forward:before, .fa-redo:before {
  content: "";
}

.fa-messages-dollar:before, .fa-comments-alt-dollar:before {
  content: "";
}

.fa-sensor-on:before {
  content: "";
}

.fa-balloon:before {
  content: "";
}

.fa-biohazard:before {
  content: "";
}

.fa-chess-queen-piece:before, .fa-chess-queen-alt:before {
  content: "";
}

.fa-location-crosshairs:before, .fa-location:before {
  content: "";
}

.fa-mars-double:before {
  content: "";
}

.fa-house-person-leave:before, .fa-house-leave:before, .fa-house-person-depart:before {
  content: "";
}

.fa-ruler-triangle:before {
  content: "";
}

.fa-card-club:before {
  content: "";
}

.fa-child-dress:before {
  content: "";
}

.fa-users-between-lines:before {
  content: "";
}

.fa-lungs-virus:before {
  content: "";
}

.fa-spinner-third:before {
  content: "";
}

.fa-face-grin-tears:before, .fa-grin-tears:before {
  content: "";
}

.fa-phone:before {
  content: "";
}

.fa-computer-mouse-scrollwheel:before, .fa-mouse-alt:before {
  content: "";
}

.fa-calendar-xmark:before, .fa-calendar-times:before {
  content: "";
}

.fa-child-reaching:before {
  content: "";
}

.fa-table-layout:before {
  content: "";
}

.fa-narwhal:before {
  content: "";
}

.fa-ramp-loading:before {
  content: "";
}

.fa-calendar-circle-plus:before {
  content: "";
}

.fa-toothbrush:before {
  content: "";
}

.fa-border-inner:before {
  content: "";
}

.fa-paw-claws:before {
  content: "";
}

.fa-kiwi-fruit:before {
  content: "";
}

.fa-traffic-light-slow:before {
  content: "";
}

.fa-rectangle-code:before {
  content: "";
}

.fa-head-side-virus:before {
  content: "";
}

.fa-keyboard-brightness:before {
  content: "";
}

.fa-books-medical:before {
  content: "";
}

.fa-lightbulb-slash:before {
  content: "";
}

.fa-house-blank:before, .fa-home-blank:before {
  content: "";
}

.fa-square-5:before {
  content: "";
}

.fa-square-heart:before, .fa-heart-square:before {
  content: "";
}

.fa-puzzle:before {
  content: "";
}

.fa-user-gear:before, .fa-user-cog:before {
  content: "";
}

.fa-pipe-circle-check:before {
  content: "";
}

.fa-arrow-up-1-9:before, .fa-sort-numeric-up:before {
  content: "";
}

.fa-octagon-exclamation:before {
  content: "";
}

.fa-dial-low:before {
  content: "";
}

.fa-door-closed:before {
  content: "";
}

.fa-laptop-mobile:before, .fa-phone-laptop:before {
  content: "";
}

.fa-conveyor-belt-boxes:before, .fa-conveyor-belt-alt:before {
  content: "";
}

.fa-shield-virus:before {
  content: "";
}

.fa-starfighter-twin-ion-engine-advanced:before, .fa-starfighter-alt-advanced:before {
  content: "";
}

.fa-dice-six:before {
  content: "";
}

.fa-starfighter-twin-ion-engine:before, .fa-starfighter-alt:before {
  content: "";
}

.fa-rocket-launch:before {
  content: "";
}

.fa-mosquito-net:before {
  content: "";
}

.fa-vent-damper:before {
  content: "";
}

.fa-bridge-water:before {
  content: "";
}

.fa-ban-bug:before, .fa-debug:before {
  content: "";
}

.fa-person-booth:before {
  content: "";
}

.fa-text-width:before {
  content: "";
}

.fa-garage-car:before {
  content: "";
}

.fa-square-kanban:before {
  content: "";
}

.fa-hat-wizard:before {
  content: "";
}

.fa-pen-fancy:before {
  content: "";
}

.fa-coffee-pot:before {
  content: "";
}

.fa-mouse-field:before {
  content: "";
}

.fa-person-digging:before, .fa-digging:before {
  content: "";
}

.fa-shower-down:before, .fa-shower-alt:before {
  content: "";
}

.fa-box-circle-check:before {
  content: "";
}

.fa-brightness:before {
  content: "";
}

.fa-car-side-bolt:before {
  content: "";
}

.fa-ornament:before {
  content: "";
}

.fa-phone-arrow-down-left:before, .fa-phone-arrow-down:before, .fa-phone-incoming:before {
  content: "";
}

.fa-cloud-word:before {
  content: "";
}

.fa-hand-fingers-crossed:before {
  content: "";
}

.fa-trash:before {
  content: "";
}

.fa-gauge-simple:before, .fa-gauge-simple-med:before, .fa-tachometer-average:before {
  content: "";
}

.fa-arrow-down-small-big:before, .fa-sort-size-down-alt:before {
  content: "";
}

.fa-book-medical:before {
  content: "";
}

.fa-face-melting:before {
  content: "";
}

.fa-poo:before {
  content: "";
}

.fa-pen-clip-slash:before, .fa-pen-alt-slash:before {
  content: "";
}

.fa-quote-right:before, .fa-quote-right-alt:before {
  content: "";
}

.fa-scroll-old:before {
  content: "";
}

.fa-guitars:before {
  content: "";
}

.fa-phone-xmark:before {
  content: "";
}

.fa-hose:before {
  content: "";
}

.fa-clock-six:before {
  content: "";
}

.fa-shirt:before, .fa-t-shirt:before, .fa-tshirt:before {
  content: "";
}

.fa-billboard:before {
  content: "";
}

.fa-square-r:before {
  content: "";
}

.fa-cubes:before {
  content: "";
}

.fa-envelope-open-dollar:before {
  content: "";
}

.fa-divide:before {
  content: "";
}

.fa-sun-cloud:before {
  content: "";
}

.fa-lamp-floor:before {
  content: "";
}

.fa-square-7:before {
  content: "";
}

.fa-tenge-sign:before, .fa-tenge:before {
  content: "";
}

.fa-headphones:before {
  content: "";
}

.fa-hands-holding:before {
  content: "";
}

.fa-campfire:before {
  content: "";
}

.fa-circle-ampersand:before {
  content: "";
}

.fa-snowflakes:before {
  content: "";
}

.fa-hands-clapping:before {
  content: "";
}

.fa-republican:before {
  content: "";
}

.fa-leaf-maple:before {
  content: "";
}

.fa-arrow-left:before {
  content: "";
}

.fa-person-circle-xmark:before {
  content: "";
}

.fa-ruler:before {
  content: "";
}

.fa-cup-straw-swoosh:before {
  content: "";
}

.fa-temperature-sun:before, .fa-temperature-hot:before {
  content: "";
}

.fa-align-left:before {
  content: "";
}

.fa-dice-d6:before {
  content: "";
}

.fa-restroom:before {
  content: "";
}

.fa-high-definition:before, .fa-rectangle-hd:before {
  content: "";
}

.fa-j:before {
  content: "J";
}

.fa-galaxy:before {
  content: "";
}

.fa-users-viewfinder:before {
  content: "";
}

.fa-file-video:before {
  content: "";
}

.fa-cherries:before {
  content: "";
}

.fa-up-right-from-square:before, .fa-external-link-alt:before {
  content: "";
}

.fa-circle-sort:before, .fa-sort-circle:before {
  content: "";
}

.fa-table-cells:before, .fa-th:before {
  content: "";
}

.fa-file-pdf:before {
  content: "";
}

.fa-siren:before {
  content: "";
}

.fa-arrow-up-to-dotted-line:before {
  content: "";
}

.fa-image-landscape:before, .fa-landscape:before {
  content: "";
}

.fa-tank-water:before {
  content: "";
}

.fa-curling-stone:before, .fa-curling:before {
  content: "";
}

.fa-gamepad-modern:before, .fa-gamepad-alt:before {
  content: "";
}

.fa-messages-question:before {
  content: "";
}

.fa-book-bible:before, .fa-bible:before {
  content: "";
}

.fa-o:before {
  content: "O";
}

.fa-suitcase-medical:before, .fa-medkit:before {
  content: "";
}

.fa-briefcase-arrow-right:before {
  content: "";
}

.fa-expand-wide:before {
  content: "";
}

.fa-clock-eleven-thirty:before {
  content: "";
}

.fa-rv:before {
  content: "";
}

.fa-user-secret:before {
  content: "";
}

.fa-otter:before {
  content: "";
}

.fa-dreidel:before {
  content: "";
}

.fa-person-dress:before, .fa-female:before {
  content: "";
}

.fa-comment-dollar:before {
  content: "";
}

.fa-business-time:before, .fa-briefcase-clock:before {
  content: "";
}

.fa-flower-tulip:before {
  content: "";
}

.fa-people-pants-simple:before {
  content: "";
}

.fa-cloud-drizzle:before {
  content: "";
}

.fa-table-cells-large:before, .fa-th-large:before {
  content: "";
}

.fa-book-tanakh:before, .fa-tanakh:before {
  content: "";
}

.fa-solar-system:before {
  content: "";
}

.fa-seal-question:before {
  content: "";
}

.fa-phone-volume:before, .fa-volume-control-phone:before {
  content: "";
}

.fa-disc-drive:before {
  content: "";
}

.fa-hat-cowboy-side:before {
  content: "";
}

.fa-table-rows:before, .fa-rows:before {
  content: "";
}

.fa-location-exclamation:before, .fa-map-marker-exclamation:before {
  content: "";
}

.fa-face-fearful:before {
  content: "";
}

.fa-clipboard-user:before {
  content: "";
}

.fa-bus-school:before {
  content: "";
}

.fa-film-slash:before {
  content: "";
}

.fa-square-arrow-down-right:before {
  content: "";
}

.fa-book-sparkles:before, .fa-book-spells:before {
  content: "";
}

.fa-washing-machine:before, .fa-washer:before {
  content: "";
}

.fa-child:before {
  content: "";
}

.fa-lira-sign:before {
  content: "";
}

.fa-user-visor:before {
  content: "";
}

.fa-file-plus-minus:before {
  content: "";
}

.fa-chess-clock-flip:before, .fa-chess-clock-alt:before {
  content: "";
}

.fa-satellite:before {
  content: "";
}

.fa-plane-lock:before {
  content: "";
}

.fa-steering-wheel:before {
  content: "";
}

.fa-tag:before {
  content: "";
}

.fa-stretcher:before {
  content: "";
}

.fa-book-section:before, .fa-book-law:before {
  content: "";
}

.fa-inboxes:before {
  content: "";
}

.fa-coffee-bean:before {
  content: "";
}

.fa-circle-yen:before {
  content: "";
}

.fa-brackets-curly:before {
  content: "";
}

.fa-ellipsis-stroke-vertical:before, .fa-ellipsis-v-alt:before {
  content: "";
}

.fa-comment:before {
  content: "";
}

.fa-square-1:before {
  content: "";
}

.fa-cake-candles:before, .fa-birthday-cake:before, .fa-cake:before {
  content: "";
}

.fa-head-side:before {
  content: "";
}

.fa-envelope:before {
  content: "";
}

.fa-dolly-empty:before {
  content: "";
}

.fa-face-tissue:before {
  content: "";
}

.fa-angles-up:before, .fa-angle-double-up:before {
  content: "";
}

.fa-paperclip:before {
  content: "";
}

.fa-chart-line-down:before {
  content: "";
}

.fa-arrow-right-to-city:before {
  content: "";
}

.fa-lock-a:before {
  content: "";
}

.fa-ribbon:before {
  content: "";
}

.fa-lungs:before {
  content: "";
}

.fa-person-pinball:before {
  content: "";
}

.fa-arrow-up-9-1:before, .fa-sort-numeric-up-alt:before {
  content: "";
}

.fa-apple-core:before {
  content: "";
}

.fa-circle-y:before {
  content: "";
}

.fa-h6:before {
  content: "";
}

.fa-litecoin-sign:before {
  content: "";
}

.fa-circle-small:before {
  content: "";
}

.fa-border-none:before {
  content: "";
}

.fa-arrow-turn-down-left:before {
  content: "";
}

.fa-circle-nodes:before {
  content: "";
}

.fa-parachute-box:before {
  content: "";
}

.fa-message-medical:before, .fa-comment-alt-medical:before {
  content: "";
}

.fa-rugby-ball:before {
  content: "";
}

.fa-comment-music:before {
  content: "";
}

.fa-indent:before {
  content: "";
}

.fa-tree-deciduous:before, .fa-tree-alt:before {
  content: "";
}

.fa-puzzle-piece-simple:before, .fa-puzzle-piece-alt:before {
  content: "";
}

.fa-truck-field-un:before {
  content: "";
}

.fa-nfc-trash:before {
  content: "";
}

.fa-hourglass:before, .fa-hourglass-empty:before {
  content: "";
}

.fa-mountain:before {
  content: "";
}

.fa-file-xmark:before, .fa-file-times:before {
  content: "";
}

.fa-house-heart:before, .fa-home-heart:before {
  content: "";
}

.fa-house-chimney-blank:before {
  content: "";
}

.fa-meter-bolt:before {
  content: "";
}

.fa-user-doctor:before, .fa-user-md:before {
  content: "";
}

.fa-slash-back:before {
  content: "\\";
}

.fa-circle-info:before, .fa-info-circle:before {
  content: "";
}

.fa-fishing-rod:before {
  content: "";
}

.fa-hammer-crash:before {
  content: "";
}

.fa-message-heart:before {
  content: "";
}

.fa-cloud-meatball:before {
  content: "";
}

.fa-camera-polaroid:before {
  content: "";
}

.fa-camera:before, .fa-camera-alt:before {
  content: "";
}

.fa-square-virus:before {
  content: "";
}

.fa-cart-arrow-up:before {
  content: "";
}

.fa-meteor:before {
  content: "";
}

.fa-car-on:before {
  content: "";
}

.fa-sleigh:before {
  content: "";
}

.fa-arrow-down-1-9:before, .fa-sort-numeric-asc:before, .fa-sort-numeric-down:before {
  content: "";
}

.fa-buoy-mooring:before {
  content: "";
}

.fa-square-4:before {
  content: "";
}

.fa-hand-holding-droplet:before, .fa-hand-holding-water:before {
  content: "";
}

.fa-tricycle-adult:before {
  content: "";
}

.fa-waveform:before {
  content: "";
}

.fa-water:before {
  content: "";
}

.fa-star-sharp-half-stroke:before, .fa-star-sharp-half-alt:before {
  content: "";
}

.fa-nfc-signal:before {
  content: "";
}

.fa-plane-prop:before {
  content: "";
}

.fa-calendar-check:before {
  content: "";
}

.fa-clock-desk:before {
  content: "";
}

.fa-calendar-clock:before, .fa-calendar-time:before {
  content: "";
}

.fa-braille:before {
  content: "";
}

.fa-prescription-bottle-medical:before, .fa-prescription-bottle-alt:before {
  content: "";
}

.fa-plate-utensils:before {
  content: "";
}

.fa-family-pants:before {
  content: "";
}

.fa-hose-reel:before {
  content: "";
}

.fa-house-window:before {
  content: "";
}

.fa-landmark:before {
  content: "";
}

.fa-truck:before {
  content: "";
}

.fa-crosshairs:before {
  content: "";
}

.fa-cloud-rainbow:before {
  content: "";
}

.fa-person-cane:before {
  content: "";
}

.fa-alien:before {
  content: "";
}

.fa-tent:before {
  content: "";
}

.fa-vest-patches:before {
  content: "";
}

.fa-people-dress-simple:before {
  content: "";
}

.fa-check-double:before {
  content: "";
}

.fa-arrow-down-a-z:before, .fa-sort-alpha-asc:before, .fa-sort-alpha-down:before {
  content: "";
}

.fa-bowling-ball-pin:before {
  content: "";
}

.fa-bell-school-slash:before {
  content: "";
}

.fa-plus-large:before {
  content: "";
}

.fa-money-bill-wheat:before {
  content: "";
}

.fa-camera-viewfinder:before, .fa-screenshot:before {
  content: "";
}

.fa-message-music:before, .fa-comment-alt-music:before {
  content: "";
}

.fa-car-building:before {
  content: "";
}

.fa-border-bottom-right:before, .fa-border-style-alt:before {
  content: "";
}

.fa-octagon:before {
  content: "";
}

.fa-comment-arrow-up-right:before {
  content: "";
}

.fa-octagon-divide:before {
  content: "";
}

.fa-cookie:before {
  content: "";
}

.fa-arrow-rotate-left:before, .fa-arrow-left-rotate:before, .fa-arrow-rotate-back:before, .fa-arrow-rotate-backward:before, .fa-undo:before {
  content: "";
}

.fa-tv-music:before {
  content: "";
}

.fa-hard-drive:before, .fa-hdd:before {
  content: "";
}

.fa-reel:before {
  content: "";
}

.fa-face-grin-squint-tears:before, .fa-grin-squint-tears:before {
  content: "";
}

.fa-dumbbell:before {
  content: "";
}

.fa-rectangle-list:before, .fa-list-alt:before {
  content: "";
}

.fa-tarp-droplet:before {
  content: "";
}

.fa-alarm-exclamation:before {
  content: "";
}

.fa-house-medical-circle-check:before {
  content: "";
}

.fa-traffic-cone:before {
  content: "";
}

.fa-grate:before {
  content: "";
}

.fa-arrow-down-right:before {
  content: "";
}

.fa-person-skiing-nordic:before, .fa-skiing-nordic:before {
  content: "";
}

.fa-calendar-plus:before {
  content: "";
}

.fa-person-from-portal:before, .fa-portal-exit:before {
  content: "";
}

.fa-plane-arrival:before {
  content: "";
}

.fa-cowbell-circle-plus:before, .fa-cowbell-more:before {
  content: "";
}

.fa-circle-left:before, .fa-arrow-alt-circle-left:before {
  content: "";
}

.fa-distribute-spacing-vertical:before {
  content: "";
}

.fa-signal-bars-fair:before, .fa-signal-alt-2:before {
  content: "";
}

.fa-sportsball:before {
  content: "";
}

.fa-game-console-handheld-crank:before {
  content: "";
}

.fa-train-subway:before, .fa-subway:before {
  content: "";
}

.fa-chart-gantt:before {
  content: "";
}

.fa-face-smile-upside-down:before {
  content: "";
}

.fa-ball-pile:before {
  content: "";
}

.fa-badge-dollar:before {
  content: "";
}

.fa-money-bills-simple:before, .fa-money-bills-alt:before {
  content: "";
}

.fa-list-timeline:before {
  content: "";
}

.fa-indian-rupee-sign:before, .fa-indian-rupee:before, .fa-inr:before {
  content: "";
}

.fa-crop-simple:before, .fa-crop-alt:before {
  content: "";
}

.fa-money-bill-1:before, .fa-money-bill-alt:before {
  content: "";
}

.fa-left-long:before, .fa-long-arrow-alt-left:before {
  content: "";
}

.fa-keyboard-down:before {
  content: "";
}

.fa-circle-up-right:before {
  content: "";
}

.fa-cloud-bolt-moon:before, .fa-thunderstorm-moon:before {
  content: "";
}

.fa-dna:before {
  content: "";
}

.fa-virus-slash:before {
  content: "";
}

.fa-bracket-round-right:before {
  content: ")";
}

.fa-circle-sterling:before {
  content: "";
}

.fa-circle-5:before {
  content: "";
}

.fa-minus:before, .fa-subtract:before {
  content: "";
}

.fa-fire-flame:before, .fa-flame:before {
  content: "";
}

.fa-right-to-line:before, .fa-arrow-alt-to-right:before {
  content: "";
}

.fa-gif:before {
  content: "";
}

.fa-chess:before {
  content: "";
}

.fa-trash-slash:before {
  content: "";
}

.fa-arrow-left-long:before, .fa-long-arrow-left:before {
  content: "";
}

.fa-plug-circle-check:before {
  content: "";
}

.fa-font-case:before {
  content: "";
}

.fa-street-view:before {
  content: "";
}

.fa-arrow-down-left:before {
  content: "";
}

.fa-franc-sign:before {
  content: "";
}

.fa-flask-round-poison:before, .fa-flask-poison:before {
  content: "";
}

.fa-volume-off:before {
  content: "";
}

.fa-book-circle-arrow-right:before {
  content: "";
}

.fa-chart-user:before, .fa-user-chart:before {
  content: "";
}

.fa-hands-asl-interpreting:before, .fa-american-sign-language-interpreting:before, .fa-asl-interpreting:before, .fa-hands-american-sign-language-interpreting:before {
  content: "";
}

.fa-presentation-screen:before, .fa-presentation:before {
  content: "";
}

.fa-circle-bolt:before {
  content: "";
}

.fa-face-smile-halo:before {
  content: "";
}

.fa-cart-circle-arrow-down:before {
  content: "";
}

.fa-house-person-return:before, .fa-house-person-arrive:before, .fa-house-return:before {
  content: "";
}

.fa-message-xmark:before, .fa-comment-alt-times:before, .fa-message-times:before {
  content: "";
}

.fa-file-certificate:before, .fa-file-award:before {
  content: "";
}

.fa-user-doctor-hair-long:before {
  content: "";
}

.fa-camera-security:before, .fa-camera-home:before {
  content: "";
}

.fa-gear:before, .fa-cog:before {
  content: "";
}

.fa-droplet-slash:before, .fa-tint-slash:before {
  content: "";
}

.fa-book-heart:before {
  content: "";
}

.fa-mosque:before {
  content: "";
}

.fa-duck:before {
  content: "";
}

.fa-mosquito:before {
  content: "";
}

.fa-star-of-david:before {
  content: "";
}

.fa-flag-swallowtail:before, .fa-flag-alt:before {
  content: "";
}

.fa-person-military-rifle:before {
  content: "";
}

.fa-car-garage:before {
  content: "";
}

.fa-cart-shopping:before, .fa-shopping-cart:before {
  content: "";
}

.fa-book-font:before {
  content: "";
}

.fa-shield-plus:before {
  content: "";
}

.fa-vials:before {
  content: "";
}

.fa-eye-dropper-full:before {
  content: "";
}

.fa-distribute-spacing-horizontal:before {
  content: "";
}

.fa-tablet-rugged:before {
  content: "";
}

.fa-temperature-snow:before, .fa-temperature-frigid:before {
  content: "";
}

.fa-moped:before {
  content: "";
}

.fa-face-smile-plus:before, .fa-smile-plus:before {
  content: "";
}

.fa-radio-tuner:before, .fa-radio-alt:before {
  content: "";
}

.fa-face-swear:before {
  content: "";
}

.fa-water-arrow-down:before, .fa-water-lower:before {
  content: "";
}

.fa-scanner-touchscreen:before {
  content: "";
}

.fa-circle-7:before {
  content: "";
}

.fa-plug-circle-plus:before {
  content: "";
}

.fa-person-ski-jumping:before, .fa-ski-jump:before {
  content: "";
}

.fa-place-of-worship:before {
  content: "";
}

.fa-water-arrow-up:before, .fa-water-rise:before {
  content: "";
}

.fa-waveform-lines:before, .fa-waveform-path:before {
  content: "";
}

.fa-split:before {
  content: "";
}

.fa-film-canister:before, .fa-film-cannister:before {
  content: "";
}

.fa-folder-xmark:before, .fa-folder-times:before {
  content: "";
}

.fa-toilet-paper-blank:before, .fa-toilet-paper-alt:before {
  content: "";
}

.fa-tablet-screen:before, .fa-tablet-android-alt:before {
  content: "";
}

.fa-hexagon-vertical-nft-slanted:before {
  content: "";
}

.fa-folder-music:before {
  content: "";
}

.fa-display-medical:before, .fa-desktop-medical:before {
  content: "";
}

.fa-share-all:before {
  content: "";
}

.fa-peapod:before {
  content: "";
}

.fa-chess-clock:before {
  content: "";
}

.fa-axe:before {
  content: "";
}

.fa-square-d:before {
  content: "";
}

.fa-grip-vertical:before {
  content: "";
}

.fa-mobile-signal-out:before {
  content: "";
}

.fa-arrow-turn-up:before, .fa-level-up:before {
  content: "";
}

.fa-u:before {
  content: "U";
}

.fa-arrow-up-from-dotted-line:before {
  content: "";
}

.fa-square-root-variable:before, .fa-square-root-alt:before {
  content: "";
}

.fa-light-switch-on:before {
  content: "";
}

.fa-arrow-down-arrow-up:before, .fa-sort-alt:before {
  content: "";
}

.fa-raindrops:before {
  content: "";
}

.fa-dash:before, .fa-minus-large:before {
  content: "";
}

.fa-clock:before, .fa-clock-four:before {
  content: "";
}

.fa-input-numeric:before {
  content: "";
}

.fa-truck-tow:before {
  content: "";
}

.fa-backward-step:before, .fa-step-backward:before {
  content: "";
}

.fa-pallet:before {
  content: "";
}

.fa-car-bolt:before {
  content: "";
}

.fa-arrows-maximize:before, .fa-expand-arrows:before {
  content: "";
}

.fa-faucet:before {
  content: "";
}

.fa-cloud-sleet:before {
  content: "";
}

.fa-lamp-street:before {
  content: "";
}

.fa-list-radio:before {
  content: "";
}

.fa-pen-nib-slash:before {
  content: "";
}

.fa-baseball-bat-ball:before {
  content: "";
}

.fa-square-up-left:before {
  content: "";
}

.fa-overline:before {
  content: "";
}

.fa-s:before {
  content: "S";
}

.fa-timeline:before {
  content: "";
}

.fa-keyboard:before {
  content: "";
}

.fa-arrows-from-dotted-line:before {
  content: "";
}

.fa-usb-drive:before {
  content: "";
}

.fa-ballot:before {
  content: "";
}

.fa-caret-down:before {
  content: "";
}

.fa-location-dot-slash:before, .fa-map-marker-alt-slash:before {
  content: "";
}

.fa-cards:before {
  content: "";
}

.fa-house-chimney-medical:before, .fa-clinic-medical:before {
  content: "";
}

.fa-boxing-glove:before, .fa-glove-boxing:before {
  content: "";
}

.fa-temperature-three-quarters:before, .fa-temperature-3:before, .fa-thermometer-3:before, .fa-thermometer-three-quarters:before {
  content: "";
}

.fa-bell-school:before {
  content: "";
}

.fa-mobile-screen:before, .fa-mobile-android-alt:before {
  content: "";
}

.fa-plane-up:before {
  content: "";
}

.fa-folder-heart:before {
  content: "";
}

.fa-circle-location-arrow:before, .fa-location-circle:before {
  content: "";
}

.fa-face-head-bandage:before {
  content: "";
}

.fa-sushi-roll:before, .fa-maki-roll:before, .fa-makizushi:before {
  content: "";
}

.fa-car-bump:before {
  content: "";
}

.fa-piggy-bank:before {
  content: "";
}

.fa-racquet:before {
  content: "";
}

.fa-car-mirrors:before {
  content: "";
}

.fa-industry-windows:before, .fa-industry-alt:before {
  content: "";
}

.fa-bolt-auto:before {
  content: "";
}

.fa-battery-half:before, .fa-battery-3:before {
  content: "";
}

.fa-flux-capacitor:before {
  content: "";
}

.fa-mountain-city:before {
  content: "";
}

.fa-coins:before {
  content: "";
}

.fa-honey-pot:before {
  content: "";
}

.fa-olive:before {
  content: "";
}

.fa-khanda:before {
  content: "";
}

.fa-filter-list:before {
  content: "";
}

.fa-outlet:before {
  content: "";
}

.fa-sliders:before, .fa-sliders-h:before {
  content: "";
}

.fa-cauldron:before {
  content: "";
}

.fa-people:before {
  content: "";
}

.fa-folder-tree:before {
  content: "";
}

.fa-network-wired:before {
  content: "";
}

.fa-croissant:before {
  content: "";
}

.fa-map-pin:before {
  content: "";
}

.fa-hamsa:before {
  content: "";
}

.fa-cent-sign:before {
  content: "";
}

.fa-swords-laser:before {
  content: "";
}

.fa-flask:before {
  content: "";
}

.fa-person-pregnant:before {
  content: "";
}

.fa-square-u:before {
  content: "";
}

.fa-wand-sparkles:before {
  content: "";
}

.fa-router:before {
  content: "";
}

.fa-ellipsis-vertical:before, .fa-ellipsis-v:before {
  content: "";
}

.fa-sword-laser-alt:before {
  content: "";
}

.fa-ticket:before {
  content: "";
}

.fa-power-off:before {
  content: "";
}

.fa-coin:before {
  content: "";
}

.fa-laptop-slash:before {
  content: "";
}

.fa-right-long:before, .fa-long-arrow-alt-right:before {
  content: "";
}

.fa-circle-b:before {
  content: "";
}

.fa-person-dress-simple:before {
  content: "";
}

.fa-pipe-collar:before {
  content: "";
}

.fa-lights-holiday:before {
  content: "";
}

.fa-citrus:before {
  content: "";
}

.fa-flag-usa:before {
  content: "";
}

.fa-laptop-file:before {
  content: "";
}

.fa-tty:before, .fa-teletype:before {
  content: "";
}

.fa-chart-tree-map:before {
  content: "";
}

.fa-diagram-next:before {
  content: "";
}

.fa-person-rifle:before {
  content: "";
}

.fa-clock-five-thirty:before {
  content: "";
}

.fa-pipe-valve:before {
  content: "";
}

.fa-arrow-up-from-arc:before {
  content: "";
}

.fa-face-spiral-eyes:before {
  content: "";
}

.fa-compress-wide:before {
  content: "";
}

.fa-circle-phone-hangup:before, .fa-phone-circle-down:before {
  content: "";
}

.fa-house-medical-circle-exclamation:before {
  content: "";
}

.fa-badminton:before {
  content: "";
}

.fa-closed-captioning:before {
  content: "";
}

.fa-person-hiking:before, .fa-hiking:before {
  content: "";
}

.fa-right-from-line:before, .fa-arrow-alt-from-left:before {
  content: "";
}

.fa-venus-double:before {
  content: "";
}

.fa-images:before {
  content: "";
}

.fa-calculator:before {
  content: "";
}

.fa-shuttlecock:before {
  content: "";
}

.fa-user-hair:before {
  content: "";
}

.fa-eye-evil:before {
  content: "";
}

.fa-people-pulling:before {
  content: "";
}

.fa-n:before {
  content: "N";
}

.fa-garage:before {
  content: "";
}

.fa-cable-car:before, .fa-tram:before {
  content: "";
}

.fa-shovel-snow:before {
  content: "";
}

.fa-cloud-rain:before {
  content: "";
}

.fa-face-lying:before {
  content: "";
}

.fa-sprinkler:before {
  content: "";
}

.fa-building-circle-xmark:before {
  content: "";
}

.fa-person-sledding:before, .fa-sledding:before {
  content: "";
}

.fa-game-console-handheld:before {
  content: "";
}

.fa-ship:before {
  content: "";
}

.fa-clock-six-thirty:before {
  content: "";
}

.fa-battery-slash:before {
  content: "";
}

.fa-tugrik-sign:before {
  content: "";
}

.fa-arrows-down-to-line:before {
  content: "";
}

.fa-download:before {
  content: "";
}

.fa-shelves:before, .fa-inventory:before {
  content: "";
}

.fa-cloud-snow:before {
  content: "";
}

.fa-face-grin:before, .fa-grin:before {
  content: "";
}

.fa-delete-left:before, .fa-backspace:before {
  content: "";
}

.fa-oven:before {
  content: "";
}

.fa-eye-dropper:before, .fa-eye-dropper-empty:before, .fa-eyedropper:before {
  content: "";
}

.fa-comment-captions:before {
  content: "";
}

.fa-comments-question:before {
  content: "";
}

.fa-scribble:before {
  content: "";
}

.fa-rotate-exclamation:before {
  content: "";
}

.fa-file-circle-check:before {
  content: "";
}

.fa-glass:before {
  content: "";
}

.fa-loader:before {
  content: "";
}

.fa-forward:before {
  content: "";
}

.fa-user-pilot:before {
  content: "";
}

.fa-mobile:before, .fa-mobile-android:before, .fa-mobile-phone:before {
  content: "";
}

.fa-code-pull-request-closed:before {
  content: "";
}

.fa-face-meh:before, .fa-meh:before {
  content: "";
}

.fa-align-center:before {
  content: "";
}

.fa-book-skull:before, .fa-book-dead:before {
  content: "";
}

.fa-id-card:before, .fa-drivers-license:before {
  content: "";
}

.fa-face-dotted:before {
  content: "";
}

.fa-face-worried:before {
  content: "";
}

.fa-outdent:before, .fa-dedent:before {
  content: "";
}

.fa-heart-circle-exclamation:before {
  content: "";
}

.fa-house:before, .fa-home:before, .fa-home-alt:before, .fa-home-lg-alt:before {
  content: "";
}

.fa-vector-circle:before {
  content: "";
}

.fa-car-circle-bolt:before {
  content: "";
}

.fa-calendar-week:before {
  content: "";
}

.fa-flying-disc:before {
  content: "";
}

.fa-laptop-medical:before {
  content: "";
}

.fa-square-down-right:before {
  content: "";
}

.fa-b:before {
  content: "B";
}

.fa-seat-airline:before {
  content: "";
}

.fa-moon-over-sun:before, .fa-eclipse-alt:before {
  content: "";
}

.fa-pipe:before {
  content: "|";
}

.fa-file-medical:before {
  content: "";
}

.fa-potato:before {
  content: "";
}

.fa-dice-one:before {
  content: "";
}

.fa-circle-a:before {
  content: "";
}

.fa-helmet-battle:before {
  content: "";
}

.fa-butter:before {
  content: "";
}

.fa-blanket-fire:before {
  content: "";
}

.fa-kiwi-bird:before {
  content: "";
}

.fa-castle:before {
  content: "";
}

.fa-golf-club:before {
  content: "";
}

.fa-arrow-right-arrow-left:before, .fa-exchange:before {
  content: "";
}

.fa-rotate-right:before, .fa-redo-alt:before, .fa-rotate-forward:before {
  content: "";
}

.fa-utensils:before, .fa-cutlery:before {
  content: "";
}

.fa-arrow-up-wide-short:before, .fa-sort-amount-up:before {
  content: "";
}

.fa-balloons:before {
  content: "";
}

.fa-mill-sign:before {
  content: "";
}

.fa-bowl-rice:before {
  content: "";
}

.fa-timeline-arrow:before {
  content: "";
}

.fa-skull:before {
  content: "";
}

.fa-game-board-simple:before, .fa-game-board-alt:before {
  content: "";
}

.fa-circle-video:before, .fa-video-circle:before {
  content: "";
}

.fa-chart-scatter-bubble:before {
  content: "";
}

.fa-house-turret:before {
  content: "";
}

.fa-banana:before {
  content: "";
}

.fa-hand-holding-skull:before {
  content: "";
}

.fa-people-dress:before {
  content: "";
}

.fa-loveseat:before, .fa-couch-small:before {
  content: "";
}

.fa-tower-broadcast:before, .fa-broadcast-tower:before {
  content: "";
}

.fa-truck-pickup:before {
  content: "";
}

.fa-block-quote:before {
  content: "";
}

.fa-up-long:before, .fa-long-arrow-alt-up:before {
  content: "";
}

.fa-stop:before {
  content: "";
}

.fa-code-merge:before {
  content: "";
}

.fa-money-check-dollar-pen:before, .fa-money-check-edit-alt:before {
  content: "";
}

.fa-up-from-line:before, .fa-arrow-alt-from-bottom:before {
  content: "";
}

.fa-upload:before {
  content: "";
}

.fa-hurricane:before {
  content: "";
}

.fa-people-pants:before {
  content: "";
}

.fa-mound:before {
  content: "";
}

.fa-windsock:before {
  content: "";
}

.fa-circle-half:before {
  content: "";
}

.fa-brake-warning:before {
  content: "";
}

.fa-toilet-portable:before {
  content: "";
}

.fa-compact-disc:before {
  content: "";
}

.fa-file-arrow-down:before, .fa-file-download:before {
  content: "";
}

.fa-saxophone-fire:before, .fa-sax-hot:before {
  content: "";
}

.fa-camera-web-slash:before, .fa-webcam-slash:before {
  content: "";
}

.fa-folder-medical:before {
  content: "";
}

.fa-folder-gear:before, .fa-folder-cog:before {
  content: "";
}

.fa-hand-wave:before {
  content: "";
}

.fa-arrow-up-arrow-down:before, .fa-sort-up-down:before {
  content: "";
}

.fa-caravan:before {
  content: "";
}

.fa-shield-cat:before {
  content: "";
}

.fa-message-slash:before, .fa-comment-alt-slash:before {
  content: "";
}

.fa-bolt:before, .fa-zap:before {
  content: "";
}

.fa-trash-can-check:before {
  content: "";
}

.fa-glass-water:before {
  content: "";
}

.fa-oil-well:before {
  content: "";
}

.fa-person-simple:before {
  content: "";
}

.fa-vault:before {
  content: "";
}

.fa-mars:before {
  content: "";
}

.fa-toilet:before {
  content: "";
}

.fa-plane-circle-xmark:before {
  content: "";
}

.fa-yen-sign:before, .fa-cny:before, .fa-jpy:before, .fa-rmb:before, .fa-yen:before {
  content: "";
}

.fa-notes:before {
  content: "";
}

.fa-ruble-sign:before, .fa-rouble:before, .fa-rub:before, .fa-ruble:before {
  content: "";
}

.fa-trash-undo:before, .fa-trash-arrow-turn-left:before {
  content: "";
}

.fa-champagne-glass:before, .fa-glass-champagne:before {
  content: "";
}

.fa-objects-align-center-horizontal:before {
  content: "";
}

.fa-sun:before {
  content: "";
}

.fa-trash-can-slash:before, .fa-trash-alt-slash:before {
  content: "";
}

.fa-screen-users:before, .fa-users-class:before {
  content: "";
}

.fa-guitar:before {
  content: "";
}

.fa-square-arrow-left:before, .fa-arrow-square-left:before {
  content: "";
}

.fa-square-8:before {
  content: "";
}

.fa-face-smile-hearts:before {
  content: "";
}

.fa-brackets-square:before, .fa-brackets:before {
  content: "";
}

.fa-laptop-arrow-down:before {
  content: "";
}

.fa-hockey-stick-puck:before {
  content: "";
}

.fa-house-tree:before {
  content: "";
}

.fa-signal-fair:before, .fa-signal-2:before {
  content: "";
}

.fa-face-laugh-wink:before, .fa-laugh-wink:before {
  content: "";
}

.fa-circle-dollar:before, .fa-dollar-circle:before, .fa-usd-circle:before {
  content: "";
}

.fa-horse-head:before {
  content: "";
}

.fa-arrows-repeat:before, .fa-repeat-alt:before {
  content: "";
}

.fa-bore-hole:before {
  content: "";
}

.fa-industry:before {
  content: "";
}

.fa-image-polaroid:before {
  content: "";
}

.fa-wave-triangle:before {
  content: "";
}

.fa-circle-down:before, .fa-arrow-alt-circle-down:before {
  content: "";
}

.fa-grill:before {
  content: "";
}

.fa-arrows-turn-to-dots:before {
  content: "";
}

.fa-chart-mixed:before, .fa-analytics:before {
  content: "";
}

.fa-florin-sign:before {
  content: "";
}

.fa-arrow-down-short-wide:before, .fa-sort-amount-desc:before, .fa-sort-amount-down-alt:before {
  content: "";
}

.fa-less-than:before {
  content: "<";
}

.fa-display-code:before, .fa-desktop-code:before {
  content: "";
}

.fa-face-drooling:before {
  content: "";
}

.fa-oil-temperature:before, .fa-oil-temp:before {
  content: "";
}

.fa-square-question:before, .fa-question-square:before {
  content: "";
}

.fa-air-conditioner:before {
  content: "";
}

.fa-angle-down:before {
  content: "";
}

.fa-mountains:before {
  content: "";
}

.fa-omega:before {
  content: "";
}

.fa-car-tunnel:before {
  content: "";
}

.fa-person-dolly-empty:before {
  content: "";
}

.fa-pan-food:before {
  content: "";
}

.fa-head-side-cough:before {
  content: "";
}

.fa-grip-lines:before {
  content: "";
}

.fa-thumbs-down:before {
  content: "";
}

.fa-user-lock:before {
  content: "";
}

.fa-arrow-right-long:before, .fa-long-arrow-right:before {
  content: "";
}

.fa-tickets-airline:before {
  content: "";
}

.fa-anchor-circle-xmark:before {
  content: "";
}

.fa-ellipsis:before, .fa-ellipsis-h:before {
  content: "";
}

.fa-nfc-slash:before {
  content: "";
}

.fa-chess-pawn:before {
  content: "";
}

.fa-kit-medical:before, .fa-first-aid:before {
  content: "";
}

.fa-grid-2-plus:before {
  content: "";
}

.fa-bells:before {
  content: "";
}

.fa-person-through-window:before {
  content: "";
}

.fa-toolbox:before {
  content: "";
}

.fa-envelope-dot:before, .fa-envelope-badge:before {
  content: "";
}

.fa-hands-holding-circle:before {
  content: "";
}

.fa-bug:before {
  content: "";
}

.fa-bowl-chopsticks:before {
  content: "";
}

.fa-credit-card:before, .fa-credit-card-alt:before {
  content: "";
}

.fa-circle-s:before {
  content: "";
}

.fa-box-ballot:before {
  content: "";
}

.fa-car:before, .fa-automobile:before {
  content: "";
}

.fa-hand-holding-hand:before {
  content: "";
}

.fa-user-tie-hair:before {
  content: "";
}

.fa-podium-star:before {
  content: "";
}

.fa-user-hair-mullet:before, .fa-business-front:before, .fa-party-back:before, .fa-trian-balbot:before {
  content: "";
}

.fa-microphone-stand:before {
  content: "";
}

.fa-book-open-reader:before, .fa-book-reader:before {
  content: "";
}

.fa-family-dress:before {
  content: "";
}

.fa-circle-x:before {
  content: "";
}

.fa-cabin:before {
  content: "";
}

.fa-mountain-sun:before {
  content: "";
}

.fa-chart-simple-horizontal:before {
  content: "";
}

.fa-arrows-left-right-to-line:before {
  content: "";
}

.fa-hand-back-point-left:before {
  content: "";
}

.fa-message-dots:before, .fa-comment-alt-dots:before, .fa-messaging:before {
  content: "";
}

.fa-file-heart:before {
  content: "";
}

.fa-beer-mug:before, .fa-beer-foam:before {
  content: "";
}

.fa-dice-d20:before {
  content: "";
}

.fa-drone:before {
  content: "";
}

.fa-truck-droplet:before {
  content: "";
}

.fa-file-circle-xmark:before {
  content: "";
}

.fa-temperature-arrow-up:before, .fa-temperature-up:before {
  content: "";
}

.fa-medal:before {
  content: "";
}

.fa-bed:before {
  content: "";
}

.fa-book-copy:before {
  content: "";
}

.fa-square-h:before, .fa-h-square:before {
  content: "";
}

.fa-square-c:before {
  content: "";
}

.fa-clock-two:before {
  content: "";
}

.fa-square-ellipsis-vertical:before {
  content: "";
}

.fa-podcast:before {
  content: "";
}

.fa-bee:before {
  content: "";
}

.fa-temperature-full:before, .fa-temperature-4:before, .fa-thermometer-4:before, .fa-thermometer-full:before {
  content: "";
}

.fa-bell:before {
  content: "";
}

.fa-candy-bar:before, .fa-chocolate-bar:before {
  content: "";
}

.fa-xmark-large:before {
  content: "";
}

.fa-pinata:before {
  content: "";
}

.fa-arrows-from-line:before {
  content: "";
}

.fa-superscript:before {
  content: "";
}

.fa-bowl-spoon:before {
  content: "";
}

.fa-hexagon-check:before {
  content: "";
}

.fa-plug-circle-xmark:before {
  content: "";
}

.fa-star-of-life:before {
  content: "";
}

.fa-phone-slash:before {
  content: "";
}

.fa-traffic-light-stop:before {
  content: "";
}

.fa-paint-roller:before {
  content: "";
}

.fa-accent-grave:before {
  content: "`";
}

.fa-handshake-angle:before, .fa-hands-helping:before {
  content: "";
}

.fa-circle-0:before {
  content: "";
}

.fa-dial-med-low:before {
  content: "";
}

.fa-location-dot:before, .fa-map-marker-alt:before {
  content: "";
}

.fa-crab:before {
  content: "";
}

.fa-box-open-full:before, .fa-box-full:before {
  content: "";
}

.fa-file:before {
  content: "";
}

.fa-greater-than:before {
  content: ">";
}

.fa-quotes:before {
  content: "";
}

.fa-pretzel:before {
  content: "";
}

.fa-person-swimming:before, .fa-swimmer:before {
  content: "";
}

.fa-arrow-down:before {
  content: "";
}

.fa-user-robot-xmarks:before {
  content: "";
}

.fa-message-quote:before, .fa-comment-alt-quote:before {
  content: "";
}

.fa-candy-corn:before {
  content: "";
}

.fa-folder-magnifying-glass:before, .fa-folder-search:before {
  content: "";
}

.fa-notebook:before {
  content: "";
}

.fa-droplet:before, .fa-tint:before {
  content: "";
}

.fa-bullseye-pointer:before {
  content: "";
}

.fa-eraser:before {
  content: "";
}

.fa-hexagon-image:before {
  content: "";
}

.fa-earth-americas:before, .fa-earth:before, .fa-earth-america:before, .fa-globe-americas:before {
  content: "";
}

.fa-crate-apple:before, .fa-apple-crate:before {
  content: "";
}

.fa-person-burst:before {
  content: "";
}

.fa-game-board:before {
  content: "";
}

.fa-hat-chef:before {
  content: "";
}

.fa-hand-back-point-right:before {
  content: "";
}

.fa-dove:before {
  content: "";
}

.fa-snowflake-droplets:before {
  content: "";
}

.fa-battery-empty:before, .fa-battery-0:before {
  content: "";
}

.fa-grid-4:before {
  content: "";
}

.fa-socks:before {
  content: "";
}

.fa-face-sunglasses:before {
  content: "";
}

.fa-inbox:before {
  content: "";
}

.fa-square-0:before {
  content: "";
}

.fa-section:before {
  content: "";
}

.fa-square-this-way-up:before, .fa-box-up:before {
  content: "";
}

.fa-gauge-high:before, .fa-tachometer-alt:before, .fa-tachometer-alt-fast:before {
  content: "";
}

.fa-square-ampersand:before {
  content: "";
}

.fa-envelope-open-text:before {
  content: "";
}

.fa-lamp-desk:before {
  content: "";
}

.fa-hospital:before, .fa-hospital-alt:before, .fa-hospital-wide:before {
  content: "";
}

.fa-poll-people:before {
  content: "";
}

.fa-whiskey-glass-ice:before, .fa-glass-whiskey-rocks:before {
  content: "";
}

.fa-wine-bottle:before {
  content: "";
}

.fa-chess-rook:before {
  content: "";
}

.fa-user-bounty-hunter:before {
  content: "";
}

.fa-bars-staggered:before, .fa-reorder:before, .fa-stream:before {
  content: "";
}

.fa-diagram-sankey:before {
  content: "";
}

.fa-cloud-hail-mixed:before {
  content: "";
}

.fa-circle-up-left:before {
  content: "";
}

.fa-dharmachakra:before {
  content: "";
}

.fa-objects-align-left:before {
  content: "";
}

.fa-oil-can-drip:before {
  content: "";
}

.fa-face-smiling-hands:before {
  content: "";
}

.fa-broccoli:before {
  content: "";
}

.fa-route-interstate:before {
  content: "";
}

.fa-ear-muffs:before {
  content: "";
}

.fa-hotdog:before {
  content: "";
}

.fa-transporter-empty:before {
  content: "";
}

.fa-person-walking-with-cane:before, .fa-blind:before {
  content: "";
}

.fa-angle-90:before {
  content: "";
}

.fa-rectangle-terminal:before {
  content: "";
}

.fa-kite:before {
  content: "";
}

.fa-drum:before {
  content: "";
}

.fa-scrubber:before {
  content: "";
}

.fa-ice-cream:before {
  content: "";
}

.fa-heart-circle-bolt:before {
  content: "";
}

.fa-fish-bones:before {
  content: "";
}

.fa-deer-rudolph:before {
  content: "";
}

.fa-fax:before {
  content: "";
}

.fa-paragraph:before {
  content: "";
}

.fa-head-side-heart:before {
  content: "";
}

.fa-square-e:before {
  content: "";
}

.fa-meter-fire:before {
  content: "";
}

.fa-cloud-hail:before {
  content: "";
}

.fa-check-to-slot:before, .fa-vote-yea:before {
  content: "";
}

.fa-money-from-bracket:before {
  content: "";
}

.fa-star-half:before {
  content: "";
}

.fa-car-bus:before {
  content: "";
}

.fa-speaker:before {
  content: "";
}

.fa-timer:before {
  content: "";
}

.fa-boxes-stacked:before, .fa-boxes:before, .fa-boxes-alt:before {
  content: "";
}

.fa-grill-hot:before {
  content: "";
}

.fa-ballot-check:before {
  content: "";
}

.fa-link:before, .fa-chain:before {
  content: "";
}

.fa-ear-listen:before, .fa-assistive-listening-systems:before {
  content: "";
}

.fa-file-minus:before {
  content: "";
}

.fa-tree-city:before {
  content: "";
}

.fa-play:before {
  content: "";
}

.fa-font:before {
  content: "";
}

.fa-cup-togo:before, .fa-coffee-togo:before {
  content: "";
}

.fa-square-down-left:before {
  content: "";
}

.fa-burger-lettuce:before {
  content: "";
}

.fa-rupiah-sign:before {
  content: "";
}

.fa-magnifying-glass:before, .fa-search:before {
  content: "";
}

.fa-table-tennis-paddle-ball:before, .fa-ping-pong-paddle-ball:before, .fa-table-tennis:before {
  content: "";
}

.fa-person-dots-from-line:before, .fa-diagnoses:before {
  content: "";
}

.fa-chevrons-down:before, .fa-chevron-double-down:before {
  content: "";
}

.fa-trash-can-arrow-up:before, .fa-trash-restore-alt:before {
  content: "";
}

.fa-signal-good:before, .fa-signal-3:before {
  content: "";
}

.fa-location-question:before, .fa-map-marker-question:before {
  content: "";
}

.fa-floppy-disk-circle-xmark:before, .fa-floppy-disk-times:before, .fa-save-circle-xmark:before, .fa-save-times:before {
  content: "";
}

.fa-naira-sign:before {
  content: "";
}

.fa-peach:before {
  content: "";
}

.fa-taxi-bus:before {
  content: "";
}

.fa-bracket-curly:before, .fa-bracket-curly-left:before {
  content: "{";
}

.fa-lobster:before {
  content: "";
}

.fa-cart-flatbed-empty:before, .fa-dolly-flatbed-empty:before {
  content: "";
}

.fa-colon:before {
  content: ":";
}

.fa-cart-arrow-down:before {
  content: "";
}

.fa-wand:before {
  content: "";
}

.fa-walkie-talkie:before {
  content: "";
}

.fa-file-pen:before, .fa-file-edit:before {
  content: "";
}

.fa-receipt:before {
  content: "";
}

.fa-table-picnic:before {
  content: "";
}

.fa-square-pen:before, .fa-pen-square:before, .fa-pencil-square:before {
  content: "";
}

.fa-circle-microphone-lines:before, .fa-microphone-circle-alt:before {
  content: "";
}

.fa-display-slash:before, .fa-desktop-slash:before {
  content: "";
}

.fa-suitcase-rolling:before {
  content: "";
}

.fa-person-circle-exclamation:before {
  content: "";
}

.fa-transporter-2:before {
  content: "";
}

.fa-hands-holding-diamond:before, .fa-hand-receiving:before {
  content: "";
}

.fa-money-bill-simple-wave:before {
  content: "";
}

.fa-chevron-down:before {
  content: "";
}

.fa-battery-full:before, .fa-battery:before, .fa-battery-5:before {
  content: "";
}

.fa-bell-plus:before {
  content: "";
}

.fa-book-arrow-right:before {
  content: "";
}

.fa-hospitals:before {
  content: "";
}

.fa-club:before {
  content: "";
}

.fa-skull-crossbones:before {
  content: "";
}

.fa-droplet-degree:before, .fa-dewpoint:before {
  content: "";
}

.fa-code-compare:before {
  content: "";
}

.fa-list-ul:before, .fa-list-dots:before {
  content: "";
}

.fa-hand-holding-magic:before {
  content: "";
}

.fa-watermelon-slice:before {
  content: "";
}

.fa-circle-ellipsis:before {
  content: "";
}

.fa-school-lock:before {
  content: "";
}

.fa-tower-cell:before {
  content: "";
}

.fa-sd-cards:before {
  content: "";
}

.fa-down-long:before, .fa-long-arrow-alt-down:before {
  content: "";
}

.fa-envelopes:before {
  content: "";
}

.fa-phone-office:before {
  content: "";
}

.fa-ranking-star:before {
  content: "";
}

.fa-chess-king:before {
  content: "";
}

.fa-nfc-pen:before {
  content: "";
}

.fa-person-harassing:before {
  content: "";
}

.fa-hat-winter:before {
  content: "";
}

.fa-brazilian-real-sign:before {
  content: "";
}

.fa-landmark-dome:before, .fa-landmark-alt:before {
  content: "";
}

.fa-bone-break:before {
  content: "";
}

.fa-arrow-up:before {
  content: "";
}

.fa-down-from-dotted-line:before {
  content: "";
}

.fa-tv:before, .fa-television:before, .fa-tv-alt:before {
  content: "";
}

.fa-border-left:before {
  content: "";
}

.fa-circle-divide:before {
  content: "";
}

.fa-shrimp:before {
  content: "";
}

.fa-list-check:before, .fa-tasks:before {
  content: "";
}

.fa-diagram-subtask:before {
  content: "";
}

.fa-jug-detergent:before {
  content: "";
}

.fa-circle-user:before, .fa-user-circle:before {
  content: "";
}

.fa-square-y:before {
  content: "";
}

.fa-user-doctor-hair:before {
  content: "";
}

.fa-planet-ringed:before {
  content: "";
}

.fa-mushroom:before {
  content: "";
}

.fa-user-shield:before {
  content: "";
}

.fa-megaphone:before {
  content: "";
}

.fa-wreath-laurel:before {
  content: "";
}

.fa-circle-exclamation-check:before {
  content: "";
}

.fa-wind:before {
  content: "";
}

.fa-box-dollar:before, .fa-box-usd:before {
  content: "";
}

.fa-car-burst:before, .fa-car-crash:before {
  content: "";
}

.fa-y:before {
  content: "Y";
}

.fa-user-headset:before {
  content: "";
}

.fa-arrows-retweet:before, .fa-retweet-alt:before {
  content: "";
}

.fa-person-snowboarding:before, .fa-snowboarding:before {
  content: "";
}

.fa-square-chevron-right:before, .fa-chevron-square-right:before {
  content: "";
}

.fa-lacrosse-stick-ball:before {
  content: "";
}

.fa-truck-fast:before, .fa-shipping-fast:before {
  content: "";
}

.fa-user-magnifying-glass:before {
  content: "";
}

.fa-star-sharp:before {
  content: "";
}

.fa-comment-heart:before {
  content: "";
}

.fa-circle-1:before {
  content: "";
}

.fa-circle-star:before, .fa-star-circle:before {
  content: "";
}

.fa-fish:before {
  content: "";
}

.fa-cloud-fog:before, .fa-fog:before {
  content: "";
}

.fa-waffle:before {
  content: "";
}

.fa-music-note:before, .fa-music-alt:before {
  content: "";
}

.fa-hexagon-exclamation:before {
  content: "";
}

.fa-cart-shopping-fast:before {
  content: "";
}

.fa-object-union:before {
  content: "";
}

.fa-user-graduate:before {
  content: "";
}

.fa-starfighter:before {
  content: "";
}

.fa-circle-half-stroke:before, .fa-adjust:before {
  content: "";
}

.fa-arrow-right-long-to-line:before {
  content: "";
}

.fa-square-arrow-down:before, .fa-arrow-square-down:before {
  content: "";
}

.fa-diamond-half-stroke:before {
  content: "";
}

.fa-clapperboard:before {
  content: "";
}

.fa-square-chevron-left:before, .fa-chevron-square-left:before {
  content: "";
}

.fa-phone-intercom:before {
  content: "";
}

.fa-link-horizontal:before, .fa-chain-horizontal:before {
  content: "";
}

.fa-mango:before {
  content: "";
}

.fa-music-note-slash:before, .fa-music-alt-slash:before {
  content: "";
}

.fa-circle-radiation:before, .fa-radiation-alt:before {
  content: "";
}

.fa-face-tongue-sweat:before {
  content: "";
}

.fa-globe-stand:before {
  content: "";
}

.fa-baseball:before, .fa-baseball-ball:before {
  content: "";
}

.fa-circle-p:before {
  content: "";
}

.fa-award-simple:before {
  content: "";
}

.fa-jet-fighter-up:before {
  content: "";
}

.fa-diagram-project:before, .fa-project-diagram:before {
  content: "";
}

.fa-pedestal:before {
  content: "";
}

.fa-chart-pyramid:before {
  content: "";
}

.fa-sidebar:before {
  content: "";
}

.fa-snowman-head:before, .fa-frosty-head:before {
  content: "";
}

.fa-copy:before {
  content: "";
}

.fa-burger-glass:before {
  content: "";
}

.fa-volume-xmark:before, .fa-volume-mute:before, .fa-volume-times:before {
  content: "";
}

.fa-hand-sparkles:before {
  content: "";
}

.fa-bars-filter:before {
  content: "";
}

.fa-paintbrush-pencil:before {
  content: "";
}

.fa-party-bell:before {
  content: "";
}

.fa-user-vneck-hair:before {
  content: "";
}

.fa-jack-o-lantern:before {
  content: "";
}

.fa-grip:before, .fa-grip-horizontal:before {
  content: "";
}

.fa-share-from-square:before, .fa-share-square:before {
  content: "";
}

.fa-keynote:before {
  content: "";
}

.fa-child-combatant:before, .fa-child-rifle:before {
  content: "";
}

.fa-gun:before {
  content: "";
}

.fa-square-phone:before, .fa-phone-square:before {
  content: "";
}

.fa-plus:before, .fa-add:before {
  content: "+";
}

.fa-expand:before {
  content: "";
}

.fa-computer:before {
  content: "";
}

.fa-fort:before {
  content: "";
}

.fa-cloud-check:before {
  content: "";
}

.fa-xmark:before, .fa-close:before, .fa-multiply:before, .fa-remove:before, .fa-times:before {
  content: "";
}

.fa-face-smirking:before {
  content: "";
}

.fa-arrows-up-down-left-right:before, .fa-arrows:before {
  content: "";
}

.fa-chalkboard-user:before, .fa-chalkboard-teacher:before {
  content: "";
}

.fa-rhombus:before {
  content: "";
}

.fa-claw-marks:before {
  content: "";
}

.fa-peso-sign:before {
  content: "";
}

.fa-face-smile-tongue:before {
  content: "";
}

.fa-cart-circle-xmark:before {
  content: "";
}

.fa-building-shield:before {
  content: "";
}

.fa-circle-phone-flip:before, .fa-phone-circle-alt:before {
  content: "";
}

.fa-baby:before {
  content: "";
}

.fa-users-line:before {
  content: "";
}

.fa-quote-left:before, .fa-quote-left-alt:before {
  content: "";
}

.fa-tractor:before {
  content: "";
}

.fa-key-skeleton:before {
  content: "";
}

.fa-trash-arrow-up:before, .fa-trash-restore:before {
  content: "";
}

.fa-arrow-down-up-lock:before {
  content: "";
}

.fa-arrow-down-to-bracket:before {
  content: "";
}

.fa-lines-leaning:before {
  content: "";
}

.fa-square-q:before {
  content: "";
}

.fa-ruler-combined:before {
  content: "";
}

.fa-symbols:before, .fa-icons-alt:before {
  content: "";
}

.fa-copyright:before {
  content: "";
}

.fa-highlighter-line:before {
  content: "";
}

.fa-bracket-square:before, .fa-bracket:before, .fa-bracket-left:before {
  content: "[";
}

.fa-island-tropical:before, .fa-island-tree-palm:before {
  content: "";
}

.fa-arrow-right-from-line:before, .fa-arrow-from-left:before {
  content: "";
}

.fa-h2:before {
  content: "";
}

.fa-equals:before {
  content: "=";
}

.fa-cake-slice:before, .fa-shortcake:before {
  content: "";
}

.fa-peanut:before {
  content: "";
}

.fa-wrench-simple:before {
  content: "";
}

.fa-blender:before {
  content: "";
}

.fa-teeth:before {
  content: "";
}

.fa-tally-2:before {
  content: "";
}

.fa-shekel-sign:before, .fa-ils:before, .fa-shekel:before, .fa-sheqel:before, .fa-sheqel-sign:before {
  content: "";
}

.fa-cars:before {
  content: "";
}

.fa-axe-battle:before {
  content: "";
}

.fa-user-hair-long:before {
  content: "";
}

.fa-map:before {
  content: "";
}

.fa-file-circle-info:before {
  content: "";
}

.fa-face-disappointed:before {
  content: "";
}

.fa-lasso-sparkles:before {
  content: "";
}

.fa-clock-eleven:before {
  content: "";
}

.fa-rocket:before {
  content: "";
}

.fa-siren-on:before {
  content: "";
}

.fa-clock-ten:before {
  content: "";
}

.fa-candle-holder:before {
  content: "";
}

.fa-video-arrow-down-left:before {
  content: "";
}

.fa-photo-film:before, .fa-photo-video:before {
  content: "";
}

.fa-floppy-disk-circle-arrow-right:before, .fa-save-circle-arrow-right:before {
  content: "";
}

.fa-folder-minus:before {
  content: "";
}

.fa-planet-moon:before {
  content: "";
}

.fa-face-eyes-xmarks:before {
  content: "";
}

.fa-chart-scatter:before {
  content: "";
}

.fa-display-arrow-down:before {
  content: "";
}

.fa-store:before {
  content: "";
}

.fa-arrow-trend-up:before {
  content: "";
}

.fa-plug-circle-minus:before {
  content: "";
}

.fa-olive-branch:before {
  content: "";
}

.fa-angle:before {
  content: "";
}

.fa-vacuum-robot:before {
  content: "";
}

.fa-sign-hanging:before, .fa-sign:before {
  content: "";
}

.fa-square-divide:before {
  content: "";
}

.fa-signal-stream-slash:before {
  content: "";
}

.fa-bezier-curve:before {
  content: "";
}

.fa-eye-dropper-half:before {
  content: "";
}

.fa-store-lock:before {
  content: "";
}

.fa-bell-slash:before {
  content: "";
}

.fa-cloud-bolt-sun:before, .fa-thunderstorm-sun:before {
  content: "";
}

.fa-camera-slash:before {
  content: "";
}

.fa-comment-quote:before {
  content: "";
}

.fa-tablet:before, .fa-tablet-android:before {
  content: "";
}

.fa-school-flag:before {
  content: "";
}

.fa-message-code:before {
  content: "";
}

.fa-glass-half:before, .fa-glass-half-empty:before, .fa-glass-half-full:before {
  content: "";
}

.fa-fill:before {
  content: "";
}

.fa-message-minus:before, .fa-comment-alt-minus:before {
  content: "";
}

.fa-angle-up:before {
  content: "";
}

.fa-drumstick-bite:before {
  content: "";
}

.fa-link-horizontal-slash:before, .fa-chain-horizontal-slash:before {
  content: "";
}

.fa-holly-berry:before {
  content: "";
}

.fa-nose:before {
  content: "";
}

.fa-chevron-left:before {
  content: "";
}

.fa-bacteria:before {
  content: "";
}

.fa-clouds:before {
  content: "";
}

.fa-money-bill-simple:before {
  content: "";
}

.fa-hand-lizard:before {
  content: "";
}

.fa-table-pivot:before {
  content: "";
}

.fa-filter-slash:before {
  content: "";
}

.fa-trash-can-undo:before, .fa-trash-can-arrow-turn-left:before, .fa-trash-undo-alt:before {
  content: "";
}

.fa-notdef:before {
  content: "";
}

.fa-disease:before {
  content: "";
}

.fa-person-to-door:before {
  content: "";
}

.fa-turntable:before {
  content: "";
}

.fa-briefcase-medical:before {
  content: "";
}

.fa-genderless:before {
  content: "";
}

.fa-chevron-right:before {
  content: "";
}

.fa-signal-weak:before, .fa-signal-1:before {
  content: "";
}

.fa-clock-five:before {
  content: "";
}

.fa-retweet:before {
  content: "";
}

.fa-car-rear:before, .fa-car-alt:before {
  content: "";
}

.fa-pump-soap:before {
  content: "";
}

.fa-computer-classic:before {
  content: "";
}

.fa-frame:before {
  content: "";
}

.fa-video-slash:before {
  content: "";
}

.fa-battery-quarter:before, .fa-battery-2:before {
  content: "";
}

.fa-ellipsis-stroke:before, .fa-ellipsis-h-alt:before {
  content: "";
}

.fa-radio:before {
  content: "";
}

.fa-baby-carriage:before, .fa-carriage-baby:before {
  content: "";
}

.fa-face-expressionless:before {
  content: "";
}

.fa-down-to-dotted-line:before {
  content: "";
}

.fa-cloud-music:before {
  content: "";
}

.fa-traffic-light:before {
  content: "";
}

.fa-cloud-minus:before {
  content: "";
}

.fa-thermometer:before {
  content: "";
}

.fa-shield-minus:before {
  content: "";
}

.fa-vr-cardboard:before {
  content: "";
}

.fa-car-tilt:before {
  content: "";
}

.fa-gauge-circle-minus:before {
  content: "";
}

.fa-brightness-low:before {
  content: "";
}

.fa-hand-middle-finger:before {
  content: "";
}

.fa-percent:before, .fa-percentage:before {
  content: "%";
}

.fa-truck-moving:before {
  content: "";
}

.fa-glass-water-droplet:before {
  content: "";
}

.fa-conveyor-belt:before {
  content: "";
}

.fa-location-check:before, .fa-map-marker-check:before {
  content: "";
}

.fa-coin-vertical:before {
  content: "";
}

.fa-display:before {
  content: "";
}

.fa-person-sign:before {
  content: "";
}

.fa-face-smile:before, .fa-smile:before {
  content: "";
}

.fa-phone-hangup:before {
  content: "";
}

.fa-signature-slash:before {
  content: "";
}

.fa-thumbtack:before, .fa-thumb-tack:before {
  content: "";
}

.fa-wheat-slash:before {
  content: "";
}

.fa-trophy:before {
  content: "";
}

.fa-clouds-sun:before {
  content: "";
}

.fa-person-praying:before, .fa-pray:before {
  content: "";
}

.fa-hammer:before {
  content: "";
}

.fa-face-vomit:before {
  content: "";
}

.fa-speakers:before {
  content: "";
}

.fa-tty-answer:before, .fa-teletype-answer:before {
  content: "";
}

.fa-mug-tea-saucer:before {
  content: "";
}

.fa-diagram-lean-canvas:before {
  content: "";
}

.fa-alt:before {
  content: "";
}

.fa-dial:before, .fa-dial-med-high:before {
  content: "";
}

.fa-hand-peace:before {
  content: "";
}

.fa-circle-trash:before, .fa-trash-circle:before {
  content: "";
}

.fa-rotate:before, .fa-sync-alt:before {
  content: "";
}

.fa-circle-quarters:before {
  content: "";
}

.fa-spinner:before {
  content: "";
}

.fa-tower-control:before {
  content: "";
}

.fa-arrow-up-triangle-square:before, .fa-sort-shapes-up:before {
  content: "";
}

.fa-whale:before {
  content: "";
}

.fa-robot:before {
  content: "";
}

.fa-peace:before {
  content: "";
}

.fa-party-horn:before {
  content: "";
}

.fa-gears:before, .fa-cogs:before {
  content: "";
}

.fa-sun-bright:before, .fa-sun-alt:before {
  content: "";
}

.fa-warehouse:before {
  content: "";
}

.fa-lock-keyhole-open:before, .fa-lock-open-alt:before {
  content: "";
}

.fa-square-fragile:before, .fa-box-fragile:before, .fa-square-wine-glass-crack:before {
  content: "";
}

.fa-arrow-up-right-dots:before {
  content: "";
}

.fa-square-n:before {
  content: "";
}

.fa-splotch:before {
  content: "";
}

.fa-face-grin-hearts:before, .fa-grin-hearts:before {
  content: "";
}

.fa-meter:before {
  content: "";
}

.fa-mandolin:before {
  content: "";
}

.fa-dice-four:before {
  content: "";
}

.fa-sim-card:before {
  content: "";
}

.fa-transgender:before, .fa-transgender-alt:before {
  content: "";
}

.fa-mercury:before {
  content: "";
}

.fa-up-from-bracket:before {
  content: "";
}

.fa-knife-kitchen:before {
  content: "";
}

.fa-border-right:before {
  content: "";
}

.fa-arrow-turn-down:before, .fa-level-down:before {
  content: "";
}

.fa-spade:before {
  content: "";
}

.fa-card-spade:before {
  content: "";
}

.fa-line-columns:before {
  content: "";
}

.fa-arrow-right-to-line:before, .fa-arrow-to-right:before {
  content: "";
}

.fa-person-falling-burst:before {
  content: "";
}

.fa-flag-pennant:before, .fa-pennant:before {
  content: "";
}

.fa-conveyor-belt-empty:before {
  content: "";
}

.fa-award:before {
  content: "";
}

.fa-ticket-simple:before, .fa-ticket-alt:before {
  content: "";
}

.fa-building:before {
  content: "";
}

.fa-angles-left:before, .fa-angle-double-left:before {
  content: "";
}

.fa-camcorder:before, .fa-video-handheld:before {
  content: "";
}

.fa-pancakes:before {
  content: "";
}

.fa-album-circle-user:before {
  content: "";
}

.fa-qrcode:before {
  content: "";
}

.fa-dice-d10:before {
  content: "";
}

.fa-fireplace:before {
  content: "";
}

.fa-browser:before {
  content: "";
}

.fa-pen-paintbrush:before, .fa-pencil-paintbrush:before {
  content: "";
}

.fa-fish-cooked:before {
  content: "";
}

.fa-chair-office:before {
  content: "";
}

.fa-nesting-dolls:before {
  content: "";
}

.fa-clock-rotate-left:before, .fa-history:before {
  content: "";
}

.fa-trumpet:before {
  content: "";
}

.fa-face-grin-beam-sweat:before, .fa-grin-beam-sweat:before {
  content: "";
}

.fa-fire-smoke:before {
  content: "";
}

.fa-phone-missed:before {
  content: "";
}

.fa-file-export:before, .fa-arrow-right-from-file:before {
  content: "";
}

.fa-shield:before, .fa-shield-blank:before {
  content: "";
}

.fa-arrow-up-short-wide:before, .fa-sort-amount-up-alt:before {
  content: "";
}

.fa-arrows-repeat-1:before, .fa-repeat-1-alt:before {
  content: "";
}

.fa-gun-slash:before {
  content: "";
}

.fa-avocado:before {
  content: "";
}

.fa-binary:before {
  content: "";
}

.fa-glasses-round:before, .fa-glasses-alt:before {
  content: "";
}

.fa-phone-plus:before {
  content: "";
}

.fa-ditto:before {
  content: "\"";
}

.fa-person-seat:before {
  content: "";
}

.fa-house-medical:before {
  content: "";
}

.fa-golf-ball-tee:before, .fa-golf-ball:before {
  content: "";
}

.fa-circle-chevron-left:before, .fa-chevron-circle-left:before {
  content: "";
}

.fa-house-chimney-window:before {
  content: "";
}

.fa-scythe:before {
  content: "";
}

.fa-pen-nib:before {
  content: "";
}

.fa-ban-parking:before, .fa-parking-circle-slash:before {
  content: "";
}

.fa-tent-arrow-turn-left:before {
  content: "";
}

.fa-face-diagonal-mouth:before {
  content: "";
}

.fa-diagram-cells:before {
  content: "";
}

.fa-cricket-bat-ball:before, .fa-cricket:before {
  content: "";
}

.fa-tents:before {
  content: "";
}

.fa-wand-magic:before, .fa-magic:before {
  content: "";
}

.fa-dog:before {
  content: "";
}

.fa-pen-line:before {
  content: "";
}

.fa-atom-simple:before, .fa-atom-alt:before {
  content: "";
}

.fa-ampersand:before {
  content: "&";
}

.fa-carrot:before {
  content: "";
}

.fa-arrow-up-from-line:before, .fa-arrow-from-bottom:before {
  content: "";
}

.fa-moon:before {
  content: "";
}

.fa-pen-slash:before {
  content: "";
}

.fa-wine-glass-empty:before, .fa-wine-glass-alt:before {
  content: "";
}

.fa-square-star:before {
  content: "";
}

.fa-cheese:before {
  content: "";
}

.fa-send-backward:before {
  content: "";
}

.fa-yin-yang:before {
  content: "";
}

.fa-music:before {
  content: "";
}

.fa-compass-slash:before {
  content: "";
}

.fa-clock-one:before {
  content: "";
}

.fa-file-music:before {
  content: "";
}

.fa-code-commit:before {
  content: "";
}

.fa-temperature-low:before {
  content: "";
}

.fa-person-biking:before, .fa-biking:before {
  content: "";
}

.fa-skeleton:before {
  content: "";
}

.fa-circle-g:before {
  content: "";
}

.fa-circle-arrow-up-left:before {
  content: "";
}

.fa-coin-blank:before {
  content: "";
}

.fa-broom:before {
  content: "";
}

.fa-vacuum:before {
  content: "";
}

.fa-shield-heart:before {
  content: "";
}

.fa-card-heart:before {
  content: "";
}

.fa-lightbulb-cfl-on:before {
  content: "";
}

.fa-melon:before {
  content: "";
}

.fa-gopuram:before {
  content: "";
}

.fa-earth-oceania:before, .fa-globe-oceania:before {
  content: "";
}

.fa-container-storage:before {
  content: "";
}

.fa-face-pouting:before {
  content: "";
}

.fa-square-xmark:before, .fa-times-square:before, .fa-xmark-square:before {
  content: "";
}

.fa-face-explode:before, .fa-exploding-head:before {
  content: "";
}

.fa-hashtag:before {
  content: "#";
}

.fa-up-right-and-down-left-from-center:before, .fa-expand-alt:before {
  content: "";
}

.fa-oil-can:before {
  content: "";
}

.fa-t:before {
  content: "T";
}

.fa-transformer-bolt:before {
  content: "";
}

.fa-hippo:before {
  content: "";
}

.fa-chart-column:before {
  content: "";
}

.fa-cassette-vhs:before, .fa-vhs:before {
  content: "";
}

.fa-infinity:before {
  content: "";
}

.fa-vial-circle-check:before {
  content: "";
}

.fa-chimney:before {
  content: "";
}

.fa-object-intersect:before {
  content: "";
}

.fa-person-arrow-down-to-line:before {
  content: "";
}

.fa-voicemail:before {
  content: "";
}

.fa-block-brick:before, .fa-wall-brick:before {
  content: "";
}

.fa-fan:before {
  content: "";
}

.fa-bags-shopping:before {
  content: "";
}

.fa-paragraph-left:before, .fa-paragraph-rtl:before {
  content: "";
}

.fa-person-walking-luggage:before {
  content: "";
}

.fa-caravan-simple:before, .fa-caravan-alt:before {
  content: "";
}

.fa-turtle:before {
  content: "";
}

.fa-pencil-mechanical:before {
  content: "";
}

.fa-up-down:before, .fa-arrows-alt-v:before {
  content: "";
}

.fa-cloud-moon-rain:before {
  content: "";
}

.fa-booth-curtain:before {
  content: "";
}

.fa-calendar:before {
  content: "";
}

.fa-box-heart:before {
  content: "";
}

.fa-trailer:before {
  content: "";
}

.fa-user-doctor-message:before, .fa-user-md-chat:before {
  content: "";
}

.fa-bahai:before, .fa-haykal:before {
  content: "";
}

.fa-amp-guitar:before {
  content: "";
}

.fa-sd-card:before {
  content: "";
}

.fa-volume-slash:before {
  content: "";
}

.fa-border-bottom:before {
  content: "";
}

.fa-wifi-weak:before, .fa-wifi-1:before {
  content: "";
}

.fa-dragon:before {
  content: "";
}

.fa-shoe-prints:before {
  content: "";
}

.fa-circle-plus:before, .fa-plus-circle:before {
  content: "";
}

.fa-face-grin-tongue-wink:before, .fa-grin-tongue-wink:before {
  content: "";
}

.fa-hand-holding:before {
  content: "";
}

.fa-plug-circle-exclamation:before {
  content: "";
}

.fa-link-slash:before, .fa-chain-broken:before, .fa-chain-slash:before, .fa-unlink:before {
  content: "";
}

.fa-clone:before {
  content: "";
}

.fa-person-walking-arrow-loop-left:before {
  content: "";
}

.fa-arrow-up-z-a:before, .fa-sort-alpha-up-alt:before {
  content: "";
}

.fa-fire-flame-curved:before, .fa-fire-alt:before {
  content: "";
}

.fa-tornado:before {
  content: "";
}

.fa-file-circle-plus:before {
  content: "";
}

.fa-delete-right:before {
  content: "";
}

.fa-book-quran:before, .fa-quran:before {
  content: "";
}

.fa-circle-quarter:before {
  content: "";
}

.fa-anchor:before {
  content: "";
}

.fa-border-all:before {
  content: "";
}

.fa-function:before {
  content: "";
}

.fa-face-angry:before, .fa-angry:before {
  content: "";
}

.fa-people-simple:before {
  content: "";
}

.fa-cookie-bite:before {
  content: "";
}

.fa-arrow-trend-down:before {
  content: "";
}

.fa-rss:before, .fa-feed:before {
  content: "";
}

.fa-face-monocle:before {
  content: "";
}

.fa-draw-polygon:before {
  content: "";
}

.fa-scale-balanced:before, .fa-balance-scale:before {
  content: "";
}

.fa-calendar-lines:before, .fa-calendar-note:before {
  content: "";
}

.fa-arrow-down-big-small:before, .fa-sort-size-down:before {
  content: "";
}

.fa-gauge-simple-high:before, .fa-tachometer:before, .fa-tachometer-fast:before {
  content: "";
}

.fa-do-not-enter:before {
  content: "";
}

.fa-shower:before {
  content: "";
}

.fa-dice-d8:before {
  content: "";
}

.fa-desktop:before, .fa-desktop-alt:before {
  content: "";
}

.fa-m:before {
  content: "M";
}

.fa-grip-dots-vertical:before {
  content: "";
}

.fa-face-viewfinder:before {
  content: "";
}

.fa-soft-serve:before, .fa-creemee:before {
  content: "";
}

.fa-h5:before {
  content: "";
}

.fa-hand-back-point-down:before {
  content: "";
}

.fa-table-list:before, .fa-th-list:before {
  content: "";
}

.fa-comment-sms:before, .fa-sms:before {
  content: "";
}

.fa-rectangle:before, .fa-rectangle-landscape:before {
  content: "";
}

.fa-clipboard-list-check:before {
  content: "";
}

.fa-turkey:before {
  content: "";
}

.fa-book:before {
  content: "";
}

.fa-user-plus:before {
  content: "";
}

.fa-ice-skate:before {
  content: "";
}

.fa-check:before {
  content: "";
}

.fa-battery-three-quarters:before, .fa-battery-4:before {
  content: "";
}

.fa-tomato:before {
  content: "";
}

.fa-sword-laser:before {
  content: "";
}

.fa-house-circle-check:before {
  content: "";
}

.fa-buildings:before {
  content: "";
}

.fa-angle-left:before {
  content: "";
}

.fa-cart-flatbed-boxes:before, .fa-dolly-flatbed-alt:before {
  content: "";
}

.fa-diagram-successor:before {
  content: "";
}

.fa-truck-arrow-right:before {
  content: "";
}

.fa-square-w:before {
  content: "";
}

.fa-arrows-split-up-and-left:before {
  content: "";
}

.fa-lamp:before {
  content: "";
}

.fa-airplay:before {
  content: "";
}

.fa-hand-fist:before, .fa-fist-raised:before {
  content: "";
}

.fa-shield-quartered:before {
  content: "";
}

.fa-slash-forward:before {
  content: "/";
}

.fa-location-pen:before, .fa-map-marker-edit:before {
  content: "";
}

.fa-cloud-moon:before {
  content: "";
}

.fa-pot-food:before {
  content: "";
}

.fa-briefcase:before {
  content: "";
}

.fa-person-falling:before {
  content: "";
}

.fa-image-portrait:before, .fa-portrait:before {
  content: "";
}

.fa-user-tag:before {
  content: "";
}

.fa-rug:before {
  content: "";
}

.fa-print-slash:before {
  content: "";
}

.fa-earth-europe:before, .fa-globe-europe:before {
  content: "";
}

.fa-cart-flatbed-suitcase:before, .fa-luggage-cart:before {
  content: "";
}

.fa-hand-back-point-ribbon:before {
  content: "";
}

.fa-rectangle-xmark:before, .fa-rectangle-times:before, .fa-times-rectangle:before, .fa-window-close:before {
  content: "";
}

.fa-tire-rugged:before {
  content: "";
}

.fa-lightbulb-dollar:before {
  content: "";
}

.fa-cowbell:before {
  content: "";
}

.fa-baht-sign:before {
  content: "";
}

.fa-corner:before {
  content: "";
}

.fa-chevrons-right:before, .fa-chevron-double-right:before {
  content: "";
}

.fa-book-open:before {
  content: "";
}

.fa-book-journal-whills:before, .fa-journal-whills:before {
  content: "";
}

.fa-inhaler:before {
  content: "";
}

.fa-handcuffs:before {
  content: "";
}

.fa-snake:before {
  content: "";
}

.fa-triangle-exclamation:before, .fa-exclamation-triangle:before, .fa-warning:before {
  content: "";
}

.fa-note-medical:before {
  content: "";
}

.fa-database:before {
  content: "";
}

.fa-down-left:before {
  content: "";
}

.fa-share:before, .fa-arrow-turn-right:before, .fa-mail-forward:before {
  content: "";
}

.fa-face-thinking:before {
  content: "";
}

.fa-turn-down-right:before {
  content: "";
}

.fa-bottle-droplet:before {
  content: "";
}

.fa-mask-face:before {
  content: "";
}

.fa-hill-rockslide:before {
  content: "";
}

.fa-scanner-keyboard:before {
  content: "";
}

.fa-circle-o:before {
  content: "";
}

.fa-grid-horizontal:before {
  content: "";
}

.fa-message-dollar:before, .fa-comment-alt-dollar:before {
  content: "";
}

.fa-right-left:before, .fa-exchange-alt:before {
  content: "";
}

.fa-columns-3:before {
  content: "";
}

.fa-paper-plane:before {
  content: "";
}

.fa-road-circle-exclamation:before {
  content: "";
}

.fa-dungeon:before {
  content: "";
}

.fa-hand-holding-box:before {
  content: "";
}

.fa-input-text:before {
  content: "";
}

.fa-window-flip:before, .fa-window-alt:before {
  content: "";
}

.fa-align-right:before {
  content: "";
}

.fa-scanner-gun:before, .fa-scanner:before {
  content: "";
}

.fa-tire:before {
  content: "";
}

.fa-engine:before {
  content: "";
}

.fa-money-bill-1-wave:before, .fa-money-bill-wave-alt:before {
  content: "";
}

.fa-life-ring:before {
  content: "";
}

.fa-hands:before, .fa-sign-language:before, .fa-signing:before {
  content: "";
}

.fa-circle-caret-right:before, .fa-caret-circle-right:before {
  content: "";
}

.fa-wheat:before {
  content: "";
}

.fa-file-spreadsheet:before {
  content: "";
}

.fa-audio-description-slash:before {
  content: "";
}

.fa-calendar-day:before {
  content: "";
}

.fa-water-ladder:before, .fa-ladder-water:before, .fa-swimming-pool:before {
  content: "";
}

.fa-arrows-up-down:before, .fa-arrows-v:before {
  content: "";
}

.fa-chess-pawn-piece:before, .fa-chess-pawn-alt:before {
  content: "";
}

.fa-face-grimace:before, .fa-grimace:before {
  content: "";
}

.fa-wheelchair-move:before, .fa-wheelchair-alt:before {
  content: "";
}

.fa-turn-down:before, .fa-level-down-alt:before {
  content: "";
}

.fa-square-s:before {
  content: "";
}

.fa-rectangle-barcode:before, .fa-barcode-alt:before {
  content: "";
}

.fa-person-walking-arrow-right:before {
  content: "";
}

.fa-square-envelope:before, .fa-envelope-square:before {
  content: "";
}

.fa-dice:before {
  content: "";
}

.fa-unicorn:before {
  content: "";
}

.fa-bowling-ball:before {
  content: "";
}

.fa-pompebled:before {
  content: "";
}

.fa-brain:before {
  content: "";
}

.fa-watch-smart:before {
  content: "";
}

.fa-book-user:before {
  content: "";
}

.fa-sensor-cloud:before, .fa-sensor-smoke:before {
  content: "";
}

.fa-clapperboard-play:before {
  content: "";
}

.fa-bandage:before, .fa-band-aid:before {
  content: "";
}

.fa-calendar-minus:before {
  content: "";
}

.fa-circle-xmark:before, .fa-times-circle:before, .fa-xmark-circle:before {
  content: "";
}

.fa-circle-4:before {
  content: "";
}

.fa-gifts:before {
  content: "";
}

.fa-album-collection:before {
  content: "";
}

.fa-hotel:before {
  content: "";
}

.fa-earth-asia:before, .fa-globe-asia:before {
  content: "";
}

.fa-id-card-clip:before, .fa-id-card-alt:before {
  content: "";
}

.fa-magnifying-glass-plus:before, .fa-search-plus:before {
  content: "";
}

.fa-thumbs-up:before {
  content: "";
}

.fa-cloud-showers:before {
  content: "";
}

.fa-user-clock:before {
  content: "";
}

.fa-onion:before {
  content: "";
}

.fa-clock-twelve-thirty:before {
  content: "";
}

.fa-arrow-down-to-dotted-line:before {
  content: "";
}

.fa-hand-dots:before, .fa-allergies:before {
  content: "";
}

.fa-file-invoice:before {
  content: "";
}

.fa-window-minimize:before {
  content: "";
}

.fa-rectangle-wide:before {
  content: "";
}

.fa-comment-arrow-up:before {
  content: "";
}

.fa-garlic:before {
  content: "";
}

.fa-mug-saucer:before, .fa-coffee:before {
  content: "";
}

.fa-brush:before {
  content: "";
}

.fa-tree-decorated:before {
  content: "";
}

.fa-mask:before {
  content: "";
}

.fa-calendar-heart:before {
  content: "";
}

.fa-magnifying-glass-minus:before, .fa-search-minus:before {
  content: "";
}

.fa-flower:before {
  content: "";
}

.fa-ruler-vertical:before {
  content: "";
}

.fa-user-large:before, .fa-user-alt:before {
  content: "";
}

.fa-starship-freighter:before {
  content: "";
}

.fa-train-tram:before {
  content: "";
}

.fa-bridge-suspension:before {
  content: "";
}

.fa-trash-check:before {
  content: "";
}

.fa-user-nurse:before {
  content: "";
}

.fa-boombox:before {
  content: "";
}

.fa-syringe:before {
  content: "";
}

.fa-cloud-sun:before {
  content: "";
}

.fa-shield-exclamation:before {
  content: "";
}

.fa-stopwatch-20:before {
  content: "";
}

.fa-square-full:before {
  content: "";
}

.fa-grip-dots:before {
  content: "";
}

.fa-comment-exclamation:before {
  content: "";
}

.fa-pen-swirl:before {
  content: "";
}

.fa-falafel:before {
  content: "";
}

.fa-circle-2:before {
  content: "";
}

.fa-magnet:before {
  content: "";
}

.fa-jar:before {
  content: "";
}

.fa-gramophone:before {
  content: "";
}

.fa-dice-d12:before {
  content: "";
}

.fa-note-sticky:before, .fa-sticky-note:before {
  content: "";
}

.fa-down:before, .fa-arrow-alt-down:before {
  content: "";
}

.fa-hundred-points:before, .fa-100:before {
  content: "";
}

.fa-paperclip-vertical:before {
  content: "";
}

.fa-wind-warning:before, .fa-wind-circle-exclamation:before {
  content: "";
}

.fa-location-pin-slash:before, .fa-map-marker-slash:before {
  content: "";
}

.fa-face-sad-sweat:before {
  content: "";
}

.fa-bug-slash:before {
  content: "";
}

.fa-cupcake:before {
  content: "";
}

.fa-light-switch-off:before {
  content: "";
}

.fa-toggle-large-off:before {
  content: "";
}

.fa-pen-fancy-slash:before {
  content: "";
}

.fa-truck-container:before {
  content: "";
}

.fa-boot:before {
  content: "";
}

.fa-arrow-up-from-water-pump:before {
  content: "";
}

.fa-file-check:before {
  content: "";
}

.fa-bone:before {
  content: "";
}

.fa-cards-blank:before {
  content: "";
}

.fa-circle-3:before {
  content: "";
}

.fa-bench-tree:before {
  content: "";
}

.fa-keyboard-brightness-low:before {
  content: "";
}

.fa-ski-boot-ski:before {
  content: "";
}

.fa-brain-circuit:before {
  content: "";
}

.fa-user-injured:before {
  content: "";
}

.fa-block-brick-fire:before, .fa-firewall:before {
  content: "";
}

.fa-face-sad-tear:before, .fa-sad-tear:before {
  content: "";
}

.fa-plane:before {
  content: "";
}

.fa-tent-arrows-down:before {
  content: "";
}

.fa-exclamation:before {
  content: "!";
}

.fa-arrows-spin:before {
  content: "";
}

.fa-face-smile-relaxed:before {
  content: "";
}

.fa-comment-xmark:before, .fa-comment-times:before {
  content: "";
}

.fa-print:before {
  content: "";
}

.fa-turkish-lira-sign:before, .fa-try:before, .fa-turkish-lira:before {
  content: "";
}

.fa-face-nose-steam:before {
  content: "";
}

.fa-circle-waveform-lines:before, .fa-waveform-circle:before {
  content: "";
}

.fa-dollar-sign:before, .fa-dollar:before, .fa-usd:before {
  content: "$";
}

.fa-ferris-wheel:before {
  content: "";
}

.fa-computer-speaker:before {
  content: "";
}

.fa-skull-cow:before {
  content: "";
}

.fa-x:before {
  content: "X";
}

.fa-magnifying-glass-dollar:before, .fa-search-dollar:before {
  content: "";
}

.fa-users-gear:before, .fa-users-cog:before {
  content: "";
}

.fa-person-military-pointing:before {
  content: "";
}

.fa-building-columns:before, .fa-bank:before, .fa-institution:before, .fa-museum:before, .fa-university:before {
  content: "";
}

.fa-circle-t:before {
  content: "";
}

.fa-sack:before {
  content: "";
}

.fa-grid-2:before {
  content: "";
}

.fa-camera-cctv:before, .fa-cctv:before {
  content: "";
}

.fa-umbrella:before {
  content: "";
}

.fa-trowel:before {
  content: "";
}

.fa-horizontal-rule:before {
  content: "";
}

.fa-bed-front:before, .fa-bed-alt:before {
  content: "";
}

.fa-d:before {
  content: "D";
}

.fa-stapler:before {
  content: "";
}

.fa-masks-theater:before, .fa-theater-masks:before {
  content: "";
}

.fa-kip-sign:before {
  content: "";
}

.fa-face-woozy:before {
  content: "";
}

.fa-cloud-question:before {
  content: "";
}

.fa-pineapple:before {
  content: "";
}

.fa-hand-point-left:before {
  content: "";
}

.fa-gallery-thumbnails:before {
  content: "";
}

.fa-circle-j:before {
  content: "";
}

.fa-eyes:before {
  content: "";
}

.fa-handshake-simple:before, .fa-handshake-alt:before {
  content: "";
}

.fa-page-caret-up:before, .fa-file-caret-up:before {
  content: "";
}

.fa-jet-fighter:before, .fa-fighter-jet:before {
  content: "";
}

.fa-comet:before {
  content: "";
}

.fa-square-share-nodes:before, .fa-share-alt-square:before {
  content: "";
}

.fa-shield-keyhole:before {
  content: "";
}

.fa-barcode:before {
  content: "";
}

.fa-plus-minus:before {
  content: "";
}

.fa-square-sliders-vertical:before, .fa-sliders-v-square:before {
  content: "";
}

.fa-video:before, .fa-video-camera:before {
  content: "";
}

.fa-message-middle:before, .fa-comment-middle-alt:before {
  content: "";
}

.fa-graduation-cap:before, .fa-mortar-board:before {
  content: "";
}

.fa-hand-holding-medical:before {
  content: "";
}

.fa-person-circle-check:before {
  content: "";
}

.fa-square-z:before {
  content: "";
}

.fa-message-text:before, .fa-comment-alt-text:before {
  content: "";
}

.fa-turn-up:before, .fa-level-up-alt:before {
  content: "";
}

.sr-only, .fa-sr-only, .sr-only-focusable:not(:focus), .fa-sr-only-focusable:not(:focus) {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-regular: normal 400 1em / 1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fa-regular-400.1d1bcff9.woff2") format("woff2"), url("../fa-regular-400.6d233f31.ttf") format("truetype");
}

.far, .fa-regular {
  font-weight: 400;
}

.ts-control {
  border: 1px solid var(--bs-border-color);
  z-index: 1;
  box-sizing: border-box;
  width: 100%;
  box-shadow: none;
  border-radius: var(--bs-border-radius);
  flex-wrap: wrap;
  padding: .375rem .75rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.ts-wrapper.multi.has-items .ts-control {
  padding: calc(.375rem - 1px - 0) .75rem calc(.375rem - 4px - 0);
}

.full .ts-control {
  background-color: var(--bs-body-bg);
}

.disabled .ts-control, .disabled .ts-control * {
  cursor: default !important;
}

.focus .ts-control {
  box-shadow: none;
}

.ts-control > * {
  vertical-align: baseline;
  display: inline-block;
}

.ts-wrapper.multi .ts-control > div {
  cursor: pointer;
  color: #343a40;
  background: #efefef;
  border: 0 solid #dee2e6;
  margin: 0 3px 3px 0;
  padding: 1px 5px;
}

.ts-wrapper.multi .ts-control > div.active {
  color: #fff;
  background: #0d6efd;
  border: 0 solid #0000;
}

.ts-wrapper.multi.disabled .ts-control > div, .ts-wrapper.multi.disabled .ts-control > div.active {
  color: #878787;
  background: #fff;
  border: 0 solid #fff;
}

.ts-control > input {
  flex: auto;
  min-width: 7rem;
  text-indent: 0 !important;
  max-width: 100% !important;
  min-height: 0 !important;
  max-height: none !important;
  line-height: inherit !important;
  -webkit-user-select: auto !important;
  user-select: auto !important;
  box-shadow: none !important;
  background: none !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  display: inline-block !important;
}

.ts-control > input::-ms-clear {
  display: none;
}

.ts-control > input:focus {
  outline: none !important;
}

.has-items .ts-control > input {
  margin: 0 4px !important;
}

.ts-control.rtl {
  text-align: right;
}

.ts-control.rtl.single .ts-control:after {
  left: calc(.75rem + 5px);
  right: auto;
}

.ts-control.rtl .ts-control > input {
  margin: 0 4px 0 -2px !important;
}

.disabled .ts-control {
  opacity: .5;
  background-color: var(--bs-secondary-bg);
}

.input-hidden .ts-control > input {
  opacity: 0;
  position: absolute;
  left: -10000px;
}

.ts-dropdown {
  z-index: 10;
  background: var(--bs-body-bg);
  box-sizing: border-box;
  border-radius: 0 0 var(--bs-border-radius) var(--bs-border-radius);
  border: 1px solid #d0d0d0;
  border-top: 0;
  width: 100%;
  margin: .25rem 0 0;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 1px 3px #0000001a;
}

.ts-dropdown [data-selectable] {
  cursor: pointer;
  overflow: hidden;
}

.ts-dropdown [data-selectable] .highlight {
  background: #ffed2866;
  border-radius: 1px;
}

.ts-dropdown .option, .ts-dropdown .optgroup-header, .ts-dropdown .no-results, .ts-dropdown .create {
  padding: 3px .75rem;
}

.ts-dropdown .option, .ts-dropdown [data-disabled], .ts-dropdown [data-disabled] [data-selectable].option {
  cursor: inherit;
  opacity: .5;
}

.ts-dropdown [data-selectable].option {
  opacity: 1;
  cursor: pointer;
}

.ts-dropdown .optgroup:first-child .optgroup-header {
  border-top: 0;
}

.ts-dropdown .optgroup-header {
  color: #6c757d;
  background: var(--bs-body-bg);
  cursor: default;
}

.ts-dropdown .active {
  background-color: var(--bs-tertiary-bg);
  color: var(--bs-body-color);
}

.ts-dropdown .active.create {
  color: var(--bs-body-color);
}

.ts-dropdown .create {
  color: #343a4080;
}

.ts-dropdown .spinner {
  width: 30px;
  height: 30px;
  margin: 3px .75rem;
  display: inline-block;
}

.ts-dropdown .spinner:after {
  content: " ";
  border: 5px solid #d0d0d0;
  border-color: #d0d0d0 #0000;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 3px;
  animation: 1.2s linear infinite lds-dual-ring;
  display: block;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.ts-dropdown-content {
  scroll-behavior: smooth;
  max-height: 200px;
  overflow: hidden auto;
}

.ts-wrapper.plugin-drag_drop .ts-dragging {
  color: #0000 !important;
}

.ts-wrapper.plugin-drag_drop .ts-dragging > * {
  visibility: hidden !important;
}

.plugin-checkbox_options:not(.rtl) .option input {
  margin-right: .5rem;
}

.plugin-checkbox_options.rtl .option input {
  margin-left: .5rem;
}

.plugin-clear_button {
  --ts-pr-clear-button: 1em;
}

.plugin-clear_button .clear-button {
  opacity: 0;
  cursor: pointer;
  transition: opacity .5s;
  position: absolute;
  top: 50%;
  right: calc(.75rem - 5px);
  transform: translateY(-50%);
  background: none !important;
  margin-right: 0 !important;
}

.plugin-clear_button.form-select .clear-button, .plugin-clear_button.single .clear-button {
  right: max(var(--ts-pr-caret), .75rem);
}

.plugin-clear_button.focus.has-items .clear-button, .plugin-clear_button:not(.disabled):hover.has-items .clear-button {
  opacity: 1;
}

.ts-wrapper .dropdown-header {
  background: color-mix(var(--bs-body-bg), #d0d0d0, 85%);
  border-radius: var(--bs-border-radius) var(--bs-border-radius) 0 0;
  border-bottom: 1px solid #d0d0d0;
  padding: 6px .75rem;
  position: relative;
}

.ts-wrapper .dropdown-header-close {
  color: #343a40;
  opacity: .4;
  margin-top: -12px;
  line-height: 20px;
  position: absolute;
  top: 50%;
  right: .75rem;
  font-size: 20px !important;
}

.ts-wrapper .dropdown-header-close:hover {
  color: #000;
}

.plugin-dropdown_input.focus.dropdown-active .ts-control {
  box-shadow: none;
  border: 1px solid var(--bs-border-color);
  box-shadow: var(--bs-box-shadow-inset);
}

.plugin-dropdown_input .dropdown-input {
  box-shadow: none;
  background: none;
  border: 0 solid #d0d0d0;
  border-bottom-width: 1px;
  width: 100%;
  padding: .375rem .75rem;
  display: block;
}

.plugin-dropdown_input.focus .ts-dropdown .dropdown-input {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem #0d6efd40;
}

.plugin-dropdown_input .items-placeholder {
  width: 100%;
  box-shadow: none !important;
  border: 0 !important;
}

.plugin-dropdown_input.has-items .items-placeholder, .plugin-dropdown_input.dropdown-active .items-placeholder {
  display: none !important;
}

.ts-wrapper.plugin-input_autogrow.has-items .ts-control > input {
  min-width: 0;
}

.ts-wrapper.plugin-input_autogrow.has-items.focus .ts-control > input {
  flex: none;
  min-width: 4px;
}

.ts-wrapper.plugin-input_autogrow.has-items.focus .ts-control > input::placeholder {
  color: #0000;
}

.ts-dropdown.plugin-optgroup_columns .ts-dropdown-content {
  display: flex;
}

.ts-dropdown.plugin-optgroup_columns .optgroup {
  border-top: 0;
  border-right: 1px solid #f2f2f2;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
}

.ts-dropdown.plugin-optgroup_columns .optgroup:last-child {
  border-right: 0;
}

.ts-dropdown.plugin-optgroup_columns .optgroup:before {
  display: none;
}

.ts-dropdown.plugin-optgroup_columns .optgroup-header {
  border-top: 0;
}

.ts-wrapper.plugin-remove_button .item {
  align-items: center;
  display: inline-flex;
}

.ts-wrapper.plugin-remove_button .item .remove {
  color: inherit;
  vertical-align: middle;
  box-sizing: border-box;
  border-radius: 0 2px 2px 0;
  padding: 0 5px;
  text-decoration: none;
  display: inline-block;
}

.ts-wrapper.plugin-remove_button .item .remove:hover {
  background: #0000000d;
}

.ts-wrapper.plugin-remove_button.disabled .item .remove:hover {
  background: none;
}

.ts-wrapper.plugin-remove_button .remove-single {
  font-size: 23px;
  position: absolute;
  top: 0;
  right: 0;
}

.ts-wrapper.plugin-remove_button:not(.rtl) .item {
  padding-right: 0 !important;
}

.ts-wrapper.plugin-remove_button:not(.rtl) .item .remove {
  border-left: 1px solid #dee2e6;
  margin-left: 5px;
}

.ts-wrapper.plugin-remove_button:not(.rtl) .item.active .remove {
  border-left-color: #0000;
}

.ts-wrapper.plugin-remove_button:not(.rtl).disabled .item .remove {
  border-left-color: #fff;
}

.ts-wrapper.plugin-remove_button.rtl .item {
  padding-left: 0 !important;
}

.ts-wrapper.plugin-remove_button.rtl .item .remove {
  border-right: 1px solid #dee2e6;
  margin-right: 5px;
}

.ts-wrapper.plugin-remove_button.rtl .item.active .remove {
  border-right-color: #0000;
}

.ts-wrapper.plugin-remove_button.rtl.disabled .item .remove {
  border-right-color: #fff;
}

:root {
  --ts-pr-clear-button: 0px;
  --ts-pr-caret: 0px;
  --ts-pr-min: .75rem;
}

.ts-wrapper.single .ts-control, .ts-wrapper.single .ts-control input {
  cursor: pointer;
}

.ts-control:not(.rtl) {
  padding-right: max(var(--ts-pr-min), var(--ts-pr-clear-button)  + var(--ts-pr-caret)) !important;
}

.ts-control.rtl {
  padding-left: max(var(--ts-pr-min), var(--ts-pr-clear-button)  + var(--ts-pr-caret)) !important;
}

.ts-wrapper {
  position: relative;
}

.ts-dropdown, .ts-control, .ts-control input {
  color: #343a40;
  font-family: inherit;
  font-size: inherit;
  line-height: 1.5;
}

.ts-control, .ts-wrapper.single.input-active .ts-control {
  background: var(--bs-body-bg);
  cursor: text;
}

.ts-hidden-accessible {
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.ts-dropdown, .ts-dropdown.form-control, .ts-dropdown.form-select {
  z-index: 1000;
  background: var(--bs-body-bg);
  border: 1px solid var(--bs-border-color-translucent);
  border-radius: .375rem;
  height: auto;
  padding: 0;
  box-shadow: 0 6px 12px #0000002d;
}

.ts-dropdown .optgroup-header {
  font-size: .875rem;
  line-height: 1.5;
}

.ts-dropdown .optgroup:first-child:before {
  display: none;
}

.ts-dropdown .optgroup:before {
  content: " ";
  border-top: 1px solid var(--bs-border-color-translucent);
  height: 0;
  margin: .5rem -.75rem;
  display: block;
  overflow: hidden;
}

.ts-dropdown .create {
  padding-left: .75rem;
}

.ts-dropdown-content {
  padding: 5px 0;
}

.ts-control {
  align-items: center;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: flex;
}

@media (prefers-reduced-motion: reduce) {
  .ts-control {
    transition: none;
  }
}

.focus .ts-control {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem #0d6efd40;
}

.ts-control .item {
  align-items: center;
  display: flex;
}

.ts-wrapper.is-invalid, .was-validated .invalid, .was-validated :invalid + .ts-wrapper {
  border-color: var(--bs-form-invalid-color);
}

.ts-wrapper.is-invalid:not(.single), .was-validated .invalid:not(.single), .was-validated :invalid + .ts-wrapper:not(.single) {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.ts-wrapper.is-invalid.single, .was-validated .invalid.single, .was-validated :invalid + .ts-wrapper.single {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
}

.ts-wrapper.is-invalid.focus .ts-control, .was-validated .invalid.focus .ts-control, .was-validated :invalid + .ts-wrapper.focus .ts-control {
  border-color: var(--bs-form-invalid-color);
  box-shadow: 0 0 0 .25rem rgba(var(--bs-form-invalid-color), .25);
}

.ts-wrapper.is-valid, .was-validated .valid, .was-validated :valid + .ts-wrapper {
  border-color: var(--bs-form-valid-color);
}

.ts-wrapper.is-valid:not(.single), .was-validated .valid:not(.single), .was-validated :valid + .ts-wrapper:not(.single) {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.ts-wrapper.is-valid.single, .was-validated .valid.single, .was-validated :valid + .ts-wrapper.single {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
}

.ts-wrapper.is-valid.focus .ts-control, .was-validated .valid.focus .ts-control, .was-validated :valid + .ts-wrapper.focus .ts-control {
  border-color: var(--bs-form-valid-color);
  box-shadow: 0 0 0 .25rem rgba(var(--bs-form-valid-color), .25);
}

.ts-wrapper {
  min-height: calc(1.5em + .75rem + calc(var(--bs-border-width) * 2));
  display: flex;
}

.input-group-sm > .ts-wrapper, .ts-wrapper.form-select-sm, .ts-wrapper.form-control-sm {
  min-height: calc(1.5em + .5rem + calc(var(--bs-border-width) * 2));
}

.input-group-sm > .ts-wrapper .ts-control, .ts-wrapper.form-select-sm .ts-control, .ts-wrapper.form-control-sm .ts-control {
  border-radius: var(--bs-border-radius-sm);
  font-size: .875rem;
}

.input-group-sm > .ts-wrapper.has-items .ts-control, .ts-wrapper.form-select-sm.has-items .ts-control, .ts-wrapper.form-control-sm.has-items .ts-control {
  padding-bottom: 0;
  font-size: .875rem;
}

.input-group-sm > .ts-wrapper.multi.has-items .ts-control, .ts-wrapper.form-select-sm.multi.has-items .ts-control, .ts-wrapper.form-control-sm.multi.has-items .ts-control {
  padding-top: calc((calc(1.5em + .5rem + calc(var(--bs-border-width) * 2))  - 1.5 * .875rem - calc((var(--bs-border-width)  + 1px) * 2)) / 2) !important;
}

.ts-wrapper.multi.has-items .ts-control {
  --ts-pr-min: calc(.75rem - 5px);
  padding-left: calc(.75rem - 5px);
}

.ts-wrapper.multi .ts-control > div {
  border-radius: calc(var(--bs-border-radius)  - 1px);
}

.input-group-lg > .ts-wrapper, .ts-wrapper.form-control-lg, .ts-wrapper.form-select-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.input-group-lg > .ts-wrapper .ts-control, .ts-wrapper.form-control-lg .ts-control, .ts-wrapper.form-select-lg .ts-control {
  border-radius: var(--bs-border-radius-lg);
  font-size: 1.25rem;
}

.ts-wrapper:not(.form-control, .form-select) {
  height: auto;
  box-shadow: none;
  background: none;
  border: none;
  padding: 0;
}

.ts-wrapper:not(.form-control, .form-select).single .ts-control {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
}

.ts-wrapper.form-select, .ts-wrapper.single {
  --ts-pr-caret: 2.25rem;
}

.ts-wrapper.form-control, .ts-wrapper.form-select {
  height: auto;
  box-shadow: none;
  display: flex;
  padding: 0 !important;
}

.ts-wrapper.form-control .ts-control, .ts-wrapper.form-control.single.input-active .ts-control, .ts-wrapper.form-select .ts-control, .ts-wrapper.form-select.single.input-active .ts-control {
  border: none !important;
}

.ts-wrapper.form-control:not(.disabled) .ts-control, .ts-wrapper.form-control:not(.disabled).single.input-active .ts-control, .ts-wrapper.form-select:not(.disabled) .ts-control, .ts-wrapper.form-select:not(.disabled).single.input-active .ts-control {
  background: none !important;
}

.input-group > .ts-wrapper {
  flex-grow: 1;
  width: 1%;
}

.input-group > .ts-wrapper:not(:nth-child(2)) > .ts-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .ts-wrapper:not(:last-child) > .ts-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.tox-tinymce-aux {
  z-index: 1050 !important;
}

.navbar {
  --tblr-navbar-active-bg: #ffffff0f;
}

.ts-wrapper {
  --bs-body-bg: var(--tblr-body-bg);
  --bs-tertiary-bg: var(--tblr-tertiary-bg);
  --bs-body-color: var(--tblr-body-color);
  --bs-border-radius: var(--tblr-border-radius);
  --bs-border-width: var(--tblr-border-width);
}
/*# sourceMappingURL=backend.css.map */
