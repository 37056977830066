@import 'npm:@tabler/core/dist/css/tabler.css';
@import 'npm:@fortawesome/fontawesome-pro/css/fontawesome.css';
@import 'npm:@fortawesome/fontawesome-pro/css/regular.css';
@import 'npm:tom-select/dist/css/tom-select.bootstrap5.css';

.tox-tinymce-aux {
  z-index: 1050 !important;
}

.navbar {
  --tblr-navbar-active-bg: #ffffff0f;
}

.ts-wrapper {
  --bs-body-bg: var(--tblr-body-bg);
  --bs-tertiary-bg: var(--tblr-tertiary-bg);
  --bs-body-color: var(--tblr-body-color);
  --bs-border-radius: var(--tblr-border-radius);
  --bs-border-width: var(--tblr-border-width);

}
